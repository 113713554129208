<div class="d-flex flex-wrap flex-stack mb-3">
    <h1 class="fw-bolder my-2"> </h1>

        <div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
            title="Click to go back">
            <a (click)="onBack()" class="btn btn-sm btn-primary d-inline-flex align-items-center p-4">
                <i class="fa fa-arrow-left me-1" aria-hidden="true"></i>
                Back
            </a>
        </div>

</div>

<div class="card mb-3">
    <div class="card-body p-5">
        <div class="row g-5 g-xxl-8">
            <form action="#" class="form form-label-right" [formGroup]="notificationTemplateForm">
                <div class="form-group row">
                    <!-- Notification Subject -->
                    <div class="col-md-12 col-lg-6 col-xl-4 mb-5">
                        <label class="form-label">Notification Subject</label>
                        <span class="required-asterisk">*</span>
                        <textarea rows="2" type="text" class="form-control form-control-lg form-control-solid" name="subject"
                            placeholder="Enter notification subject" autocomplete="off"
                            [class.is-invalid]="isControlInvalid('subject')" [class.is-valid]="isControlValid('subject')"
                            formControlName="subject" ></textarea>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'subject') ">
                            Notification Subject is required.
                        </div>
                    </div>


                    <!-- Description -->
                    <div class="col-md-12 col-lg-6 col-xl-4 mb-5">
                        <label class="form-label">Notification Description</label>
                        <span class="required-asterisk">*</span>
                        <textarea rows="7" class="form-control form-control-lg form-control-solid" name="description"
                            placeholder="Enter notification description" autocomplete="off"
                            [class.is-invalid]="isControlInvalid('body')"
                            [class.is-valid]="isControlValid('body')" formControlName="body" ></textarea>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'body')">
                            Notification Description is required.
                        </div>
                    </div>

                    <!-- Recipient Selection -->
                    <div class="col-md-12 col-lg-6 col-xl-4 mb-5">
                        <label class="form-label">Select Notification Recipients</label>
                        <span class="required-asterisk">*</span>
                        <ng-select class="p-0"
                            [class.is-invalid]="isControlInvalid('recepients')"
                            [class.is-valid]="isControlValid('recepients')" [searchable]="true" [clearable]="true" [multiple]="true"
                            labelForId="heroId" formControlName="recepients" (search)=onSearch($event)>
                            <ng-option *ngFor="let recipient of recipientsList" [value]="recipient._id">
                                {{recipient.fname }}
                            </ng-option>
                        </ng-select>
                         <div class="valid-feedback" *ngIf="isControlValid('recepients')">

                        </div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'recepients')">
                            Please select the recipients.
                        </div>
                    </div>


                </div>
            </form>
        </div>
    </div>
    <div class="card-footer p-5">
        <!-- Save -->
        <button  type="submit"
            class="btn btn-primary mr-2 ml-2" (click)="addNotificationTemplate()"><span [inlineSVG]="'./assets/media/icons/normal/check.svg'" class="svg-icon svg-icon-4 me-1"></span>Save</button>
        <!-- Update -->
        <button  type="submit"
            class="btn btn-primary mr-2 ml-2" (click)="addNotificationTemplate()"><span [inlineSVG]="'./assets/media/icons/normal/paper-plane.svg'" class="svg-icon svg-icon-4 me-1"></span> Save & Send</button>
        <button  type="cancel" class="btn btn-primary mr-2" (click)="resetForm()"><span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-4 me-1"></span> Cancel</button>

    </div>
</div>
