import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConst } from '../shared/constants/app.constant';

const BACKEND_URL = environment.apiUrl + "/partnerPanel/usersPanel";


@Injectable({
  providedIn: 'root'
})
export class PartnerUserManagementService {

  mainId = localStorage.getItem('mainId');
  loginId = localStorage.getItem('ID');

  constructor(private http: HttpClient) { }

  getAllUsers(
    status: string,
    search: string,
    page: string,
    limit: any,
    name: any,
    sort: any
  ) {
    let loginId = this.loginId;
    if (!loginId) {
      let userIds = JSON.parse(localStorage.getItem('userIds') || '')
      loginId = userIds[userIds.length - 1];
    }
    let params = new HttpParams()
      .set('status', status ? status : 'true')
      .set('fname', search ? search : '')
      .set('page', page)
      .set('pageSize', limit)
      .set('name', name ? name : 'createdAt')
      .set('sort', sort ? sort : -1)
    return this.http.get<any>(BACKEND_URL + "/userlist/" + this.mainId + "/" + loginId, { params });

  }

  selectOrganizations() {
    let loginId = this.loginId;
    let url = `${environment.apiUrl}/partnerPanel/organization/selectOrganization/${this.mainId}/${loginId}`
    return this.http.get<any>(url);
  }

  selectGroups() {
    let url = environment.apiUrl + `/partnerPanel/groupPermissionPanel/selectgroup/${this.mainId}`;
    return this.http.get<any>(url);
  }

  getUserDetailsByID(id: any) {
    return this.http.get<any>(`${BACKEND_URL}/getUser/${id}/${this.mainId}`);

  }

  updateUser(id: any, body: any) {
    return this.http.put<any>(`${BACKEND_URL}/updateUser/${id}/${this.mainId}`, body);
  }

  addNewUser(body: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(BACKEND_URL + "/addUser/" + this.mainId, body);
  }

  suspendUser(id: any) {
    return this.http.put<any>(`${BACKEND_URL}/disable/${id}/${this.mainId}`, {});
  }

  deleteUser(id: any) {
    return this.http.delete<any>(`${BACKEND_URL}/delete/${id}/${this.mainId}`, {});
  }

  selectOrganizationsByUserId(id: any) {
    return this.http.get<any>(`${BACKEND_URL}/getUser/${id}/${this.mainId}`);
  }

}
