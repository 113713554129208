import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedSdwanWanAddGatewayComponent } from '../../shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-add-gateway/shared-sdwan-wan-add-gateway.component';
import { SharedSdwanWanAddProtocolComponent } from '../../shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-add-protocol/shared-sdwan-wan-add-protocol.component';
import { SharedSdwanWanAddVlanComponent } from '../../shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-add-vlan/shared-sdwan-wan-add-vlan.component';
import { SharedSdwanWanDeleteComponent } from '../../shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-delete/shared-sdwan-wan-delete.component';
import { SharedSpaceIpAddComponent } from '../../shared-space-create/shared-space-ip/shared-space-ip-add/shared-space-ip-add.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-shared-sdwan-wan',
  templateUrl: './shared-sdwan-wan.component.html',
  styleUrls: ['./shared-sdwan-wan.component.scss']
})
export class SharedSdwanWanComponent implements OnInit {
  apiURL: any;
  selectedTab: any = 1;
  parentId: any;
  groups: any = [];
  aggregatorList: any = [];
  @Input() vlanAssignmentsList: any[] = [];
  @Input() protocolsList: any[] = [];
  @Input() filtersList: any[] = [];
  @Input() gatewayAggList: any[] = [];

  aFrm1: any[] = [];
  aFrm2: any[] = [];
  aFrm3: any[] = [];
  aFrm4: any[] = [];
  aFrm5: any[] = [];
  wFrm1 = false;
  wFrm2 = false;
  wFrm3 = false;
  wFrm4 = false;
  wFrm5 = false;
  errors: any = {};
  constructor(private modalService: NgbModal,
    private sharedService: SharedService,
    private cd: ChangeDetectorRef, private cookie: CookieService,
    private spaceService: PartnerSpaceService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.apiURL = this.cookie.get('api_url');
    this.spaceService.spaceParentId$.subscribe(
      (res: any) => {
        if (res) {
          this.parentId = res.parentId
        }
        this.cd.detectChanges();
      }
    );

    // this.groupList();
    // this.aggregatorlist();
    this.getSpaceList();
    this.getWanList();

    // this.wanForm1 = this.createAddressOptionForm(this.legsData);
    this.wanForm1 = this.createAddressOptionForm();
    this.wanForm2 = this.createAddressOptionForm();
    this.wanForm3 = this.createAddressOptionForm();
    this.nttForm = this.createAddressOptionForm();
    this.prtForm = this.createAddressOptionForm();

    this.wanForm1.valueChanges.subscribe(x => {
      // console.log('form value changed')
      // console.log(x)
      this.wFrm1 = true;
      this.aFrm1 = x.data;
    })
    this.wanForm2.valueChanges.subscribe(x => {
      this.wFrm2 = true;
      this.aFrm2 = x.data;
    })
    this.wanForm3.valueChanges.subscribe(x => {
      this.wFrm3 = true;
      this.aFrm3 = x.data;
    })
    this.nttForm.valueChanges.subscribe(x => {
      this.wFrm4 = true;
      this.aFrm4 = x.data;
    })
    this.prtForm.valueChanges.subscribe(x => {
      this.wFrm5 = true;
      this.aFrm5 = x.data;
    })
  }
  groupList() {
    let reqObj = {
      method: "GET",
      url: `routing_groups/?search=&ordering=name&page=1&page_size=100&space=__current__`,
      data: {}
    }
    this.spaceService.generalSpaceApi(reqObj).subscribe((groups) => {
      console.log("🚀 ~ file: ============================================================================= groups", groups)
      if (groups.code == 200) {
        this.groups = groups.data;
      }
    })
  }
  aggregatorlist() {
    let reqObj = {
      method: "GET",
      url: `aggregators/#/?search=&ordering=name,&page=1&page_size=100&space__under=__current__`
    }
    this.spaceService.generalSpaceApi(reqObj).subscribe((aggregator) => {

      if (aggregator.code == 200) {
        this.aggregatorList = aggregator.data;
      }
    })
  }
  onTabChange(tabNumber: number) {
    this.selectedTab = tabNumber;
  }
  checkValidationAdd(instance: any) {
    let IpObject = {
      method: "POST",
      url: `spaces/${this.parentId}/private_wan/space_routing_group_vlan_assignments/`,
      data: instance.body
    }
    let noError: boolean = false;
    this.cd.detectChanges();
    this.sharedService.showLoader();
    this.spaceService.addVlanData(IpObject).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.spaceService.successForVlan.next({ success: true })
        this.sharedService.loggerSuccess(addRes.message);
        this.sharedService.hideLoader();
        noError = true;
        this.cd.detectChanges();
      } else {
        this.sharedService.loggerError(addRes.message);
      }

    },
      (err) => {
        try {
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          instance.errors = {
            ...instance.errors,
            //...JSON.parse(err)
          };
          this.spaceService.successForVlan.next({ success: false })
          Object.keys(instance.errors).forEach(x => {
            let vlanFormControl = instance.vlanForm.get(x);
            if (vlanFormControl) {
              instance.vlanForm.setErrors({ ...instance.vlanForm.errors, [x]: instance.errors[x] })
            }
          })
          this.sharedService.hideLoader();
          this.cd.detectChanges();
          noError = false;
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    return noError;
  }
  addVlan() {
    let modal = this.modalService.open(SharedSdwanWanAddVlanComponent,
      { size: 'lg', beforeDismiss: () => this.checkValidationAdd(modal.componentInstance) });
    modal.componentInstance.groupList = this.groups;
  }
  deleteVlan(data: any) {
    console.log("delete vlan")
    let modal = this.modalService.open(SharedSdwanWanDeleteComponent,
      { size: 'lg' });
    modal.componentInstance.vlanData = data;
    modal.componentInstance.parentId = this.parentId;

  }
  checkValidationEdit(instance: any, vlanData: any) {
    let noError: boolean = false;
    this.cd.detectChanges();
    let IpObject = {
      method: "PATCH",
      url: `spaces/${this.parentId}/private_wan/space_routing_group_vlan_assignments/${vlanData.id}/`,
      data: instance.body
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(IpObject).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.spaceService.successForVlan.next({ success: true })
        this.sharedService.loggerSuccess(addRes.message);
        this.sharedService.hideLoader();
        noError = true;
        this.cd.detectChanges();
      } else {
        this.sharedService.loggerError(addRes.message);
      }

    },
      (err) => {
        try {
          console.log("🚀 ~ ==================== ~ err", err)
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          instance.errors = err;
          instance.errors = {
            ...instance.errors,
            ...JSON.parse(err)
          };
          this.spaceService.successForVlan.next({ success: false })
          Object.keys(instance.errors).forEach(x => {
            let vlanFormControl = instance.vlanForm.get(x);
            if (vlanFormControl) {
              instance.vlanForm.setErrors({ ...instance.vlanForm.errors, [x]: instance.errors[x] })
            }
          })
          this.sharedService.hideLoader();
          this.cd.detectChanges();
          noError = false;
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    return noError;
  }
  editVlan(data: any) {
    console.log("edit vlan")
    let modal = this.modalService.open(SharedSdwanWanAddVlanComponent,
      { size: 'lg', beforeDismiss: () => this.checkValidationEdit(modal.componentInstance, data) });
    modal.componentInstance.vlanData = data;
    modal.componentInstance.edit = true;
    modal.componentInstance.groupList = this.groups;

  }

  addProtocol() {
    let modal = this.modalService.open(SharedSdwanWanAddProtocolComponent,
      { size: 'lg' });
  }
  deleteProtocol(data: any) {
    let modal = this.modalService.open(SharedSdwanWanDeleteComponent,
      { size: 'lg' });
  }
  addFilter() {
    let modal = this.modalService.open(SharedSpaceIpAddComponent,
      { size: 'lg' });
  }
  deleteFilter(data: any) {
    let modal = this.modalService.open(SharedSdwanWanDeleteComponent,
      { size: 'lg' });
  }
  checkValidationGatewayAdd(instance: any) {
    let IpObject = {
      method: "POST",
      url: `spaces/${this.parentId}/private_wan/managed_mesh_gateway_aggregators/`,
      data: instance.body
    }
    let noError: boolean = false;
    this.cd.detectChanges();
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(IpObject).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.spaceService.successForGateway.next({ success: true })
        this.sharedService.loggerSuccess(addRes.message);
        this.sharedService.hideLoader();
        noError = true;
        this.cd.detectChanges();
      } else {
        this.sharedService.loggerError(addRes.message);
      }

    },
      (err) => {
        try {
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          instance.errors = err;
          instance.errors = {
            ...instance.errors,
            ...JSON.parse(err)
          };
          this.spaceService.successForGateway.next({ success: false })
          Object.keys(instance.errors).forEach(x => {
            let gatewayFormControl = instance.gatewayForm.get(x);
            if (gatewayFormControl) {
              instance.gatewayForm.setErrors({ ...instance.gatewayForm.errors, [x]: instance.errors[x] })
              this.cd.detectChanges()
            }
          })
          this.sharedService.hideLoader();
          this.cd.detectChanges();
          noError = false;
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    return noError;
  }
  checkValidationGatewayEdit(instance: any, gatewaydata: any) {
    let noError: boolean = false;
    this.cd.detectChanges();
    let IpObject = {
      method: "PATCH",
      url: `spaces/${this.parentId}/private_wan/managed_mesh_gateway_aggregators/${gatewaydata.id}/`,
      data: instance.bodyhttps
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(IpObject).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.spaceService.successForGateway.next({ success: true })
        this.sharedService.loggerSuccess(addRes.message);
        this.sharedService.hideLoader();
        noError = true;
        this.cd.detectChanges();
      } else {
        this.sharedService.loggerError(addRes.message);
      }

    },
      (err) => {
        try {
          console.log("🚀 ~ ==================== ~ err", err)
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          instance.errors = err;
          instance.errors = {
            ...instance.errors,
            ...JSON.parse(err)
          };
          this.spaceService.successForGateway.next({ success: false })
          Object.keys(instance.errors).forEach(x => {
            let gatewayFormControl = instance.gatewayForm.get(x);
            if (gatewayFormControl) {
              instance.gatewayForm.setErrors({ ...instance.gatewayForm.errors, [x]: instance.errors[x] })
            }
          })
          this.sharedService.hideLoader();
          this.cd.detectChanges();
          noError = false;
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    return noError;
  }
  addGateway() {
    let modal = this.modalService.open(SharedSdwanWanAddGatewayComponent,
      { size: 'lg', beforeDismiss: () => this.checkValidationGatewayAdd(modal.componentInstance) });
    modal.componentInstance.groupList = this.groups;
    modal.componentInstance.parentId = this.parentId;
    modal.componentInstance.aggregatorList = this.aggregatorList;
  }
  editGateway(data: any) {
    let modal = this.modalService.open(SharedSdwanWanAddGatewayComponent,
      { size: 'lg', beforeDismiss: () => this.checkValidationGatewayEdit(modal.componentInstance, data) });
    modal.componentInstance.groupList = this.groups;
    modal.componentInstance.gatewayData = data;
    modal.componentInstance.edit = true;
    modal.componentInstance.parentId = this.parentId;
    modal.componentInstance.aggregator = this.aggregatorList;
  }
  deleteGatway(data: any) {
    let modal = this.modalService.open(SharedSdwanWanDeleteComponent,
      { size: 'lg' });
  }


  // errors: any;
  addGatewayR = "-1";

  isDivVisible: any = false;
  noLegsConfigured: any = true;

  wanForm1!: FormGroup;
  wanForm2!: FormGroup;
  wanForm3!: FormGroup;
  nttForm!: FormGroup;
  prtForm!: FormGroup;
  get wanData1(): FormArray {
    return this.wanForm1.get("data") as FormArray
  }
  get wanData2(): FormArray {
    return this.wanForm2.get("data") as FormArray
  }
  get wanData3(): FormArray {
    return this.wanForm3.get("data") as FormArray
  }
  get nttData(): FormArray {
    return this.nttForm.get("data") as FormArray
  }
  get prtData(): FormArray {
    return this.prtForm.get("data") as FormArray
  }

  routingGrps: any[] = [];
  routingData: any[] = [];
  getSpaceList() {
    let reqObj = { "url": `spaces/${this.parentId}`, "method": "GET" }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.routingData = addRes.data;
        this.routingGrps = addRes.data.valid_routing_groups;
        this.cd.detectChanges();
      }
    })
  }
  pWanList: any = [];
  pWanEnbl = false;
  pWanMode = "";
  pWanMesh = false;

  wanNatList: any[] = [];
  wanNatUrl = "";
  wanPwnList: any[] = [];
  wanBndList: any[] = [];
  wanNttList: any[] = [];
  wanNttUrl = "";
  wanPrtList: any[] = [];
  wanPrtUrl = "";
  getWanList() {
    this.pWanList = [];
    let reqObj = { "url": `spaces/${this.parentId}/private_wan/`, "method": "GET" }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.pWanList = addRes.data;
        this.pWanEnbl = this.pWanList.enabled;
        this.pWanMode = this.pWanList.mode;
        this.pWanMesh = this.pWanList.managed_mesh_include_aggregator_interfaces;
        this.wanNatUrl = addRes.data.outbound_gateways;
        this.wanNatList = addRes.data.outbound_gateways_list.filter((o: any) => {
          return o.mode == "nat-via-pwan";
        });
        this.wanPwnList = addRes.data.outbound_gateways_list.filter((o: any) => {
          return o.mode == "gateway-via-pwan";
        });
        this.wanBndList = addRes.data.outbound_gateways_list.filter((o: any) => {
          return o.mode == "gateway-via-bonder";
        });

        this.wanNttUrl = addRes.data.nat_rules;
        this.wanNttList = addRes.data.nat_rules_list;

        this.wanPrtUrl = addRes.data.port_forward_rules;
        this.wanPrtList = addRes.data.port_forward_rules_list;

        if (this.wanNatList.length > 0)
          this.addedGatewayRouter('1', this.wanNatList);
        if (this.wanPwnList.length > 0)
          this.addedGatewayRouter('2', this.wanPwnList);
        if (this.wanBndList.length > 0)
          this.addedGatewayRouter('3', this.wanBndList);
        if (this.wanNttList.length > 0)
          this.addedGatewayRouter('4', this.wanNttList);
        if (this.wanPrtList.length > 0)
          this.addedGatewayRouter('5', this.wanPrtList);

        this.cd.detectChanges();
      }

    })
  }
  addedGatewayRouter(TYP: string, data: any) {
    this.noLegsConfigured = false;
    this.isDivVisible = true;

    if (TYP == '1') {
      for (let d of data)
        this.wanData1.push(this.newGroup1(d, true));
      this.wFrm1 = false;
    }
    else if (TYP == '2') {
      for (let d of data)
        this.wanData2.push(this.newGroup2(d, true));
      this.wFrm2 = false;
    }
    else if (TYP == '3') {
      for (let d of data)
        this.wanData3.push(this.newGroup3(d, true));
      this.wFrm3 = false;
    }
    else if (TYP == '4') {
      for (let d of data)
        this.nttData.push(this.nttGroup(d, true));
      this.wFrm4 = false;
    }
    else if (TYP == '5') {
      for (let d of data) {
        this.prtData.push(this.prtGroup(d, true));
        setTimeout(() => {
          this.isPrtProtocol(this.prtData.length - 1);
          this.wFrm5 = false;
        }, 200);
      }

    }
  }
  addGatewayRouterChange() {
    if (this.addGatewayR == "-1")
      return false;

    this.noLegsConfigured = false;
    this.isDivVisible = true;

    if (this.addGatewayR == '1')
      this.wanData1.push(this.newGroup1([], true));
    else if (this.addGatewayR == '2')
      this.wanData2.push(this.newGroup2([], true));
    else if (this.addGatewayR == '3')
      this.wanData3.push(this.newGroup3([], true));

    setTimeout(() => {
      this.addGatewayR = '-1';
    }, 100);
  }
  addNttForm() {
    this.nttData.push(this.nttGroup([], true));
  }
  addPrtForm() {
    this.prtData.push(this.prtGroup([], true));
    setTimeout(() => {
      this.isPrtProtocol(this.prtData.length - 1);
    }, 200);
  }

  toggle1: any = [];
  toggle2: any = [];
  toggle3: any = [];
  toggle4: any = [];
  toggle5: any = [];
  onToggle1(index: number) {
    // if (!this.wanData1.controls[index].get('isNew')?.value) { 
    let control = this.wanData1.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)
    // }
    // else{
    //   let val = data.controls['dropDownOption'].value;
    // this.wanData1.removeAt(index);

    if (this.wanData1.controls[index].get('isDeleted')?.value == true) {
      this.wanData1.controls[index].disable();
      this.toggle1[index] = true;
    }
    else {
      this.wanData1.controls[index].enable();
      this.toggle1[index] = false;
    }

    // this.wanData1.controls.isDeleted.value = true;
    //   if(this.wanData1.controls.length==0){
    //     this.noLegsConfigured=true;
    //   }
    //   if (val == '3') {
    //   return;
    // }
    setTimeout(() => {
      // for (let i = 0; i < this.dropDownArray.length; i++) {
      //   this.dropDownArray[i].push(this.rowDropDownArray.find((a: any) => a.value == val))
      // }
      this.cd.detectChanges();
    });
    // }

    //  // let val = data.controls['dropDownOption'].value;
    //this.data.removeAt(index);
    // if (val == '3') {
    //   return;
    // }
    // setTimeout(() => {
    //   for (let i = 0; i < this.dropDownArray.length; i++) {
    //     this.dropDownArray[i].push(this.rowDropDownArray.find((a: any) => a.value == val))
    //   }
    //   this.cd.detectChanges();
    // });
    // if (this.data.controls[index].get('isNew')?.value) {
    // }
    // else {
    //   let control = this.data.controls[index].get('isDeleted');
    //   control?.patchValue(!control?.value)
    // }
  }
  onToggle2(index: number) {
    let control = this.wanData2.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)

    if (this.wanData2.controls[index].get('isDeleted')?.value == true) {
      this.wanData2.controls[index].disable();
      this.toggle2[index] = true;
    }
    else {
      this.toggle2[index] = false;
      this.wanData2.controls[index].enable();
    }

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }
  onToggle3(index: number) {
    let control = this.wanData3.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)

    if (this.wanData3.controls[index].get('isDeleted')?.value == true) {
      this.wanData3.controls[index].disable();
      this.toggle3[index] = true;
    }
    else {
      this.wanData3.controls[index].enable();
      this.toggle3[index] = false;
    }

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }
  onNttToggle(index: number) {
    let control = this.nttData.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)

    if (this.nttData.controls[index].get('isDeleted')?.value == true) {
      this.nttData.controls[index].disable();
      this.toggle4[index] = true;
    }
    else {
      this.nttData.controls[index].enable();
      this.toggle4[index] = false;
    }

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }
  onPrtToggle(index: number) {
    let control = this.prtData.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)

    if (this.prtData.controls[index].get('isDeleted')?.value == true) {
      this.prtData.controls[index].disable();
      this.toggle5[index] = true;
    }
    else {
      this.prtData.controls[index].enable();
      this.toggle5[index] = false;
      this.isPrtProtocol(index);
    }

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }
  createAddressOptionForm(data: any = {}) {
    return this.fb.group({
      data: this.fb.array([])
    })
  }

  newGroup1(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    // let selectedValues = this.wanData1.controls.map(x => x.value.dropDownOption).filter(x => x == '4');
    // console.log(selectedValues);
    // let selectedValues = this.wanData1.controls.map(x => x.value.dropDownOption).filter(x => x != '3');
    // let dropdown = this.spaceList.filter((x: any) => !selectedValues.includes(x.value));
    // this.dropDownArray.push(dropdown);
    // let length = this.wanData1.length;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list)
        dropDownArray.push(drp.routing_group);

      let group = this.fb.group({
        natenbl: [data.enabled],
        natip: [data.ip],
        natrouting: this.fb.array(dropDownArray),
        natnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [false],
        id: [data.id],
        // idx: [this.wanData1.length]
      });
      this.toggle1.push(false);

      return group;
    }
    else {
      let group = this.fb.group({
        // dropDownOption: [data.dropDownOption || dropdown[0]?.value],
        natenbl: [true],
        natip: [''],
        natrouting: null,
        natnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true],
        // id: [data.id || 0],
        // idx: [this.wanData1.length]
      });
      this.toggle1.push(false);

      return group;
    }

    // group.get('dropDownOption')?.valueChanges.subscribe((value: any) => {
    //   this.onOptionSelectionChange(value, length);
    // })
    // this.onOptionSelectionChange(dropdown[0]?.value, length);
    // return group;
  }
  newGroup2(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list)
        dropDownArray.push(drp.routing_group);

      let group = this.fb.group({
        pwnenbl: [data.enabled],
        pwnip: [data.ip],
        pwngate: [data.gateway],
        pwnip6: [data.ipv6],
        pwnip6gate: [data.gateway6],
        pwnvlan: [data.vlan],
        pwnrouting: this.fb.array(dropDownArray),
        pwnnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        id: [data.id],
        isNew: [false]
      });
      this.toggle2.push(false);
      return group;
    }
    else {
      let group = this.fb.group({
        pwnenbl: [true],
        pwnip: [''],
        pwngate: [''],
        pwnip6: [''],
        pwnip6gate: [''],
        pwnvlan: [''],
        pwnrouting: null,
        pwnnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true]
      });
      this.toggle2.push(false);
      return group;
    }
  }
  newGroup3(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list)
        dropDownArray.push(drp.routing_group);

      let group = this.fb.group({
        bndenbl: [data.enabled],
        bndgate: [data.gateway],
        bndip6gate: [data.gateway6],
        bndrouting: this.fb.array(dropDownArray),
        bndnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        id: [data.id],
        isNew: [false]
      });
      this.toggle3.push(false);
      return group;
    }
    else {
      let group = this.fb.group({
        bndenbl: [true],
        bndgate: [''],
        bndip6gate: [''],
        bndrouting: null,
        bndnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true]
      });
      this.toggle3.push(false);
      return group;
    }
  }
  nttGroup(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list)
        dropDownArray.push(drp.routing_group);

      let group = this.fb.group({
        nttenbl: [data.enabled],
        nttpubip: [data.public_ip],
        nttpriip: [data.private_ip],
        nttrouting: this.fb.array(dropDownArray),
        nttnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: false,
        id: [data.id],
        isNew: [false]
      });
      this.toggle4.push(false);
      return group;
    }
    else {
      let group = this.fb.group({
        nttenbl: [true],
        nttpubip: [''],
        nttpriip: [''],
        nttrouting: null,
        nttnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true]
      });
      this.toggle4.push(false);
      return group;
    }
  }
  prtGroup(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list)
        dropDownArray.push(drp.routing_group);

      let group = this.fb.group({
        prtenbl: [data.enabled],
        prtprotocol: [data.protocol],
        prtpubip: [data.public_ip],
        prtpubport: [data.public_port],
        prtpriip: [data.private_ip],
        prtpriport: [data.private_port],
        prtrouting: this.fb.array(dropDownArray),
        prthelper: [data.helper],
        prtnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        id: [data.id],
        isNew: [false]
      });
      this.toggle5.push(false);
      return group;
    }
    else {
      let group = this.fb.group({
        prtenbl: [true],
        prtprotocol: ['ICMP'],
        prtpubip: [''],
        prtpubport: [''],
        prtpriip: [''],
        prtpriport: [''],
        prtrouting: null,
        prthelper: [''],
        prtnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true]
      });
      this.toggle5.push(false);
      return group;
    }
  }
  // onOptionSelectionChange(value: any, idx: any) {
  //   setTimeout(() => {
  //     let selectedValues = this.wanData1.controls.map(x => x.value.dropDownOption).filter(x => x != '3');
  //     for (let i = 0; i < this.dropDownArray.length; i++) {
  //       this.dropDownArray[i] = this.rowDropDownArray.filter((x: any) => !selectedValues.includes(x.value));

  //       if (i == idx && value != '3') {
  //         this.dropDownArray[i].unshift(this.rowDropDownArray.find((a: any) => a.value == value))
  //       }
  //     }
  //     this.cd.detectChanges();
  //   });
  // }
  isPrtProtocol(i: number) {
    let prt = this.prtData.controls[i].get('prtprotocol')?.value;
    if (prt == 'TCP' || prt == 'UDP' || prt == 'SCTP') {
      document.getElementById('prtpubport' + i)?.removeAttribute('disabled');
      document.getElementById('prtpriport' + i)?.removeAttribute('disabled');
    }
    else {
      document.getElementById('prtpubport' + i)?.setAttribute('disabled', 'true');
      document.getElementById('prtpriport' + i)?.setAttribute('disabled', 'true');
    }
  }

  respForm1: any[] = [];
  respForm2: any[] = [];
  respForm3: any[] = [];
  respForm4: any[] = [];
  respForm5: any[] = [];
  aRoute1: any[] = [];
  aRoute2: any[] = [];
  aRoute3: any[] = [];
  aRoute4: any[] = [];
  aRoute5: any[] = [];
  updateAllRouting() {
    if (this.wFrm1 == true) {
      this.respForm1 = [];
      this.aRoute1 = [];

      let dta: any = this.wanForm1.controls.data;
      let dtalen = dta.controls.length;
      let del = 0;
      for (let dt of dta.controls) {
        if (dt.value.isDeleted == true && dt.value.isNew == false) {
          this.deleteRouting('1', this.wanNatUrl, dt.value.id, del);
        }
        del++;

        if (del == dtalen) {
          this.doUpdatesForm1();
        }
      }
    }

    if (this.wFrm2 == true) {
      this.respForm2 = [];
      this.aRoute2 = [];

      let dta: any = this.wanForm2.controls.data;
      let dtalen = dta.controls.length;
      let del = 0;
      for (let dt of dta.controls) {
        if (dt.value.isDeleted == true && dt.value.isNew == false) {
          this.deleteRouting('2', this.wanNatUrl, dt.value.id, del);
        }
        del++;

        if (del == dtalen) {
          this.doUpdatesForm2();
        }
      }
    }

    if (this.wFrm3 == true) {
      this.respForm3 = [];
      this.aRoute3 = [];

      let dta: any = this.wanForm3.controls.data;
      let dtalen = dta.controls.length;
      let del = 0;
      for (let dt of dta.controls) {
        if (dt.value.isDeleted == true && dt.value.isNew == false) {
          this.deleteRouting('3', this.wanNatUrl, dt.value.id, del);
        }
        del++;

        if (del == dtalen) {
          this.doUpdatesForm3();
        }
      }
    }

    if (this.wFrm4 == true) {
      this.respForm4 = [];
      this.aRoute4 = [];

      let dta: any = this.nttForm.controls.data;
      let dtalen = dta.controls.length;
      let del = 0;
      for (let dt of dta.controls) {
        if (dt.value.isDeleted == true && dt.value.isNew == false) {
          this.deleteRouting('4', this.wanNttUrl, dt.value.id, del);
        }
        del++;

        if (del == dtalen) {
          this.doUpdatesForm4();
        }
      }
    }

    if (this.wFrm5 == true) {
      this.respForm5 = [];
      this.aRoute5 = [];

      let dta: any = this.prtForm.controls.data;
      let dtalen = dta.controls.length;
      let del = 0;
      for (let dt of dta.controls) {
        if (dt.value.isDeleted == true && dt.value.isNew == false) {
          this.deleteRouting('5', this.wanPrtUrl, dt.value.id, del);
        }
        del++;

        if (del == dtalen) {
          this.doUpdatesForm5();
        }
      }
    }
  }
  doUpdatesForm1() {
    let f = 0;
    for (let fdt of this.aFrm1) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];

      for (let v of this.routingGrps) {
        v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
        mv_rout.push({ "routing_group": v.url, "display_name": v.display_name });
      }
      let rcnt = 0;
      for (let r of fdt.natrouting) {
        let frouting: any = v_rout.filter((fr: any) => {
          return fr.routing_group == r;
        });
        if (frouting.length > 0) {
          a_rout.push(frouting[0]);
          this.aRoute1.push(frouting[0]);
          mv_rout[rcnt]['url'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
          mv_rout[rcnt]['name'] = this.routingGrps[rcnt]['name'];

          ma_rout.push(mv_rout[rcnt]);
        }
        rcnt++;
      }

      // console.log("ROUTINGS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log(this.routingData);
      // console.log(this.routingGrps);
      // console.log(this.wanNatList);
      // console.log(v_rout);
      // console.log(a_rout);
      // console.log(mv_rout);
      // console.log(ma_rout);

      if (fdt.isNew == true) {
        this.addRouting1(fdt, v_rout, a_rout, f);
      }
      else {
        let tmp = this.wanNatList.filter((w) => {
          return w.id == fdt.id;
        })

        this.respForm1.push(tmp[0]);
        this.respForm1[this.respForm1.length - 1]['id'] = fdt.id;
        this.respForm1[this.respForm1.length - 1]['url'] = `${this.wanNatUrl}${fdt.id}/`;
        this.respForm1[this.respForm1.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm1[this.respForm1.length - 1]['mode'] = "nat-via-pwan";
        this.respForm1[this.respForm1.length - 1]['enabled'] = fdt.natenbl;
        this.respForm1[this.respForm1.length - 1]['ip'] = fdt.natip;
        this.respForm1[this.respForm1.length - 1]['ipv6'] = null;
        this.respForm1[this.respForm1.length - 1]['gateway'] = null;
        this.respForm1[this.respForm1.length - 1]['gateway6'] = null;
        this.respForm1[this.respForm1.length - 1]['vlan'] = null;
        this.respForm1[this.respForm1.length - 1]['source_nat'] = true;
        this.respForm1[this.respForm1.length - 1]['note'] = fdt.natnote;
        this.respForm1[this.respForm1.length - 1]['assigned_routing_groups'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
        this.respForm1[this.respForm1.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm1[this.respForm1.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm1[this.respForm1.length - 1]['newer'] = false;

        f++;
        if (f == this.aFrm1.length) {
          this.patchRouting1(f);
        }
      }

    }
  }
  doUpdatesForm2() {
    let f = 0;
    for (let fdt of this.aFrm2) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];

      for (let v of this.routingGrps) {
        v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
        mv_rout.push({ "routing_group": v.url, "display_name": v.display_name })
      }
      let rcnt = 0;
      for (let r of fdt.pwnrouting) {
        let frouting: any = v_rout.filter((fr: any) => {
          return fr.routing_group == r;
        });
        if (frouting.length > 0) {
          a_rout.push(frouting[0]);
          this.aRoute2.push(frouting[0]);
          mv_rout[rcnt]['url'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
          mv_rout[rcnt]['name'] = this.routingGrps[rcnt]['name'];

          ma_rout.push(mv_rout[rcnt]);
        }
        rcnt++;
      }

      if (fdt.isNew == true) {
        this.addRouting2(fdt, v_rout, a_rout, f);
      }
      else {
        let tmp = this.wanNatList.filter((w) => {
          return w.id == fdt.id;
        })

        this.respForm2.push(tmp[0]);
        this.respForm2[this.respForm2.length - 1]['id'] = fdt.id;
        this.respForm2[this.respForm2.length - 1]['url'] = `${this.wanNatUrl}${fdt.id}/`;
        this.respForm2[this.respForm2.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm2[this.respForm2.length - 1]['mode'] = "gateway-via-pwan";
        this.respForm2[this.respForm2.length - 1]['enabled'] = fdt.pwnenbl;
        this.respForm2[this.respForm2.length - 1]['ip'] = fdt.pwnip;
        this.respForm2[this.respForm2.length - 1]['ipv6'] = fdt.pwnip6;
        this.respForm2[this.respForm2.length - 1]['gateway'] = fdt.pwngate;
        this.respForm2[this.respForm2.length - 1]['gateway6'] = fdt.pwnip6gate;
        this.respForm2[this.respForm2.length - 1]['vlan'] = fdt.pwnvlan;
        this.respForm2[this.respForm2.length - 1]['source_nat'] = false;
        this.respForm2[this.respForm2.length - 1]['note'] = fdt.pwnnote;
        this.respForm2[this.respForm2.length - 1]['assigned_routing_groups'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
        this.respForm2[this.respForm2.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm2[this.respForm2.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm2[this.respForm2.length - 1]['newer'] = false;

        f++;
        if (f == this.aFrm2.length) {
          this.patchRouting2(f);
        }
      }

    }
  }
  doUpdatesForm3() {
    let f = 0;
    for (let fdt of this.aFrm3) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];

      for (let v of this.routingGrps) {
        v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
        mv_rout.push({ "routing_group": v.url, "display_name": v.display_name })
      }
      let rcnt = 0;
      for (let r of fdt.bndrouting) {
        let frouting: any = v_rout.filter((fr: any) => {
          return fr.routing_group == r;
        });
        if (frouting.length > 0) {
          a_rout.push(frouting[0]);
          this.aRoute3.push(frouting[0]);
          mv_rout[rcnt]['url'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
          mv_rout[rcnt]['name'] = this.routingGrps[rcnt]['name'];

          ma_rout.push(mv_rout[rcnt]);
        }
        rcnt++;
      }

      if (fdt.isNew == true) {
        this.addRouting3(fdt, v_rout, a_rout, f);
      }
      else {
        let tmp = this.wanNatList.filter((w) => {
          return w.id == fdt.id;
        })

        this.respForm3.push(tmp[0]);
        this.respForm3[this.respForm3.length - 1]['id'] = fdt.id;
        this.respForm3[this.respForm3.length - 1]['url'] = `${this.wanNatUrl}${fdt.id}/`;
        this.respForm3[this.respForm3.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm3[this.respForm3.length - 1]['mode'] = "gateway-via-bonder";
        this.respForm3[this.respForm3.length - 1]['enabled'] = fdt.bndenbl;
        this.respForm3[this.respForm3.length - 1]['ip'] = null;
        this.respForm3[this.respForm3.length - 1]['ipv6'] = null;
        this.respForm3[this.respForm3.length - 1]['gateway'] = fdt.bndgate;
        this.respForm3[this.respForm3.length - 1]['gateway6'] = fdt.bndip6gate;
        this.respForm3[this.respForm3.length - 1]['vlan'] = null;
        this.respForm3[this.respForm3.length - 1]['source_nat'] = false;
        this.respForm3[this.respForm3.length - 1]['note'] = fdt.bndnote;
        this.respForm3[this.respForm3.length - 1]['assigned_routing_groups'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
        this.respForm3[this.respForm3.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm3[this.respForm3.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm3[this.respForm3.length - 1]['newer'] = false;

        f++;
        if (f == this.aFrm3.length) {
          this.patchRouting3(f);
        }
      }

    }
  }
  doUpdatesForm4() {
    let f = 0;
    for (let fdt of this.aFrm4) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];

      for (let v of this.routingGrps) {
        v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
        mv_rout.push({ "routing_group": v.url, "display_name": v.display_name })
      }
      let rcnt = 0;
      for (let r of fdt.nttrouting) {
        let frouting: any = v_rout.filter((fr: any) => {
          return fr.routing_group == r;
        });
        if (frouting.length > 0) {
          a_rout.push(frouting[0]);
          this.aRoute4.push(frouting[0]);
          mv_rout[rcnt]['url'] = `${this.wanNttUrl}${fdt.id}/assigned_routing_groups/`;
          mv_rout[rcnt]['name'] = this.routingGrps[rcnt]['name'];

          ma_rout.push(mv_rout[rcnt]);
        }
        rcnt++;
      }

      if (fdt.isNew == true) {
        this.addRouting4(fdt, v_rout, a_rout, f);
      }
      else {
        let tmp = this.wanNttList.filter((w) => {
          return w.id == fdt.id;
        })

        this.respForm4.push(tmp[0]);
        this.respForm4[this.respForm4.length - 1]['id'] = fdt.id;
        this.respForm4[this.respForm4.length - 1]['url'] = `${this.wanNttUrl}${fdt.id}/`;
        this.respForm4[this.respForm4.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm4[this.respForm4.length - 1]['enabled'] = fdt.nttenbl;
        this.respForm4[this.respForm4.length - 1]['public_ip'] = fdt.nttpubip;
        this.respForm4[this.respForm4.length - 1]['private_ip'] = fdt.nttpriip;
        this.respForm4[this.respForm4.length - 1]['note'] = fdt.nttnote;
        // this.respForm4[this.respForm4.length - 1]['order']=fdt.nttorder;
        this.respForm4[this.respForm4.length - 1]['assigned_routing_groups'] = `${this.wanNttUrl}${fdt.id}/assigned_routing_groups/`;
        this.respForm4[this.respForm4.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm4[this.respForm4.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm4[this.respForm4.length - 1]['newer'] = false;

        f++;
        if (f == this.aFrm4.length) {
          this.patchRouting4(f);
        }
      }

    }
  }
  doUpdatesForm5() {
    let f = 0;
    for (let fdt of this.aFrm5) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];

      for (let v of this.routingGrps) {
        v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
        mv_rout.push({ "routing_group": v.url, "display_name": v.display_name })
      }
      let rcnt = 0;
      for (let r of fdt.prtrouting) {
        let frouting: any = v_rout.filter((fr: any) => {
          return fr.routing_group == r;
        });
        if (frouting.length > 0) {
          a_rout.push(frouting[0]);
          this.aRoute5.push(frouting[0]);
          mv_rout[rcnt]['url'] = `${this.wanPrtUrl}${fdt.id}/assigned_routing_groups/`;
          mv_rout[rcnt]['name'] = this.routingGrps[rcnt]['name'];

          ma_rout.push(mv_rout[rcnt]);
        }
        rcnt++;
      }

      if (fdt.isNew == true) {
        this.addRouting5(fdt, v_rout, a_rout, f);
      }
      else {
        let tmp = this.wanPrtList.filter((w) => {
          return w.id == fdt.id;
        })

        this.respForm5.push(tmp[0]);
        this.respForm5[this.respForm5.length - 1]['id'] = fdt.id;
        this.respForm5[this.respForm5.length - 1]['url'] = `${this.wanPrtUrl}${fdt.id}/`;
        this.respForm5[this.respForm5.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm5[this.respForm5.length - 1]['enabled'] = fdt.prtenbl;
        this.respForm5[this.respForm5.length - 1]['protocol'] = fdt.prtprotocol;
        this.respForm5[this.respForm5.length - 1]['public_ip'] = fdt.prtpubip;
        this.respForm5[this.respForm5.length - 1]['public_port'] = fdt.prtpubport;
        this.respForm5[this.respForm5.length - 1]['private_ip'] = fdt.prtpriip;
        this.respForm5[this.respForm5.length - 1]['private_port'] = fdt.prtpriport;
        this.respForm5[this.respForm5.length - 1]['note'] = fdt.prtnote;
        this.respForm5[this.respForm5.length - 1]['helper'] = fdt.prthelper;
        // this.respForm5[this.respForm5.length - 1]['order']=fdt.prtorder;
        this.respForm5[this.respForm5.length - 1]['assigned_routing_groups'] = `${this.wanPrtUrl}${fdt.id}/assigned_routing_groups/`;
        this.respForm5[this.respForm5.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm5[this.respForm5.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm5[this.respForm5.length - 1]['newer'] = false;

        f++;
        if (f == this.aFrm5.length) {
          this.patchRouting5(f);
        }
      }

    }
  }

  deleteRouting(typ: string, url: string, id: number, d: number) {
    // let reqObj={};

    let reqObj = {
      "url": `${url}${id}/`,
      "method": "DELETE"
    }
    console.log("DELETING >>>", typ, url, id, reqObj);

    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {

      // console.log("DELETE >>>>", typ, url, id, addRes);
      //   // if (addRes.code == 200) {
      //     // console.log("DELETEROUTING >>>>>>>>>>>");
      //     // console.log(addRes);

      //     // this.cd.detectChanges();
      //   // }

    },
      (err) => {
        try {
          console.log("🚀 ~ ==================== ~ err", err)
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
  }

  addRouting1(dta: any, v_routing: any, a_routing: any, f: number) {
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.natenbl,
        "mode": "nat-via-pwan",
        "source_nat": true,
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "ip": dta.natip,
        "note": dta.natnote
      }
    }
    // console.log("ADD REQ", reqObj);
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 201) {
        if (addRes.message == "Created") {
          this.respForm1.push(addRes.data);
          this.respForm1[this.respForm1.length - 1]['newer'] = true;
        }

        f++;
        if (f == this.aFrm1.length) {
          this.patchRouting1(f);
        }
        // this.cd.detectChanges();
      }

    }, (err) => {
      try {
        console.log("🚀 ~ ==================== ~ err", err)
        let errors = JSON.parse(err);
        if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });

  }
  patchRouting1(cnt: number) {
    if (cnt == this.aFrm1.length) {
      let reqObj = {
        "url": `${this.wanNatUrl}`,
        "method": "PATCH",
        "data": this.respForm1
      }
      this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
        if (addRes) {
          let cnt1 = 0;
          for (let r of this.respForm1) {
            if (r.newer == true) {
              let reqObj1 = {
                "url": `${r.assigned_routing_groups}`,
                "method": "POST",
                "data": this.aRoute1[cnt1] //r.assigned_routing_groups_list,
              }
              this.spaceService.generalSpaceApi(reqObj1).subscribe((addRes1) => {
                if (addRes1) {
                }
              })
            }
            cnt1++;
          }
        }
      }, (err) => {
        try {
          console.log("🚀 ~ ==================== ~ err", err)
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
    this.cd.detectChanges();
  }

  addRouting2(dta: any, v_routing: any, a_routing: any, f: number) {
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.pwnenbl,
        "mode": "gateway-via-pwan",
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "ip": dta.pwnip,
        "ipv6": dta.pwnip6,
        "gateway": dta.pwngate,
        "gateway6": dta.pwnip6gate,
        "vlan": dta.pwnvlan,
        "note": dta.pwnnote
      }
    }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 201) {
        if (addRes.message == "Created") {
          this.respForm2.push(addRes.data);
          this.respForm2[this.respForm2.length - 1]['newer'] = true;
        }

        f++;
        if (f == this.aFrm2.length) {
          this.patchRouting2(f);
        }
      }

    }, (err) => {
      try {
        console.log("🚀 ~ ==================== ~ err", err)
        let errors = JSON.parse(err);
        if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });

  }
  patchRouting2(cnt: number) {
    if (cnt == this.aFrm2.length) {
      if (cnt == this.aFrm2.length) {
        let reqObj = {
          "url": `${this.wanNatUrl}`,
          "method": "PATCH",
          "data": this.respForm2
        }
        this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
          if (addRes) {
            let cnt1 = 0;
            for (let r of this.respForm2) {
              if (r.newer == true) {
                let reqObj1 = {
                  "url": `${r.assigned_routing_groups}`,
                  "method": "POST",
                  "data": this.aRoute2[cnt1]
                }
                this.spaceService.generalSpaceApi(reqObj1).subscribe((addRes1) => {
                  if (addRes1) {
                  }
                })
              }
              cnt1++;
            }
          }
        }, (err) => {
          try {
            console.log("🚀 ~ ==================== ~ err", err)
            let errors = JSON.parse(err);
            if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    }
  }

  addRouting3(dta: any, v_routing: any, a_routing: any, f: number) {
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.bndenbl,
        "mode": "gateway-via-bonder",
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "gateway": dta.bndgate,
        "gateway6": dta.bndip6gate,
        "note": dta.bndnote
      }
    }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 201) {
        if (addRes.message == "Created") {
          this.respForm3.push(addRes.data);
          this.respForm3[this.respForm3.length - 1]['newer'] = true;
        }

        f++;
        if (f == this.aFrm3.length) {
          this.patchRouting3(f);
        }
      }

    }, (err) => {
      try {
        console.log("🚀 ~ ==================== ~ err", err)
        let errors = JSON.parse(err);
        if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });

  }
  patchRouting3(cnt: number) {
    if (cnt == this.aFrm3.length) {
      let reqObj = {
        "url": `${this.wanNatUrl}`,
        "method": "PATCH",
        "data": this.respForm3
      }
      this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
        if (addRes) {
          let cnt1 = 0;
          for (let r of this.respForm3) {
            if (r.newer == true) {
              let reqObj1 = {
                "url": `${r.assigned_routing_groups}`,
                "method": "POST",
                "data": this.aRoute3[cnt1]
              }
              this.spaceService.generalSpaceApi(reqObj1).subscribe((addRes1) => {
                if (addRes1) {
                }
              })
            }
            cnt1++;
          }
        }
      }, (err) => {
        try {
          console.log("🚀 ~ ==================== ~ err", err)
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  addRouting4(dta: any, v_routing: any, a_routing: any, f: number) {
    let reqObj = {
      "url": `${this.wanNttUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.nttenbl,
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "public_ip": dta.nttpubip,
        "private_ip": dta.nttpriip,
        "note": dta.nttnote
      }
    }

    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        if (addRes.message == "Created") {
          this.respForm4.push(addRes.data);
          this.respForm4[this.respForm4.length - 1]['newer'] = true;
        }

        f++;
        if (f == this.aFrm4.length) {
          this.patchRouting4(f);
        }
      }

    }, (err) => {
      try {
        console.log("🚀 ~ ==================== ~ err", err)
        let errors = JSON.parse(err);
        if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });

  }
  patchRouting4(cnt: number) {
    if (cnt == this.aFrm4.length) {
      let reqObj = {
        "url": `${this.wanNttUrl}`,
        "method": "PATCH",
        "data": this.respForm4
      }
      this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
        if (addRes) {
          let cnt1 = 0;
          for (let r of this.respForm4) {
            if (r.newer == true) {
              let reqObj1 = {
                "url": `${r.assigned_routing_groups}`,
                "method": "POST",
                "data": this.aRoute4[cnt1]
              }
              this.spaceService.generalSpaceApi(reqObj1).subscribe((addRes1) => {
                if (addRes1) {
                }
              })
            }
            cnt1++;
          }
        }
      }, (err) => {
        try {
          console.log("🚀 ~ ==================== ~ err", err)
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  addRouting5(dta: any, v_routing: any, a_routing: any, f: number) {
    let reqObj = {
      "url": `${this.wanPrtUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.prtenbl,
        "helper": dta.prthelper,
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "protocol": dta.prtprotocol,
        "private_port": dta.prtpriport,
        "public_port": dta.prtpubport,
        "public_ip": dta.prtpubip,
        "private_ip": dta.prtpriip,
        "note": dta.prtnote
      }
    }

    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        if (addRes.message == "Created") {
          this.respForm5.push(addRes.data);
          this.respForm5[this.respForm5.length - 1]['newer'] = true;
        }

        f++;
        if (f == this.aFrm5.length) {
          this.patchRouting5(f);
        }
      }

    }, (err) => {
      try {
        console.log("🚀 ~ ==================== ~ err", err)
        let errors = JSON.parse(err);
        if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }
  patchRouting5(cnt: number) {
    if (cnt == this.aFrm5.length) {
      let reqObj = {
        "url": `${this.wanPrtUrl}`,
        "method": "PATCH",
        "data": this.respForm5
      }
      this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
        if (addRes) {
          let cnt1 = 0;
          for (let r of this.respForm5) {
            if (r.newer == true) {
              let reqObj1 = {
                "url": `${r.assigned_routing_groups}`,
                "method": "POST",
                "data": this.aRoute5[cnt1]
              }
              this.spaceService.generalSpaceApi(reqObj1).subscribe((addRes1) => {
                if (addRes1) {
                }
              })
            }
            cnt1++;
          }
        }
      }, (err) => {
        try {
          console.log("🚀 ~ ==================== ~ err", err)
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  changePWANMode(mode: string) {
    this.pWanMode = mode;
    this.cd.detectChanges();
  }
  savePWANBasics() {
    this.pWanList.enabled = this.pWanEnbl;
    this.pWanList.mode = this.pWanMode;
    this.pWanList.managed_mesh_include_aggregator_interfaces = this.pWanMesh;
    let reqObj = {
      "url": `spaces/${this.parentId}/private_wan/`,
      "method": "PATCH",
      "data": this.pWanList
    }

    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        let reqObj1 = {
          "url": `spaces/${this.parentId}/configuration_check/`,
          "method": "GET"
        }
        this.spaceService.generalSpaceApi(reqObj1).subscribe((addRes1) => {
          console.log("CONFIG PWAN >>>", addRes1);
        })
      }
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

}
