<div class="card mb-5 mb-xl-8 mt-5">
    <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50 justify-content-end">
        <div></div>
        <div class="fw-bolder my-2 div-position col-md-2 me-4">
            <div class="form-group inputtext-block">
                <input type="text" class="form-control form-control-lg form-control-solid" [formControl]="searchControl"
                    name="searchText" placeholder="Search" (keydown)="preventSpace($event)" value="" />
                <span (click)="onResetFilter()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
                    class="svg-icon svg-icon-1 me-0 span-input"></span>
            </div>
        </div>
    </div>
    <div class="card-body p-2 h-100">
        <!-- begin::Table container -->
        <div>
            <div class="table-responsive border">
                <!-- begin::Table -->
                <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                    <!-- begin::Table head -->
                    <thead class="align-middle table-light">
                        <tr class="fw-bolder text-dark text-capitalize">
                            <!-- <th class="ps-4 fs-7 py-4">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" [(ngModel)]="isMasterSel"
                                        (change)="checkUncheckAll($event)" type="checkbox">
                                </div>
                            </th> -->
                            <th class="py-4 cursor-pointer" (click)="bondsList.length > 0 ? onSort('id', true): ''">
                                Node Id
                                <span *ngIf="sortBy == 'id' && bondsList.length > 0" [inlineSVG]="arrow"
                                    class="svg-icon svg-icon-7" style="height: 10px;"></span>
                            </th>
                            <th class="py-4 cursor-pointer" (click)="bondsList.length > 0 ? onSort('name', true): ''">
                                Name
                                <span *ngIf="sortBy == 'name' && bondsList.length > 0" [inlineSVG]="arrow"
                                    class="svg-icon svg-icon-7" style="height: 10px;"></span>
                            </th>
                            <th class="py-4 cursor-pointer" (click)="bondsList.length > 0 ? onSort('space', true): ''">
                                Space<span *ngIf="sortBy == 'space' && bondsList.length > 0" [inlineSVG]="arrow"
                                    class="svg-icon svg-icon-7" style="height: 10px;"></span></th>
                            <th class="py-4 cursor-pointer"
                                (click)="bondsList.length > 0 ? onSort('organizationName', true): ''"> Organization
                                <span *ngIf="sortBy == 'organizationName' && bondsList.length > 0" [inlineSVG]="arrow"
                                    class="svg-icon svg-icon-7" style="height: 10px;"></span>
                            </th>
                            <th class="py-4 min-w-50px w-50px text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="!preLoaderScreen && bondsList && bondsList.length > 0" class="fs-7">
                        <tr *ngFor="let bond of bondsList; let i = index">
                            <!-- <td class="ps-4">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="bond.isSelected"
                                        [disabled]="!bond.isSelected && selectedItemList.length > 0 ? true:  false"
                                        name="list_name" value="{{bond.id}}" (change)="isAllSelected()" />
                                </div>
                            </td> -->
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-star">
                                        <a class="text-dark  d-block fs-7">
                                            {{bond?.id}}
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center ">
                                    <div class="d-flex justify-content-start flex-column ">
                                        <a class="text-primary word-wrap d-block bond-name cursor-pointer fw-bolder">
                                            {{bond?.name}}
                                            <span *ngIf="bond.isSharedSdwan"
                                                [inlineSVG]="'./assets/media/icons/normal/chart-network.svg'"
                                                class="svg-icon svg-icon-5 mx-2 "></span>
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-star">
                                        <a class="text-dark  d-block fs-7">
                                            {{bond?.space?.name}}
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-star">
                                        <a class="text-dark  d-block fs-7">
                                            {{bond?.organizationName}}
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                    *ngIf="!bond.isSharedSdwan"
                                    class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                        class="svg svg-icon svg-transparent">
                                    </span>
                                </a>
                                <mat-menu #actionMenu="matMenu">
                                    <button mat-menu-item class="menu-list-block" title="Delete" title="Revoke"
                                        (click)="onRevoked(bond.id,bond?._id)">
                                        <div>
                                            <span
                                                [inlineSVG]="'./assets/media/icons/duotune/arrows/arrow-alt-from-left.svg'"
                                                class="svg svg-icon svg-icon-danger">
                                            </span>
                                        </div>
                                        <span>Revoke</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                    <!-- end::Table body -->
                </table>
                <app-preloader-table-list *ngIf="preLoaderScreen"></app-preloader-table-list>
            </div>
            <div *ngIf="!preLoaderScreen && bondsList.length <= 0" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>
                <!-- <hr> -->
            </div>
            <!-- end::Table -->
            <app-pagination *ngIf="!preLoaderScreen && bondsList && bondsList.length > 0"
                [childMessage]="isSearch ? searchBondList.length : allBonds.length" [index]="page" [pagesize]="limit"
                (messageEvent)="receiveMessage($event)">
            </app-pagination>
        </div>
    </div>
</div>