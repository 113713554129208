import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup,Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';

@Component({
  selector: 'app-shared-space-wan-add-filter',
  templateUrl: './shared-space-wan-add-filter.component.html',
  styleUrls: ['./shared-space-wan-add-filter.component.scss']
})
export class SharedSpaceWanAddFilterComponent implements OnInit {
  addFilter: FormGroup;
  errors: any;
  description: any = '';
  filterData: any;
  parentId: any;
  body: any;
  method: any;
  isSubmitted: boolean = false;
  isEdit: boolean;
  NameFieldValidation:any = AppConst.NameFieldPattern;

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal, private cookie: CookieService,
    private partnerBondingService: PartnerBondingService,
    private sharedService: SharedService,
    private formErrorHandler: FormErrorService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.addFilter = this.createNewForm();
    this.addFilter.valueChanges.subscribe(x => { this.errors = null; });
    this.getInfo();
  }


  getInfo() {
    if (this.filterData) {
      this.isEdit = true;
      this.method = "PATCH";
      this.setFormData();
    } else {
      this.isEdit = false;
      this.method = "POST";
    }
    this.cd.detectChanges();
  }

  setFormData() {
    console.log(' this.filterData :', this.filterData);
    let arrval = this.filterData?.rules;
    // console.log('arrval', arrval);
    this.addFilter.patchValue({
      id: this.filterData?.id,
      name: this.filterData?.name,
    });
    setTimeout(() => {
      this.setrulesval(arrval);
    }, 500);
  }

  setrulesval(arrval: any) {
    if (arrval.length > 0) {
      arrval.forEach((element: any, i: any) => {
        let rules = this.newospfRules(element);
        let control = <FormArray>this.addFilter.controls.rules;
        control.push(rules);
        if (element.actions.length > 0) this.setactionsval(i, element.actions);
        if (element.matches.length > 0) this.setmatchesval(i, element.matches);
      });
    }
  }

  setactionsval(ruleIndex: number, actions: any) {
    //console.log('actions:',actions);
    if (actions.length > 0) {
      actions.forEach((element: any, i: any) => {
        let arules: any = this.newospfeditactions(element);
        let ruleControl: any = this.addFilter.get('rules');
        let acontrol = ruleControl?.controls[ruleIndex].get('actions');
        if (acontrol) acontrol.push(arules);
        if (element.value && typeof (element.value) == 'object' && element.value.length > 0) this.setValueList(ruleIndex, i, element);
      });
    }
  }

  setmatchesval(ruleIndex: number, matches: any) {
    // console.log('matches:',matches);
    if (matches.length > 0) {
      matches.forEach((element: any, i: any) => {
        let matchesval = this.newospfmatches(element);
        let ruleControl: any = this.addFilter.get('rules');
        let mcontrol = ruleControl?.controls[ruleIndex].get('matches');
        if (mcontrol) mcontrol.push(matchesval);
        if (element.value && typeof (element.value) == 'object' && element.value.length > 0) this.setvalues(ruleIndex, i, element.value);
      });
    }
  }

  setvalues(ruleIndex: number, matchIndex: number, values: any) {
    // console.log('values:',values);
    if (values?.length > 0) {
      values.forEach((element: any) => {
        let val = this.newospfvalues(element);
        let ruleControl: any = this.addFilter.get('rules');
        let mcontrol = ruleControl?.controls[ruleIndex].get('matches');
        let vcontrol = mcontrol?.controls[matchIndex].get('value')
        if (vcontrol) vcontrol.push(val);
      });
    }
  }

  setValueList(ruleIndex: number, matchIndex: number, element: any) {
    // console.log('values:',values);
    if (element?.value?.length > 0) {
      element.value.forEach((item: any) => {
        let val = this.newactionvalues(item, element.attribute);
        let ruleControl: any = this.addFilter.get('rules');
        let mcontrol = ruleControl?.controls[ruleIndex].get('actions');
        let vcontrol = mcontrol?.controls[matchIndex].get('valueArray')
        if (vcontrol) vcontrol.push(val);
      });
    }
  }

  createNewForm() {
    // console.log(this.addFilter?.controls);
    return this.fb.group({
      id: [''],
      name: ['', [this.sharedService.xssValidator, Validators.pattern(this.NameFieldValidation)]],
      rules: this.fb.array([]),
    })
  }

  get ospfRules(): any {
    return this.addFilter.get("rules") as FormArray
  }
  get ospfmatches(): FormArray {
    return this.ospfRules.get("matches") as FormArray
  }
  getOspfmatches(i: any): FormArray {
    return this.ospfRules.controls[i].get("matches") as FormArray
  }
  getOspfactions(i: any): FormArray {
    return this.ospfRules.controls[i].get("actions") as FormArray
  }
  getOspfValues(matchIndex: any, valueIndex: any) {
    return this.getOspfmatches(matchIndex).controls[valueIndex].get("value") as FormArray
  }
  getActionValues(actionIndex: any, valueIndex: any) {
    return this.getOspfactions(actionIndex).controls[valueIndex].get("valueArray") as FormArray
  }
  get ospfactions(): FormArray {
    return this.ospfRules.get("actions") as FormArray
  }
  get ospfvalues(): FormArray {
    return this.ospfmatches.get("value") as FormArray
  }


  onAddospfRules() {
    this.ospfRules.controls?.push(this.newospfRules());
  }
  onRemoveospfRules(index: any) {
    this.ospfRules.removeAt(index);
  }
  onAddospfmatches(index: any) {
    //console.log('match',this.ospfRules[index])
    <FormArray>(this.ospfRules.controls[index]?.get('matches')).push(this.newospfmatches());
  }

  onRemoveospfmatches(ruleIndex: number, index: any) {
    this.getOspfmatches(ruleIndex).removeAt(index);
  }
  onAddospfactions(index: any) {
    <FormArray>(this.ospfRules.controls[index]?.get('actions')).push(this.newospfeditactions());
  }
  onRemoveospfactions(ruleIndex: number, index: any) {
    this.getOspfactions(ruleIndex).removeAt(index);
  }
  onAddospfvalues(matchIndex: any, valueIndex: any) {
    (this.getOspfmatches(matchIndex).controls[valueIndex].get('value') as FormArray)?.push(this.newospfvalues(''))
  }
  onAddactionvalues(matchIndex: any, valueIndex: any) {
    (this.getOspfactions(matchIndex).controls[valueIndex].get('valueArray') as FormArray)?.push(this.newactionvalues(''))
  }
  onRemoveospfvalues(ruleIndex: number, matchIndex: number, index: any) {
    this.getOspfValues(ruleIndex, matchIndex).removeAt(index);
  }
  onRemoveactionvalues(ruleIndex: number, matchIndex: number, index: any) {
    this.getActionValues(ruleIndex, matchIndex).removeAt(index);
  }

  newospfvalues(value: string): FormGroup {
    return this.fb.group({ value: [value || ''] })
  }

  newactionvalues(data: any = {}, attr: any = ''): FormGroup {
    let key1 = '';
    let key2 = '';
    let key3 = '';
    let value = '';
    let value1 = '';
    let value2 = '';
    let value22 = '';
    let value3 = '';
    let type = '';
    let type_id = '';
    if (attr && attr == 'bgp_cluster_list.filter' || attr == 'bgp_path.filter' || attr == 'bgp_path.delete') value = data;
    if (attr == 'bgp_community.filter') {
      key1 = data[0];
      value1 = data[1];
    }
    if (attr == 'bgp_large_community.filter') {
      key2 = data[0];
      value2 = data[1];
      value22 = data[2];
    }
    if (attr == 'bgp_ext_community.filter') {
      type = data.type;
      type_id = data.type_id;
      key3 = data.key;
      value3 = data.value;
    }
    return this.fb.group({
      value: [value],
      type: [type], // ext
      type_id: [type_id], // ext
      key3: [key3], // ext
      value3: [value3], // ext
      key2: [key2], // large
      value2: [value2], // large
      value22: [value22], // large
      key1: [key1], // community
      value1: [value1], // community
    });
  }

  newospfeditactions(data: any = {}): FormGroup {
    let key1 = '';
    let key2 = '';
    let key3 = '';
    let value1 = '';
    let value2 = '';
    let value22 = '';
    let value3 = '';
    let type = '';
    let type_id = '';
    if (data?.attribute == 'bgp_community.add' || data?.attribute == 'bgp_community.delete') {
      key1 = data.value[0];
      value1 = data.value[1];
    }
    if (data?.attribute == 'bgp_large_community.add' || data?.attribute == 'bgp_large_community.add' || data?.attribute == 'bgp_large_community.delete') {
      key2 = data.value[0];
      value2 = data.value[1];
      value22 = data.value[2];
    }
    if (data?.attribute == 'bgp_ext_community.add' || data?.attribute == 'bgp_ext_community.delete') {
      type = data.value.type;
      type_id = data.value.type_id;
      key3 = data.value.key;
      value3 = data.value.value;
    }
    return this.fb.group({
      action: [data.action || ''],
      attribute: [data.attribute || ''],
      value: [data.value || ''],
      message: [data.message || '',[this.sharedService.xssValidator]],
      type: [type], // ext
      type_id: [type_id], // ext
      key3: [key3], // ext
      value3: [value3], // ext
      key2: [key2], // large
      value2: [value2], // large
      value22: [value22], // large
      key1: [key1], // community
      value1: [value1], // community
      valueArray: new FormArray([]),
    })

  }

  newospfmatches(data: any = {}): FormGroup {
    // console.log('newospfmatches:', data); 
    let value1: any = '';
    if (data.value && typeof (data.value) == 'string') value1 = data.value;
    if (data.value && typeof (data.value) == 'boolean') {
      if (data.value == true) value1 = true;
      else value1 = false;
    } value1 = data.value;
    return this.fb.group({
      negate: [data.negate || false],
      attribute: [data.attribute || ''],
      value: new FormArray([]),
      value1: [value1 || '']
    });
  }

  newospfRules(data: any = {}): FormGroup {
    // console.log('newospfRules:',data);
    return this.fb.group({
      description: [data.description || '',[this.sharedService.xssValidator]],
      matches: new FormArray([]),
      actions: new FormArray([]),
      negate: [data.negate || '']
    })
  }

  onChangeMatchAtt(i: any, actioni: any) {
    this.errors = null;
    let value: any = this.getOspfmatches(i).controls[actioni].get("value");
    let value1: any = this.getOspfmatches(i).controls[actioni].get("value1");
    value.clear();
    value1.setValue('');
  }

  onChangeActionAtt(i: any, actioni: any) {
    this.errors = null
    let a: any = this.getOspfactions(i).controls[actioni].get("valueArray");
    a.clear();
    this.getOspfactions(i).controls[actioni].get("type")?.setValue('');
    this.getOspfactions(i).controls[actioni].get("type_id")?.setValue('');
    this.getOspfactions(i).controls[actioni].get("key3")?.setValue('');
    this.getOspfactions(i).controls[actioni].get("value3")?.setValue('');
    this.getOspfactions(i).controls[actioni].get("key2")?.setValue('');
    this.getOspfactions(i).controls[actioni].get("value2")?.setValue('');
    this.getOspfactions(i).controls[actioni].get("value22")?.setValue('');
    this.getOspfactions(i).controls[actioni].get("key1")?.setValue('');
    this.getOspfactions(i).controls[actioni].get("value1")?.setValue('');
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onsave() {
    this.errors = null
    this.isSubmitted = true;
    let ruleArr: any[] = [];
    if (this.addFilter?.invalid) {
      this.addFilter.markAllAsTouched();
      return;
    }
    let arrval = (this.addFilter.controls['rules'] as FormArray).controls;
    if (arrval && arrval?.length > 0) {
      arrval.forEach(element => {
        console.log('element', element.value);
        let objval = element.value;
        let actionArr: any[] = [];
        let matchArr: any[] = [];

        if (objval && objval?.matches && objval?.matches?.length > 0) {
          objval.matches.forEach((ele: any) => {
            if (ele) {
              let vArr: any = [];
              if (ele?.value && ele?.value?.length > 0) {
                ele.value.forEach((v: any) => {
                  if (v && v?.value) vArr.push(v.value)
                });
              }
              if (ele?.attribute == 'proto' || ele?.attribute == 'ifname' || ele?.attribute == 'bgp_atomic_aggr') vArr = ele?.value1;
              matchArr.push({
                "attribute": ele.attribute,
                "value": vArr,
                "negate": ele.negate
              });
            }
          });
        }
        if (objval && objval?.actions && objval?.actions?.length > 0) {
          objval.actions.forEach((m: any) => {
            if (m) {
              if (m.action == 'modify') {
                if (m.attribute == 'bgp_path.empty' || m.attribute == 'bgp_community.empty' ||
                  m.attribute == 'bgp_ext_community.empty' || m.attribute == 'bgp_large_community.empty' ||
                  m.attribute == 'bgp_cluster_list.empty') {
                  m.value = null;
                }
                if (m?.attribute == 'bgp_community.add' || m?.attribute == 'bgp_community.delete') {
                  m.value = [m.key1, m.value1];
                }
                if (m?.attribute == 'bgp_large_community.add' || m?.attribute == 'bgp_large_community.delete') {
                  m.value = [m.key2, m.value2, m.value22];
                }
                if (m?.attribute == 'bgp_ext_community.add' || m?.attribute == 'bgp_ext_community.delete') {
                  m.value = { type: m.type, type_id: m.type_id, key: m.key3, value: m.value3 };
                }
                if (m?.attribute == 'bgp_cluster_list.filter' || m?.attribute == 'bgp_path.filter' || m?.attribute == 'bgp_path.delete') {
                  let valueArr: any = []
                  if (m.valueArray && m.valueArray.length > 0) {
                    m.valueArray.forEach((ip: any) => {
                      if (ip) valueArr.push(ip.value);
                    });
                  }
                  m.value = valueArr;
                }
                if (m?.attribute == 'bgp_community.filter') {
                  let valueArr: any = []
                  if (m.valueArray && m.valueArray.length > 0) {
                    m.valueArray.forEach((ip: any) => {
                      if (ip) valueArr.push([ip.key1, ip.value1]);
                    });
                  }
                  m.value = valueArr;
                }
                if (m?.attribute == 'bgp_large_community.filter') {
                  let valueArr: any = []
                  if (m.valueArray && m.valueArray.length > 0) {
                    m.valueArray.forEach((ip: any) => {
                      if (ip) valueArr.push([ip.key2, ip.value2, ip.value22]);
                    });
                  }
                  m.value = valueArr;
                }
                if (m?.attribute == 'bgp_ext_community.filter') {
                  let valueArr: any = []
                  if (m.valueArray && m.valueArray.length > 0) {
                    m.valueArray.forEach((ip: any) => {
                      if (ip) valueArr.push({ type: ip.type, type_id: ip.type_id, key: ip.key3, value: ip.value3 });
                    });
                  }
                  m.value = valueArr;
                }
                actionArr.push({
                  "action": m.action,
                  "attribute": m.attribute,
                  "value": m.value,
                });
              } else if (m.action == 'log') {
                actionArr.push({ "action": m.action, "message": m.message });
              } else actionArr.push({ "action": m.action });
            }
          });
        }

        ruleArr.push({
          "description": objval.description ? objval.description : '',
          "matches": matchArr,
          "actions": actionArr
        });
      });
    }
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    this.body = {
      "id": this.addFilter.value.id,
      "name": this.addFilter.value.name,
      "space": { "url": `${apiURL}spaces/${this.parentId}/` },
      "rules": ruleArr,
    }
    let url = '';
    if (this.method == 'POST') {
      url = `dynamic_routing/space/filters/`;
    } else {
      url = `dynamic_routing/space/filters/${this.addFilter.value.id}/`;
    }
    let ProtocolData: any = { "method": this.method, "url": url, data: this.body };
    this.sharedService.showLoader()
    this.partnerBondingService.addData(ProtocolData).subscribe((addRes) => {
      if (addRes.code == 201 || addRes.code == 200) {
        this.partnerBondingService.successForFilter.next({ success: true })
        this.sharedService.loggerSuccess(addRes.message);
      } else this.sharedService.loggerError(addRes.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
      this.activeModal.close({ event: 'save' });
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.partnerBondingService.successForFilter.next({ success: false });
        this.errorHandler(this.errors, this.addFilter.controls);
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  errorHandler(errorObj: any, controller: any) {
    Object.keys(errorObj).forEach(key => {
      let filterAddControl = controller[key];
      if (filterAddControl) {
        if (errorObj[key] && errorObj[key].length > 0) {
          if (filterAddControl) filterAddControl.setErrors(errorObj[key].join(''));
        } else this.errorHandler(errorObj[key], filterAddControl.controls)
      }
    })
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.addFilter);
 
   }
}
