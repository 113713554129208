<!-- Toggle -->
<button
  id="kt_explore_toggle"
  class="
    btn btn-sm btn-white btn-active-primary
    shadow-sm
    position-fixed
    px-5
    fw-bolder
    zindex-2
    top-50
    mt-10
    end-0
    transform-90
    fs-6
    rounded-top-0
  "
>
  <span id="kt_explore_toggle_label">Explore</span>
</button>

<!-- Drawer -->
<div
  id="kt_explore"
  class="bg-white"
  data-kt-drawer="true"
  data-kt-drawer-name="explore"
  data-kt-drawer-activate="true"
  data-kt-drawer-overlay="true"
  data-kt-drawer-width="{default:'300px', 'lg': '375px'}"
  data-kt-drawer-direction="end"
  data-kt-drawer-toggle="#kt_explore_toggle"
  data-kt-drawer-close="#kt_explore_close"
>
  <!-- begin::Card -->
  <div class="card shadow-none w-100">
    <!-- begin::Header -->
    <div class="card-header" id="kt_explore_header">
      <h5 class="card-title fw-bold text-gray-600">
        <!-- Explore {{ appThemeName }} -->
      </h5>

      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-icon explore-btn-dismiss me-n5"
          id="kt_explore_close"
        >
          <span
            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </button>
      </div>
    </div>
    <!-- end::Header  -->
    <!-- begin::Body  -->
    <div class="card-body" id="kt_explore_body">
      <!-- begin::Content  -->
      <div
        id="kt_explore_scroll"
        class="scroll-y me-n5 pe-5"
        data-kt-scroll="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-wrappers="#kt_explore_body"
        data-kt-scroll-dependencies="#kt_explore_header, #kt_explore_footer"
        data-kt-scroll-offset="5px"
      >
        <div class="mb-0">
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo1</h3>
            <div
              class="
                overlay
                overflow-hidden
                border border-4 border-primary
                p-2
                rounded
              "
            >
              <div class="overlay-wrapper rounded">
                <img
                  src="./assets/media/demos/demo1.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <!-- <a [href]="appPreviewUrl" class="btn btn-primary shadow">
                  Preview
                </a> -->
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo2</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo2.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo3</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo3.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo4</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="../assets/media/demos/demo4.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo5</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo5.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo6</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo6.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo7</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo7.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo8</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo8.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo9</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo9.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo10</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo10.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo11</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo11.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo12</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo12.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo13</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo13.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo14</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo14.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
          <div class="mb-7">
            <h3 class="fw-bold text-center mb-3">Demo15</h3>
            <div class="overlay overflow-hidden border border-4 p-2 rounded">
              <div class="overlay-wrapper">
                <img
                  src="./assets/media/demos/demo15.png"
                  alt="demo"
                  class="w-100 rounded"
                />
              </div>
              <div class="overlay-layer bg-dark bg-opacity-10">
                <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">
                  Coming soon
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::Content  -->
    </div>
    <!-- end::Body  -->
    <!-- begin::Footer  -->
    <div class="card-footer py-5 text-center" id="kt_explore_footer">
      <!-- <a [href]="appPurchaseUrl" class="btn btn-primary">
        Purchase {{ appThemeName }}
      </a> -->
    </div>
    <!-- end::Footer -->
  </div>
  <!-- begin::Card  -->
</div>
