<div class="overflow-auto mt-0 card border-1 border bg-light border-bottom-0">

    <div class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0">
      <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap fs-6">
        <li class="nav-item">
          <a (click)="onTabChange(1)" [class.active]="selectedTab==1" class="nav-link text-active-primary mx-0 px-5"> <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'" class="svg-icon svg-icon-5 me-2 "></span> SD-WAN </a>
        </li>
        <li class="nav-item align-items-center"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
        <li class="nav-item">
          <a (click)="onTabChange(2)" [class.active]="selectedTab==2" class="nav-link text-active-primary mx-0 px-5">
            <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'" class="svg-icon svg-icon-5 me-2 "></span> Managed Mesh Routing  </a>
        </li>
        <li class="nav-item align-items-center"><span class="h-40px border-start border-muted mx-0 border-1"></span></li>
        <li class="nav-item">
          <a (click)="onTabChange(3)" [class.active]="selectedTab==3" class="nav-link text-active-primary mx-0 px-5"> <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'" class="svg-icon svg-icon-5 me-2 "></span> Private WAN router rules </a>
        </li>
      </ul>
    </div>
  </div>

  <!-- Private WAN HTML start -->
  <ng-container *ngIf="selectedTab==1">
    <div class="card mb-5 mb-xl-8 border border-2">
      <div class="card-header align-items-center px-5">
        <h2 class="mb-0 fs-3"> Private WAN </h2>
        <div style="float: right;">
          <button type="submit" class="btn btn-primary mr-2 ml-2" (click)="savePWANBasics()">Save</button>
        </div>
      </div>
      <div class="card-body p-5">
        <div class="row">
          <div class="col-md-12 col-lg-12 mb-5">
            <div class="d-flex align-items-center mt-3">
              <div class="form-check form-check-sm form-check-custom form-check-solid">
                <input class="form-check-input" type="checkbox" id="pWanEnbl" name="pWanEnbl" [(ngModel)]="pWanEnbl"> <label class="mb-0 form-label ms-2" for="pWanEnbl">
                  Enable private WAN </label>
              </div>
            </div>
            <small>Use private WAN routing between sites </small>
          </div>
          <div class="col-md-12 col-lg-12 mb-5">
            <p> Private WAN is the feature that allows geographically separated sites to securely route traffic to each
              other as if each site was connected to the same router. It also allows access to the Internet to be
              centralized for all bonds in a space.</p>
            <p> Regardless of which mode is selected, enabling private WAN on a space will set up dynamic routing such
              that bonds in the space can route traffic between private WAN-enabled connected IPs and routes, providing
              the bonds are on the same aggregator.
            </p>
            <p> Communication between bonds on separate aggregators and routing to external gateways can be configured
              below, depending on the private WAN mode. </p>
            <!-- <a href="#" class="text-primary"> Continue reading </a> -->
          </div>
        </div>

      </div>

      <div class="card-header align-items-center border-top d-flex justify-content-between mb-0 px-5">
        <h2 class="mb-0 fs-4"> Mode </h2>
      </div>

      <div class="card-body p-5">
        <div class="row">

          <div class="col-md-12 col-lg-12 mb-5">
            <div class="d-flex">
              <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                <input type="radio" name="optSpaceMode" id="radio1" value="with_pwan_routers" class="form-check-input" [checked]="pWanMode == 'with_pwan_routers'" (click)="changePWANMode('with_pwan_routers')">
                <span class="form-check-label form-label mb-0">With private WAN routers</span></label>
            </div>
            <small> With private WAN routers uses the gateways and rules from this page to route traffic via private WAN
              routers
            </small>
          </div>

          <div class="col-md-12 col-lg-12 mb-5">
            <div class="d-flex">
              <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                <input type="radio" name="optSpaceMode" id="radio2" value="managed_mesh" class="form-check-input" [checked]="pWanMode == 'managed_mesh'" (click)="changePWANMode('managed_mesh')">
                <span class="form-check-label form-label mb-0">Managed mesh</span></label>
            </div>
            <small> Managed mesh configures VXLAN and Wireguard encrypted tunnels between all aggregators. Routing to
              external networks requires custom protocol configuration to be defined on at least one aggregator.
            </small>
          </div>

          <div class="col-md-12 col-lg-12 mb-5">
            <div class="d-flex">
              <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                <input type="radio" name="optSpaceMode" id="radio3" value="unmanaged" class="form-check-input" [checked]="pWanMode == 'unmanaged'" (click)="changePWANMode('unmanaged')">
                <span class="form-check-label form-label mb-0">Unmanaged</span></label>
            </div>
            <small> Unmanaged requires custom protocols to be configured on every involved node to manage how traffic is
              routed between those nodes and external networks.
            </small>
          </div>

        </div>

      </div>

      <div class="card-header align-items-center border-top d-flex justify-content-between mb-0 px-5">
        <h2 class="mb-0 fs-4"> Managed Mesh Options
        </h2>
      </div>

      <div class="card-body p-5">
        <div class="row">
          <div class="col-md-12 col-lg-12 mb-5">
            <div class="d-flex align-items-center mt-3">
              <div class="form-check form-check-sm form-check-custom form-check-solid">
                <input class="form-check-input" type="checkbox" id="pWanMesh" name="pWanMesh" [(ngModel)]="pWanMesh"> <label class="mb-0 form-label ms-2" for="pWanMesh">
                  Include aggregator interfaces assigned to this space in the mesh </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer p-5">
        <!-- Save -->

      </div>

    </div>
  </ng-container>

  <!-- Private WAN HTML End -->

  <!-- Aggregator routing HTML Start -->
  <ng-container *ngIf="selectedTab==2">
    <div class="card mb-5 mb-xl-8 border border-2">

      <div class="card-header align-items-center border-top d-flex justify-content-between px-5">
        <h2 class="mb-0 fs-3"> Aggregator routing </h2>
      </div>

      <div class="card-body p-5">
        <p> Interfaces, IP addresses, and routing protocols can be configured on a per-aggregator basis or configured
          per-routing group below. The following configuration applies in managed mesh mode only.
        </p>

        <div class="align-items-center d-flex justify-content-between mb-5 px-0">
          <h2 class="mb-0 fs-4"> Routing group VLAN assignments</h2>
          <a class="btn btn-sm btn-primary p-3" (click)="addVlan()"> <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-2 me-0"></span>  Add </a>
        </div>
        <p> Defines which VLANs and IP pools to assign address on VLAN ports for aggregators in each routing group. </p>
        <div class="table-responsive border">
          <table class="table table-row-bordered table-row-gray-300 align-middle gs-5 gy-2 min-w-900">
            <thead class="table-light fs-7">
              <tr class="fw-bolder text-dark">
                <th class="py-4">ID</th>
                <th class="py-4">Routing group </th>
                <th class="py-4">VLAN </th>
                <th class="py-4">IPv4 pool </th>
                <th class="py-4">IPv4 exclusions </th>
                <th class="py-4"> IPv6 pool </th>
                <th class="py-4"> IPv6 exclusions </th>
                <th class="py-4"> Actions </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of vlanAssignmentsList; let i=index">
                <td class="ps-5"> {{item.id}} </td>
                <td> <span class="badge badge-light-warning">{{item.routing_group_name}}</span></td>
                <td>{{item.vlan_id}}</td>
                <td> {{item.ipv4_address_pool}} </td>
                <td> {{item.ipv4_excluded_addresses}}</td>
                <td> {{item.ipv6_address_pool}} </td>
                <td> {{item.ipv6_excluded_addresses}} </td>
                <td>
                  <a title="Edit" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'" class="svg-icon svg-icon-3 svg-icon-warning"
                      (click)="editVlan(item)">
                    </span>
                  </a>
                  <a title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger"
                      (click)="deleteVlan(item)">
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="separator   my-3 mb-0"></div>

      <div class="card-body p-5">
        <div class="align-items-center d-flex justify-content-between mb-5 px-0">
          <h2 class="mb-0 fs-3"> Gateway aggregators </h2>
          <a class="btn btn-sm btn-primary p-3" (click)="addGateway()"> Add <span
              [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-2 me-0"></span> </a>
        </div>

        <p>By default, an aggregator will not run a space unless a bond, interface, or protocol is assigned to it. A
          gateway aggregator will be configured to run the space regardless of whether the prior conditions are met.
        </p>

        <div class="table-responsive border">
          <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2 min-w-900">
            <thead class="table-light">
              <tr class="fw-bolder text-dark fs-7">
                <th class="py-4">ID</th>
                <th class="py-4">Routing group </th>
                <th class="py-4">VLAN </th>
                <th class="py-4">IPv4 pool </th>
                <th class="py-4">IPv4 exclusions </th>
                <th class="py-4"> IPv6 pool </th>
                <th class="py-4"> IPv6 exclusions </th>
                <th class="py-4"> Actions </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of gatewayAggList; let i=index">
                <td> {{item.id}} </td>
                <td> <span class="badge badge-light-warning">{{item.routing_group_name}}</span></td>
                <td>{{item.vlan_id}}</td>
                <td> {{item.ipv4_address_pool}} </td>
                <td> {{item.ipv4_excluded_addresses}}</td>
                <td> {{item.ipv6_address_pool}} </td>
                <td> {{item.ipv6_excluded_addresses}} </td>
                <td>
                  <a title="Edit" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'" class="svg-icon svg-icon-3 svg-icon-warning">
                    </span>
                  </a>
                  <a title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger">
                    </span>
                  </a>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>

      <div class="separator  my-3 mb-0"></div>

      <div class="card-body p-5">
        <div class="align-items-center d-flex justify-content-between mb-5 px-0">
          <h2 class="mb-0 fs-4"> Protocols </h2>
          <a class="btn btn-sm btn-primary p-3"> Add <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
              class="svg-icon svg-icon-2 me-0" (click)="addProtocol()"></span> </a>
        </div>

        <p> Protocols will run on any aggregator in the assigned routing groups that have a VLAN trunk interface defined
          and either a bond, interface or protocol configured in this space, as well as any aggregators that are
          configured as gateway aggregators.
        </p>

        <div class="overflow-x border">
          <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2">
            <thead class="table-light">
              <tr class="fw-bolder text-dark fs-7">
                <th class="py-4">ID</th>
                <th class="py-4">Routing group </th>
                <th class="py-4">VLAN </th>
                <th class="py-4">IPv4 pool </th>
                <th class="py-4">IPv4 exclusions </th>
                <th class="py-4"> IPv6 pool </th>
                <th class="py-4"> IPv6 exclusions </th>
                <th class="py-4"> Actions </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of protocolsList; let i=index">
                <td class="ps-5"> {{item.id}} </td>
                <td> <span class="badge badge-light-warning">{{item.routing_group_name}}</span></td>
                <td>{{item.vlan_id}}</td>
                <td> {{item.ipv4_address_pool}} </td>
                <td> {{item.ipv4_excluded_addresses}}</td>
                <td> {{item.ipv6_address_pool}} </td>
                <td> {{item.ipv6_excluded_addresses}} </td>
                <td>
                  <a title="Edit" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'" class="svg-icon svg-icon-3 svg-icon-warning">
                    </span>
                  </a>
                  <a title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger">
                    </span>
                  </a>
                </td>
              </tr>
              <!-- <tr>
               <td> 1	 </td>
               <td> <span class="badge badge-light-warning"> Antaresv2 dev space-Default group </span></td>
               <td> 71 </td>
               <td> 192.168.0.1/32 </td>
               <td> — </td>
               <td> — </td>
               <td> — </td>
               <td>
             <a title="Edit"
                 class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2">
                 <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                     class="svg-icon svg-icon-3">
                 </span>
             </a>
             <a title="Delete"
                 class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm">
                 <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                     class="svg-icon svg-icon-3">
                 </span>
             </a>	 </td>
             </tr> -->
            </tbody>
          </table>
        </div>

      </div>

      <div class="separator   my-3 mb-0"></div>

      <div class="card-body p-5">
        <div class="align-items-center d-flex justify-content-between mb-5 px-0">
          <h2 class="mb-0 fs-4"> Filters </h2>
          <a class="btn btn-sm btn-primary p-3"> Add <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
              class="svg-icon svg-icon-2 me-0" (click)="addFilter()"></span> </a>
        </div>

        <div class="table-responsive border">
          <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2">
            <thead class="table-light">
              <tr class="fw-bolder text-dark fs-7">
              <th class="py-4">ID</th>
              <th class="py-4">Name</th>
              <!-- <th class="min-w-40px text-muted fw-bolder">Routing group </th>
            <th class="min-w-40px text-muted fw-bolder">VLAN </th>
            <th class="min-w-40px text-muted fw-bolder">IPv4 pool </th>
            <th class="min-w-40px text-muted fw-bolder">IPv4 exclusions </th>
            <th class="min-w-40px text-muted fw-bolder"> IPv6 pool </th>
            <th class="min-w-40px text-muted fw-bolder"> IPv6 exclusions </th> -->
              <th class="py-4"> Actions </th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of filtersList; let i=index">
                <td class="ps-5"> {{item.id}} </td>
                <td class="ps-5">{{item.name}}</td>
                <!-- <td> <span class="badge badge-light-warning">{{item.routing_group_name}}</span></td>
            <td>{{item.vlan_id}}</td>
            <td>{{item.ipv4_address_pool}} </td>
            <td>{{item.ipv4_excluded_addresses}}</td>
            <td>{{item.ipv6_address_pool}} </td>
            <td>{{item.ipv6_excluded_addresses}} </td> -->
                <td>
                  <a title="Edit" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'" class="svg-icon svg-icon-3 svg-icon-warning">
                    </span>
                  </a>
                  <a title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger">
                    </span>
                  </a>
                </td>
              </tr>
              <!-- <tr>
             <td> 1	 </td>
             <td> <span class="badge badge-light-warning"> Antaresv2 dev space-Default group </span></td>
             <td> 71 </td>
             <td> 192.168.0.1/32 </td>
             <td> — </td>
             <td> — </td>
             <td> — </td>
             <td>
           <a title="Edit"
               class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2">
               <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                   class="svg-icon svg-icon-3">
               </span>
           </a>
           <a title="Delete"
               class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm">
               <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                   class="svg-icon svg-icon-3">
               </span>
           </a>	 </td>
           </tr> -->
            </tbody>
          </table>
        </div>

      </div>

    </div>
  </ng-container>

  <!-- Aggregator routing HTML End -->

  <!-- Private WAN router rules HTML Start -->
  <ng-container *ngIf="selectedTab==3">
    <div class="card mb-5 mb-xl-8 border border-2">

      <div class="card-header align-items-center border-top d-flex justify-content-between mb-0 px-5">
        <h2 class="mb-0 fs-3"> Private WAN router rules </h2>
        <div style="float: right;">
          <button *ngIf="wFrm1 == true || wFrm2 == true || wFrm3 == true || wFrm4 == true || wFrm5 == true" type="submit" class="btn btn-primary mr-2 ml-2" (click)="updateAllRouting()">Save</button>
          <button *ngIf="wFrm1 == false && wFrm2 == false && wFrm3 == false && wFrm4 == false && wFrm5 == false" type="submit" class="btn btn-primary mr-2 ml-2 notallowIcon">Save</button>
        </div>
      </div>

      <div class="card-body p-0">
        <div class="align-items-center d-flex justify-content-between mb-5 p-5 pb-0">
          <h2 class="mb-0 fs-3"> Gateways </h2>
          <ng-select class="" name="addgateways" [(ngModel)]="addGatewayR" class="fs-6 min-w-200px"
            (change)="addGatewayRouterChange()">
            <ng-option value="-1">Add Gateway </ng-option>
            <ng-option value="1"> NAT via PWAN router </ng-option>
            <ng-option value="2"> Dedicated gateway via PWAN router </ng-option>
            <ng-option value="3"> Dedicated gateway via bonder </ng-option>
          </ng-select>
        </div>

        <form [formGroup]="wanForm1" class="px-5 gateways">
          <div formArrayName="data">
            <ng-container *ngIf="wanData1.length > 0">
              <h3 class="fs-5 text-muted"> NAT via PWAN router </h3>
              <div class="overflow-x border">
                <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2 min-w-900">
                  <thead class="table-light fs-7 ">
                    <tr class="fw-bolder text-dark py-4">
                    <th class="py-4">Enabled</th>
                    <th class="py-4">IP</th>
                    <th class="py-4 min-w-200px">Routing groups</th>
                    <th class="py-4"> Note </th>
                    <th class="py-4"> Action </th>
                    </tr>
                  </thead>

                  <tbody>
                      <tr [formGroupName]="i" *ngFor="let x of wanData1?.controls;let i=index">
                        <td>
                          <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox" name="natenbl" formControlName="natenbl"> <label class="mb-0 form-label ms-2">
                            </label>
                          </div>
                        </td>
                        <td> <input type="text" class="form-control form-control-lg form-control-solid" name="natip" formControlName="natip" placeholder=""
                            autocomplete="off" /> </td>

                        <td>
                          <ng-select name="natrouting" formControlName="natrouting" class="select-width" [multiple]="true" placeholder="No routing groups" [readonly]="toggle1[i]">
                            <ng-option *ngFor="let rgp of routingGrps" value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                          </ng-select>
                        </td>

                        <td> <input type="text" class="form-control form-control-lg form-control-solid" name="natnote" formControlName="natnote" autocomplete="off" /> </td>

                        <td>
                          <a *ngIf="!wanData1.controls[i].get('isDeleted')?.value" (click)="onToggle1(i)" title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger"></span>
                          </a>

                          <a *ngIf="wanData1.controls[i].get('isDeleted')?.value" (click)="onToggle1(i)" title="Restore" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'" class="svg-icon svg-icon-3 svg-icon-success"></span>
                          </a>
                        </td>
                      </tr>

                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>
        </form>
        <form [formGroup]="wanForm2" class="px-5 gateways">
          <div formArrayName="data">
            <ng-container *ngIf="wanData2.length > 0">
              <div class="separator   my-5"></div>
              <h3 class="fs-5 text-muted"> Dedicated gateway via PWAN router </h3>
              <div class="overflow-x">
                <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2 min-w-1900">
                  <thead class="table-light">
                    <tr class="fw-bolder text-dark fs-7">
                    <th class="py-4">Enabled</th>
                    <th class="py-4">IP</th>
                    <th class="py-4">Gateway</th>
                    <th class="py-4"> IPv6 </th>
                    <th class="py-4"> IPv6 gateway </th>
                    <th class="py-4"> VLAN </th>
                    <th class="py-4 min-w-200px"> Routing groups </th>
                    <th class="py-4"> Note </th>
                    <th class="py-4"> Action </th>
                    </tr>
                  </thead>

                  <tbody>
                      <tr [formGroupName]="i" *ngFor="let x of wanData2?.controls;let i=index">

                        <td>
                          <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox" name="pwnenbl" formControlName="pwnenbl"> <label class="mb-0 form-label ms-2">
                            </label>
                          </div>
                        </td>

                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="pwnip" formControlName="pwnip" placeholder="" autocomplete="off" /></td>
                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="pwngate" formControlName="pwngate" placeholder="" autocomplete="off" /></td>
                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="pwnip6" formControlName="pwnip6" placeholder="" autocomplete="off" /></td>
                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="pwnip6gate" formControlName="pwnip6gate" placeholder="" autocomplete="off" /></td>
                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="pwnvlan" formControlName="pwnvlan" placeholder="" autocomplete="off" /></td>
                        <td>
                          <ng-select name="pwnrouting" formControlName="pwnrouting" class="select-width" [multiple]="true" placeholder="No routing groups">
                            <ng-option *ngFor="let rgp of routingGrps" value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                          </ng-select>
                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="pwnnote" formControlName="pwnnote" placeholder="" autocomplete="off" /></td>

                        <td>
                          <a *ngIf="!wanData2.controls[i].get('isDeleted')?.value" (click)="onToggle2(i)" title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger"></span>
                          </a>

                          <a *ngIf="wanData2.controls[i].get('isDeleted')?.value" (click)="onToggle2(i)" title="Restore" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'" class="svg-icon svg-icon-3 svg-icon-success"></span>
                          </a>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>
        </form>
        <form [formGroup]="wanForm3" class="px-5 gateways">
          <div formArrayName="data">
            <ng-container *ngIf="wanData3.length > 0">
              <div class="separator   my-5"></div>
              <h3 class="fs-5 text-muted"> Dedicated gateway via bonder </h3>
              <div class="overflow-x">
                <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2">
                  <thead class="table-light">
                    <tr class="fw-bolder text-dark fs-7 ">
                    <th class="py-4">Enabled</th>
                    <th class="py-4">Gateway</th>
                    <th class="py-4">IPv6 gateway </th>
                    <th class="py-4 min-w-200px"> Routing groups </th>
                    <th class="py-4"> Note </th>
                    <th class="py-4"> Action </th>
                  </tr>
                  </thead>

                  <tbody>
                      <tr [formGroupName]="i" *ngFor="let x of wanData3?.controls;let i=index">
                        <td>
                          <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox" name="bndenbl" formControlName="bndenbl"> <label class="mb-0 form-label ms-2">
                            </label>
                          </div>
                        </td>

                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="bndgate" formControlName="bndgate" placeholder="" autocomplete="off" /></td>
                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="bndip6gate" formControlName="bndip6gate" placeholder="" autocomplete="off" /></td>
                        <td>
                          <ng-select name="bndrouting" formControlName="bndrouting" class="select-width" [multiple]="true" placeholder="No routing groups">
                            <ng-option *ngFor="let rgp of routingGrps" value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                          </ng-select>
                        </td>
                        <td><input type="text" class="form-control form-control-lg form-control-solid" name="bndnote" formControlName="bndnote" placeholder="" autocomplete="off" /></td>

                        <td>
                          <a *ngIf="!wanData3.controls[i].get('isDeleted')?.value" (click)="onToggle3(i)" title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger"></span>
                          </a>

                          <a *ngIf="wanData3.controls[i].get('isDeleted')?.value" (click)="onToggle3(i)" title="Restore" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                            <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'" class="svg-icon svg-icon-3 svg-icon-success"></span>
                          </a>
                        </td>
                      </tr>

                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>
        </form>

        <div class="card-header align-items-center border-top d-flex justify-content-between mb-0 py-5 mt-5 border-bottom-0 pb-0">
          <h2 class="mb-0 fs-3"> NAT Rules </h2>
          <a class="btn btn-sm btn-primary p-3" (click)="addNttForm()"> Add NAT rule <span
              [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-2 me-0"></span> </a>
        </div>
        <form [formGroup]="nttForm" class="px-5 nat-rules">
          <div formArrayName="data">
            <p> 1:1 NAT rules allow incoming traffic to a public IP address to be forwarded to a specific host in the PWAN
              space.
            </p>
            <div *ngIf="nttData.length > 0" class="overflow-x mb-5">
              <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2 min-w-1900">
                <thead class="table-light">
                  <tr class="fw-bolder text-dark fs-7 ">
                  <th class="py-4"></th>
                  <th class="py-4"> Enable</th>
                  <th class="py-4">Public IP </th>
                  <th class="py-4">Private IP </th>
                  <th class="py-4 min-w-200px">Routing groups</th>
                  <th class="py-4"> Note </th>
                  <th class="py-4"> Action </th>
                  </tr>
                </thead>

                <tbody>
                  <tr [formGroupName]="i" *ngFor="let x of nttData?.controls;let i=index">
                    <td><a href="javascript:void(0)" class="hoverGrab"><i class="fa fa-bars"></i></a></td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" name="nttenbl" formControlName="nttenbl"> <label class="mb-0 form-label ms-2">
                        </label>
                      </div>
                    </td>
                    <td><input type="text" class="form-control form-control-lg form-control-solid" name="nttpubip" formControlName="nttpubip" placeholder="" autocomplete="off" /></td>
                    <td><input type="text" class="form-control form-control-lg form-control-solid" name="nttpriip" formControlName="nttpriip" placeholder="" autocomplete="off" /></td>
                    <td>
                      <ng-select name="nttrouting" formControlName="nttrouting" class="select-width" [multiple]="true" placeholder="No routing groups" [readonly]="toggle4[i]">
                        <ng-option *ngFor="let rgp of routingGrps" value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                      </ng-select>
                    </td>
                    <td><input type="text" class="form-control form-control-lg form-control-solid" name="nttnote" formControlName="nttnote" placeholder="" autocomplete="off" /></td>
                    <td>
                      <a *ngIf="!nttData.controls[i].get('isDeleted')?.value" (click)="onNttToggle(i)" title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger"></span>
                      </a>

                      <a *ngIf="nttData.controls[i].get('isDeleted')?.value" (click)="onNttToggle(i)" title="Restore" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'" class="svg-icon svg-icon-3 svg-icon-success"></span>
                      </a>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>

        <div class="align-items-center border-top d-flex justify-content-between mb-0 mt-5 p-5 border-bottom-0 pb-0">
          <h2 class="mb-0 fs-3">Port Forwards </h2>
          <a class="btn btn-sm btn-primary p-3" (click)="addPrtForm()"> Add port forward rule <span
              [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-2 me-0"></span> </a>
        </div>

        <form [formGroup]="prtForm" class="px-5 port-forwards">
          <div formArrayName="data">
            <p class="mt-3">Port Forward rules allow incoming traffic to a public IP/protocol/port to be forwarded to a specific private
              IP/port of a host in the PWAN space.
            </p>
            <div *ngIf="prtData.length > 0" class="overflow-x mb-5">
              <table class="table table-row-bordered table-row-gray-300 align-middle gs-2 gy-2 min-w-1900">
                <thead class="table-light">
                  <tr class="fw-bolder text-dark fs-7 ">
                  <th class="py-4"></th>
                  <th class="py-4">Enabled</th>
                  <th class="py-4">Protocol</th>
                  <th class="py-4">Public IP </th>
                  <th class="py-4"> Public port </th>
                  <th class="py-4"> Private IP </th>
                  <th class="py-4"> Private port </th>
                  <th class="py-4 min-w-150px"> Routing groups </th>
                  <th class="py-4"> Helper </th>
                  <th class="py-4"> Note </th>
                  <th class="py-4"> Action </th>
                  </tr>
                </thead>

                <tbody>
                  <tr [formGroupName]="i" *ngFor="let x of prtData?.controls;let i=index">
                    <td><a href="javascript:void(0)" class="hoverGrab"><i class="fa fa-bars"></i></a></td>
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" name="prtenbl" formControlName="prtenbl"> <label class="mb-0 form-label ms-2">
                        </label>
                      </div>
                    </td>
                    <td>
                      <ng-select name="prtprotocol" formControlName="prtprotocol" class="select-width" (change)="isPrtProtocol(i)">
                        <ng-option value="ICMP"> ICMP </ng-option>
                        <ng-option value="IGMP"> IGMP </ng-option>
                        <ng-option value="TCP"> TCP </ng-option>
                        <ng-option value="UDP"> UDP </ng-option>
                        <ng-option value="GRE"> GRE </ng-option>
                        <ng-option value="ESP"> ESP </ng-option>
                        <ng-option value="SCTP"> SCTP </ng-option>
                      </ng-select>
                    </td>
                    <td><input type="text" class="form-control form-control-lg form-control-solid" name="prtpubip" formControlName="prtpubip" placeholder="" autocomplete="off" /></td>
                    <td><input type="text" class="form-control form-control-lg form-control-solid" id="prtpubport{{i}}" name="prtpubport" formControlName="prtpubport" placeholder="" autocomplete="off" /></td>

                    <td><input type="text" class="form-control form-control-lg form-control-solid" name="prtpriip" formControlName="prtpriip" placeholder="" autocomplete="off" /></td>
                    <td><input type="text" class="form-control form-control-lg form-control-solid" id="prtpriport{{i}}" name="prtpriport" formControlName="prtpriport" placeholder="" autocomplete="off" /></td>

                    <td>
                      <ng-select name="prtrouting" formControlName="prtrouting" class="select-width" [multiple]="true" placeholder="No routing groups" [readonly]="toggle5[i]">
                        <ng-option *ngFor="let rgp of routingGrps" value="{{rgp.url}}">{{rgp.display_name}}</ng-option>
                      </ng-select>
                    </td>

                    <td>
                      <ng-select name="prthelper" formControlName="prthelper" class="select-width">
                        <ng-option value=""></ng-option>
                        <ng-option value="ftp"> FTP </ng-option>
                        <ng-option value="irc"> IRC </ng-option>
                        <ng-option value="pptp"> PPTP </ng-option>
                        <ng-option value="sip"> SIP </ng-option>
                        <ng-option value="tftp"> TFTP </ng-option>
                      </ng-select>
                    </td>

                    <td><input type="text" class="form-control form-control-lg form-control-solid" name="prtnote" formControlName="prtnote" placeholder="" autocomplete="off" /></td>

                    <td>
                      <a *ngIf="!prtData.controls[i].get('isDeleted')?.value" (click)="onPrtToggle(i)" title="Delete" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-3 svg-icon-danger"></span>
                      </a>

                      <a *ngIf="prtData.controls[i].get('isDeleted')?.value" (click)="onPrtToggle(i)" title="Restore" class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/arrow-rotate-right-solid.svg'" class="svg-icon svg-icon-3 svg-icon-success"></span>
                      </a>
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </form>


      </div>

      <div class="card-footer p-5">
        <!-- <button *ngIf="wFrm1 == true || wFrm2 == true || wFrm3 == true || wFrm4 == true || wFrm5 == true" type="submit" class="btn btn-info mr-2 ml-2" (click)="updateAllRouting()">Save</button>
        <button *ngIf="wFrm1 == false && wFrm2 == false && wFrm3 == false && wFrm4 == false && wFrm5 == false" type="submit" class="btn btn-default mr-2 ml-2 notallowIcon">Save</button> -->
      </div>

    </div>
  </ng-container>
  <!-- Private WAN router rules HTML End -->
