<form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate"
  [formGroup]="forgotPasswordForm" (ngSubmit)="loading ? '':submit()" id="kt_login_password_reset_form">
  <div class="text-center mb-10">
    <div class="text-gray-400 fw-bold fs-4">
      Enter email to reset your password and 2FA code.
    </div>
  </div>
  <div class="mb-8">
    <div class="form-group inputtext-block mb-5">
      <label class="form-label fw-bolder text-gray-900 fs-6">Email</label>
      <input class="form-control form-control-lg form-control-solid" type="email" formControlName="email"
        [readOnly]="true" placeholder="Email" name="email" autocomplete="off" [ngClass]="{
        'is-invalid': forgotPasswordForm.controls['email'].invalid,
        'is-valid': forgotPasswordForm.controls['email'].valid
      }" />
      <small class="form-invalid-message">
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Email is required',
        control: forgotPasswordForm.controls['email']
      }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
        validation: 'pattern',
        message: 'Email is invalid',
        control: forgotPasswordForm.controls['email']
      }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
        validation: 'minLength',
        message: 'Email should have at least 3 symbols',
        control: forgotPasswordForm.controls['email']
      }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Email should have maximum 360 symbols',
        control: forgotPasswordForm.controls['email']
      }"></ng-container>
      </small>
    </div>
  </div>
  <div class="mb-5 d-flex align-items-center justify-content-center">
    <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
  </div>
  <div class="d-flex flex-wrap justify-content-center pb-lg-0">
    <button [disabled]="forgotPasswordForm.invalid || !isCaptcha" type="submit" id="kt_password_reset_submit"
      class="btn btn-lg btn-primary fw-bolder me-4 br-6">
      <span *ngIf="!loading" class="indicator-label">Submit</span>
      <ng-container *ngIf="loading">
        <span class="indicator-progress" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </ng-container>
    </button>
    <button *ngIf="!loading" (click)="cancel()" id="kt_login_password_reset_form_cancel_button"
      class="btn btn-lg btn-light-primary fw-bolder br-6">
      Cancel
    </button>
  </div>
</form>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>