import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from 'src/app/_metronic/kt/components';
import { AuthService } from 'src/app/modules/shared/auth';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { LayoutService } from '../../core/layout.service';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent implements OnInit, OnDestroy {
  asideTheme: string = '';
  asideMinimize: boolean = false;
  asideMenuCSSClasses: string = '';
  asideDisplay: boolean;
  navBarImageUrl: any;
  faviconImageUrl: any;
  // appPreviewDocsUrl: string = environment.appPreviewDocsUrl;
  private unsubscribe: Subscription[] = [];
  isNavImageSelected: boolean;
  isNavTextSelected: boolean;
  isAsideMinimize: boolean = false;
  navBarText: any;
  routerLink: string;

  @ViewChild('ktAsideScroll', { static: true }) ktAsideScroll: ElementRef;
  @ViewChild('kt_aside_logo', { static: true }) kt_aside_logo: ElementRef;

  constructor(private layout: LayoutService, private router: Router,
    private elRef: ElementRef, private renderer: Renderer2,
    private authService: AuthService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.asideTheme = this.layout.getProp('aside.theme') as string;
    this.asideMinimize = this.layout.getProp('aside.minimize') as boolean;
    this.asideDisplay = this.layout.getProp('aside.display') as boolean;
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('asideMenu');
    this.fetchNavOptions();
    this.routingChanges();
    this.fetchRouterLink();
    this.detectWidthChanges();
  }

  fetchRouterLink() {
    if (Number(localStorage.getItem('role')) == AppConst.admin
      || Number(localStorage.getItem('role')) == AppConst.basicUser) {
      this.routerLink = '/dashboard'
    } else if (Number(localStorage.getItem('role')) == AppConst.partner) {
      this.routerLink = '/partner/dashboard'
    } else if (Number(localStorage.getItem('role')) == AppConst.user) {
      this.routerLink = '/partner/dashboard'
    } else if (Number(localStorage.getItem('role')) == AppConst.organization) {
      this.routerLink = '/partner/dashboard'
    }
  }

  fetchNavOptions() {
    this.authService.navBar$.subscribe((data: any) => {
      if (data?.image) this.navBarImageUrl = data.image;
      if (data?.text) this.navBarText = data.text;
      if (data?.favicon) this.faviconImageUrl = data.favicon;
      this.cd.detectChanges();
    })
    // if(data.type==1){
    //      this.isNavImageSelected=true;
    //      this.isNavTextSelected=false;
    //      this.navBarImageUrl=data.content
    // }
    // if(data.type==2){
    //   this.isNavTextSelected=true;
    //   this.isNavImageSelected=false;
    //   this.navBarText=data.content
    // }
  }

  routingChanges() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.menuReinitialization();
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  menuReinitialization() {
    setTimeout(() => {
      MenuComponent.reinitialization();
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
      ScrollComponent.reinitialization();
      if (this.ktAsideScroll && this.ktAsideScroll.nativeElement) {
        this.ktAsideScroll.nativeElement.scrollTop = 0;
      }
    }, 50);
  }

  animationAdd() {
    const asideRef = document.getElementById('kt_aside');
    asideRef?.classList.add('animating');
    setTimeout(() => asideRef?.classList.remove('animating'), 1000);
  }

  detectWidthChanges() {
    const divElement = this.elRef.nativeElement;
    this.renderer.listen(divElement, 'transitionend', (event: TransitionEvent) => {
      if (event.propertyName === 'width') {
        this.isAsideMinimize = divElement.offsetWidth > 75 ? false : true;
        console.log('Width changed:transitionend', divElement.offsetWidth, this.isAsideMinimize);
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
