import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BrandingService } from 'src/app/services/branding.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '.';
import { AuthHTTPService } from './services/auth-http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  favIcon: any = document.querySelector('#appIcons');
  today: Date = new Date();
  loginPageImg: string;
  isResult: boolean = false;

  constructor(private cd: ChangeDetectorRef, private authHTTPService: AuthHTTPService, private titleService: Title,
    private authService: AuthService, private router: Router, private brandSettingService: BrandingService) { }

  ngOnInit(): void {
    document.body.classList.add('bg-white');
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url == '/auth/login/partner' && !this.loginPageImg) this.dnsConfig();
        if (ev.url == '/auth/login') {
          this.loginPageImg = '';
          this.cd.detectChanges();
        }
      }
    })
    this.dnsConfig();
  }

  dnsConfig() {
    localStorage.clear();
    let hostname = window.location.hostname;
    // let hostname = "mtp.antares-sdwan.com"
    if (!(hostname == environment.domainUrl || hostname == environment.appUrl.split('/')[2] || hostname == 'localhost')) {
      if (hostname.includes(environment.domainUrl)) {
        let domainName = hostname.split('.')[0];
        this.authService.spinner.show();
        this.authHTTPService.getBrandSettingBySubdomain(domainName).subscribe((res) => {
          if (res && res.code == 200 && res.data) {
            if (this.router.url == '/auth/login') this.router.navigate(['/auth/login/partner']);
            if (this.router.url != '/auth/login') {
              if (res?.data && res.data?.loginPageImg) this.loginPageImg = res.data?.loginPageImg;
              if (res?.data && res.data?.navBarText) localStorage.setItem('navBarText', res.data?.navBarText);
              if (res?.data && res.data?.favicon) localStorage.setItem('favIconForApp', res.data?.favicon);
              this.authService.setCssProperty('--buttonPrimaryColour', res.data?.buttonPrimaryColour);
              this.authService.setCssProperty('--buttonTextColour', res.data?.buttonTextColour);
              this.favIcon.href = res.data?.favicon;
              this.titleService.setTitle(res.data?.navBarText || 'Fusion Broadband');
              if (res.data.mainId) localStorage.setItem('mainId', res.data.mainId);
            }
          }
          this.isResult = true;
          this.authService.spinner.hide();
          this.cd.detectChanges();
        }, (err) => {
          this.isResult = true;
          this.authService.spinner.hide();
          this.router.navigate(['/error']);
          this.cd.detectChanges();
        })
      } else this.isResult = true;
    } else {
      this.brandSettingService.getBrandSettingData('/admin/branding/details').subscribe((res: any) => {
        if (res && res.code == 200 && res.data && res.data.loginPageImg) {
          this.loginPageImg = res.data.loginPageImg;
          localStorage.setItem('navBarText', res.data?.navBarText);
          localStorage.setItem('favIconForApp', res.data?.favicon);
          this.authService.setCssProperty('--buttonPrimaryColour', res.data?.buttonPrimaryColour);
          this.authService.setCssProperty('--buttonTextColour', res.data?.buttonTextColour);
          this.favIcon.href = res.data?.favicon;
          this.titleService.setTitle(res.data?.navBarText || 'Fusion Broadband');
        }
        this.isResult = true;
        this.authService.spinner.hide();
        this.cd.detectChanges();
      }, (err) => {
        this.isResult = true;
        this.authService.spinner.hide();
        this.cd.detectChanges();
      });
    }
  }

  ngOnDestroy() {
    document.body.classList.remove('bg-white');
  }
}
