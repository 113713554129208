import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandingService } from 'src/app/services/branding.service';
import { AppConst, coreMailType, emailType } from 'src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as customEditor from '../../../../ckeditor-build/build/ckeditor'

@Component({
  selector: 'app-shared-email-send-form',
  templateUrl: './shared-email-send-form.component.html',
  styleUrls: ['./shared-email-send-form.component.scss']
})
export class SharedEmailSendFormComponent implements OnInit {

  partnerList: any[] = [];
  emailTemplate: any;

  currentEditor = "header";

  editorConfig = {
    toolbar: {
      items: [
        'heading', '|', 'alignment:left', "alignment:right", "alignment:center",
        "alignment:justify", 'fontfamily', 'fontcolor', 'fontbackgroundcolor',
        '|', "blockQuote", "bold", 'italic', "selectAll", "undo", "redo", "|",
        "htmlEmbed", "link", "mediaEmbed", "|", "insertImage",
        "imageStyle:inline"
        , "imageStyle:alignLeft"
        , "imageStyle:alignRight"
        , "imageStyle:alignCenter"
        , "imageStyle:alignBlockLeft"
        , "imageStyle:alignBlockRight"
        , "imageStyle:block"
        , "imageStyle:side"
        , "imageStyle:wrapText"
        , "imageStyle:breakText", "|",
        "indent", "outdent", "|",
        "numberedList", "bulletedList", "|",
        "sourceEditing", "|",
        "insertTable", "tableColumn", "tableRow", "mergeTableCells"
      ],
      shouldNotGroupWhenFull: true
    },
    language: 'en',
  }

  basicEditor: any;
  editor = customEditor;
  sendEmailTemplateForm: FormGroup;
  foundationArray = AppConst.foundationList;
  emailValidator: any = AppConst.emailVal;
  emailType = emailType;
  isDisabled: boolean;
  sharedS = SharedService;
  coreMailType = coreMailType;
  emailItems: any[] = [];
  isDuplicated: boolean;
  isNoValue: boolean;
  txt = document.getElementById('txt');
  list = document.getElementById('list');
  isInvalidEmail: boolean;
  isNoOptionSelected: boolean;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    public brandingService: BrandingService,
    private formErrorHandler: FormErrorService,
  ) { }


  ngOnInit(): void {
    this.init();
  }

  init() {
    this.sendEmailTemplateForm = this.createNewForm(this.emailTemplate);
    if (this.emailTemplate.moduleType == emailType.core) {
      this.sendEmailTemplateForm.controls['customMailType'].patchValue(this.sharedS.prettifyCamelCase(this.coreMailType[this.emailTemplate.coreMailType]));
      this.isDisabled = true;
    }
    else {
      this.isDisabled = false;
    }
  }

  setEditorValueOnFoundationChange(event: any) {
    if (event) {
      const content = event;
      const viewFragment = this.basicEditor.data.processor.toView(content);
      const modelFragment = this.basicEditor.data.toModel(viewFragment);
      this.basicEditor.model.insertContent(modelFragment);
      this.onReady(this.basicEditor);
    }
  }

  onEnter(event: any) {
    event.preventDefault();
    let email = event.target.value;

    if (email) {
      if (email.match(this.emailValidator)) {
        this.isInvalidEmail = false;
        if (this.emailItems.indexOf(email) >= 0) {
          this.isDuplicated = true;
          return;
        }
        else {
          this.isDuplicated = false;
          this.emailItems.push(email);
          event.target.value = "";
        }
      }
      else {
        this.isInvalidEmail = true;
      }

    }
    else {
      this.isNoValue = true;
      return;
    }
  }

  onEmailBackspace(event: any) {
    if (event.target.value) return;
    this.emailItems.pop();
  }

  removeEmailItem(item: any) {
    this.isDuplicated = false;
    this.isInvalidEmail = false;
    this.emailItems.splice(this.emailItems.indexOf(item), 1);
  }




  public onReady(editor: any) {
    this.basicEditor = editor;
  }

  createNewForm(formValues: any = {}) {
    return this.fb.group({
      // _id: this.fb.control(formValues?._id),
      moduleType: this.fb.control(formValues?.moduleType),
      // coreMailType: this.fb.control(formValues?.coreMailType),
      customMailSubject: this.fb.control(formValues?.customMailSubject, Validators.required),
      // customMailType: this.fb.control(formValues?.customMailType, Validators.required),
      // headerTemplate: this.fb.control(formValues?.headerTemplate),
      styleTemplate: this.fb.control(formValues?.styleTemplate),
      // footerTemplate: this.fb.control(formValues?.footerTemplate),
      partnerIds: this.fb.control('', Validators.required),
      //customEmail: this.fb.control('', [Validators.pattern(this.emailValidator), Validators.required])
    });
  }

  send() {
    if (!this.emailItems.length && !this.sendEmailTemplateForm.value.partnerIds) {
      this.isNoOptionSelected = true;
    }
    else if (this.emailItems.length && !this.sendEmailTemplateForm.value.partnerIds) {
      this.sendEmailTemplateForm.get('partnerIds')?.clearValidators();
      this.sendEmailTemplateForm.get('partnerIds')?.updateValueAndValidity();
      this.isNoOptionSelected = false;
    }
    else if (this.sendEmailTemplateForm.value.partnerIds && !this.emailItems.length) {
      this.isNoOptionSelected = false;
      this.sendEmailTemplateForm.get('partnerIds')?.setValidators([Validators.required]);
      this.sendEmailTemplateForm.get('partnerIds')?.updateValueAndValidity();
    }
    if (this.sendEmailTemplateForm.invalid) {
      this.sendEmailTemplateForm.markAllAsTouched();
      return;
    }
    let data: any[] = this.sendEmailTemplateForm.value.partnerIds;
    let list: any[] = [];
    if (data) {
      list = this.partnerList
        .filter(x => data.findIndex(y => y == x._id) >= 0)
        .map(x => x.email);
    }

    let arr = [...list, ...this.emailItems];
    if (!this.sendEmailTemplateForm.controls['partnerIds'].value) {
      delete this.sendEmailTemplateForm.value.partnerIds
    }
    // console.log(arr);
    this.modal.close({ ...this.sendEmailTemplateForm.value, partnerEmails: arr });
  }

  setSubjectFoundation(event: any) {
    // if (event?.length) {
    let value
    if (event == undefined) {
      return
    }
    value = this.sendEmailTemplateForm.controls['customMailSubject'].value;

    this.sendEmailTemplateForm.controls['customMailSubject'].patchValue(value + ' ' + event);
    //}
  }

  isControlValid(controlName: string): boolean {
    return this.formErrorHandler.isControlValid(controlName, this.sendEmailTemplateForm);
  }

  isControlInvalid(controlName: string): boolean {
    return this.formErrorHandler.isControlInvalid(controlName, this.sendEmailTemplateForm);
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.sendEmailTemplateForm);
  }

  isControlTouched(controlName: string): boolean {
    return this.formErrorHandler.isControlTouched(controlName, this.sendEmailTemplateForm);
  }

}
