import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-policies-classification-form',
  templateUrl: './policies-classification-form.component.html',
  styleUrls: ['./policies-classification-form.component.scss']
})
export class PoliciesClassificationFormComponent implements OnInit, OnDestroy {
  classificationDetailsForm: FormGroup;
  packetDetailsForm: FormGroup;
  spaceList: any[] = [];
  arrPacketDetails: any[] = [];
  errors: any;
  classificationId: any;
  spaceName: any;
  array: any;
  profileData: any;
  homeSpace: any;
  packetDetailsArr: any;
  apiURL: any = null;
  classificationDetailsView: boolean;
  isEdit: boolean;
  isAdd: boolean;
  isView: boolean;
  isPartner: boolean = false;
  isSharedClassification: boolean = false;
  disableAddFilter: boolean;
  disableSaveOrder: boolean = true;
  arrDefaultTarget: any = [
    { 'default_target': 'tunnel-bypass' },
    { 'default_target': 'tunnel' }
  ];
  arrPacketTargets: any = [
    { 'target': 'tunnel-bypass' },
    { 'target': 'tunnel' }
  ];

  constructor(private fb: FormBuilder,
    private policiesService: PoliciesService,
    private sharedService: SharedService,
    private formErrorHandler: FormErrorService,
    private bondService: PartnerBondingService,
    private activatedRouter: ActivatedRoute,
    private cookie: CookieService,
    private router: Router, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (Number(localStorage.getItem('role')) === AppConst.partner ||
      (Number(localStorage.getItem('role')) == AppConst.user &&
        Number(localStorage.getItem('parentRole')) == AppConst.partner)) this.isPartner = true;
    this.homeSpace = localStorage.getItem('homeSpace') && localStorage.getItem('homeSpace') != 'undefined' ? localStorage.getItem('homeSpace') : null;
    let space = localStorage.getItem('homeSpace');
    this.homeSpace = space && space != 'undefined' ? space : null;
    if (this.cookie.check('api_url')) this.apiURL = this.cookie.get('api_url');
    if (this.apiURL) {
      if (Number(localStorage.getItem('role')) == AppConst.partner) {
        this.getSpaceList();
      } else if (Number(localStorage.getItem('role')) == AppConst.organization) {
        if (this.homeSpace != null) {
          if (this.homeSpace == '0') {
            this.spaceList = [];
            this.homeSpace = null;
          } else this.getSpaces();
        }
      } else if (Number(localStorage.getItem('role')) == AppConst.user) {
        if (Number(localStorage.getItem('parentRole')) == AppConst.partner) {
          this.getSpaceList();
        } else if (Number(localStorage.getItem('parentRole')) == AppConst.organization) {
          if (this.homeSpace != null) {
            if (this.homeSpace == '0') {
              this.spaceList = [];
              this.homeSpace = null;
            } else this.getSpaces();
          }
        }
      }
    }
    this.getInfo();
  }

  //  Get all the available space list.
  getSpaceList() {
    this.sharedService.showLoader();
    this.policiesService.getAllSpace()?.subscribe((res: any) => {
      if ((res.code == 200 || res.code == 201) && res?.data) this.spaceList = res.data;
      else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err: any) => {
      this.sharedService.hideLoader();
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  getSpaces() {
    this.sharedService.showLoader();
    let url = `spaces/?as_tree=true`;
    this.bondService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res.data) {
        if (this.homeSpace != null) {
          let tmpSpaceList: any = [];
          const addedf = (array: any = []): any => {
            if (array && array.length > 0) {
              array.forEach((item: any) => {
                if (item?.level != 0) tmpSpaceList.push(item);
                if (item.child_spaces && item.child_spaces.length > 0) addedf(item.child_spaces);
              })
            }
          }

          const findedf = (array: any = [], id: string): any => {
            if (array && array.length > 0) {
              for (const item of array) {
                if (item.key === id) {
                  tmpSpaceList.push(item);
                  if (item.child_spaces && item.child_spaces.length > 0) addedf(item.child_spaces);
                  return;
                } else findedf(item.child_spaces, id);
              }
            }
          };

          findedf(res.data, this.homeSpace);
          this.spaceList = tmpSpaceList;
        } else {
          let tmpSpaceList: any = [];
          const addedf = (array: any = []): any => {
            if (array && array.length > 0) {
              array.forEach((item: any) => {
                if (item?.level != 0) tmpSpaceList.push(item);
                if (item.child_spaces && item.child_spaces.length > 0) addedf(item.child_spaces);
              })
            }
          }
          addedf(res.data);
          this.spaceList = tmpSpaceList;
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }
    }, (err: any) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  /*
   Get classfication details for edit record or add record
  */
  getInfo() {
    // Initialize the form group and form array
    this.classificationDetailsForm = this.createDetailsForm();
    this.packetDetailsForm = this.fb.group({ packetDetailsArray: this.fb.array([]) });
    this.classificationId = this.sharedService.getCipherText('classificationId');
    if (this.classificationId) {
      this.isEdit = true;
      this.isView = true;
      this.getClassificationDetailsById(this.classificationId);
      this.isSharedClassification = this.sharedService.getCipherBoolean('isSharedClassification') ?? false;
    } else this.isAdd = true;
  }

  /*
   Get classfication details record based on id
  */
  getClassificationDetailsById(classificationId: any) {
    this.policiesService.getClassificationDetailsById(classificationId)?.subscribe((res: any) => {
      if ((res.code == 200 || res.code == 201) && res?.data) {
        // will use for add packet filter api
        this.profileData = res?.data;
        this.spaceName = res?.data?.space?.name;
        res.data.space = res?.data?.space.url;
        // this.arrPacketDetails = res?.data?.packet_filter_list;
        // Populate the form array with initial data from the 'array' variable
        this.populatePacketDetailsForm(res?.data?.packet_filter_list);
        this.classificationDetailsForm.patchValue(res?.data);
        if (!(this.homeSpace || this.isPartner)) this.classificationDetailsForm.controls.space.disable();
        this.cd.detectChanges();
      } else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err: any) => {
      this.sharedService.hideLoader();
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  saveOrder() {
    this.sharedService.showLoader();
    let url = `classification/packet_filters/`;
    let method = 'PATCH';
    let payload: any = [];
    if (this.packetArr.controls && this.packetArr.value?.length > 0) this.packetArr.value.forEach((filter: any, i: number) => payload.push({ order: i, url: filter.url }))
    this.policiesService.saveOrder({ url, method, data: payload })?.subscribe((res: any) => {
      if ((res.code == 200 || res.code == 201) && res?.data) this.disableSaveOrder = true;
      else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err: any) => {
      this.sharedService.hideLoader();
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  /*
   Redirect to classification list page
  */
  onBack() {
    this.router.navigate(['/partner/policies/classification'])
  }

  /*
  Add or update classfication details section
 */
  onSubmit() {
    if (this.classificationDetailsForm.invalid) {
      this.classificationDetailsForm.markAllAsTouched();
      return;
    }
    let data = {
      default_target: this.classificationDetailsForm.value.default_target,
      name: this.classificationDetailsForm.value.name,
      space: {
        url: this.classificationDetailsForm.value.space
      }
    };
    let method;
    let url;
    if (this.isEdit) {
      method = 'PATCH';
      url = `classification/profiles/${this.classificationId}/`;
    } else {
      method = 'POST';
      url = `classification/profiles/`;
    }
    this.sharedService.showLoader();
    this.policiesService.classificationDetailsUpdate(url, method, data)?.subscribe((res: any) => {
      if ((res.code == 200 || res.code == 201) && res?.data) {
        if (this.isAdd) this.router.navigate([`/partner/policies/classification/edit/${res?.data?.id}`])
        this.isView = true;
        this.spaceName = res?.data?.space?.name;
        res.data.space = res?.data?.space.url;
        this.classificationDetailsForm.patchValue(res?.data);
        this.cd.detectChanges();
      } else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err: any) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  /*
    Add/update packet filter API 
  */
  editPacketDetails(packetDetails: any) {
    if (packetDetails?.value) packetDetails = packetDetails?.value;
    if (packetDetails?.protocol == 'null') packetDetails.protocol = null;
    if (packetDetails?.dscp_class == 'null') packetDetails.dscp_class = null;
    if (packetDetails?.source_network == '') packetDetails.source_network = null;
    if (packetDetails?.destination_network == '') packetDetails.destination_network = null;
    let method;
    let url;
    if (packetDetails?.id) {
      method = "PATCH";
      url = `classification/packet_filters/${packetDetails?.id}/`;
    } else {
      method = "POST";
      url = `classification/packet_filters/`;
      packetDetails.profile = this.profileData;
    }
    this.sharedService.showLoader();
    const packetDetailsArray = this.packetDetailsForm.get('packetDetailsArray') as FormArray;
    this.policiesService.editPacketDetails(url, method, packetDetails)?.subscribe((res: any) => {
      if (res.code == 200 || res.code == 201 || res.code == 204) {
        this.sharedService.loggerSuccess('packet filter has been updated successfully');
      }
      else this.sharedService.loggerError(res.message);
      if (!packetDetails?.id) {
        this.disableAddFilter = false;
        packetDetailsArray?.controls[packetDetailsArray?.controls?.length - 1]?.get('isEdit')?.setValue(false);
        res.data.isEdit = false;
        res.data.isError = false;
        if (res?.data?.protocol == null) res.data.protocol = 'null';
        if (res?.data?.dscp_class == null) res.data.dscp_class = 'null';
        packetDetailsArray?.controls[packetDetailsArray?.controls?.length - 1].setValue(res?.data);
      } else {
        // Loop through the packetDetailsArray and find the object with the matching id
        for (let i = 0; i < packetDetailsArray?.value.length; i++) {
          const packetControl = packetDetailsArray.at(i);
          if (packetControl.get('id')?.value === packetDetails?.id) {
            packetControl.get('isEdit')?.setValue(false);
            packetControl.get('isError')?.setValue(false);
            break; // Exit the loop as there's no need to continue searching
          }
        }
      }
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err: any) => {
      try {
        for (let i = 0; i < packetDetailsArray?.value.length; i++) {
          const packetControl = packetDetailsArray.at(i);
          if (packetControl.get('id')?.value === packetDetails?.id) {
            packetControl.get('isError')?.setValue(true);
            break; // Exit the loop as there's no need to continue searching
          }
        }
        this.errors = JSON.parse(err);
        this.sharedService.hideLoader();
        if (this.errors?.non_field_errors) this.sharedService.loggerError(this.errors?.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  /*
  Delete packet details.
  */
  packetDelete(id: any) {
    if (id) {
      let questionTitle = 'Are you sure?';
      let text = "<b>You are deleting this packet filter.</b>"
      let confirmButtonText = "Yes, Delete it!"
      this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
        if (result.isConfirmed) {
          this.sharedService.showLoader();
          this.policiesService.deletePacketFilters(id)?.subscribe((res: any) => {
            if (res.code == 200 || res.code == 201 || res.code == 204) this.sharedService.loggerSuccess('packet filter has been deleted successfully');
            else this.sharedService.loggerError(res.message);
            const packetDetailsArray = this.packetDetailsForm.get('packetDetailsArray') as FormArray;

            for (let i = 0; i < packetDetailsArray.length; i++) {
              const packetControl = packetDetailsArray.at(i);
              if (packetControl.get('id')?.value === id) {
                packetControl.get('isEdit')?.setValue(true);
                // Delete the packetControl from the array
                packetDetailsArray.removeAt(i);
                break; // Exit the loop as there's no need to continue searching
              }
            }
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          }, (err: any) => {
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          });
        }
      });
    }
  }

  createDetailsForm(formValues: any = {}) {
    return this.fb.group({
      id: [formValues?.id],
      name: [formValues?.name, [Validators.required]],
      space: [formValues?.space, [Validators.required]],
      default_target: [formValues?.default_target || '', [Validators.required]],
    })
  }

  createAddressOptionForm() {
    return this.fb.group({ data: this.fb.array([]) })
  }

  /*
   Update packetArr value constantly
  */
  get packetArr(): FormArray {
    return this.packetDetailsForm.get("packetDetailsArray") as FormArray
  }

  /*
    Defaul packet filter form
  */
  createPacketFilterForm(packetFilter: any = {}) {
    return this.fb.group({
      profile: this.fb.group({
        url: [packetFilter?.profile?.url],
        id: [packetFilter?.profile?.id],
        name: [packetFilter?.profile?.name],
      }),
      comment: [packetFilter?.comment || ''],
      destination_network: [packetFilter?.destination_network || null],
      destination_ports: [packetFilter?.destination_ports || ''],
      dscp_class: [packetFilter?.dscp_class == null ? 'null' : packetFilter?.dscp_class || null],
      id: [packetFilter?.id || null],
      order: [packetFilter?.order || 0],
      protocol: [packetFilter?.protocol || null],
      source_network: [packetFilter?.source_network || null],
      source_ports: [packetFilter?.source_ports || ''],
      target: [packetFilter?.target || ''],
      isEdit: [packetFilter?.isEdit || false],
      isError: [packetFilter?.isError || false],
      url: [packetFilter?.url || '']
    });
  }

  /*
    Open packet filter edit 
  */
  OnPacketEdit(id: any) {
    const packetDetailsArray = this.packetDetailsForm.get('packetDetailsArray') as FormArray;
    // Loop through the packetDetailsArray and find the object with the matching id
    this.packetDetailsArr = packetDetailsArray.value;
    for (let i = 0; i < packetDetailsArray?.value.length; i++) {
      const packetControl = packetDetailsArray.at(i);
      if (packetControl.get('id')?.value === id) {
        packetControl.get('isEdit')?.setValue(true);
        break; // Exit the loop as there's no need to continue searching
      }
    }
    this.cd.detectChanges();
  }

  /*
    Add packet filter form
  */
  addFilter() {
    const packetDetailsArray = this.packetDetailsForm.get('packetDetailsArray') as FormArray;
    let formgroup = this.fb.group({
      profile: this.fb.group({ url: [''], id: [null], name: [''] }),
      comment: [''],
      destination_network: [null],
      destination_ports: [''],
      dscp_class: ['null'],
      id: [null],
      order: [0],
      protocol: ['null'],
      source_network: [null],
      source_ports: [''],
      target: ['tunnel-bypass'],
      isEdit: [true],
      isError: [false],
      url: ['']
    });
    this.disableAddFilter = true;
    packetDetailsArray.push(formgroup);
    this.cd.detectChanges();
  }

  /*
   close packet filter edit 
 */
  cancelPacketEdit(id: any) {
    const packetDetailsArray = this.packetDetailsForm.get('packetDetailsArray') as FormArray;
    if (id == null || id == 'null') {
      if (packetDetailsArray?.length > 0) {
        packetDetailsArray?.removeAt(packetDetailsArray.length - 1);
        this.disableAddFilter = false;
      }
    } else {
      // Loop through the packetDetailsArray and find the object with the matching id
      for (let i = 0; i < packetDetailsArray?.value.length; i++) {
        const packetControl = packetDetailsArray.at(i);
        if (packetControl.get('id')?.value === id) {
          //set 
          let data = this.packetDetailsArr.find((item: any) => item.id === id);
          data.isEdit = false;
          data.isError = false;
          //set old record data.
          packetControl.patchValue(data);
          break; // Exit the loop as there's no need to continue searching
        }
      }
    }
    this.cd.detectChanges();
  }

  // Function to populate the form array with data from the 'array' variable
  populatePacketDetailsForm(dataArray: any[]): void {
    const packetDetailsArray = this.packetDetailsForm.get('packetDetailsArray') as FormArray;
    dataArray.forEach((packetFilter) => {
      const formGroup = this.fb.group({
        profile: this.fb.group({
          url: [packetFilter?.profile?.url],
          id: [packetFilter?.profile?.id],
          name: [packetFilter?.profile?.name]
        }),
        comment: [packetFilter?.comment || ''],
        destination_network: [packetFilter?.destination_network || null],
        destination_ports: [packetFilter?.destination_ports || ''],
        dscp_class: [packetFilter?.dscp_class == null ? 'null' : packetFilter?.dscp_class || null],
        id: [packetFilter?.id || null],
        order: [packetFilter?.order || 0],
        protocol: [packetFilter?.protocol == null ? 'null' : packetFilter?.protocol || null],
        source_network: [packetFilter?.source_network || null],
        source_ports: [packetFilter?.source_ports || ''],
        target: [packetFilter?.target || ''],
        isEdit: [packetFilter?.isEdit || false],
        isError: [packetFilter?.isError || false],
        url: [packetFilter?.url || '']
      });

      packetDetailsArray.push(formGroup);
    });
  }

  // Display protocol name into view only
  getProtocolName(protocolId: any) {
    if (protocolId == null || protocolId == 'null') return 'Any';
    if (protocolId == '1') return 'ICMP';
    if (protocolId == '2') return 'IGMP';
    if (protocolId == '6') return 'TCP';
    if (protocolId == '17') return 'UDP';
    if (protocolId == '47') return 'GRE';
    if (protocolId == '50') return 'ESP';
    if (protocolId == '58') return 'ICMPv6';
    if (protocolId == '132') return 'SCTP';
  }

  //drag &  drop
  dropPort(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.packetArr?.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.packetArr?.value, event.previousIndex, event.currentIndex);
    this.disableSaveOrder = false;
    this.cd.detectChanges();
  }

  //  default form
  isControlValid(controlName: string): boolean {
    return this.formErrorHandler.isControlValid(controlName, this.classificationDetailsForm);
  }

  isControlInvalid(controlName: string): boolean {
    return this.formErrorHandler.isControlInvalid(controlName, this.classificationDetailsForm);
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.classificationDetailsForm);
  }

  ngOnDestroy() {
    this.sharedService.removeCipherText('classificationId');
    this.sharedService.removeCipherText('isSharedClassification');
  }
}
