import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/';

@Injectable({ providedIn: 'root' })
export class PoliciesService {

    constructor(private http: HttpClient) { }

    getAllClassificationListing(search: string,
        sortBy: any,
        sortOrder: any,
        key?: any, spaceOption?: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `classification/profiles/?`;
        // if (search) url += '&search=' + search;
        // if (sortBy) {
        //     if (sortOrder == -1) url += '&ordering=-' + sortBy;
        //     else url += '&ordering=' + sortBy;
        // } else url += '&ordering=id';
        if (key) {
            if (spaceOption == '0') url += '&space=';
            else url += '&space__under=';
            url += key;
        } else {
            if (spaceOption == '0') url += '&space=';
            else url += '&space__under=';
            url += '__current__';
        }
        return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' });
    }

    getClassificationListing(key?: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `classification/profiles/?space__under=`;
        if (key) url += key;
        else url += '__current__';
        return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' });
    }

    deleteClassification(id: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `classification/profiles/${id}/`;
        return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${mainId}`, { url, method: 'DELETE' });
    }

    copyClassification(id: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `classification/profiles/${id}/copy/`;
        return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${mainId}`, { url, method: 'POST' });
    }

    copyQOS(id: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `qos/${id}/copy/`;
        return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${mainId}`, { url, method: 'GET' });
    }

    deletePacketFilters(id: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `classification/packet_filters/${id}/`;
        return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${mainId}`, { url, method: 'DELETE' });
    }

    getAllQOSListing(search: string,
        // page: number,
        // limit: number,
        sortBy: any,
        sortOrder: any,
        key?: any, spaceOption?: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        // let url = `qos/?page=${page}&page_size=${limit}`;
        let url = `qos/?`;
        // if (search) url += '&search=' + search;
        // if (sortBy) {
        //     if (sortOrder == -1) url += '&ordering=-' + sortBy;
        //     else url += '&ordering=' + sortBy;
        // } else url += '&ordering=id';
        if (key) {
            if (spaceOption == '0') url += '&space=';
            else url += '&space__under=';
            url += key;
        } else {
            if (spaceOption == '0') url += '&space=';
            else url += '&space__under=';
            url += '__current__';
        }
        return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' });
    }

    getQOSListing(key?: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `qos/?space__under=`;
        if (key) url += key;
        else url += '__current__';
        return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' });
    }

    deleteQOS(id: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `qos/${id}/`;
        return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${mainId}`, { url, method: 'DELETE' });
    }

    getAggregatorListing(key?: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `aggregators/?space__under=`;
        if (key) url += key;
        else url += '__current__';
        return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' });
    }

    getAllSpace() {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `flat/spaces/`;
        return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' });
    }

    classificationDetailsUpdate(url: any, method: any, data: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let payload = {
            method: method,
            data: data,
            url: url
        }
        return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${mainId}`, payload);
    }

    getClassificationDetailsById(id: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `classification/profiles/${id}/`;
        return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' });
    }

    getQOSDetailsById(id: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let url = `qos/${id}/`;
        return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' });
    }

    editPacketDetails(url: any, method: any, data: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        let payload = {
            method: method,
            data: data,
            url: url
        }
        return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${mainId}`, payload);
    }

    saveOrder(payload: any) {
        if (!localStorage.getItem('mainId')) return;
        let mainId = localStorage.getItem('mainId');
        return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${mainId}`, payload);
    }
}
