import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MustMatch } from 'src/app/shared/validators/confirm-password.validator';
import { AuthHTTPService } from '../../services/auth-http';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  passwordValidator: any = AppConst.pwdPatternWithValidations;
  private unsubscribe: Subscription[] = [];
  forgotPasswordForm: FormGroup;
  isLoading: boolean = false;
  tokenValue = '';
  show_newbutton: Boolean = false;
  show_confirmbutton: Boolean = false;
  show_neweye: Boolean = false;
  show_confirmeye: Boolean = false;
  isCreatePassword: Boolean = false;
  isPartner: Boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private authHTTPService: AuthHTTPService,
    private router: Router,
    private _router: ActivatedRoute,
    private sharedService: SharedService
  ) {
    this._router.queryParams.subscribe(params => {
      this.tokenValue = params.token;
    });
  }

  ngOnInit(): void {
    if (this.router.url.includes('create')) this.isCreatePassword = true;
    if (this.router.url.includes('partner')) this.isPartner = true;
    this.initForm();
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  initForm() {
    this.forgotPasswordForm = this.fb.group({
      newPass: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(40),
          Validators.pattern(this.passwordValidator)
        ]),
      ],
      confirmPass: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(40),
          Validators.pattern(this.passwordValidator)
        ]),
      ],
    },
      {
        validator: MustMatch('newPass', 'confirmPass')
      }
    );
  }

  submit() {
    if (this.f.invalid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }
    let formData = { token: this.tokenValue, password: this.f.newPass.value };
    if (!this.isPartner) {
      this.adminResetPassword(formData)
    } else {
      this.partnerResetPassword(formData)
    }
  }

  adminResetPassword(data: any) {
    this.isLoading = true;
    const resetPasswordSub = this.authHTTPService.resetPassword(data).subscribe((result: any) => {
      if (result && result?.code == 200) {
        this.sharedService.loggerSuccess(result.message);
        setTimeout(() => {
          this.authService.logout();
        }, 1500);
      } else this.sharedService.loggerError('internal server error');
      this.isLoading = false;
    }, (err: any) => {
      this.isLoading = false;
      this.sharedService.loggerError(err);
    });
    this.unsubscribe.push(resetPasswordSub);
  }

  partnerResetPassword(data: any) {
    this.isLoading = true;
    const resetPasswordSub = this.authHTTPService.createPassword(data).subscribe((result: any) => {
      if (result && result.code == 200) {
        this.sharedService.loggerSuccess(result.message);
        setTimeout(() => {
          this.authService.logout();
        }, 1500);
      } else this.sharedService.loggerError("Server Error");
      this.isLoading = false;
    }, (err: any) => {
      this.isLoading = false;
      this.sharedService.loggerError(err);
    });
    this.unsubscribe.push(resetPasswordSub);
  }

  showNewPassword() {
    this.show_newbutton = !this.show_newbutton;
    this.show_neweye = !this.show_neweye;
  }

  showConfirmPassword() {
    this.show_confirmbutton = !this.show_confirmbutton;
    this.show_confirmeye = !this.show_confirmeye;
  }

  onCancelClicked() {
    if (this.isPartner) this.router.navigate(['/auth/login/partner']);
    else this.router.navigate(['/auth/login']);
  }
}
