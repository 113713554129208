import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { AuthHTTPService, AuthService } from '../..';

@Component({
  selector: 'app-partner-login',
  templateUrl: './partner-login.component.html',
  styleUrls: ['./partner-login.component.scss']
})
export class PartnerLoginComponent implements OnInit {
  siteKey: string = environment.siteKey;
  emailValidator: any = AppConst.emailVal;
  passwordValidator: any = AppConst.pwdPatternWithValidations;
  partnerLoginForm: FormGroup;
  isLoading$: Observable<boolean>;
  partnerAccessArray: any[] = [];
  organizations: any[] = [];
  isLoading: boolean = false;
  isCaptcha: Boolean = false;
  show_button: Boolean = false;
  show_eye: Boolean = false;
  showNext: boolean = false;
  hasOneParent: boolean = false;
  isVisible: any = true;

  @ViewChild('emailId', { read: ElementRef, static: false }) emailId: ElementRef;

  constructor(private fb: FormBuilder,
    private authHTTPService: AuthHTTPService,
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private cookie: CookieService,
    private cd: ChangeDetectorRef,) {
    this.isLoading$ = this.authService.isLoading$;
  }

  ngOnInit(): void {
    this.createNewForm();
    this.clearCookies();
  }

  createNewForm() {
    this.partnerLoginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailValidator)
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(40),
          Validators.pattern(this.passwordValidator)
        ]),
      ],
      parent: ['', [Validators.required]],
      organization: ['', [Validators.required]]
    })
  }

  get email() { return this.partnerLoginForm.get('email') }
  get password() { return this.partnerLoginForm.get('password') }
  get parent() { return this.partnerLoginForm.get('parent') }
  get organization() { return this.partnerLoginForm.get('organization') }

  clearCookies() {
    this.cookie.delete('authToken');
    localStorage.removeItem('isPartnerLoggedInAsUser');
    localStorage.removeItem('ID');
    localStorage.removeItem('IpAddress');
    localStorage.removeItem('Module-Access');
    localStorage.removeItem('Name');
    localStorage.removeItem('role');
    localStorage.removeItem('menu');
    localStorage.removeItem('logInDuration');
    localStorage.removeItem('navBarImage');
    localStorage.removeItem('organization');
    localStorage.removeItem('partnerId');
    localStorage.removeItem('userId');
    localStorage.removeItem('breadCrumbs');
    localStorage.removeItem('userIds');
    this.authService.tfaSubject.next(null);
    this.authService.otpSubject.next(null);
    this.authService.forgotPwdSubject$.next(null);
  }

  onShowNext() {
    if (!this.isLoading) {
      let partnerMainId = localStorage.getItem('mainId') || '';
      if (!partnerMainId) {
        this.sharedService.loggerError("Partner Record not found, Kindly reload web-page");
        return;
      }
      let formValue = this.partnerLoginForm.value;
      if (!formValue.email) {
        this.sharedService.loggerError("Email should not be empty");
        return;
      }
      this.isLoading = true;
      this.emailId.nativeElement.classList.remove('is-valid');
      this.hasOneParent = false;
      this.organizations = [];
      this.sharedService.showLoader();
      this.authHTTPService.getParent(formValue.email, partnerMainId).subscribe((res: any) => {
        if (res && res.data) {
          this.partnerAccessArray = res.data;
          if (partnerMainId) {
            this.partnerAccessArray = this.partnerAccessArray.filter((data) => {
              if (data.mainId == partnerMainId) return true;
              else return false;
            });
          }
          if (this.partnerAccessArray && this.partnerAccessArray.length <= 0) {
            this.sharedService.loggerError('Record not found OR User is deactivated');
            this.showNext = false;
            this.isLoading = false;
            this.hasOneParent = false;
          } else {
            if (this.partnerAccessArray.length == 1) {
              this.hasOneParent = true;
              this.partnerLoginForm.patchValue({ parent: this.partnerAccessArray[0] });
              this.setOrganizations(this.partnerAccessArray[0]);
            }
            this.showNext = true;
          }
        }
        this.isLoading = false;
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        this.showNext = false;
        this.isLoading = false;
        this.hasOneParent = false;
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      });
    }
  }

  setOrganizations(data: any) {
    if (data.role == AppConst.partner) {
      this.partnerLoginForm?.get('organization')?.clearValidators();
      this.partnerLoginForm.get('organization')?.updateValueAndValidity();
      this.isVisible = false;
    } else if (data.role == AppConst.organization) {
      if (data.organizationId && data.organizationId[0] && data.organizationId[0]._id) {
        localStorage.setItem('organization', data.organizationId[0]._id)
      }
      this.partnerLoginForm?.get('organization')?.clearValidators();
      this.partnerLoginForm.get('organization')?.updateValueAndValidity();
      this.isVisible = false;
    } else if (data.role == AppConst.user) {
      if (!data.isOrgAssociate) {
        if (data.parentRole == AppConst.organization && data.parentOrgId) localStorage.setItem('organization', data.parentOrgId)
        this.partnerLoginForm?.get('organization')?.clearValidators();
        this.partnerLoginForm.get('organization')?.updateValueAndValidity();
        this.isVisible = false;
      } else {
        this.organizations = data.organizationId;
        if (data.organizationId && data.organizationId.length == 1) {
          localStorage.setItem('organization', data.organizationId[0]._id)
          this.partnerLoginForm.patchValue({ organization: data.organizationId[0] })
          this.isVisible = false;
        } else if (data.organizationId && data.organizationId.length > 1) {
          this.isVisible = true;
        } else {
          if (data.parentRole == AppConst.organization && data.parentOrgId) localStorage.setItem('organization', data.parentOrgId)
          this.partnerLoginForm?.get('organization')?.clearValidators();
          this.partnerLoginForm.get('organization')?.updateValueAndValidity();
          this.isVisible = false;
        }
      }
    } else {
      this.partnerLoginForm?.get('organization')?.clearValidators();
      this.partnerLoginForm.get('organization')?.updateValueAndValidity();
      this.isVisible = false;
    }
  }

  getOrganizations(mainId: any, parentId: any, email: any) {
    this.sharedService.showLoader();
    this.authHTTPService.getOrganization(mainId, parentId, email).subscribe((res: any) => {
      if (res.data) {
        this.organizations = res.data;
        if (!this.organizations.length) {
          if (res?.organizationId) localStorage.setItem('organization', res?.organizationId)
          this.partnerLoginForm?.get('organization')?.clearValidators();
          this.partnerLoginForm.get('organization')?.updateValueAndValidity();
          this.isVisible = false;
        } else if (this.organizations.length == 1) {
          localStorage.setItem('organization', this.organizations[0]._id)
          this.partnerLoginForm.patchValue({
            organization: this.organizations[0]
          })
          this.isVisible = false;
        } else {
          this.isVisible = true;
        }
      } else {
        this.partnerLoginForm?.get('organization')?.clearValidators();
        this.partnerLoginForm.get('organization')?.updateValueAndValidity();
        this.isVisible = false;
      }
      this.cd.detectChanges();
      this.sharedService.hideLoader();
    });
  }

  submit() {
    if (!this.partnerLoginForm.value.parent) {
      this.onShowNext();
      return;
    }
    if (this.partnerLoginForm.invalid) {
      this.partnerLoginForm.markAsTouched();
      return;
    }
    let formValue = this.partnerLoginForm.value;
    let obj = {
      email: formValue.email,
      password: formValue.password,
      role: formValue.parent.role,
      _id: formValue.parent._id,
      mainId: localStorage.getItem('mainId') ?? formValue.parent.mainId,
      organizationId: formValue.parent.role === AppConst.organization ? localStorage.getItem('organization') : formValue.organization?._id ?? "",
    }
    if (!this.isLoading && this.isCaptcha) {
      this.isLoading = true;
      this.sharedService.showLoader();
      this.authService.partnerLogin(obj).subscribe((user: any) => {
        if (user) {
          this.isLoading = false;
          this.router.navigate(['/partner/dashboard']).then(res => {
            this.sharedService.loggerSuccess('Successfully logged in');
          });
        } else {
          this.partnerLoginForm.reset();
          this.showNext = false;
          this.isLoading = false;
          this.hasOneParent = false;
        }
        if (formValue.organization?._id) localStorage.setItem('organization', formValue.organization?._id);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      }, (err) => {
        this.editEmail();
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      });
    }
  }

  onChangeParent(parent: any) {
    if (parent) {
      this.setOrganizations(parent);
      this.cd.detectChanges();
    }
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  editEmail() {
    this.emailId.nativeElement.classList.add('is-valid');
    this.isLoading = false;
    this.showNext = false;
    this.hasOneParent = false;
    localStorage.removeItem('organization');
    this.partnerAccessArray = [];
    this.organizations = [];
    this.partnerLoginForm.reset();
  }

  OnForgotPassword() {
    let formValue = this.partnerLoginForm.value;
    if (!(formValue.parent && formValue.parent._id)) {
      this.sharedService.loggerError('please select parent')
      return;
    }
    this.authService.forgotPwdSubject$.next({
      email: formValue.email,
      role: formValue.parent.role,
      mainId: localStorage.getItem('mainId') ?? formValue.parent.mainId,
      _id: formValue.parent._id,
      parentId: formValue.parent.parentId,
      organizationId: formValue.organization?._id ?? null,
    });
    this.router.navigate(['/auth/forgot-password/partner'], { queryParams: { role: 2 } });
  }

  isControlValid(controlName: string): boolean {
    const control = this.partnerLoginForm.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.partnerLoginForm.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation: any, controlName: any): boolean {
    const control = this.partnerLoginForm.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName: any): boolean {
    const control = this.partnerLoginForm.controls[controlName];
    return control.dirty || control.touched;
  }

  resolved(res: any) {
    if (res) this.isCaptcha = true;
    else this.isCaptcha = false;
  }

}
