import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InlineSVGModule } from 'ng-inline-svg';

// Other
import { DropdownMenusModule } from '../dropdown-menus/dropdown-menus.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ListsWidget5Component } from './lists/lists-widget5/lists-widget5.component';
import { ChartsWidget1Component } from './charts/charts-widget1/charts-widget1.component';
import { FeedsWidget2Component } from './feeds/feeds-widget2/feeds-widget2.component';
import { TablesWidget1Component } from './tables/tables-widget1/tables-widget1.component';
import { TablesWidget5Component } from './tables/tables-widget5/tables-widget5.component';


@NgModule({
  declarations: [

    // Other
    ListsWidget5Component,
    ChartsWidget1Component,
    FeedsWidget2Component,
    TablesWidget1Component,
    TablesWidget5Component,
  ],
  imports: [
    CommonModule,
    DropdownMenusModule,
    InlineSVGModule,
    NgApexchartsModule,
    NgbDropdownModule,
  ],
  exports: [

    // Other
    ChartsWidget1Component,
    FeedsWidget2Component,
    ListsWidget5Component,
    TablesWidget1Component,
    TablesWidget5Component
  ],
})
export class WidgetsModule { }
