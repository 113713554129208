<div class="d-flex flex-wrap justify-content-end my-3">
    <div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
        title="Click to go back">
        <button (click)="onBackCalled()" type="button" class="btn btn-primary btn-style">
            <div>
                <span [inlineSVG]="'./assets/media/icons/duotune/icon/angle-left-solid.svg'"
                    class="svg-icon svg-icon-block mb-2">
                </span>
            </div> Back
        </button>
    </div>
</div>

<form [formGroup]="addGroupForm" class="form form-label-right">
    <div class="card pt-5">
        <div class="card-body p-5 pb-0">
            <div class="row g-5 g-xxl-8">
                <div class="form-group row">
                    <div class="col-md-4 col-lg-4 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Group Name
                                <span class="required-asterisk m-0">*</span>
                            </label>
                            <input [readOnly]="isView" formControlName="name" type="text"
                                class="form-control form-control-lg form-control-solid" name="fname"
                                placeholder="Enter Group Name" autocomplete="off"
                                [class.is-invalid]="isControlInvalid('name')"
                                [class.is-valid]="isControlValid('name')" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="controlHasError('required', 'name')">
                                    Please add group name.
                                </span>
                                <span class="text-danger" *ngIf="!controlHasError('minlength', 'name') 
                                    && !controlHasError('maxlength', 'name') && controlHasError('pattern', 'name')">
                                    Group name is invalid.
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('minlength', 'name')">
                                    Group Name should have minimum 2 characters
                                </span>
                                <span class="text-danger" *ngIf="controlHasError('maxlength', 'name')">
                                    Group name should have maximum 50 characters
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="card mb-5 mb-xl-10">
    <div class="card-body p-5">
        <div class="form-group row mb-5">
            <div class="col-md-5">
                <label class="form-label mb-2">Available Users </label>
                <select class="form-control br-6 group-select-box" [(ngModel)]="selectedToAdd" multiple
                    name="myselecttsms2">
                    <option class="fs-6" *ngFor="let item of groupsArray"
                        style="text-align: left; border-radius: 0px; background: #fff; margin-bottom: 2px; padding: 5px 15px;"
                        [ngValue]="item">{{item.email}}</option>
                </select>
            </div>
            <div class="col-md-2 text-center d-flex align-items-center justify-content-center flex-column">
                <button [disabled]="isView" (click)="assign()"
                    class="btn  btn-light-primary d-flex align-items-center justify-content-center mb-3 h-40px w-40px br-6">
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr024.svg'" class="svg-icon svg-icon-3">
                    </span> </button>
                <button [disabled]="isView" (click)="unassign()"
                    class="btn btn-light-primary d-flex align-items-center justify-content-center mb-3 h-40px w-40px br-6">
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr021.svg'" class="svg-icon svg-icon-3">
                    </span> </button>
            </div>
            <div class="col-md-5">
                <label class="mb-2 form-label">Chosen Users</label>
                <select class="form-control br-6 group-select-box" [(ngModel)]="selectedToRemove" multiple
                    name="myselecttsms">
                    <option *ngFor="let item of selectedItems"
                        style="text-align: left;border-radius: 2px;background: #fff;margin-bottom: 2px;padding: 5px 15px;"
                        [ngValue]="item">{{item.email}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="card-footer px-5 py-3">
        <button [hidden]="isView" type="button" class="btn btn-primary btn-style" (click)="onSubmit()">
            <div>
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                    class="svg-icon svg-icon-block mb-2"></span>
            </div> Save & Next
        </button>
        <button [hidden]="isView" type="button" class="btn btn-secondary btn-style" (click)="onBackCalled()">
            <div>
                <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                    class="svg-icon svg-icon-block mb-2"></span>
            </div> Cancel
        </button>
    </div>
</div>