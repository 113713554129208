<div class="d-flex flex-wrap justify-content-end mb-3 gap-5">
    <div class="my-0">
        <div (click)="onBack()" class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top"
            data-bs-trigger="hover" title="Click to go back">
            <button type="button" class="btn btn-primary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/angle-left-solid.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Back
            </button>
        </div>
    </div>
</div>
<div class="card border-0 p-0 mb-5">
    <div class="card-body p-0">
        <div class="row">
            <div class="col-lg-12 col-xxl-12">
                <div class="row">
                    <div class="col-lg-12 col-xxl-12">
                        <div class="card mb-0 border-1 border">
                            <div
                                class="card-header cursor-pointer border-top d-flex px-5 min-h-50px bg-light border-top-0">
                                <div class="card-title m-0">
                                    <h3 class="fw-bolder m-0">Details</h3>
                                </div>
                            </div>
                            <div class="card-body p-5">
                                <div class="row g-5 g-xxl-8" *ngIf="!isView">
                                    <form [formGroup]="classificationDetailsForm" class="form form-label-right">
                                        <div class="form-group row mb-1">
                                            <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-5">
                                                <div class="form-group inputtext-block mb-5">
                                                    <label class="form-label"> Name
                                                        <span class="required-asterisk m-0 position-absolute">*</span>
                                                    </label>
                                                    <input formControlName="name" type="text"
                                                        class="form-control form-control-lg form-control-solid"
                                                        name="name" placeholder="" autocomplete="off" />
                                                    <small class="form-invalid-message">
                                                        <span class="text-danger"
                                                            *ngIf="controlHasError('required', 'name')">
                                                            Name is required
                                                        </span>
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-5"
                                                *ngIf="!isSharedClassification">
                                                <div class="form-group inputtext-block mb-5">
                                                    <label class="form-label">Space
                                                        <span class="required-asterisk m-0 position-absolute">*</span>
                                                    </label>
                                                    <ng-select class="dropdown-block" formControlName="space"
                                                        placeholder="">
                                                        <ng-option *ngFor="let spaces of spaceList"
                                                            [value]="spaces.url">
                                                            {{spaces.name}}
                                                        </ng-option>
                                                    </ng-select>
                                                    <small class="form-invalid-message">
                                                        <span class="text-danger"
                                                            *ngIf="controlHasError('required', 'space')">
                                                            Space is required
                                                        </span>
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-5">
                                                <div class="form-group inputtext-block mb-5">
                                                    <label class="form-label">Default target
                                                        <span class="required-asterisk m-0 position-absolute">*</span>
                                                    </label>
                                                    <ng-select class="dropdown-block" formControlName="default_target"
                                                        placeholder="" [clearable]="false" [searchable]="false">
                                                        <ng-option *ngFor="let defaultTargets of arrDefaultTarget"
                                                            [value]="defaultTargets.default_target">
                                                            {{defaultTargets.default_target}}
                                                        </ng-option>
                                                    </ng-select>
                                                    <small class="form-invalid-message">
                                                        <span class="text-danger"
                                                            *ngIf="controlHasError('required', 'default_target')">
                                                            Default target is required
                                                        </span>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="px-5 w-100 d-flex mt-0 gap-3">
                                        <button type="button" class="btn btn-primary btn-style" (click)="onSubmit()">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                                                    class="svg-icon svg-icon-block mb-2"></span>
                                            </div> Save
                                        </button>
                                        <button *ngIf="!isAdd" type="button" class="btn btn-secondary btn-style"
                                            (click)="isView = true;">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                                                    class="svg-icon svg-icon-block mb-2"></span>
                                            </div> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div class="row" *ngIf="isView">
                                    <div class="col-lg-10 col-xxl-10 col-md-10">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="row mb-1">
                                                    <label
                                                        class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                        ID</label>
                                                    <div class="col-lg-7 col-xxl-7 col-md-7">
                                                        <span
                                                            class="fs-7 text-dark">{{classificationDetailsForm?.value?.id}}</span>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <label
                                                        class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                        Name</label>
                                                    <div class="col-lg-7 col-xxl-7 col-md-7">
                                                        <span
                                                            class="fs-7 text-dark">{{classificationDetailsForm?.value?.name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row mb-1">
                                                    <label
                                                        class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                        Space</label>
                                                    <div class="col-lg-7 col-xxl-7 col-md-7">
                                                        <span class="fs-7 text-dark">{{spaceName}}</span>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <label
                                                        class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                        Default target</label>
                                                    <div class="col-lg-7 col-xxl-7 col-md-7">
                                                        <span
                                                            class="fs-7 text-dark">{{classificationDetailsForm?.value?.default_target}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="me-2 card-toolbar d-flex align-items-center justify-content-end"
                                            title="Edit" *ngIf="!isAdd && isView">
                                            <button type="button" (click)="isView = false;"
                                                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 br-6">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg-icon svg-icon-3 svg-icon-warning">
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 p-0" *ngIf="!isAdd">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-lg-12 col-xxl-12">
                        <div class="row">
                            <div class="col-lg-12 col-xxl-12">
                                <div class="card mb-0 border-1 border">
                                    <div
                                        class="card-header cursor-pointer border-top d-flex px-5 min-h-50px bg-light border-top-0">
                                        <div class="card-title m-0">
                                            <h3 class="fw-bolder m-0">Packet filters</h3>
                                        </div>
                                    </div>
                                    <div class="card-body p-5">
                                        <div class="row" *ngIf="isEdit">
                                            <form [formGroup]="packetDetailsForm">
                                                <div formArrayName="packetDetailsArray" cdkDropList
                                                    (cdkDropListDropped)="dropPort($event)">
                                                    <div [formGroupName]="i"
                                                        *ngFor="let packetDetails of packetArr.controls; let i = index"
                                                        cdkDrag>
                                                        <div class="col-lg-12 col-xxl-12 col-md-12 border border-1 rounded p-5 mt-0 mb-5"
                                                            *ngIf="!packetDetails.get('isEdit')?.value">
                                                            <div class="row">
                                                                <div class="col-sm-5">
                                                                    <div class="row mb-1">
                                                                        <label
                                                                            class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                                            Comment:</label>
                                                                        <div class="col-lg-7 col-xxl-7 col-md-7">
                                                                            <span
                                                                                class="fs-7 text-dark">{{packetDetails.get('comment')?.value}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-1">
                                                                        <label
                                                                            class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                                            Target:</label>
                                                                        <div class="col-lg-7 col-xxl-7 col-md-7">
                                                                            <span
                                                                                class="fs-7 text-dark">{{packetDetails.get('target')?.value}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <div class="row mb-1">
                                                                        <label
                                                                            class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                                            Source:</label>
                                                                        <div class="col-lg-7 col-xxl-7 col-md-7">
                                                                            <span
                                                                                class="fs-7 text-dark">{{packetDetails.get('source_network')?.value
                                                                                == null
                                                                                ?
                                                                                '0.0.0.0/0' :
                                                                                packetDetails.get('source_network')?.value}}</span>
                                                                            <span>{{packetDetails.get('source_ports')?.value
                                                                                ? ':'+
                                                                                packetDetails.get('source_ports')?.value
                                                                                : ''}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-1">
                                                                        <label
                                                                            class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                                            Destination:</label>
                                                                        <div class="col-lg-7 col-xxl-7 col-md-7">
                                                                            <span
                                                                                class="fs-7 text-dark">{{packetDetails.get('destination_network')?.value
                                                                                ==
                                                                                null ?
                                                                                '0.0.0.0/0' :
                                                                                packetDetails.get('destination_network')?.value}}</span>
                                                                            <span>{{packetDetails.get('destination_ports')?.value
                                                                                ? ':'+
                                                                                packetDetails.get('destination_ports')?.value
                                                                                : ''}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-1">
                                                                        <label
                                                                            class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                                            Protocol:</label>
                                                                        <div class="col-lg-7 col-xxl-7 col-md-7">
                                                                            <span
                                                                                class="fs-7 text-dark">{{getProtocolName(packetDetails.get('protocol')?.value)}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-1"
                                                                        *ngIf="packetDetails.get('dscp_class')?.value != 'null'">
                                                                        <label
                                                                            class="col-lg-4 col-md-4 col-xxl-3 fw-bolder fs-7 text-black text-end separator-vertical">
                                                                            DSCP class</label>
                                                                        <div class="col-lg-7 col-xxl-7 col-md-7">
                                                                            <span
                                                                                class="fs-7 text-dark">{{packetDetails.get('dscp_class')?.value}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-sm-2 d-flex align-items-center justify-content-center">
                                                                    <button type="button"
                                                                        (click)="packetDelete(packetDetails.get('id')?.value)"
                                                                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 br-6">
                                                                        <span
                                                                            [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                                            class="svg-icon svg-icon-3 svg-icon-danger">
                                                                        </span>
                                                                    </button>
                                                                    <button type="button"
                                                                        placeholder="Drag to change order"
                                                                        *ngIf="!packetDetails.get('isEdit')?.value"
                                                                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 hoverGrab br-6"
                                                                        cdkDragHandle>
                                                                        <i class="fa fa-bars"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                        (click)="OnPacketEdit(packetDetails.get('id')?.value)"
                                                                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 br-6">
                                                                        <span
                                                                            [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                                            class="svg-icon svg-icon-3 svg-icon-warning">
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-xxl-12 col-md-12 border border-1 rounded p-5 mt-0 mb-5"
                                                            *ngIf="packetDetails.get('isEdit')?.value">
                                                            <div class="form-group row mb-3">
                                                                <div class="col-md-6 col-lg-3 mb-2">
                                                                    <div class="form-group inputtext-block mb-5">
                                                                        <label class="form-label"> Comment
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                                                placement="top"
                                                                                ngbTooltip="Description of filter"></span>
                                                                        </label>
                                                                        <input formControlName="comment" type="text"
                                                                            class="form-control form-control-lg form-control-solid"
                                                                            name="comment" placeholder="Enter comment"
                                                                            autocomplete="off" />
                                                                        <small class="form-invalid-message">
                                                                            <span class="text-danger" *ngIf="errors?.comment &&
                                                                                packetDetails.get('isError')?.value &&
                                                                                errors?.comment?.length &&
                                                                                errors?.comment?.length> 0 ">
                                                                                {{errors?.comment?.join(' ')}}</span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class=" col-md-6 col-lg-3 mb-2">
                                                                    <div class="form-group inputtext-block mb-5">
                                                                        <label class="form-label">Target
                                                                            <span class="required-asterisk m-0">*</span>
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                                                placement="top"
                                                                                ngbTooltip="Target for matching traffic"></span>
                                                                        </label>
                                                                        <ng-select formControlName="target"
                                                                            class="dropdown-block" placeholder=""
                                                                            [clearable]="false" [searchable]="false">
                                                                            <ng-option
                                                                                *ngFor="let packetTargets of arrPacketTargets"
                                                                                [value]="packetTargets.target">
                                                                                {{packetTargets.target}}
                                                                            </ng-option>
                                                                        </ng-select>
                                                                        <small class="form-invalid-message">
                                                                            <span class="text-danger"
                                                                                *ngIf="errors?.target && packetDetails.get('isError')?.value && errors?.target?.length && errors?.target?.length > 0 ">
                                                                                {{errors?.target?.join(' ')}}</span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-lg-3 mb-2">
                                                                    <div class="form-group inputtext-block mb-5">
                                                                        <label class="form-label">Protocol
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                                                placement="top"
                                                                                ngbTooltip="Match the protocol type"></span>
                                                                        </label>
                                                                        <ng-select name="protocol"
                                                                            formControlName="protocol"
                                                                            class="dropdown-block" [clearable]="false"
                                                                            [searchable]="false">
                                                                            <ng-option [value]="'null'">Any</ng-option>
                                                                            <ng-option [value]="1">ICMP</ng-option>
                                                                            <ng-option [value]="2">IGMP</ng-option>
                                                                            <ng-option [value]="6">TCP</ng-option>
                                                                            <ng-option [value]="17">UDP</ng-option>
                                                                            <ng-option [value]="47">GRE</ng-option>
                                                                            <ng-option [value]="50">ESP</ng-option>
                                                                            <ng-option [value]="58">ICMPv6</ng-option>
                                                                            <ng-option [value]="132">SCTP</ng-option>
                                                                        </ng-select>
                                                                        <small class="form-invalid-message">
                                                                            <span class="text-danger"
                                                                                *ngIf="errors?.protocol && packetDetails.get('isError')?.value && errors?.protocol?.length && errors?.protocol?.length > 0 ">
                                                                                {{errors?.protocol?.join(' ')}}</span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-lg-3 mb-2">
                                                                    <div class="form-group inputtext-block mb-5">
                                                                        <label class="form-label">
                                                                            DSCP (Type of Service)
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                                                placement="top"
                                                                                ngbTooltip="Match the differentiated services field"></span>
                                                                        </label>
                                                                        <ng-select name="dscp_class"
                                                                            formControlName="dscp_class"
                                                                            class="dropdown-block" [clearable]="false"
                                                                            [searchable]="false">
                                                                            <ng-option [value]="'null'">Any</ng-option>
                                                                            <ng-option [value]="'CS0'">CS0
                                                                                routine</ng-option>
                                                                            <ng-option [value]="'CS1'">CS1
                                                                                priority</ng-option>
                                                                            <ng-option [value]="'AF11'">AF11 class 1 low
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'AF12'">AF12 class 1
                                                                                medium
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'AF13'">AF13 class 1
                                                                                high
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'CS2'">CS2
                                                                                immediate</ng-option>
                                                                            <ng-option [value]="'AF21'">AF21 class 2 low
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'AF22'">AF22 class 2
                                                                                medium
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'AF23'">AF23 class 2
                                                                                high
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'CS3'">CS3
                                                                                flash</ng-option>
                                                                            <ng-option [value]="'AF31'">AF31 class 3 low
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'AF32'">AF32 class 3
                                                                                medium
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'AF33'">AF33 class 3
                                                                                high
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'CS4'">CS4 flash
                                                                                override</ng-option>
                                                                            <ng-option [value]="'AF41'">AF41 class 4 low
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'AF42'">AF42 class 4
                                                                                medium
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'AF43'">AF43 class 4
                                                                                high
                                                                                drop</ng-option>
                                                                            <ng-option [value]="'CS5'">CS5
                                                                                critical</ng-option>
                                                                            <ng-option [value]="'CS6'">CS6 internetwork
                                                                                control</ng-option>
                                                                            <ng-option [value]="'CS7'">CS7 network
                                                                                control</ng-option>
                                                                            <ng-option [value]="'EF'">Expedited
                                                                                Forwarding</ng-option>
                                                                        </ng-select>
                                                                        <small class="form-invalid-message">
                                                                            <span class="text-danger"
                                                                                *ngIf="errors?.dscp_class && packetDetails.get('isError')?.value && errors?.dscp_class?.length && errors?.dscp_class?.length > 0 ">
                                                                                {{errors?.dscp_class?.join(' ')}}</span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row mb-3">
                                                                <div class="col-md-6 col-lg-3 mb-2">
                                                                    <div class="form-group inputtext-block mb-5">
                                                                        <label class="form-label"> Source network
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                                                placement="top"
                                                                                ngbTooltip="Match source network"></span>
                                                                        </label>
                                                                        <input formControlName="source_network"
                                                                            type="text"
                                                                            class="form-control form-control-lg form-control-solid"
                                                                            name="source_network"
                                                                            placeholder="Enter source network"
                                                                            autocomplete="off" />
                                                                        <small class="form-invalid-message">
                                                                            <span class="text-danger"
                                                                                *ngIf="errors?.source_network && packetDetails.get('isError')?.value && errors?.source_network?.length && errors?.source_network?.length > 0 ">
                                                                                {{errors?.source_network?.join(' ')}}
                                                                            </span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-lg-3 mb-2">
                                                                    <div class="form-group inputtext-block mb-5">
                                                                        <label class="form-label"> Source ports
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                                                placement="top"
                                                                                ngbTooltip="Multiple ports may be specified by separating values with commas (eg: 80,443),
                                                                                    while ranges may be specified by separating two values with a colon (eg: 5060:5080)">
                                                                            </span>
                                                                        </label>
                                                                        <input formControlName="source_ports"
                                                                            type="text"
                                                                            class="form-control form-control-lg form-control-solid"
                                                                            name="source_ports"
                                                                            placeholder="Enter source ports"
                                                                            autocomplete="off"
                                                                            [attr.disabled]="packetDetails.get('protocol')?.value == '6' || packetDetails.get('protocol')?.value == '17' || packetDetails.get('protocol')?.value == '132' ? null : true" />
                                                                        <small class="form-invalid-message">
                                                                            <span class="text-danger"
                                                                                *ngIf="errors?.source_ports && packetDetails.get('isError')?.value && errors?.source_ports?.length && errors?.source_ports?.length > 0 ">
                                                                                {{errors?.source_ports?.join(' ')}}
                                                                            </span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-lg-3 mb-2">
                                                                    <div class="form-group inputtext-block mb-5">
                                                                        <label class="form-label"> Destination network
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                                                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"
                                                                                placement="top"
                                                                                ngbTooltip="Match destination network">
                                                                            </span>
                                                                        </label>
                                                                        <input formControlName="destination_network"
                                                                            type="text"
                                                                            class="form-control form-control-lg form-control-solid"
                                                                            name="destination_network"
                                                                            placeholder="Enter destination network"
                                                                            autocomplete="off" />
                                                                        <small class="form-invalid-message">
                                                                            <span class="text-danger"
                                                                                *ngIf="errors?.destination_network && packetDetails.get('isError')?.value && errors?.destination_network?.length && errors?.destination_network?.length > 0 ">
                                                                                {{errors?.destination_network?.join('
                                                                                ')}}
                                                                            </span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-lg-3 mb-2">
                                                                    <div class="form-group inputtext-block mb-5">
                                                                        <label class="form-label"> Destination ports
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                                                                                class="svg-icon svg-icon-6 svg-icon-primary ps-1"
                                                                                placement="top"
                                                                                ngbTooltip="Multiple ports may be specified by separating values with commas (eg: 80,443),
                                                                                    while ranges may be specified by separating two values with a colon (eg: 5060:5080)"></span>
                                                                        </label>
                                                                        <input formControlName="destination_ports"
                                                                            type="text"
                                                                            class="form-control form-control-lg form-control-solid"
                                                                            name="destination_ports"
                                                                            placeholder="Enter destination ports"
                                                                            autocomplete="off"
                                                                            [attr.disabled]="packetDetails.get('protocol')?.value == '6' || packetDetails.get('protocol')?.value == '17' || packetDetails.get('protocol')?.value == '132' ? null : true" />
                                                                        <small class="form-invalid-message">
                                                                            <span class="text-danger"
                                                                                *ngIf="errors?.destination_ports && packetDetails.get('isError')?.value && errors?.destination_ports?.length && errors?.destination_ports?.length > 0 ">
                                                                                {{errors?.destination_ports?.join(' ')}}
                                                                            </span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row mb-3"
                                                                *ngIf="packetDetails.get('isEdit')?.value">
                                                                <div class="col-lg-3 col-xxl-3 col-md-3">
                                                                    <button type="button"
                                                                        class="btn btn-primary btn-style me-3"
                                                                        (click)="editPacketDetails(packetDetails)">
                                                                        <div>
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                                                                                class="svg-icon svg-icon-block mb-2"></span>
                                                                        </div> Save
                                                                    </button>
                                                                    <button type="button"
                                                                        class="btn btn-secondary btn-style"
                                                                        (click)="cancelPacketEdit(packetDetails.get('id')?.value)">
                                                                        <div>
                                                                            <span
                                                                                [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                                                                                class="svg-icon svg-icon-block mb-2"></span>
                                                                        </div> Cancel
                                                                    </button>
                                                                    <span cdkDragHandle></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="col-lg-4 col-xxl-4 col-md-5">
                                                <button type="button" class="btn btn-primary btn-style me-3"
                                                    (click)="addFilter()"
                                                    [attr.disabled]="!disableAddFilter ? null : true">
                                                    <div>
                                                        <span
                                                            [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                                            class="svg-icon svg-icon-block mb-2">
                                                        </span>
                                                    </div> Add Filter
                                                </button>
                                                <button type="button" class="btn btn-primary btn-style"
                                                    (click)="saveOrder()"
                                                    [attr.disabled]="!disableSaveOrder ? null : true">
                                                    <div>
                                                        <span
                                                            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                                                            class="svg-icon svg-icon-block mb-2"></span>
                                                    </div> Save Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>