import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-space-configuration',
  templateUrl: './shared-space-configuration.component.html',
  styleUrls: ['./shared-space-configuration.component.scss']
})
export class SharedSpaceConfigurationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
