<div class="card">

    <div class="card-header d-flex justify-content-between align-items-center px-5">
      <h2 class="mb-0" *ngIf="edit!=true"> Add gateway aggregator    </h2>
      <h2 class="mb-0" *ngIf="edit==true"> Edit gateway aggregator    </h2>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
    </div>

    <div class="card-body p-5">
      <p> A gateway aggregator will run the protocols in the routing group even if there are no bonds, interfaces, or protocols configured directly on it.
      </p>
      <form  [formGroup]="gatewayForm" *ngIf="gatewayForm">
        <p> Specify the IP subnet to delegate to a child space. </p>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="form-group mb-5">
              <label class="form-label"> Aggregator <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'" class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer"  placement="top" ngbTooltip="The chosen aggregator must have a routing group VLAN assignment assigned to this space, and be configured with a managed trunk interface."></span> </label>
              <ng-select class="" formControlName="aggregator" name="aggregator">
                <ng-option *ngFor="let agg of aggregatorList" [value]="agg.url"> {{agg.space.name}} - {{agg.name}} </ng-option>
              </ng-select>
              <div>

              </div>
            </div>
          </div>

        </div>
      </form>
    </div>

    <div class="card-footer p-5">

      <button type="button" class="btn btn-primary"  data-bs-dismiss="modal"  *ngIf="edit!=true" (click)="onAdd()"><span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'" class="svg-icon svg-icon-4 me-1"></span>Add</button>
      <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" *ngIf="edit==true" (click)="onEdit()"><span [inlineSVG]="'./assets/media/icons/normal/repeat.svg'" class="svg-icon svg-icon-4 me-1"></span>Update</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onClose()"><span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-4 me-1"></span>Cancel</button>
    </div>

  </div>
