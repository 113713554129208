import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { PartnerManagmentService } from 'src/app/services/partner-management.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../..';

@Component({
  selector: 'app-tfa',
  templateUrl: './tfa.component.html',
  styleUrls: ['./tfa.component.scss']
})
export class TfaComponent implements OnInit {
  tfaForm: FormGroup;
  userData: any;
  isLoading$: Observable<boolean>;
  isLoading: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private partnerService: PartnerManagmentService,
    private cookie: CookieService
  ) {
    this.isLoading$ = this.authService.isLoading$;
    this.route.queryParams.subscribe(params => {
      if (params.role == 1) this.isAdmin = true;
      else this.isAdmin = false;
    });
  }

  initForm() {
    this.tfaForm = this.fb.group({
      otp: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
    });
  }

  ngOnInit() {
    this.initForm();
    this.authService.tfaSubject.subscribe(res => {
      if (!res) {
        if (this.isAdmin) this.router.navigate(['/auth/login']);
        else this.router.navigate(['/auth/login/partner']);
      } else {
        if (res?.message) this.sharedService.loggerSuccess(res?.message);
        this.userData = res;
      }
    })
  }

  get f() {
    return this.tfaForm.controls;
  }

  submit() {
    if (this.tfaForm.invalid) {
      this.tfaForm.markAllAsTouched();
      return;
    }
    if (this.isAdmin) this.submitAdmin();
    else this.submitPartner();
  }

  submitAdmin() {
    if (!this.isLoading) {
      let payload = {
        email: this.userData.email,
        password: this.userData.pass,
        otp: this.f.otp.value,
        v_type: this.userData.v_type
      }
      this.isLoading = true;
      this.sharedService.showLoader();
      this.authService.loginWithOTPAdmin(payload).subscribe((user: any) => {
        if (user) {
          this.router.navigate(['/admin/dashboard']).then(res => {
            this.sharedService.loggerSuccess('Successfully logged in');
          });
        }
        this.isLoading = false;
        this.tfaForm.reset();
        this.sharedService.hideLoader();
      }, err => {
        this.isLoading = false;
        this.tfaForm.reset();
        this.sharedService.hideLoader();
        if (err?.error?.message) this.sharedService.loggerError(err?.error?.message);
        else this.sharedService.loggerError(err);
      });
    }
  }

  submitPartner() {
    if (!this.isLoading) {
      let body = {
        email: this.userData.email,
        password: this.userData.pass,
        _id: this.userData._id,
        mainId: localStorage.getItem('mainId'),
        role: this.userData.role,
        otp: this.f.otp.value,
        v_type: this.userData.v_type,
        organizationId: localStorage.getItem('organization') ?? ""
      }
      this.isLoading = true;
      this.sharedService.showLoader();
      this.authService.partnerLoginWithOTP(body).subscribe((user: any) => {
        if (user) {
          if (Number(this.userData.role == AppConst.user)) {
            let data: any = {
              userId: this.userData._id,
              LoginID: this.userData.createdBy,
              partner_role: this.userData.role.toString(),
            }
            if (localStorage.getItem('organization')) data["organizationId"] = localStorage.getItem('organization')
            this.onLoginAsUser(data);
          } else {
            this.router.navigate(['/partner/dashboard']).then(res => {
              this.sharedService.loggerSuccess('Successfully logged in');
              window.location.reload();
            });
          }
        }
        this.isLoading = false;
        this.tfaForm.reset();
        this.sharedService.hideLoader();
      }, err => {
        this.isLoading = false;
        this.tfaForm.reset();
        this.sharedService.hideLoader();
        if (err?.error?.message) this.sharedService.loggerError(err?.error?.message);
        else this.sharedService.loggerError(err);
      });
    }
  }

  onLoginAsUser(data: any) {
    if (!data?.organizationId) {
      localStorage.setItem('userId', data?.userId + "");
      if (localStorage.getItem('organization') && localStorage.getItem('orgdetails')) {
        let orgDetails = JSON.parse(localStorage.getItem('orgdetails') || '');
        localStorage.setItem('organization', orgDetails?._id);
      }
      if (data) {
        this.sharedService.showLoader();
        this.partnerService.loginAsUser(data).subscribe((res: any) => {
          if (res) {
            if (!res.homeSpace) localStorage.removeItem('homeSpace');
            this.setLocalStorage(res);
          }
          this.sharedService.hideLoader();
        }, (err) => {
          this.sharedService.loggerError(err);
          this.sharedService.hideLoader();
        });
      }
    } else {
      localStorage.setItem('organization', data?.organizationId + "");
      localStorage.setItem('loginOrgId', data?.organizationId + "");
      localStorage.setItem('userId', data?.userId + "");
      if (Number(localStorage.getItem('role')) == AppConst.user) localStorage.setItem('loginId', data?.userId + "");
      if (data) {
        this.sharedService.showLoader();
        this.partnerService.loginAsUser(data).subscribe((res: any) => {
          if (res) {
            this.setLocalStorage(res);
            if (res.homeSpace) localStorage.setItem('homeSpace', res.homeSpace);
          }
          this.sharedService.hideLoader();
        }, (err) => {
          this.sharedService.loggerError(err);
          this.sharedService.hideLoader();
        });
      }
    }
  }

  setLocalStorage(res: any) {
    localStorage.setItem('partnerId', localStorage.getItem('ID') + "");
    this.cookie.set('authToken', res.token);
    localStorage.setItem('ID', res.data._id);
    localStorage.setItem('loginId', res.data.LoginID);
    localStorage.setItem('role', res.data.role);
    localStorage.setItem('isPartnerLoggedInAsUser', '1');
    localStorage.setItem('logInDuration', res.data.duration);
    localStorage.setItem('logInExpiresIn', res.data.expTime);
    localStorage.setItem('partnerMenu', localStorage.getItem('menu') || '');
    localStorage.setItem('partnerNavBarImage', localStorage.getItem('navBarImage') || '');
    localStorage.setItem('partnerNavBarText', localStorage.getItem('navBarText') || '');
    localStorage.setItem('partnerModuleAccess', localStorage.getItem('Module-Access') || '');
    localStorage.setItem('Module-Access', JSON.stringify(res?.moduleAccess || ''));
    localStorage.setItem('menu', res.brandSettingMenu);
    localStorage.setItem('navBarImage', res.brandSettingNavImg || '');
    localStorage.setItem('navBarText', res.brandSettingNavText || '');
    this.authService.setLayoutConfig();
    this.authService.showHeader();
    if (this.isAdmin) this.router.navigate(['/admin/dashboard']).then((res: any) => {
      window.location.reload();
    });
    else this.router.navigate(['/partner/dashboard']).then((res: any) => {
      window.location.reload();
    });
  }

  OnForgotPassword() {
    this.authService.forgotPwdSubject$.next({
      email: this.userData.email,
      role: this.userData.role,
      mainId: localStorage.getItem('mainId'),
      _id: this.userData._id,
    });
    if (this.isAdmin) this.router.navigate(['/auth/forgot-password'], { queryParams: { role: 2 } });
    else this.router.navigate(['/auth/forgot-password/partner'], { queryParams: { role: 2 } });
  }

  controlHasError(validation: any, controlName: any): boolean {
    const control = this.tfaForm.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

}
