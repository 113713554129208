<form class="form w-100 login_signin_form" [formGroup]="partnerLoginForm" novalidate="novalidate"
    id="kt_login_signin_form" (ngSubmit)="partnerLoginForm.invalid || !isCaptcha ? '':submit()">
    <div class="text-center mb-10">
        <div class="text-gray-400 fs-4 text-italic text-start">
            <small class="fw-bold fs-5"> Enter your email and password below. </small>
        </div>
    </div>
    <div class="fv-row mb-5">
        <div class="form-group inputtext-block mb-5 w-100">
            <label class="form-label fs-6 text-dark">Email<span class="required-asterisk m-0">*</span></label>
            <div class="d-flex container-div">
                <input #emailId [readOnly]="showNext" class="form-control form-control-lg form-control-solid"
                    type="email" name="email" formControlName="email" autocomplete="off"
                    [class.is-invalid]="isControlInvalid('email')" [class.is-valid]="isControlValid('email')" />
                <i *ngIf="showNext && partnerLoginForm.controls['email'].valid" class="fa fa-edit pencil-icon"
                    (click)="editEmail()"></i>
            </div>
            <small class="form-invalid-message">
                <span class="text-danger" *ngIf="controlHasError('required', 'email')">
                    Email is required
                </span>
                <span class="text-danger" *ngIf="controlHasError('pattern', 'email')">
                    Email is invalid
                </span>
            </small>
        </div>
    </div>
    <ng-container *ngIf="showNext && isControlValid('email')">
        <div class="fv-row mb-10" *ngIf="!hasOneParent">
            <div class="form-group inputtext-block mb-5">
                <label class="form-label">Select Parent</label>
                <ng-select class="dropdown-block" (change)="onChangeParent($event)"
                    [class.is-invalid]="isControlInvalid('parent')" [class.is-valid]="isControlValid('parent')"
                    [searchable]="true" [clearable]="false" formControlName="parent">
                    <ng-option *ngFor="let parent of partnerAccessArray" [value]="parent">
                        {{parent.parentName}}
                    </ng-option>
                </ng-select>
                <small class="form-invalid-message">
                    <span class="text-danger" *ngIf="controlHasError('required', 'parent')">
                        Parent information is required
                    </span>
                </small>
            </div>
        </div>
        <ng-container *ngIf="isVisible && organizations.length > 0">
            <div class="fv-row mb-10">
                <div class="form-group inputtext-block mb-5">
                    <label class="form-label">Select Organization</label>
                    <ng-select class="dropdown-block" [class.is-invalid]="isControlInvalid('organization')"
                        [class.is-valid]="isControlValid('organization')" [searchable]="true" [clearable]="false"
                        formControlName="organization">
                        <ng-option *ngFor="let organization of organizations" [value]="organization">
                            {{organization.fname}}
                        </ng-option>
                    </ng-select>
                    <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="controlHasError('required', 'organization')">
                            Organization information is required
                        </span>
                    </small>
                </div>
            </div>
        </ng-container>
        <div class="fv-row mb-5">
            <div class="d-flex justify-content-between mt-5">
                <div class="form-group inputtext-block mb-5 w-100">
                    <label class="form-label">Password <span class="required-asterisk m-0">*</span>
                    </label>
                    <div class="password-input-block">
                        <!-- [readOnly]="!isControlValid('parent') && !hasOneParent" -->
                        <input class="form-control form-control-lg form-control-solid"
                            [type]="show_button ? 'text' : 'password'" name="password" autocomplete="off"
                            formControlName="password"
                            [ngClass]="{ 'is-invalid': partnerLoginForm.controls['password'].invalid }" />
                        <i [class]="show_eye ? 'fa fa-eye password_hide_show' : 'fa fa-eye-slash password_hide_show'"
                            (click)="showPassword()"></i>
                    </div>
                    <small class="form-invalid-message">
                        <span class="text-danger" *ngIf="controlHasError('required', 'password')">
                            Password is required
                        </span>
                        <span class="text-danger" *ngIf="!controlHasError('maxlength', 'password') 
                            && !controlHasError('minlength', 'password') && controlHasError('pattern', 'password')">
                            Password should have atleast 2 capital letters, 3 lower case letters, 2 numbers and 1
                            special character.
                        </span>
                        <span class="text-danger" *ngIf="controlHasError('maxlength', 'password')">
                            Password should not exceed 40 characters.
                        </span>
                        <span class="text-danger" *ngIf="controlHasError('minlength', 'password')">
                            Password should have at least 8 characters.
                        </span>
                    </small>
                </div>
            </div>
            <div class="d-flex mt-5 mb-3 align-items-center justify-content-between">
                <div class="form-check form-check-sm form-check-custom form-check-solid">
                    <!-- <input class="form-check-input" type="checkbox" name="compression" id="">
                    <label class="m-2 form-label"> Keep me signed in </label> -->
                </div>
                <div>
                    <a (click)="OnForgotPassword()" style="float:right"
                        class="link-primary cursor-pointer fs-7 fw-bolder text-primary mb-5 mt-1 text-underline"
                        id="kt_login_forgot">
                        Forgot Password ?
                    </a>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center">
                <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"
                    *ngIf="showNext && isControlValid('email')"></re-captcha>
            </div>
        </div>
    </ng-container>
    <div class="text-center">
        <button [disabled]="partnerLoginForm.invalid || !isCaptcha" *ngIf="showNext && isControlValid('email')"
            type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary br-6 w-100 mb-5">
            <ng-container *ngIf="isLoading$ | async">
                <span class="indicator-progress" [style.display]="'block'">
                    Please wait...
                    <!-- <span class="spinner-border spinner-border-sm align-middle ms-2"></span> -->
                </span>
            </ng-container>
            <ng-container *ngIf="!(isLoading$ | async)">
                <span class="indicator-label">Login</span>
            </ng-container>
        </button>
        <button *ngIf="!showNext" (click)="onShowNext()" type="button" id="kt_sign_in_submit"
            class="btn btn-lg btn-primary br-6 w-100 mb-5">
            <ng-container>
                <span class="indicator-label">Next</span>
            </ng-container>
        </button>
    </div>
</form>