import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationTemplateService } from 'src/app/services/notification-template.service';
import { PartnerManagmentService } from 'src/app/services/partner-management.service';
import { PartnerOrganizationManagementService } from 'src/app/services/partner-organization-management.service';
import { PartnerUserManagementService } from 'src/app/services/partner-user-management.service';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-notification-form',
  templateUrl: './shared-notification-form.component.html',
  styleUrls: ['./shared-notification-form.component.scss']
})
export class SharedNotificationFormComponent implements OnInit {

  notificationTemplateForm!: FormGroup;
  recipientsList: any[] = [];
  search: string = '';

@Output() submit=new EventEmitter<any>();
@Output() reset=new EventEmitter<any>();
@Output() back=new EventEmitter<any>();


  constructor(private fb: FormBuilder,
    private formErrorHandler: FormErrorService,
    private partnersService: PartnerManagmentService,
    private partnerUserManagement:PartnerUserManagementService,
    private sharedService: SharedService,
    private notificationTemplateService: NotificationTemplateService,
    private organizationService:PartnerOrganizationManagementService,
    private cd:ChangeDetectorRef,
    private router: Router) {
  }

  ngOnInit(): void {
    this.getAllPartnersList();
    this.notificationTemplateForm = this.createForm();
  }

  createForm(data: any = {}) {
    return this.fb.group({
      subject: [data.subject, [Validators.required]],
      body: [data.body, [Validators.required]],
      recepients: [data.recepients, [Validators.required]]
    })
  }

  addNotificationTemplate() {
    if (this.notificationTemplateForm.invalid) {
      this.notificationTemplateForm.markAllAsTouched();
      return;
    }

    this.sharedService.showLoader();
    //add partnerId property to existing formgroup recepients array
    
    let recepients = this.notificationTemplateForm.get('recepients')?.value?.map((x: any) => ({ 
      
      userId: x }))
    
    let obj = {
      ...this.notificationTemplateForm.value,
      recepients
    }
    this.submit.emit({templateData:obj})
    // this.notificationTemplateService.createNotificationTemplate(obj).subscribe(
    //   (res: any) => {
    //     this.sharedService.hideLoader();
    //     if (res.code === 200) {
    //       this.resetForm();
    //       this.sharedService.loggerSuccess(res.message);
    //     } else {
    //       this.sharedService.loggerError(res.message);
    //     }
    //   }
    // )
  }

  saveAndShareTemplate() {

  }

  onBack(){
    this.back.emit();
  }

  resetForm() {
    this.notificationTemplateForm.reset();
    this.reset.emit();
    //this.router.navigate(['/admin/configuration/notification-template'])
  }

  getAllPartnersList() {
    this.sharedService.showLoader();
    this.organizationService.getAllUsers().subscribe(
      (result: any) => {
        this.sharedService.hideLoader();
        if (result.data.length) {
          this.recipientsList = result.data
        }
      },
      (err) => {
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      }
    )
    
  }

  onSearch(event: any) {
    if (event.items.length == 0) {
      this.search = event.term;
      this.getAllPartnersList();
    }
  }

  isControlValid(controlName: string): boolean {
    return this.formErrorHandler.isControlValid(controlName, this.notificationTemplateForm);
  }

  isControlInvalid(controlName: string): boolean {
    return this.formErrorHandler.isControlInvalid(controlName, this.notificationTemplateForm);
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.notificationTemplateForm);
  }

  isControlTouched(controlName: string): boolean {
    return this.formErrorHandler.isControlTouched(controlName, this.notificationTemplateForm);
  }

}
