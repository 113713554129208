import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/shared/auth/services/auth.guard';
import { AdminPermissionGuard } from './modules/shared/auth/services/admin-permission.guard';

const LayoutRoute: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },
];

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/shared/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('./modules/shared/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'admin',
    data: { breadcrumb: { skip: true } },
    children: LayoutRoute,
  },
  {
    path: '',
    children: LayoutRoute
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
