import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedSpaceWanAddFilterComponent } from './shared-space-wan-add-filter/shared-space-wan-add-filter.component';
import { SharedSpaceWanAddGatewayComponent } from './shared-space-wan-add-gateway/shared-space-wan-add-gateway.component';
import { SharedSpaceWanAddProtocolComponent } from './shared-space-wan-add-protocol/shared-space-wan-add-protocol.component';
import { SharedSpaceWanAddVlanComponent } from './shared-space-wan-add-vlan/shared-space-wan-add-vlan.component';

@Component({
  selector: 'app-shared-space-wan',
  templateUrl: './shared-space-wan.component.html',
  styleUrls: ['./shared-space-wan.component.scss']
})
export class SharedSpaceWanComponent implements OnInit {
  apiURL: any;
  // private_wan
  pWanEnable = false;
  pWanMode = "unmanaged";
  pWanMesh = false;
  pWanError: any;
  //aggregator_routing
  vlanAssignmentsList: any[] = [];
  protocolsList: any[] = [];
  filtersList: any[] = [];
  gatewayAggList: any[] = [];
  isVlanLoader: boolean = false;
  isGatewayLoader: boolean = false;
  isProtocolLoader: boolean = false;
  isFilterLoader: boolean = false;
  errors: any;
  //router rules

  aFrm1: any[] = [];
  aFrm2: any[] = [];
  aFrm3: any[] = [];
  aFrm4: any[] = [];
  aFrm5: any[] = [];
  wFrm1 = false;
  wFrm2 = false;
  wFrm3 = false;
  wFrm4 = false;
  wFrm5 = false;

  aFrm1Error: any = [];
  aFrm2Error: any = [];
  aFrm3Error: any = [];
  aFrm4Error: any = [];
  aFrm5Error: any = [];
  isUndefinedError: boolean = false;

  pWanList: any = [];
  wanNatList: any[] = [];
  wanNatUrl = "";
  wanPwnList: any[] = [];
  wanBndList: any[] = [];
  wanNttList: any[] = [];
  wanNttUrl = "";
  wanPrtList: any[] = [];
  wanPrtUrl = "";

  routingGroups: any[] = [];
  aggregatorsList: any[] = [];
  bondList: any[] = [];

  isDivVisible: any = false;
  noLegsConfigured: any = true;

  wanForm1!: FormGroup;
  wanForm2!: FormGroup;
  wanForm3!: FormGroup;
  nttForm!: FormGroup;
  prtForm!: FormGroup;
  cloudFirewallForm: FormGroup;

  respForm1: any[] = [];
  respForm2: any[] = [];
  respForm3: any[] = [];
  respForm4: any[] = [];
  respForm5: any[] = [];

  fetchCount1: number = 0;
  fetchCount2: number = 0;
  fetchCount3: number = 0;
  fetchCount4: number = 0;
  fetchCount5: number = 0;

  isSharedManageMashPermission: boolean = true;
  isSharedSdwanEditPermission: boolean = true;
  IsManagedMesh: boolean = false;
  IsCloudFirewallLogin: boolean = false;

  isEditOption: boolean;

  @Input() selectedChildTab: any = 1;
  @Input() parentId: any;
  @Input() spaceData: any;
  @Input() sharedSdwanData: any;
  @Input() cloudFirewallArr: any;
  @Input() ipList: any = [];
  @Input() delIpList: any = [];
  @Input() privateWANPermissionsObj: any = { view: true, update: true, delete: true };
  @Input() manageMeshRoutingPermissionsObj: any = { view: true, update: true, delete: true };
  @Input() privateWANRoutingPermissionsObj: any = { view: true, update: true, delete: true };;
  @Input() privateWANCloudPermissionsObj: any = { view: true, update: true, delete: true };

  constructor(private modalService: NgbModal,
    private sharedService: SharedService,
    private cd: ChangeDetectorRef, private cookie: CookieService,
    private spaceService: PartnerSpaceService,
    private formErrorHandler: FormErrorService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.apiURL = this.cookie.get('api_url');
    this.wanForm1 = this.createAddressOptionForm();
    this.wanForm2 = this.createAddressOptionForm();
    this.wanForm3 = this.createAddressOptionForm();
    this.nttForm = this.createAddressOptionForm();
    this.prtForm = this.createAddressOptionForm();
    this.getvlanAssignments()
    this.getprotocols()
    this.getfilters()
    this.getgatewayAgg();
    this.getBondList();
    this.getWanList()
    if (this.sharedSdwanData) {
      if (!this.sharedSdwanData.isManageMeshRouting) this.isSharedManageMashPermission = false;
      if (this.sharedSdwanData.permissions?.update == 'false') this.isSharedSdwanEditPermission = false;
      else this.isSharedSdwanEditPermission = true;
    }

    this.wanForm1.valueChanges.subscribe(x => {
      this.wFrm1 = true;
      this.aFrm1 = x.data;
      this.isUndefinedError = false;
      this.aFrm1Error = [];
    })
    this.wanForm2.valueChanges.subscribe(x => {
      this.wFrm2 = true;
      this.aFrm2 = x.data;
      this.isUndefinedError = false;
      this.aFrm2Error = [];
    })
    this.wanForm3.valueChanges.subscribe(x => {
      this.wFrm3 = true;
      this.aFrm3 = x.data;
      this.isUndefinedError = false;
      this.aFrm3Error = [];
    })
    this.nttForm.valueChanges.subscribe(x => {
      this.wFrm4 = true;
      this.aFrm4 = x.data;
      this.isUndefinedError = false;
      this.aFrm4Error = [];
    })
    this.prtForm.valueChanges.subscribe(x => {
      this.wFrm5 = true;
      this.aFrm5 = x.data;
      this.isUndefinedError = false;
      this.aFrm5Error = [];
    })
  }

  ngOnChanges() {
    if (this.spaceData?.id) {
      this.cloudFirewallForm = this.createCloudFirewallForm(this.cloudFirewallArr);
      if (this.cloudFirewallArr?.url) {
        this.IsCloudFirewallLogin = true;
        this.isEditOption = false;
      } else {
        this.isEditOption = true;
      }
    }
  }

  onTabChange(tabNumber: number) {
    this.selectedChildTab = tabNumber;
  }

  // private_wan

  async getWanList() {
    await Promise.all([this.getSpaceList(), this.getAggregatorList()]);
    this.pWanList = [];
    let reqObj = {
      "url": `spaces/${this.parentId}/private_wan/`,
      "method": "GET"
    }
    // this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200 || addRes.code == 201) {
        // console.log("PRIVATE_WAN>>>");
        if (addRes?.data?.mode == "managed_mesh") this.IsManagedMesh = true;
        else this.IsManagedMesh = false;
        this.pWanList = addRes.data;
        this.pWanEnable = this.pWanList.enabled;
        this.pWanMode = this.pWanList.mode;
        this.pWanMesh = this.pWanList.managed_mesh_include_aggregator_interfaces;
        // console.log(this.pWanList);
        this.wanNatUrl = addRes.data.outbound_gateways;

        this.wanNatList = addRes.data.outbound_gateways_list.filter((o: any) => {
          return o.mode == "nat-via-pwan";
        });
        this.wanPwnList = addRes.data.outbound_gateways_list.filter((o: any) => {
          return o.mode == "gateway-via-pwan";
        });
        this.wanBndList = addRes.data.outbound_gateways_list.filter((o: any) => {
          return o.mode == "gateway-via-bonder";
        });

        this.wanNttUrl = addRes.data.nat_rules;
        this.wanNttList = addRes.data.nat_rules_list;

        this.wanPrtUrl = addRes.data.port_forward_rules;
        this.wanPrtList = addRes.data.port_forward_rules_list;

        if (this.wanNatList.length > 0) this.addedGatewayRouter('1', this.wanNatList);
        if (this.wanPwnList.length > 0) this.addedGatewayRouter('2', this.wanPwnList);
        // if (this.wanBndList.length > 0) this.addedGatewayRouter('3', this.wanBndList);
        if (this.wanNttList.length > 0) this.addedGatewayRouter('4', this.wanNttList);
        if (this.wanPrtList.length > 0) this.addedGatewayRouter('5', this.wanPrtList);
      }
      this.cd.detectChanges();
      // this.sharedService.hideLoader();
    }, (err) => {
      this.sharedService.loggerError(err);
      // this.sharedService.hideLoader();
    })
  }

  changePWANMode(mode: string) {
    this.pWanMode = mode;
    this.cd.detectChanges();
  }

  savePWANBasics() {
    this.pWanError = null;
    this.pWanList.enabled = this.pWanEnable;
    this.pWanList.mode = this.pWanMode;
    this.pWanList.managed_mesh_include_aggregator_interfaces = this.pWanMesh;
    let reqObj = {
      "url": `spaces/${this.parentId}/private_wan/`,
      "method": "PATCH",
      "data": this.pWanList
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200 || addRes.code == 201) {
        if (addRes?.data?.mode == "managed_mesh") this.IsManagedMesh = true;
        else this.IsManagedMesh = false;
        this.sharedService.loggerSuccess('private wan saved successfully.');
        let reqObj1 = {
          "url": `spaces/${this.parentId}/configuration_check/`,
          "method": "GET"
        }
        this.spaceService.generalSpaceApi(reqObj1).subscribe((addRes1) => {
          if (addRes1.code == 200 || addRes1.code == 201) {
            if (addRes1.data.errors && addRes1.data.errors.length > 0) this.pWanError = addRes1.data.errors;
          }
        }, (err) => {
          try {
            this.pWanError = JSON.parse(err);
            if (this.pWanError.non_field_errors) this.sharedService.loggerError(this.pWanError.non_field_errors);
            else this.sharedService.loggerError('Please correct the errors.');
            this.sharedService.hideLoader();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        })
      }
      this.getWanList();
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.pWanError = JSON.parse(err);
        if (this.pWanError.non_field_errors) this.sharedService.loggerError(this.pWanError.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    })
  }

  //aggregator_routing

  getvlanAssignments(parentId?: any) {
    let parentId1 = (parentId) ? parentId : this.parentId;
    let reqObj = {
      "url": `spaces/${parentId1}/private_wan/space_routing_group_vlan_assignments/`,
      "method": "GET"
    }
    this.isVlanLoader = true;
    this.spaceService.getIps(reqObj).subscribe((list: any) => {
      if (list?.data) this.vlanAssignmentsList = list.data;
      this.isVlanLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isVlanLoader = false;
    })
  }

  getgatewayAgg(parentId?: any) {
    let parentId1 = (parentId) ? parentId : this.parentId;
    let reqObj = {
      "url": `spaces/${parentId1}/private_wan/managed_mesh_gateway_aggregators/`,
      "method": "GET"
    }
    this.isGatewayLoader = true;
    this.spaceService.getIps(reqObj).subscribe((list: any) => {
      if (list?.data) this.gatewayAggList = list.data;
      this.isGatewayLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isGatewayLoader = false;
    })
  }

  getprotocols(parentId?: any) {
    let parentId1 = (parentId) ? parentId : this.parentId;
    let reqObj = {
      "url": `dynamic_routing/space/protocols?space=${parentId1}`,
      "method": "GET"
    }
    this.isProtocolLoader = true;
    this.spaceService.getIps(reqObj).subscribe((list: any) => {
      if (list?.data) this.protocolsList = list.data;
      this.isProtocolLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isProtocolLoader = false;
    })
  }

  getfilters(parentId?: any) {
    let parentId1 = (parentId) ? parentId : this.parentId;
    let reqObj = {
      "url": `dynamic_routing/space/filters?space=${parentId1}`,
      "method": "GET"
    }
    this.isFilterLoader = true;
    this.spaceService.getIps(reqObj).subscribe((list: any) => {
      if (list?.data) this.filtersList = list.data;
      this.isFilterLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.isFilterLoader = false;
    })
  }

  editVlan(data: any) {
    let modal = this.modalService.open(SharedSpaceWanAddVlanComponent, { size: 'lg' });
    modal.componentInstance.parentId = this.parentId;
    modal.componentInstance.groupList = this.spaceData?.valid_routing_groups;
    if (data) {
      modal.componentInstance.vlanData = data;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getvlanAssignments();
    }, () => { });
  }

  deleteVlan(data: any) {
    let reqObj = {
      "method": "DELETE",
      "url": `spaces/${this.parentId}/private_wan/space_routing_group_vlan_assignments/${data.id}/`
    }
    let questionTitle = 'Delete routing group VLAN assignment';
    let text = `Are you sure you want to delete this assignment? This will deconfigure the VLAN interfaces, addresses,
      and protocols running for this space on aggregators in this routing group, and will remove this routing group from any space protocols.`
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.spaceService.deleteData(reqObj).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getvlanAssignments();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error?.detail) this.sharedService.loggerError(error?.detail);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getvlanAssignments();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error?.detail) this.sharedService.loggerError(error?.detail);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addProtocol(data: any) {
    let modal = this.modalService.open(SharedSpaceWanAddProtocolComponent, { size: 'lg' });
    modal.componentInstance.parentId = this.parentId;
    modal.componentInstance.allfilters = this.filtersList;
    modal.componentInstance.vlanAssignmentsList = this.vlanAssignmentsList;
    if (data) {
      modal.componentInstance.protocolData = data;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getprotocols();
    }, () => { });
  }

  deleteProtocol(data: any) {
    let reqObj = {
      "method": "DELETE",
      "url": `dynamic_routing/space/protocols/${data.id}/`
    }
    let questionTitle = 'Delete protocol ';
    let text = "Are you sure you want to delete this protocol? This will deconfigure this protocol on any aggregators using it."
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.spaceService.deleteData(reqObj).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getprotocols();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error?.non_field_errors) this.sharedService.loggerError(error?.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getprotocols();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error?.detail) this.sharedService.loggerError(error?.detail);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addFilter(data: any) {
    let modal = this.modalService.open(SharedSpaceWanAddFilterComponent, { size: 'lg' });
    modal.componentInstance.parentId = this.parentId;
    if (data) {
      modal.componentInstance.filterData = data;
      modal.componentInstance.isEdit = true
    } else modal.componentInstance.isEdit = false;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getfilters();
    }, () => { });
  }

  deleteFilter(data: any) {
    let reqObj = {
      "method": "DELETE",
      "url": `dynamic_routing/space/filters/${data.id}/`
    }
    let questionTitle = 'Delete filter';
    let text = "Are you sure you want to delete this filter? This will deconfigure this filter on any aggregators using it."
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.spaceService.deleteData(reqObj).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getfilters();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error?.non_field_errors) this.sharedService.loggerError(error?.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getfilters();
            this.cd.detectChanges();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error?.detail) this.sharedService.loggerError(error?.detail);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addGateway() {
    let modal = this.modalService.open(SharedSpaceWanAddGatewayComponent, { size: 'md' });
    modal.componentInstance.parentId = this.parentId;
    modal.componentInstance.aggregatorList = this.spaceData?.valid_aggregators;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getgatewayAgg();
    }, () => { });
  }

  deleteGateway(data: any) {
    let reqObj = {
      "method": "DELETE",
      "url": `spaces/${this.parentId}/private_wan/managed_mesh_gateway_aggregators/${data.id}/`
    }
    let questionTitle = 'Unassign gateway aggregator';
    let text = "Are you sure you want to unassign this aggregator as a gateway?";
    let confirmButtonText = "Unassign";
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.spaceService.deleteData(reqObj).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getgatewayAgg();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error?.non_field_errors) this.sharedService.loggerError(error?.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getgatewayAgg();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error?.detail) this.sharedService.loggerError(error?.detail);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  //router rules

  get wanData1(): FormArray {
    return this.wanForm1.get("data") as FormArray
  }
  get wanData2(): FormArray {
    return this.wanForm2.get("data") as FormArray
  }
  get wanData3(): FormArray {
    return this.wanForm3.get("data") as FormArray
  }
  get nttData(): FormArray {
    return this.nttForm.get("data") as FormArray
  }
  get prtData(): FormArray {
    return this.prtForm.get("data") as FormArray
  }

  getSpaceList() {
    return new Promise((resolve, reject) => {
      let reqObj = { "url": `routing_groups/`, "method": "GET" }
      this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
        if (addRes.code == 200) this.routingGroups = addRes.data;
        this.cd.detectChanges();
        return resolve(true);
      })
    });
  }

  getAggregatorList() {
    return new Promise((resolve, reject) => {
      let reqObj = { "url": `aggregators/`, "method": "GET" }
      this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
        if (addRes.code == 200) this.aggregatorsList = addRes.data;
        this.cd.detectChanges();
        return resolve(true);
      })
    });
  }

  getBondList() {
    let reqObj = {
      "url": `bonds/?space=${this.parentId}`,
      "method": "GET"
    }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.bondList = addRes.data;
        this.cd.detectChanges();
      }
    })
  }

  addedGatewayRouter(TYP: string, data: any) {
    this.noLegsConfigured = false;
    this.isDivVisible = true;

    if (TYP == '1') {
      for (let d of data) this.wanData1.push(this.newGroup1(d, true));
      this.wFrm1 = false;
    } else if (TYP == '2') {
      for (let d of data) this.wanData2.push(this.newGroup2(d, true));
      this.wFrm2 = false;
    } else if (TYP == '3') {
      for (let d of data) this.wanData3.push(this.newGroup3(d, true));
      this.wFrm3 = false;
    } else if (TYP == '4') {
      for (let d of data) this.nttData.push(this.nttGroup(d, true));
      this.wFrm4 = false;
    } else if (TYP == '5') {
      for (let d of data) this.prtData.push(this.prtGroup(d, true));
      this.wFrm5 = false;
    }
  }

  addGatewayRouterChange(data: any) {
    this.noLegsConfigured = false;
    this.isDivVisible = true;
    if (data == '1') this.wanData1.push(this.newGroup1([], true));
    else if (data == '2') this.wanData2.push(this.newGroup2([], true));
    else if (data == '3') this.wanData3.push(this.newGroup3([], true));
  }

  addNttForm() {
    this.nttData.push(this.nttGroup([], true));
  }

  addPrtForm() {
    this.prtData.push(this.prtGroup([], true));
  }

  onToggle1(index: number) {
    let control = this.wanData1.controls[index].get('isDeleted');
    control?.patchValue(!control?.value);

    if (this.wanData1.controls[index].get('isDeleted')?.value == true) this.wanData1.controls[index].disable();
    else this.wanData1.controls[index].enable();

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }

  onToggle2(index: number) {
    let control = this.wanData2.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)

    if (this.wanData2.controls[index].get('isDeleted')?.value == true) this.wanData2.controls[index].disable();
    else this.wanData2.controls[index].enable();

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }

  onToggle3(index: number) {
    let control = this.wanData3.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)

    if (this.wanData3.controls[index].get('isDeleted')?.value == true) this.wanData3.controls[index].disable();
    else this.wanData3.controls[index].enable();

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }

  onNttToggle(index: number) {
    let control = this.nttData.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)

    if (this.nttData.controls[index].get('isDeleted')?.value == true) this.nttData.controls[index].disable();
    else this.nttData.controls[index].enable();

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }

  onPrtToggle(index: number) {
    let control = this.prtData.controls[index].get('isDeleted');
    control?.patchValue(!control?.value)

    if (this.prtData.controls[index].get('isDeleted')?.value == true) this.prtData.controls[index].disable();
    else this.prtData.controls[index].enable();

    setTimeout(() => {
      this.cd.detectChanges();
    });
  }

  createAddressOptionForm() {
    return this.fb.group({ data: this.fb.array([]) })
  }

  newGroup1(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list) dropDownArray.push(drp.routing_group);
      let natRoutingArray: any[] = [];
      if (data.ip) {
        let public_ip = data.ip.split('/')[0];
        if (this.routingGroups && this.routingGroups.length > 0) {
          natRoutingArray = this.routingGroups.filter((item) => {
            let isInclude: boolean = false
            if (item.ip_allocations && item.ip_allocations.length > 0) {
              item.ip_allocations.forEach((element: any) => {
                if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
              });
            }
            return isInclude;
          });
          if (this.bondList && this.bondList.length > 0) {
            let bondRoutingGroupArray: string[] = [];
            this.bondList.forEach((bond) => {
              if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                this.aggregatorsList.forEach((aggregator) => {
                  if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                });
              }
            });
            natRoutingArray = natRoutingArray.filter((item) => bondRoutingGroupArray.includes(item.url));
          }
        }
      }
      data?.assigned_routing_groups_list?.forEach((item: any) => {
        let isInclude: boolean = false
        natRoutingArray?.forEach((route: any) => {
          if (item.routing_group == route.url) isInclude = true;
        });
        if (!isInclude) natRoutingArray?.push({ ...item, display_name: 'unavailable', url: item.routing_group });
      });
      let group = this.fb.group({
        natenbl: [data.enabled],
        natip: [data.ip],
        natrouting: [dropDownArray],
        natRoutingList: [natRoutingArray],
        natnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [false],
        id: [data.id],
      });
      group.controls.natip.valueChanges.subscribe((value) => {
        group.controls.natrouting.setValue([]);
        if (!value) group.controls.natRoutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
            if (this.bondList && this.bondList.length > 0) {
              let bondRoutingGroupArray: string[] = [];
              this.bondList.forEach((bond) => {
                if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                  this.aggregatorsList.forEach((aggregator) => {
                    if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                  });
                }
              });
              finalArray = finalArray.filter((item) => bondRoutingGroupArray.includes(item.url));
            }
          }
          group.controls.natRoutingList.setValue(finalArray);
        }
      })
      return group;
    } else {
      let group = this.fb.group({
        natenbl: [true],
        natip: [''],
        natrouting: [[]],
        natRoutingList: [[]],
        natnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true],
        id: [0],
      });
      group.controls.natip.valueChanges.subscribe((value) => {
        group.controls.natrouting.setValue([]);
        if (!value) group.controls.natRoutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
            if (this.bondList && this.bondList.length > 0) {
              let bondRoutingGroupArray: string[] = [];
              this.bondList.forEach((bond) => {
                if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                  this.aggregatorsList.forEach((aggregator) => {
                    if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                  });
                }
              });
              finalArray = finalArray.filter((item) => bondRoutingGroupArray.includes(item.url));
            }
          }
          group.controls.natRoutingList.setValue(finalArray);
        }
      })
      return group;
    }
  }

  newGroup2(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list) dropDownArray.push(drp.routing_group);
      let pwnRoutingArray: any[] = [];
      if (data.ip) {
        let public_ip = data.ip.split('/')[0];
        if (this.routingGroups && this.routingGroups.length > 0) {
          pwnRoutingArray = this.routingGroups.filter((item) => {
            let isInclude: boolean = false
            if (item.ip_allocations && item.ip_allocations.length > 0) {
              item.ip_allocations.forEach((element: any) => {
                if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
              });
            }
            return isInclude;
          });
          if (this.bondList && this.bondList.length > 0) {
            let bondRoutingGroupArray: string[] = [];
            this.bondList.forEach((bond) => {
              if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                this.aggregatorsList.forEach((aggregator) => {
                  if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                });
              }
            });
            pwnRoutingArray = pwnRoutingArray.filter((item) => bondRoutingGroupArray.includes(item.url));
          }
        }
      }
      data?.assigned_routing_groups_list?.forEach((item: any) => {
        let isInclude: boolean = false
        pwnRoutingArray?.forEach((route: any) => {
          if (item.routing_group == route.url) isInclude = true;
        });
        if (!isInclude) pwnRoutingArray?.push({ ...item, display_name: 'unavailable', url: item.routing_group });
      });
      let group = this.fb.group({
        pwnenbl: [data.enabled],
        pwnip: [data.ip],
        pwngate: [data.gateway],
        pwnip6: [data.ipv6],
        pwnip6gate: [data.gateway6],
        pwnvlan: [data.vlan],
        pwnrouting: [dropDownArray],
        pwnRoutingList: [pwnRoutingArray],
        pwnnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [false],
        id: [data.id]
      });
      group.controls.pwnip.valueChanges.subscribe((value) => {
        group.controls.pwnrouting.setValue([]);
        if (!value) group.controls.pwnRoutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
            if (this.bondList && this.bondList.length > 0) {
              let bondRoutingGroupArray: string[] = [];
              this.bondList.forEach((bond) => {
                if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                  this.aggregatorsList.forEach((aggregator) => {
                    if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                  });
                }
              });
              finalArray = finalArray.filter((item) => bondRoutingGroupArray.includes(item.url));
            }
          }
          group.controls.pwnRoutingList.setValue(finalArray);
        }
      })
      return group;
    } else {
      let group = this.fb.group({
        pwnenbl: [true],
        pwnip: [''],
        pwngate: [''],
        pwnip6: [''],
        pwnip6gate: [''],
        pwnvlan: [''],
        pwnrouting: [[]],
        pwnRoutingList: [[]],
        pwnnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true],
        id: [0],
      });
      group.controls.pwnip.valueChanges.subscribe((value) => {
        group.controls.pwnrouting.setValue([]);
        if (!value) group.controls.pwnRoutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
            if (this.bondList && this.bondList.length > 0) {
              let bondRoutingGroupArray: string[] = [];
              this.bondList.forEach((bond) => {
                if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                  this.aggregatorsList.forEach((aggregator) => {
                    if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                  });
                }
              });
              finalArray = finalArray.filter((item) => bondRoutingGroupArray.includes(item.url));
            }
          }
          group.controls.pwnRoutingList.setValue(finalArray);
        }
      })
      return group;
    }
  }

  newGroup3(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list) dropDownArray.push(drp.routing_group);
      let bndRoutingArray: any[] = [];
      if (data.gateway6) {
        let public_ip = data.gateway6.split('/')[0];
        if (this.routingGroups && this.routingGroups.length > 0) {
          bndRoutingArray = this.routingGroups.filter((item) => {
            let isInclude: boolean = false
            if (item.ip_allocations && item.ip_allocations.length > 0) {
              item.ip_allocations.forEach((element: any) => {
                if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
              });
            }
            return isInclude;
          });
          if (this.bondList && this.bondList.length > 0) {
            let bondRoutingGroupArray: string[] = [];
            this.bondList.forEach((bond) => {
              if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                this.aggregatorsList.forEach((aggregator) => {
                  if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                });
              }
            });
            bndRoutingArray = bndRoutingArray.filter((item) => bondRoutingGroupArray.includes(item.url));
          }
        }
      }
      data?.assigned_routing_groups_list?.forEach((item: any) => {
        let isInclude: boolean = false
        bndRoutingArray?.forEach((route: any) => {
          if (item.routing_group == route.url) isInclude = true;
        });
        if (!isInclude) bndRoutingArray?.push({ ...item, display_name: 'unavailable', url: item.routing_group });
      });
      let group = this.fb.group({
        bndenbl: [data.enabled],
        bndgate: [data.gateway],
        bndip6gate: [data.gateway6],
        bndrouting: [dropDownArray],
        bndRoutingList: [bndRoutingArray],
        bndnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [false],
        id: [data.id]
      });
      group.controls.bndip6gate.valueChanges.subscribe((value) => {
        group.controls.bndrouting.setValue([]);
        if (!value) group.controls.bndRoutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
            if (this.bondList && this.bondList.length > 0) {
              let bondRoutingGroupArray: string[] = [];
              this.bondList.forEach((bond) => {
                if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                  this.aggregatorsList.forEach((aggregator) => {
                    if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                  });
                }
              });
              finalArray = finalArray.filter((item) => bondRoutingGroupArray.includes(item.url));
            }
          }
          group.controls.bndRoutingList.setValue(finalArray);
        }
      })
      return group;
    } else {
      let group = this.fb.group({
        bndenbl: [true],
        bndgate: [''],
        bndip6gate: [''],
        bndrouting: [[]],
        bndRoutingList: [[]],
        bndnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true],
        id: [0],
      });
      group.controls.bndip6gate.valueChanges.subscribe((value) => {
        group.controls.bndrouting.setValue([]);
        if (!value) group.controls.bndRoutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
            if (this.bondList && this.bondList.length > 0) {
              let bondRoutingGroupArray: string[] = [];
              this.bondList.forEach((bond) => {
                if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                  this.aggregatorsList.forEach((aggregator) => {
                    if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                  });
                }
              });
              finalArray = finalArray.filter((item) => bondRoutingGroupArray.includes(item.url));
            }
          }
          group.controls.bndRoutingList.setValue(finalArray);
        }
      })
      return group;
    }
  }

  nttGroup(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list) dropDownArray.push(drp.routing_group);
      let nttRoutingArray: any[] = [];
      if (data.public_ip) {
        let public_ip = data.public_ip.split('/')[0];
        if (this.routingGroups && this.routingGroups.length > 0) {
          nttRoutingArray = this.routingGroups.filter((item) => {
            let isInclude: boolean = false
            if (item.ip_allocations && item.ip_allocations.length > 0) {
              item.ip_allocations.forEach((element: any) => {
                if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
              });
            }
            return isInclude;
          });
          if (this.bondList && this.bondList.length > 0) {
            let bondRoutingGroupArray: string[] = [];
            this.bondList.forEach((bond) => {
              if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                this.aggregatorsList.forEach((aggregator) => {
                  if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                });
              }
            });
            nttRoutingArray = nttRoutingArray.filter((item) => bondRoutingGroupArray.includes(item.url));
          }
        }
      }
      data?.assigned_routing_groups_list?.forEach((item: any) => {
        let isInclude: boolean = false
        nttRoutingArray?.forEach((route: any) => {
          if (item.routing_group == route.url) isInclude = true;
        });
        if (!isInclude) nttRoutingArray?.push({ ...item, display_name: 'unavailable', url: item.routing_group });
      });
      let group = this.fb.group({
        nttenbl: [data.enabled],
        nttpubip: [data.public_ip],
        nttpriip: [data.private_ip],
        nttrouting: [dropDownArray],
        nttRoutingList: [nttRoutingArray],
        nttnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [false],
        id: [data.id],
      });
      group.controls.nttpubip.valueChanges.subscribe((value) => {
        group.controls.nttrouting.setValue([]);
        if (!value) group.controls.nttRoutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
            if (this.bondList && this.bondList.length > 0) {
              let bondRoutingGroupArray: string[] = [];
              this.bondList.forEach((bond) => {
                if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                  this.aggregatorsList.forEach((aggregator) => {
                    if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                  });
                }
              });
              finalArray = finalArray.filter((item) => bondRoutingGroupArray.includes(item.url));
            }
          }
          group.controls.nttRoutingList.setValue(finalArray);
        }
      })
      return group;
    } else {
      let group = this.fb.group({
        nttenbl: [true],
        nttpubip: [''],
        nttpriip: [''],
        nttrouting: [''],
        nttRoutingList: [[]],
        nttnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true],
        id: [0],
      });
      group.controls.nttpubip.valueChanges.subscribe((value) => {
        group.controls.nttrouting.setValue([]);
        if (!value) group.controls.nttRoutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
            if (this.bondList && this.bondList.length > 0) {
              let bondRoutingGroupArray: string[] = [];
              this.bondList.forEach((bond) => {
                if (bond && bond.aggregator_id && this.aggregatorsList && this.aggregatorsList.length > 0) {
                  this.aggregatorsList.forEach((aggregator) => {
                    if (aggregator && aggregator.id == bond.aggregator_id && !bondRoutingGroupArray.includes(aggregator.routing_group.url)) bondRoutingGroupArray.push(aggregator.routing_group.url)
                  });
                }
              });
              finalArray = finalArray.filter((item) => bondRoutingGroupArray.includes(item.url));
            }
          }
          group.controls.nttRoutingList.setValue(finalArray);
        }
      })
      return group;
    }
  }

  prtGroup(data: any = {}, isNew = false): FormGroup {
    let isData = true;
    if (data.length == 0 || data == null || data == undefined) isData = false;
    if (isData) {
      let dropDownArray = [];
      for (let drp of data.assigned_routing_groups_list) dropDownArray.push(drp.routing_group);
      let prtRoutingArray: any[] = [];
      if (data.public_ip) {
        let public_ip = data.public_ip.split('/')[0];
        if (this.routingGroups && this.routingGroups.length > 0) {
          prtRoutingArray = this.routingGroups.filter((item) => {
            let isInclude: boolean = false
            if (item.ip_allocations && item.ip_allocations.length > 0) {
              item.ip_allocations.forEach((element: any) => {
                if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
              });
            }
            return isInclude;
          });
        }
      }
      data?.assigned_routing_groups_list?.forEach((item: any) => {
        let isInclude: boolean = false
        prtRoutingArray?.forEach((route: any) => {
          if (item.routing_group == route.url) isInclude = true;
        });
        if (!isInclude) prtRoutingArray?.push({ ...item, display_name: 'unavailable', url: item.routing_group });
      });
      let group = this.fb.group({
        prtenbl: [data.enabled],
        prtprotocol: [data.protocol],
        prtpubip: [data.public_ip],
        prtpubport: [data.public_port],
        prtpriip: [data.private_ip],
        prtpriport: [data.private_port],
        prtrouting: [dropDownArray],
        prtroutingList: [prtRoutingArray],
        prthelper: [data.helper],
        prtnote: [data.note, [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [false],
        id: [data.id],
      });
      group.controls.prtpubip.valueChanges.subscribe((value) => {
        group.controls.prtrouting.setValue([]);
        if (!value) group.controls.prtroutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
          }
          group.controls.prtroutingList.setValue(finalArray);
        }
      })
      return group;
    } else {
      let group = this.fb.group({
        prtenbl: [true],
        prtprotocol: ['ICMP'],
        prtpubip: [''],
        prtpubport: [''],
        prtpriip: [''],
        prtpriport: [''],
        prtrouting: [[]],
        prtroutingList: [[]],
        prthelper: [''],
        prtnote: ['', [this.sharedService.xssValidator]],
        isDeleted: [false],
        isNew: [true],
        id: [0],
      });
      group.controls.prtpubip.valueChanges.subscribe((value) => {
        group.controls.prtrouting.setValue([]);
        if (!value) group.controls.prtroutingList.setValue([]);
        else {
          let finalArray = [];
          let public_ip = value.split('/')[0];
          if (this.routingGroups && this.routingGroups.length > 0) {
            finalArray = this.routingGroups.filter((item) => {
              let isInclude: boolean = false
              if (item.ip_allocations && item.ip_allocations.length > 0) {
                item.ip_allocations.forEach((element: any) => {
                  if (this.isIpInSubnet(public_ip, element?.network)) isInclude = true;
                });
              }
              return isInclude;
            });
          }
          group.controls.prtroutingList.setValue(finalArray);
        }
      })
      return group;
    }
  }

  isPrtProtocol(i: number) {
    let prt = this.prtData.controls[i].get('prtprotocol')?.value;
    if (!(prt == 'TCP' || prt == 'UDP' || prt == 'SCTP')) {
      this.prtData.controls[i].get('prtpubport')?.setValue('');
      this.prtData.controls[i].get('prtpriport')?.setValue('');
    }
  }

  updateAllRouting() {
    this.isUndefinedError = false;
    if (this.wFrm1 == true) {
      this.aFrm1Error = [];
      this.respForm1 = [];

      let dta: any = this.wanForm1.controls.data;
      let dtalen = dta.controls.length;
      if (dtalen > 0) {
        dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.isDeleted == true && data.value.isNew == true));
        this.wanForm1.controls.data = dta;
        this.cd.detectChanges();
        let del = 0;
        for (let dt of dta.controls) {
          if (dt.value.isDeleted == true && dt.value.isNew == false) this.deleteRouting('1', this.wanNatUrl, dt.value.id, del);
          del++;
          if (del == dta.controls.length) this.doUpdatesForm1();
        }
      }
    }

    if (this.wFrm2 == true) {
      this.aFrm2Error = [];
      this.respForm2 = [];

      let dta: any = this.wanForm2.controls.data;
      let dtalen = dta.controls.length;
      if (dtalen > 0) {
        dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.isDeleted == true && data.value.isNew == true));
        this.wanForm2.controls.data = dta;
        this.cd.detectChanges();
        let del = 0;
        for (let dt of dta.controls) {
          if (dt.value.isDeleted == true && dt.value.isNew == false) this.deleteRouting('2', this.wanNatUrl, dt.value.id, del);
          del++;
          if (del == dta.controls.length) this.doUpdatesForm2();
        }
      }
    }

    if (this.wFrm3 == true) {
      this.aFrm3Error = [];
      this.respForm3 = [];

      let dta: any = this.wanForm3.controls.data;
      let dtalen = dta.controls.length;
      if (dtalen > 0) {
        dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.isDeleted == true && data.value.isNew == true));
        this.wanForm3.controls.data = dta;
        this.cd.detectChanges();
        let del = 0;
        for (let dt of dta.controls) {
          if (dt.value.isDeleted == true && dt.value.isNew == false) this.deleteRouting('3', this.wanNatUrl, dt.value.id, del);
          del++;
          if (del == dta.controls.length) this.doUpdatesForm3();
        }
      }
    }

    if (this.wFrm4 == true) {
      this.aFrm4Error = [];
      this.respForm4 = [];

      let dta: any = this.nttForm.controls.data;
      let dtalen = dta.controls.length;
      if (dtalen > 0) {
        dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.isDeleted == true && data.value.isNew == true));
        this.nttForm.controls.data = dta;
        this.cd.detectChanges();
        let del = 0;
        for (let dt of dta.controls) {
          if (dt.value.isDeleted == true && dt.value.isNew == false) this.deleteRouting('4', this.wanNttUrl, dt.value.id, del);
          del++;
          if (del == dta.controls.length) this.doUpdatesForm4();
        }
      }
    }

    if (this.wFrm5 == true) {
      this.aFrm5Error = [];
      this.respForm5 = [];
      let dta: any = this.prtForm.controls.data;
      let dtalen = dta.controls.length;
      if (dtalen) {
        dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.isDeleted == true && data.value.isNew == true));
        this.prtForm.controls.data = dta;
        this.cd.detectChanges();
        let del = 0;
        for (let dt of dta.controls) {
          if (dt.value.isDeleted == true && dt.value.isNew == false) this.deleteRouting('5', this.wanPrtUrl, dt.value.id, del);
          del++;
          if (del == dta.controls.length) this.doUpdatesForm5();
        }
      }
    }
  }

  doUpdatesForm1() {
    this.fetchCount1 = 0;
    let index = 0;
    for (let fdt of this.aFrm1) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];

      if (this.routingGroups && this.routingGroups.length > 0) {
        for (let v of this.routingGroups) {
          v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
          mv_rout.push({ "routing_group": v.url, "display_name": v.display_name });
        }
      }
      let rcnt = 0;
      if (fdt && fdt.natrouting && fdt.natrouting.length > 0) {
        for (let r of fdt.natrouting) {
          let frouting: any = v_rout.filter((fr: any) => fr.routing_group == r);
          if (frouting.length > 0) {
            a_rout.push(frouting[0]);
            mv_rout[rcnt]['url'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
            mv_rout[rcnt]['name'] = this.routingGroups[rcnt]['name'];
            ma_rout.push(mv_rout[rcnt]);
          }
          rcnt++;
        }
      }

      if (fdt.isNew) {
        this.addRouting1(fdt, v_rout, a_rout, index);
        index++;
      } else if (!fdt.isDeleted) {
        let tmp = this.wanNatList.filter((w) => w.id == fdt.id);

        this.respForm1.push(tmp[0]);
        this.respForm1[this.respForm1.length - 1]['id'] = fdt.id;
        this.respForm1[this.respForm1.length - 1]['url'] = `${this.wanNatUrl}${fdt.id}/`;
        this.respForm1[this.respForm1.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm1[this.respForm1.length - 1]['mode'] = "nat-via-pwan";
        this.respForm1[this.respForm1.length - 1]['enabled'] = fdt.natenbl;
        this.respForm1[this.respForm1.length - 1]['ip'] = fdt.natip;
        this.respForm1[this.respForm1.length - 1]['ipv6'] = null;
        this.respForm1[this.respForm1.length - 1]['gateway'] = null;
        this.respForm1[this.respForm1.length - 1]['gateway6'] = null;
        this.respForm1[this.respForm1.length - 1]['vlan'] = null;
        this.respForm1[this.respForm1.length - 1]['source_nat'] = true;
        this.respForm1[this.respForm1.length - 1]['note'] = fdt.natnote;
        this.respForm1[this.respForm1.length - 1]['assigned_routing_groups'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
        // this.respForm1[this.respForm1.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm1[this.respForm1.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm1[this.respForm1.length - 1]['a_routing'] = a_rout;
        this.respForm1[this.respForm1.length - 1]['newer'] = false;
        this.respForm1[this.respForm1.length - 1]['isUpdate'] = true;

        this.fetchCount1++;
        if (this.fetchCount1 == this.aFrm1.length) this.patchRouting1();
        index++;
      }
    }
  }

  doUpdatesForm2() {
    this.fetchCount2 = 0;
    let index = 0;
    for (let fdt of this.aFrm2) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];

      if (this.routingGroups && this.routingGroups.length > 0) {
        for (let v of this.routingGroups) {
          v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
          mv_rout.push({ "routing_group": v.url, "display_name": v.display_name })
        }
      }
      let rcnt = 0;
      if (fdt && fdt.pwnrouting && fdt.pwnrouting.length > 0) {
        for (let r of fdt.pwnrouting) {
          let frouting: any = v_rout.filter((fr: any) => fr.routing_group == r);
          if (frouting.length > 0) {
            a_rout.push(frouting[0]);
            mv_rout[rcnt]['url'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
            mv_rout[rcnt]['name'] = this.routingGroups[rcnt]['name'];
            ma_rout.push(mv_rout[rcnt]);
          }
          rcnt++;
        }
      }
      if (fdt.isNew == true) {
        this.addRouting2(fdt, v_rout, a_rout, index);
        index++;
      } else if (!fdt.isDeleted) {
        let tmp = this.wanPwnList.filter((w) => w.id == fdt.id);

        this.respForm2.push(tmp[0]);
        this.respForm2[this.respForm2.length - 1]['id'] = fdt.id;
        this.respForm2[this.respForm2.length - 1]['url'] = `${this.wanNatUrl}${fdt.id}/`;
        this.respForm2[this.respForm2.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm2[this.respForm2.length - 1]['mode'] = "gateway-via-pwan";
        this.respForm2[this.respForm2.length - 1]['enabled'] = fdt.pwnenbl;
        this.respForm2[this.respForm2.length - 1]['ip'] = fdt.pwnip;
        this.respForm2[this.respForm2.length - 1]['ipv6'] = fdt.pwnip6;
        this.respForm2[this.respForm2.length - 1]['gateway'] = fdt.pwngate;
        this.respForm2[this.respForm2.length - 1]['gateway6'] = fdt.pwnip6gate;
        this.respForm2[this.respForm2.length - 1]['vlan'] = fdt.pwnvlan;
        this.respForm2[this.respForm2.length - 1]['source_nat'] = false;
        this.respForm2[this.respForm2.length - 1]['note'] = fdt.pwnnote;
        this.respForm2[this.respForm2.length - 1]['assigned_routing_groups'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
        // this.respForm2[this.respForm2.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm2[this.respForm2.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm2[this.respForm2.length - 1]['a_routing'] = a_rout;
        this.respForm2[this.respForm2.length - 1]['newer'] = false;
        this.respForm2[this.respForm2.length - 1]['isUpdate'] = true;

        this.fetchCount2++;
        if (this.fetchCount2 == this.aFrm2.length) this.patchRouting2();
        index++;
      }
    }
  }

  doUpdatesForm3() {
    this.fetchCount3 = 0;
    let index = 0;
    for (let fdt of this.aFrm3) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];
      if (this.routingGroups && this.routingGroups.length > 0) {
        for (let v of this.routingGroups) {
          v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
          mv_rout.push({ "routing_group": v.url, "display_name": v.display_name })
        }
      }
      let rcnt = 0;
      if (fdt && fdt.bndrouting && fdt.bndrouting.length > 0) {
        for (let r of fdt.bndrouting) {
          let frouting: any = v_rout.filter((fr: any) => fr.routing_group == r);
          if (frouting.length > 0) {
            a_rout.push(frouting[0]);
            mv_rout[rcnt]['url'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
            mv_rout[rcnt]['name'] = this.routingGroups[rcnt]['name'];
            ma_rout.push(mv_rout[rcnt]);
          }
          rcnt++;
        }
      }
      if (fdt.isNew == true) {
        this.addRouting3(fdt, v_rout, a_rout, index);
        index++;
      } else if (!fdt.isDeleted) {
        let tmp = this.wanBndList.filter((w) => w.id == fdt.id);

        this.respForm3.push(tmp[0]);
        this.respForm3[this.respForm3.length - 1]['id'] = fdt.id;
        this.respForm3[this.respForm3.length - 1]['url'] = `${this.wanNatUrl}${fdt.id}/`;
        this.respForm3[this.respForm3.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm3[this.respForm3.length - 1]['mode'] = "gateway-via-bonder";
        this.respForm3[this.respForm3.length - 1]['enabled'] = fdt.bndenbl;
        this.respForm3[this.respForm3.length - 1]['ip'] = null;
        this.respForm3[this.respForm3.length - 1]['ipv6'] = null;
        this.respForm3[this.respForm3.length - 1]['gateway'] = fdt.bndgate;
        this.respForm3[this.respForm3.length - 1]['gateway6'] = fdt.bndip6gate;
        this.respForm3[this.respForm3.length - 1]['vlan'] = null;
        this.respForm3[this.respForm3.length - 1]['source_nat'] = false;
        this.respForm3[this.respForm3.length - 1]['note'] = fdt.bndnote;
        this.respForm3[this.respForm3.length - 1]['assigned_routing_groups'] = `${this.wanNatUrl}${fdt.id}/assigned_routing_groups/`;
        // this.respForm3[this.respForm3.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm3[this.respForm3.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm3[this.respForm3.length - 1]['a_routing'] = a_rout;
        this.respForm3[this.respForm3.length - 1]['newer'] = false;
        this.respForm3[this.respForm3.length - 1]['isUpdate'] = true;

        this.fetchCount3++;
        if (this.fetchCount3 == this.aFrm3.length) this.patchRouting3();
        index++;
      }
    }
  }

  doUpdatesForm4() {
    this.fetchCount4 = 0;
    let index = 0;
    for (let fdt of this.aFrm4) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];
      if (this.routingGroups && this.routingGroups.length > 0) {
        for (let v of this.routingGroups) {
          v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
          mv_rout.push({ "routing_group": v.url, "display_name": v.display_name })
        }
      }
      let rcnt = 0;
      if (fdt && fdt.nttrouting && fdt.nttrouting.length > 0) {
        for (let r of fdt.nttrouting) {
          let frouting: any = v_rout.filter((fr: any) => fr.routing_group == r);
          if (frouting.length > 0) {
            a_rout.push(frouting[0]);
            mv_rout[rcnt]['url'] = `${this.wanNttUrl}${fdt.id}/assigned_routing_groups/`;
            mv_rout[rcnt]['name'] = this.routingGroups[rcnt]['name'];
            ma_rout.push(mv_rout[rcnt]);
          }
          rcnt++;
        }
      }
      if (fdt.isNew == true) {
        this.addRouting4(fdt, v_rout, a_rout, index);
        index++;
      } else if (!fdt.isDeleted) {
        let tmp = this.wanNttList.filter((w) => w.id == fdt.id)

        this.respForm4.push(tmp[0]);
        this.respForm4[this.respForm4.length - 1]['id'] = fdt.id;
        this.respForm4[this.respForm4.length - 1]['url'] = `${this.wanNttUrl}${fdt.id}/`;
        this.respForm4[this.respForm4.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm4[this.respForm4.length - 1]['enabled'] = fdt.nttenbl;
        this.respForm4[this.respForm4.length - 1]['public_ip'] = fdt.nttpubip;
        this.respForm4[this.respForm4.length - 1]['private_ip'] = fdt.nttpriip;
        this.respForm4[this.respForm4.length - 1]['note'] = fdt.nttnote;
        // this.respForm4[this.respForm4.length - 1]['order']=fdt.nttorder;
        this.respForm4[this.respForm4.length - 1]['assigned_routing_groups'] = `${this.wanNttUrl}${fdt.id}/assigned_routing_groups/`;
        // this.respForm4[this.respForm4.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm4[this.respForm4.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm4[this.respForm4.length - 1]['a_routing'] = a_rout;
        this.respForm4[this.respForm4.length - 1]['newer'] = false;
        this.respForm4[this.respForm4.length - 1]['isUpdate'] = true;
        this.respForm4[this.respForm4.length - 1]['order'] = this.fetchCount4;

        this.fetchCount4++;
        if (this.fetchCount4 == this.aFrm4.length) this.patchRouting4();
        index++;
      }
    }
  }

  doUpdatesForm5() {
    this.fetchCount5 = 0;
    let index = 0;
    for (let fdt of this.aFrm5) {
      let mv_rout: any = [];
      let ma_rout: any = [];
      let v_rout: any = [];
      let a_rout: any = [];
      if (this.routingGroups && this.routingGroups.length > 0) {
        for (let v of this.routingGroups) {
          v_rout.push({ "routing_group": v.url, "display_name": v.display_name });
          mv_rout.push({ "routing_group": v.url, "display_name": v.display_name })
        }
      }
      let rcnt = 0;
      if (fdt && fdt.prtrouting && fdt.prtrouting.length > 0) {
        for (let r of fdt.prtrouting) {
          let frouting: any = v_rout.filter((fr: any) => fr.routing_group == r);
          if (frouting.length > 0) {
            a_rout.push(frouting[0]);
            mv_rout[rcnt]['url'] = `${this.wanPrtUrl}${fdt.id}/assigned_routing_groups/`;
            mv_rout[rcnt]['name'] = this.routingGroups[rcnt]['name'];
            ma_rout.push(mv_rout[rcnt]);
          }
          rcnt++;
        }
      }

      if (fdt.isNew == true) {
        this.addRouting5(fdt, v_rout, a_rout, index);
        index++;
      } else if (!fdt.isDeleted) {
        let tmp = this.wanPrtList.filter((w) => w.id == fdt.id)

        this.respForm5.push(tmp[0]);
        this.respForm5[this.respForm5.length - 1]['id'] = fdt.id;
        this.respForm5[this.respForm5.length - 1]['url'] = `${this.wanPrtUrl}${fdt.id}/`;
        this.respForm5[this.respForm5.length - 1]['space_private_wan'] = `${this.apiURL}spaces/${this.parentId}/private_wan/`;
        this.respForm5[this.respForm5.length - 1]['enabled'] = fdt.prtenbl;
        this.respForm5[this.respForm5.length - 1]['protocol'] = fdt.prtprotocol;
        this.respForm5[this.respForm5.length - 1]['public_ip'] = fdt.prtpubip;
        this.respForm5[this.respForm5.length - 1]['public_port'] = fdt.prtpubport;
        this.respForm5[this.respForm5.length - 1]['private_ip'] = fdt.prtpriip;
        this.respForm5[this.respForm5.length - 1]['private_port'] = fdt.prtpriport;
        this.respForm5[this.respForm5.length - 1]['note'] = fdt.prtnote;
        this.respForm5[this.respForm5.length - 1]['helper'] = fdt.prthelper;
        // this.respForm5[this.respForm5.length - 1]['order']=fdt.prtorder;
        this.respForm5[this.respForm5.length - 1]['assigned_routing_groups'] = `${this.wanPrtUrl}${fdt.id}/assigned_routing_groups/`;
        // this.respForm5[this.respForm5.length - 1]['assigned_routing_groups_list'] = ma_rout;
        this.respForm5[this.respForm5.length - 1]['_valid_routing_groups'] = mv_rout;
        this.respForm5[this.respForm5.length - 1]['a_routing'] = a_rout;
        this.respForm5[this.respForm5.length - 1]['newer'] = false;
        this.respForm5[this.respForm5.length - 1]['isUpdate'] = true;
        this.respForm5[this.respForm5.length - 1]['order'] = this.fetchCount5;

        this.fetchCount5++;
        if (this.fetchCount5 == this.aFrm5.length) this.patchRouting5();
        index++;
      }
    }
  }

  deleteRouting(typ: string, url: string, id: number, d: number) {
    let reqObj = { "url": `${url}${id}/`, "method": "DELETE" };
    console.log("DELETING >>>", typ, url, id, reqObj);
    this.sharedService.showLoader();
    this.spaceService.deleteData(reqObj).subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        if (typ == '1') {
          let dta: any = this.wanForm1.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.id == id));
            this.wanForm1.controls.data = dta;
            this.cd.detectChanges();
          }
          this.wanNatList = this.wanNatList.filter((item: any) => item.id != id);
        } else if (typ == '2') {
          let dta: any = this.wanForm2.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.id == id));
            this.wanForm2.controls.data = dta;
            this.cd.detectChanges();
          }
          this.wanPwnList = this.wanPwnList.filter((item: any) => item.id != id);
        } else if (typ == '3') {
          let dta: any = this.wanForm3.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.id == id));
            this.wanForm3.controls.data = dta;
            this.cd.detectChanges();
          }
          this.wanBndList = this.wanBndList.filter((item: any) => item.id != id);
        } else if (typ == '4') {
          let dta: any = this.nttForm.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.id == id));
            this.nttForm.controls.data = dta;
            this.cd.detectChanges();
          }
          this.wanNttList = this.wanNttList.filter((item: any) => item.id != id);
        } else if (typ == '5') {
          let dta: any = this.prtForm.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls = dta.controls.filter((data: any) => !(data && data.value && data.value.id == id));
            this.prtForm.controls.data = dta;
            this.cd.detectChanges();
          }
          this.wanPrtList = this.wanPrtList.filter((item: any) => item.id != id);
        }
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error?.non_field_errors) this.sharedService.loggerError(error?.non_field_errors);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addRouting1(dta: any, v_routing: any, a_routing: any, index: number) {
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.natenbl,
        "mode": "nat-via-pwan",
        "source_nat": true,
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "ip": dta.natip,
        "note": dta.natnote
      }
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 201 || addRes.code == 200) {
        if (addRes.message == "Created") {
          this.wanNatList.push(addRes.data);
          let dta: any = this.wanForm1.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls[index].controls['isNew'].setValue(false);
            dta.controls[index].controls['id'].setValue(addRes.data.id);
            this.cd.detectChanges();
          }
          this.respForm1.push(addRes.data);
          this.respForm1[this.respForm1.length - 1]['newer'] = true;
          this.respForm1[this.respForm1.length - 1]['a_routing'] = a_routing;
        }
        this.fetchCount1++;
        if (this.fetchCount1 == this.aFrm1.length) this.patchRouting1();
        this.cd.detectChanges();
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          this.aFrm1Error[index] = JSON.parse(err);
          if (this.aFrm1Error[index].non_field_errors) this.sharedService.loggerError(this.aFrm1Error[index].non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  patchRouting1() {
    this.fetchCount1 = 0;
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "PATCH",
      "data": this.respForm1
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes) {
        let i = 0;
        for (let r of this.respForm1) {
          if (r.isUpdate) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => {
                let isAdd = true;
                if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
                  r.assigned_routing_groups_list.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isAdd = false;
                  });
                }
                if (isAdd) this.addRout(r.id, route, 1);
              });
            }
            if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
              r.assigned_routing_groups_list.forEach((route: any) => {
                let isDelete = true;
                if (r.a_routing && r.a_routing.length > 0) {
                  r.a_routing.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isDelete = false;
                  });
                }
                if (isDelete) this.deleteRout(r.id, route, 1);
              });
            }
          } else if (r.newer) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => { this.addRout(r.id, route, 1); });
            }
          }
          i++;
        }
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          if (errors && errors.length > 0) {
            errors.forEach((item: any) => {
              if (item && item.url) {
                let itemId = item.url.split('/')[9];
                let dta: any = this.wanForm1.controls.data;
                let dtalen = dta.controls.length;
                if (dtalen > 0) {
                  dta.controls.forEach((ctl: any, i: number) => {
                    if (ctl && ctl.value && ctl.value.id == itemId) this.aFrm1Error[i] = item;
                  });
                }
              }
            });
          }
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addRouting2(dta: any, v_routing: any, a_routing: any, index: number) {
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.pwnenbl,
        "mode": "gateway-via-pwan",
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "ip": dta.pwnip,
        "ipv6": dta.pwnip6,
        "gateway": dta.pwngate,
        "gateway6": dta.pwnip6gate,
        "vlan": dta.pwnvlan,
        "note": dta.pwnnote
      }
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 201 || addRes.code == 200) {
        if (addRes.message == "Created") {
          this.wanPwnList.push(addRes.data)
          let dta: any = this.wanForm2.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls[index].controls['isNew'].setValue(false)
            dta.controls[index].controls['id'].setValue(addRes.data.id)
            this.cd.detectChanges();
          }
          this.respForm2.push(addRes.data);
          this.respForm2[this.respForm2.length - 1]['newer'] = true;
          this.respForm2[this.respForm2.length - 1]['a_routing'] = a_routing;
        }
        this.fetchCount2++;
        if (this.fetchCount2 == this.aFrm2.length) this.patchRouting2();
        this.cd.detectChanges();
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          this.aFrm2Error[index] = JSON.parse(err);
          if (this.aFrm2Error[index]?.non_field_errors) this.sharedService.loggerError(this.aFrm2Error[index]?.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  patchRouting2() {
    this.fetchCount2 = 0;
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "PATCH",
      "data": this.respForm2
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes) {
        let i = 0;
        for (let r of this.respForm2) {
          if (r.isUpdate) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => {
                let isAdd = true;
                if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
                  r.assigned_routing_groups_list.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isAdd = false;
                  });
                }
                if (isAdd) this.addRout(r.id, route, 2);
              });
            }
            if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
              r.assigned_routing_groups_list.forEach((route: any) => {
                let isDelete = true;
                if (r.a_routing && r.a_routing.length > 0) {
                  r.a_routing.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isDelete = false;
                  });
                }
                if (isDelete) this.deleteRout(r.id, route, 2);
              });
            }
          } else if (r.newer) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => { this.addRout(r.id, route, 2); });
            }
          }
          i++;
        }
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          if (errors && errors.length > 0) {
            errors.forEach((item: any) => {
              if (item && item.url) {
                let itemId = item.url.split('/')[9];
                let dta: any = this.wanForm2.controls.data;
                let dtalen = dta.controls.length;
                if (dtalen > 0) {
                  dta.controls.forEach((ctl: any, i: number) => {
                    if (ctl && ctl.value && ctl.value.id == itemId) this.aFrm2Error[i] = item;
                  });
                }
              }
            });
          }
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addRouting3(dta: any, v_routing: any, a_routing: any, index: number) {
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.bndenbl,
        "mode": "gateway-via-bonder",
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "gateway": dta.bndgate,
        "gateway6": dta.bndip6gate,
        "note": dta.bndnote
      }
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 201 || addRes.code == 200) {
        if (addRes.message == "Created") {
          this.wanBndList.push(addRes.data)
          let dta: any = this.wanForm3.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls[index].controls['isNew'].setValue(false)
            dta.controls[index].controls['id'].setValue(addRes.data.id)
            this.cd.detectChanges();
          }
          this.respForm3.push(addRes.data);
          this.respForm3[this.respForm3.length - 1]['newer'] = true;
          this.respForm1[this.respForm1.length - 1]['a_routing'] = a_routing;
        }
        this.fetchCount3++;
        if (this.fetchCount3 == this.aFrm3.length) this.patchRouting3();
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          this.aFrm3Error[index] = JSON.parse(err);
          if (this.aFrm3Error[index]?.non_field_errors) this.sharedService.loggerError(this.aFrm3Error[index]?.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  patchRouting3() {
    this.fetchCount3 = 0;
    let reqObj = {
      "url": `${this.wanNatUrl}`,
      "method": "PATCH",
      "data": this.respForm3
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes) {
        let i = 0;
        for (let r of this.respForm3) {
          if (r.isUpdate) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => {
                let isAdd = true;
                if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
                  r.assigned_routing_groups_list.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isAdd = false;
                  });
                }
                if (isAdd) this.addRout(r.id, route, 3);
              });
            }
            if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
              r.assigned_routing_groups_list.forEach((route: any) => {
                let isDelete = true;
                if (r.a_routing && r.a_routing.length > 0) {
                  r.a_routing.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isDelete = false;
                  });
                }
                if (isDelete) this.deleteRout(r.id, route, 3);
              });
            }
          } else if (r.newer) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => { this.addRout(r.id, route, 3); });
            }
          }
          i++;
        }
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          if (errors && errors.length > 0) {
            errors.forEach((item: any) => {
              if (item && item.url) {
                let itemId = item.url.split('/')[9];
                let dta: any = this.wanForm3.controls.data;
                let dtalen = dta.controls.length;
                if (dtalen > 0) {
                  dta.controls.forEach((ctl: any, i: number) => {
                    if (ctl && ctl.value && ctl.value.id == itemId) this.aFrm3Error[i] = item;
                  });
                }
              }
            });
          }
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addRouting4(dta: any, v_routing: any, a_routing: any, index: number) {
    let reqObj = {
      "url": `${this.wanNttUrl}`,
      "method": "POST",
      "data": {
        "enabled": dta.nttenbl,
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "public_ip": dta.nttpubip,
        "private_ip": dta.nttpriip,
        "note": dta.nttnote
      }
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 201 || addRes.code == 200) {
        if (addRes.message == "Created") {
          this.wanNttList.push(addRes.data)
          let dta: any = this.nttForm.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls[index].controls['isNew'].setValue(false)
            dta.controls[index].controls['id'].setValue(addRes.data.id)
            this.cd.detectChanges();
          }
          this.respForm4.push(addRes.data);
          this.respForm4[this.respForm4.length - 1]['newer'] = true;
          this.respForm4[this.respForm4.length - 1]['a_routing'] = a_routing;
          this.respForm4[this.respForm4.length - 1]['order'] = this.fetchCount4;
        }
        this.fetchCount4++;
        if (this.fetchCount4 == this.aFrm4.length) this.patchRouting4();
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          this.aFrm4Error[index] = JSON.parse(err);
          if (this.aFrm4Error[index]?.non_field_errors) this.sharedService.loggerError(this.aFrm4Error[index]?.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  patchRouting4() {
    this.fetchCount4 = 0;
    let reqObj = {
      "url": `${this.wanNttUrl}`,
      "method": "PATCH",
      "data": this.respForm4
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes) {
        let i = 0;
        for (let r of this.respForm4) {
          if (r.isUpdate) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => {
                let isAdd = true;
                if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
                  r.assigned_routing_groups_list.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isAdd = false;
                  });
                }
                if (isAdd) this.addRout(r.id, route, 4);
              });
            }
            if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
              r.assigned_routing_groups_list.forEach((route: any) => {
                let isDelete = true;
                if (r.a_routing && r.a_routing.length > 0) {
                  r.a_routing.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isDelete = false;
                  });
                }
                if (isDelete) this.deleteRout(r.id, route, 4);
              });
            }
          } else if (r.newer) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => { this.addRout(r.id, route, 4); });
            }
          }
          i++;
        }
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          if (errors && errors.length > 0) {
            errors.forEach((item: any) => {
              if (item && item.url) {
                let itemId = item.url.split('/')[9];
                let dta: any = this.nttForm.controls.data;
                let dtalen = dta.controls.length;
                if (dtalen > 0) {
                  dta.controls.forEach((ctl: any, i: number) => {
                    if (ctl && ctl.value && ctl.value.id == itemId) this.aFrm4Error[i] = item;
                  });
                }
              }
            });
          }
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addRouting5(dta: any, v_routing: any, a_routing: any, index: number) {
    let reqObj = {
      "url": `spaces/${this.parentId}/private_wan/port_forward_rules/`,
      "method": "POST",
      "data": {
        "enabled": dta.prtenbl,
        "helper": dta.prthelper,
        "_valid_routing_groups": v_routing,
        "assigned_routing_groups_list": a_routing,
        "protocol": dta.prtprotocol,
        "private_port": dta.prtpriport,
        "public_port": dta.prtpubport,
        "public_ip": dta.prtpubip,
        "private_ip": dta.prtpriip,
        "note": dta.prtnote
      }
    }

    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200 || addRes.code == 201) {
        if (addRes.message == "Created") {
          this.wanPrtList.push(addRes.data)
          let dta: any = this.prtForm.controls.data;
          let dtalen = dta.controls.length;
          if (dtalen > 0) {
            dta.controls[index].controls['isNew'].setValue(false)
            dta.controls[index].controls['id'].setValue(addRes.data.id)
            this.cd.detectChanges();
          }
          this.respForm5.push(addRes.data);
          this.respForm5[this.respForm5.length - 1]['newer'] = true;
          this.respForm5[this.respForm5.length - 1]['a_routing'] = a_routing;
          this.respForm5[this.respForm5.length - 1]['order'] = this.fetchCount5;
        }
        this.fetchCount5++;
        if (this.fetchCount5 == this.aFrm5.length) this.patchRouting5();
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          this.aFrm5Error[index] = JSON.parse(err);
          if (this.aFrm5Error[index]?.non_field_errors) this.sharedService.loggerError(this.aFrm5Error[index]?.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  patchRouting5() {
    this.fetchCount5 = 0;
    let reqObj = {
      "url": `${this.wanPrtUrl}`,
      "method": "PATCH",
      "data": this.respForm5
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes) {
        for (let r of this.respForm5) {
          if (r.isUpdate) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => {
                let isAdd = true;
                if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
                  r.assigned_routing_groups_list.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isAdd = false;
                  });
                }
                if (isAdd) this.addRout(r.id, route, 5);
              });
            }
            if (r.assigned_routing_groups_list && r.assigned_routing_groups_list.length > 0) {
              r.assigned_routing_groups_list.forEach((route: any) => {
                let isDelete = true;
                if (r.a_routing && r.a_routing.length > 0) {
                  r.a_routing.forEach((item: any) => {
                    if (item && item.routing_group && item.routing_group == route.routing_group) isDelete = false;
                  });
                }
                if (isDelete) this.deleteRout(r.id, route, 5);
              });
            }
          } else if (r.newer) {
            if (r.a_routing && r.a_routing.length > 0) {
              r.a_routing.forEach((route: any) => { this.addRout(r.id, route, 5); });
            }
          }
        }
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          let errors = JSON.parse(err);
          if (errors?.non_field_errors) this.sharedService.loggerError(errors?.non_field_errors);
          if (errors && errors.length > 0) {
            errors.forEach((item: any) => {
              if (item && item.url) {
                let itemId = item.url.split('/')[9];
                let dta: any = this.prtForm.controls.data;
                let dtalen = dta.controls.length;
                if (dtalen > 0) {
                  dta.controls.forEach((ctl: any, i: number) => {
                    if (ctl && ctl.value && ctl.value.id == itemId) this.aFrm5Error[i] = item;
                  });
                }
              }
            });
          }
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  addRout(routeId: number, data: any, type: number) {
    if (!routeId) return;
    let url = `spaces/${this.parentId}/private_wan/outbound_gateways/${routeId}/assigned_routing_groups/`;
    if (type == 4) url = `spaces/${this.parentId}/private_wan/nat_rules/${routeId}/assigned_routing_groups/`;
    if (type == 5) url = `spaces/${this.parentId}/private_wan/port_forward_rules/${routeId}/assigned_routing_groups/`;
    let reqObj = { "url": url, "method": "POST", "data": data }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 201 || addRes.code == 200) {
        if (type == 1 && this.wanNatList && this.wanNatList.length > 0) {
          this.wanNatList.forEach((item: any) => {
            if (item && item.id == routeId && item.assigned_routing_groups_list) item.assigned_routing_groups_list.push(addRes.data);
          });
        } else if (type == 2 && this.wanPwnList && this.wanPwnList.length > 0) {
          this.wanPwnList.forEach((item: any) => {
            if (item && item.id == routeId && item.assigned_routing_groups_list) item.assigned_routing_groups_list.push(addRes.data);
          });
        } else if (type == 3 && this.wanBndList && this.wanBndList.length > 0) {
          this.wanBndList.forEach((item: any) => {
            if (item && item.id == routeId && item.assigned_routing_groups_list) item.assigned_routing_groups_list.push(addRes.data);
          });
        } else if (type == 4 && this.wanNttList && this.wanNttList.length > 0) {
          this.wanNttList.forEach((item: any) => {
            if (item && item.id == routeId && item.assigned_routing_groups_list) item.assigned_routing_groups_list.push(addRes.data);
          });
        } else if (type == 5 && this.wanPrtList && this.wanPrtList.length > 0) {
          this.wanPrtList.forEach((item: any) => {
            if (item && item.id == routeId && item.assigned_routing_groups_list) item.assigned_routing_groups_list.push(addRes.data);
          });
        }
      }
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          let error = JSON.parse(err);
          if (type == 1) {
            let dta: any = this.wanForm1.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm1Error[i]) this.aFrm1Error[i] = {};
                  this.aFrm1Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          } else if (type == 2) {
            let dta: any = this.wanForm2.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm2Error[i]) this.aFrm2Error[i] = {};
                  this.aFrm2Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          } else if (type == 3) {
            let dta: any = this.wanForm3.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm3Error[i]) this.aFrm3Error[i] = {};
                  this.aFrm3Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          } else if (type == 4) {
            let dta: any = this.nttForm.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm4Error[i]) this.aFrm4Error[i] = {};
                  this.aFrm4Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          } else if (type == 5) {
            let dta: any = this.prtForm.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm5Error[i]) this.aFrm5Error[i] = {};
                  this.aFrm5Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          }
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  deleteRout(routeId: number, route: any, type: number) {
    let reqObj = { "url": route.url, "method": "DELETE" };
    this.spaceService.deleteData(reqObj).subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        if (type == 1 && this.wanNatList && this.wanNatList.length > 0) {
          this.wanNatList.forEach((item: any) => {
            if (item && item.id == routeId) {
              item.assigned_routing_groups_list = item.assigned_routing_groups_list.filter((data: any) => data.routing_group != route.routing_group);
            }
          });
        } else if (type == 2 && this.wanPwnList && this.wanPwnList.length > 0) {
          this.wanPwnList.forEach((item: any) => {
            if (item && item.id == routeId) {
              item.assigned_routing_groups_list = item.assigned_routing_groups_list.filter((data: any) => data.routing_group != route.routing_group);
            }
          });
        } else if (type == 3 && this.wanBndList && this.wanBndList.length > 0) {
          this.wanBndList.forEach((item: any) => {
            if (item && item.id == routeId) {
              item.assigned_routing_groups_list = item.assigned_routing_groups_list.filter((data: any) => data.routing_group != route.routing_group);
            }
          });
        } else if (type == 4 && this.wanNttList && this.wanNttList.length > 0) {
          this.wanNttList.forEach((item: any) => {
            if (item && item.id == routeId) {
              item.assigned_routing_groups_list = item.assigned_routing_groups_list.filter((data: any) => data.routing_group != route.routing_group);
            }
          });
        } else if (type == 5 && this.wanPrtList && this.wanPrtList.length > 0) {
          this.wanPrtList.forEach((item: any) => {
            if (item && item.id == routeId) {
              item.assigned_routing_groups_list = item.assigned_routing_groups_list.filter((data: any) => data.routing_group != route.routing_group);
            }
          });
        }
      }
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        if (!err) this.isUndefinedError = true;
        else {
          let error = JSON.parse(err);
          if (type == 1) {
            let dta: any = this.wanForm1.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm1Error[i]) this.aFrm1Error[i] = {};
                  this.aFrm1Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          } else if (type == 2) {
            let dta: any = this.wanForm2.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm2Error[i]) this.aFrm2Error[i] = {};
                  this.aFrm2Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          } else if (type == 3) {
            let dta: any = this.wanForm3.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm3Error[i]) this.aFrm3Error[i] = {};
                  this.aFrm3Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          } else if (type == 4) {
            let dta: any = this.nttForm.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm4Error[i]) this.aFrm4Error[i] = {};
                  this.aFrm4Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          } else if (type == 5) {
            let dta: any = this.prtForm.controls.data;
            let dtalen = dta.controls.length;
            if (dtalen > 0) {
              dta.controls.forEach((ctl: any, i: number) => {
                if (ctl && ctl.value && ctl.value.id == routeId) {
                  if (!this.aFrm5Error[i]) this.aFrm5Error[i] = {};
                  this.aFrm5Error[i].routing_groups = error?.non_field_errors;
                }
              });
            }
          }
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  //drag &  drop
  dropPort(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.prtData?.controls, event.previousIndex, event.currentIndex);
    this.wFrm5 = true;
    this.isUndefinedError = false;
    this.aFrm5Error = [];
    this.cd.detectChanges();
  }

  dropNAT(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.nttData?.controls, event.previousIndex, event.currentIndex);
    this.wFrm4 = true;
    this.isUndefinedError = false;
    this.aFrm4Error = [];
    this.cd.detectChanges();
  }

  isIpInSubnet(ipAddress: string, subnetAddress: string) {
    // Convert the IP address string to an array of four integers
    const ip = ipAddress.split('.').map(Number);
    // Convert the subnet address string to an array of four integers and a prefix length
    const subnet = subnetAddress.split('/');
    const subnetIp = subnet[0].split('.').map(Number);
    const subnetPrefix = Number(subnet[1]);
    // Calculate the subnet mask as a four-element array of integers
    const subnetMask = [0, 0, 0, 0];
    for (let i = 0; i < subnetPrefix; i++) {
      subnetMask[Math.floor(i / 8)] += 1 << (7 - i % 8);
    }
    // Calculate the network address as a four-element array of integers
    const networkAddress = [0, 0, 0, 0];
    for (let i = 0; i < 4; i++) {
      networkAddress[i] = subnetIp[i] & subnetMask[i];
    }
    // Check if the IP address is in the subnet range
    for (let i = 0; i < 4; i++) {
      if ((ip[i] & subnetMask[i]) !== networkAddress[i]) return false;
    }
    return true;
  }

  loginToFirewall(): void {
    const form = document.createElement('form');
    // form.action = 'http://43.245.170.106/';
    form.action = this.cloudFirewallArr?.url;
    form.method = 'post';
    form.target = '_blank';

    const userNameInput = document.createElement('input');
    userNameInput.type = 'hidden';
    userNameInput.name = 'UserName';
    userNameInput.value = this.cloudFirewallArr?.username;
    form.appendChild(userNameInput);

    const userPassInput = document.createElement('input');
    userPassInput.type = 'hidden';
    userPassInput.name = 'UserPass';
    userPassInput.value = this.cloudFirewallArr?.password;
    form.appendChild(userPassInput);
    document.body.appendChild(form);
    form.submit();
  }

  createCloudFirewallForm(data: any = {}) {
    return this.fb.group({
      url: [data?.url || '', [Validators.required]],
      username: [data?.username || '', [Validators.required]],
      password: [data?.password || '', [Validators.required]]
    });
  }

  OnSaveCloudFirewall() {
    if (this.cloudFirewallForm.invalid) {
      this.cloudFirewallForm.markAllAsTouched();
      return;
    }
    this.sharedService.showLoader();
    this.cloudFirewallForm.value.spaceKey = this?.spaceData?.id;
    this.spaceService.updateCloudFirewallRecord(this.cloudFirewallForm?.value).subscribe((response: any) => {
      if (response?.code == 200) {
        this.isEditOption = false;
        this.IsCloudFirewallLogin = true;
        this.cloudFirewallArr = response?.data;
        this.sharedService.loggerSuccess(response?.message);
        this.cd.detectChanges();
        this.sharedService.hideLoader();
      } else {
        this.sharedService.hideLoader();
        this.sharedService.loggerError(response?.message);
      }
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    })
  }

  onRemoveCloudFirewall() {
    this.sharedService.showLoader();
    this.spaceService.deleteCloudFirewallRecord(this?.spaceData?.id).subscribe((response: any) => {
      if (response?.code == 200) {
        this.IsCloudFirewallLogin = false;
        this.isEditOption = true;
        this.cloudFirewallArr = null;
        this.sharedService.loggerSuccess(response?.message);
        this.cloudFirewallForm.reset();
        this.cd.detectChanges();
        this.sharedService.hideLoader();
      } else {
        this.sharedService.hideLoader();
        this.sharedService.loggerError(response?.message);
      }
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  OnEditCloudFirewall() {
    this.isEditOption = true;
  }

  OnCanceCloudFirewall() {
    this.isEditOption = false;
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.cloudFirewallForm);
  }
}
