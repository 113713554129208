import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedNetworkInterdfaceAddComponent } from './shared-network-interdface-add/shared-network-interdface-add.component';
import { SharedNetworkInterfaceEditComponent } from './shared-network-interface-edit/shared-network-interface-edit.component';
@Component({
  selector: 'app-shared-network-interface',
  templateUrl: './shared-network-interface.component.html',
  styleUrls: ['./shared-network-interface.component.scss']
})
export class SharedNetworkInterfaceComponent implements OnInit {
  authService = AuthService;
  latest_tuning: any;
  bondPermission: any;

  @Input() allInterfaces: any;
  @Input() ethernetInterfaceDetailArray: any
  @Input() bonderId: any;
  @Input() bondId: any;
  @Input() permissionsObj: any;
  @Input() arrBridge: any;
  @Input() bondName: any;

  @Output() getRoutes = new EventEmitter<any>();

  constructor(private PartnerBondingService: PartnerBondingService,
    public sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private partnerBondingService: PartnerBondingService) { }

  ngOnInit(): void {
    this.allInterfaces = this.allInterfaces.sort((a: any, b: any) => +a.id - +b.id)
    this.cd.detectChanges();
    this.PartnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    if (localStorage.getItem('bondPermission')) {
      let data = JSON.parse(localStorage.getItem('bondPermission') || '');
      if (data && data.id == this.bondId) this.bondPermission = data.permission;
      this.cd.detectChanges();
    }
  }

  ngOnChanges() { this.cd.detectChanges(); }

  addInterface() {
    let modal = this.modalService.open(SharedNetworkInterdfaceAddComponent, { size: 'lg' });
    modal.componentInstance.allInterfaces = this.ethernetInterfaceDetailArray;
    modal.componentInstance.arrBridge = this.arrBridge;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondName = this.bondName;
    modal.componentInstance.bondId = this.bondId;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getRoutes.emit();
      this.allInterfaces = this.allInterfaces.sort((a: any, b: any) => +a.id - +b.id);
      this.cd.detectChanges();
    }, () => { });
    this.cd.detectChanges();
  }

  deleteInterface(type: any, InterfaceId: any, InterfaceName: any) {
    let questionTitle = 'Are you sure you want to delete this interface?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        let body = { method: "DELETE", url: "", type: type, interfaceId: InterfaceId, bondName: this.bondName, interfaceName: InterfaceName, bondId: this.bondId };
        if (type == "Ethernet") body.url = `bonders/${this.bonderId}/ethernet_interfaces/${InterfaceId}/`
        else if (type == "VLAN") body.url = `bonders/${this.bonderId}/vlan_interfaces/${InterfaceId}/`
        else if (type == "VXLAN") body.url = `bonders/${this.bonderId}/vxlan_interfaces/${InterfaceId}/`
        else body.url = `bonders/${this.bonderId}/bridge_interfaces/${InterfaceId}/`;
        this.sharedService.showLoader();
        this.PartnerBondingService.deleteData(body).subscribe((data: any) => {
          if (data.code == 204 || data.code == 200) this.sharedService.loggerSuccess(data.message);
          else this.sharedService.loggerError(data.message);
          this.sharedService.hideLoader();
          this.getRoutes.emit();
          this.allInterfaces = this.allInterfaces.sort((a: any, b: any) => +a.id - +b.id);
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getRoutes.emit();
            this.allInterfaces = this.allInterfaces.sort((a: any, b: any) => +a.id - +b.id);
            this.cd.detectChanges();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });

  }

  editInterface(interfaceData: any) {
    this.partnerBondingService.failbackMessage$.subscribe((res => {
      if (!res) return;
      res.forEach((element: any) => {
        if (element.object_id == interfaceData.id && element.object_type == 'interface' && element.severity == 'warning') {
          interfaceData.failbackMessage = element.message
        }
      })
    }))

    let modal = this.modalService.open(SharedNetworkInterfaceEditComponent, { size: 'lg' });
    modal.componentInstance.interfaceDetail = interfaceData;
    modal.componentInstance.allInterfaces = this.ethernetInterfaceDetailArray;
    modal.componentInstance.arrBridge = this.arrBridge;
    modal.componentInstance.bonderId = this.bonderId;
    modal.componentInstance.bondName = this.bondName;
    modal.componentInstance.bondId = this.bondId;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getRoutes.emit();
      this.allInterfaces = this.allInterfaces.sort((a: any, b: any) => +a.id - +b.id);
      this.cd.detectChanges();
    }, () => { });
    this.cd.detectChanges();
  }

  calculateDiff(dateSent: any) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    let day = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    return this.getFormatedStringFromDays(day);
  }

  getFormatedStringFromDays(numberOfDays: number) {
    let years = Math.floor(numberOfDays / 365);
    let months = Math.floor(numberOfDays % 365 / 30);
    let days = Math.floor(numberOfDays % 365 % 30);
    let monthval = ''; let yearsval = ''; let daysval = '';
    if (months) monthval = months + ' months';
    if (years) yearsval = years + ' years';
    if (days) daysval = days + ' days';
    return [yearsval + monthval + daysval + ' ago.'];
  }
}



