<div *ngIf="permissions.view" class="d-flex flex-wrap mb-2 justify-content-end gap-5">
    <div class="form-group inputtext-block">
        <input type="text" class="form-control form-control-lg" [formControl]="searchControl" name="searchText"
            placeholder="Search" (keydown)="preventSpace($event)" value="" />
        <span (click)="onResetFilter()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
            class="svg-icon svg-icon-1 me-0 span-input"></span>
    </div>
    <div class="d-flex flex-wrap">
        <div class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Add Group">
            <button *ngIf="permissions.update" type="button" (click)="onAddGroup()" class="btn btn-primary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Add Group
            </button>
        </div>
    </div>
</div>
<div *ngIf="permissions.view" class="card mb-5 mb-xl-8">
    <div class="card-body p-2">
        <div class="table-responsive border">
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                <thead class="align-middle table-light">
                    <tr class="fw-bolder text-dark fs-7">
                        <th class="py-4" (click)="onSort('name')"> Name
                            <span *ngIf="sortBy == 'name' && $any(groups?.length) > 0" [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th class="py-4"> Users </th>
                        <th class="py-4" (click)="onSort('createdAt')">
                            Created At
                            <span *ngIf="sortBy == 'createdAt' && $any(groups?.length) > 0" [inlineSVG]="arrow"
                                class="svg-icon svg-icon-5" style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-50px w-50px text-center">Action </th>
                    </tr>
                </thead>
                <tbody *ngIf="!preLoaderScreen && length > 0">
                    <tr *ngFor="let group of groups">
                        <td>
                            <a class="cursor" (click)="onView(group._id)"> {{group.name}}</a>
                        </td>
                        <td *ngIf="group.userID.length">
                            <span class="d-block" *ngFor="let user of group.userID">{{ findUseById(user?._id) == false ?
                                user?.email : '' }}
                            </span>
                        </td>
                        <td *ngIf="!group.userID.length">
                            -
                        </td>
                        <td>
                            <a> {{group.createdAt | date}}</a>
                        </td>
                        <td class="text-center">
                            <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                    class="svg svg-icon svg-transparent">
                                </span>
                            </a>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item class="menu-list-block" title="View"
                                    [disabled]="permissions.view ? false : true"
                                    (click)="permissions.view ? onView(group._id):''">
                                    <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/eye.svg'"
                                            class="svg svg-icon svg-icon-primary">
                                        </span>
                                    </div>
                                    <span>View</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Edit"
                                    [disabled]="permissions.update ? false : true"
                                    (click)="permissions.update ? onEdit(group._id): ''">
                                    <div class="clone-icon">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                            class="svg svg-icon svg-icon-warning">
                                        </span>
                                    </div>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item class="menu-list-block" title="Delete"
                                    [disabled]="permissions.delete ? false : true"
                                    (click)="permissions.delete ? onDelete(group._id): ''">
                                    <div>
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                            class="svg svg-icon svg-icon-danger">
                                        </span>
                                    </div>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-preloader-table-list *ngIf="preLoaderScreen"></app-preloader-table-list>
            <div *ngIf="!preLoaderScreen && length == 0" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>
            </div>
        </div>
        <app-pagination *ngIf="length > 0" [childMessage]="length" [index]="page" [pagesize]="limit"
            (messageEvent)="receiveMessage($event)">
        </app-pagination>
    </div>
</div>

<div *ngIf="!permissions.view">
    <span>You have no permission to view the data. Kindly contact the authority.</span>
</div>