// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v0.0.18',
  sdwanVersion: 'v0.0.18',
  isMockEnabled: true,
  // apiUrl: 'http://localhost:3011',
  // appUrl: 'http://localhost:3011',
  // apiUrl: 'https://superadmin.sdwancloud.net', //client prod
  apiUrl: 'https://dev-api.sdwancloud.net', //client dev
  appUrl: 'https://dev-superadmin.sdwancloud.net', //client dev
  ipUrl: 'https://api.ipify.org/?format=json',
  // domainUrl: "antares-cloudwan.com", // prod
  domainUrl: "antares-sdwan.com", // dev
  // ipUrl: 'https://api.db-ip.com/v2/free/self',
  siteKey: "6LeLAtklAAAAAK2_R2rhuMh8xXM63uRacevBfD3E",
  secretKey: "6LeLAtklAAAAAK2R2rhuMh8xXM63uRacevBfD3E",
  // googleMapKey: "AIzaSyCaKbVhcX_22R_pRKDYuNA7vox-PtGaDkI",
  googleMapKey: "AIzaSyAR_wBubwaMqDml6jNl_5QpCCX3nEx3qUI",
  rhsHost: "rhs.sdwancloud.net",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
