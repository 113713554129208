import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  siteKey: string = environment.siteKey;
  passwordValidator: any = AppConst.pwdPatternWithValidations;
  emailValidator: any = AppConst.emailVal;
  private unsubscribe: Subscription[] = [];
  loginForm: FormGroup;
  isLoading$: Observable<boolean>;
  isLoading: boolean = false;
  isCaptcha: Boolean = false;
  show_button: Boolean = false;
  show_eye: Boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
  ) {
    this.isLoading$ = this.authService.isLoading$;
  }

  ngOnInit(): void {
    localStorage.removeItem('breadCrumbs');
    localStorage.removeItem('userIds');
    this.initForm();
  }

  get f() {
    return this.loginForm.controls;
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailValidator),
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(40),
          Validators.pattern(this.passwordValidator)
        ]),
      ],
    });
  }

  submit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    if (!this.isLoading && this.isCaptcha) {
      let payload = { email: this.f.email.value, password: this.f.password.value }
      this.isLoading = true;
      this.sharedService.showLoader();
      this.authService.login(payload).subscribe((user: any) => {
        if (user) {
          this.router.navigate(['/admin/dashboard']).then(res => {
            this.sharedService.loggerSuccess('Successfully logged in');
          });
        } else this.loginForm.reset();
        this.isLoading = false;
        this.sharedService.hideLoader();
      }, err => {
        this.loginForm.reset();
        this.isLoading = false;
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
      });
    }
  }

  OnForgotPassword() {
    let formValue = this.loginForm.value;
    if (!formValue.email) {
      this.sharedService.loggerError('please enter email')
      return;
    }
    this.authService.forgotPwdSubject$.next({ email: formValue.email });
    this.router.navigate(['/auth/forgot-password'], { queryParams: { role: 2 } });
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  // helpers for View
  isControlValid(controlName: string): boolean {
    const control = this.loginForm.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.loginForm.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation: any, controlName: any): boolean {
    const control = this.loginForm.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName: any): boolean {
    const control = this.loginForm.controls[controlName];
    return control.dirty || control.touched;
  }

  resolved(res: any) {
    if (res) this.isCaptcha = true;
    else this.isCaptcha = false;
  }
}
