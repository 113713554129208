import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let hostname = window.location.hostname;
        // let hostname = "mtp.antares-sdwan.com";
        if (state.url === '/auth/login') {
            if (!(hostname == environment.appUrl.split('/')[2] || hostname == 'localhost')) {
                this.router.navigate([`/auth/login/partner`]);
                return false;
            }
        } else if (state.url === '/auth/login/partner') {
            if (hostname == environment.appUrl.split('/')[2]) {
                this.router.navigate([`/auth/login`]);
                return false;
            }
        }
        return true;
    }
}
