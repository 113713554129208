<!-- -=-=-=-=-=-=-=-=-=-=- create new templete =--=-=-=-=-=-=-=-=-==--=-=-->

<div class="d-flex flex-wrap justify-content-end mb-3">

    <div class="my-2">
        <div class="col-2 card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
            title="Click to go back">
            <button (click)="onBackClicked()" class="btn btn-sm btn-primary d-inline-flex align-items-center p-3">
                <i class="fa fa-arrow-left me-1" aria-hidden="true"></i>
                Back
            </button>
        </div>
    </div>
</div>
<form [formGroup]="createEmailTemplateForm">
    <div class="card mb-5 mb-xl-10">
        <div class="card-body p-5">
            <div class="">
                <!-- <form action="#" class="form form-label-right border-bottom"> -->
                <div class="form-group row">

                    <!-- <div class="col-md-4 col-lg-4 mb-5">
                        <label class="form-label">Mail Type </label>
                        <ng-select formControlName="moduleType" class=""
                         placeholder="Email Type" (change)="onChangeMailType($event)">
                            <ng-option *ngFor="let item of emailTypeArray"
                             [value]="item.value">{{item.text}}</ng-option>

                        </ng-select>

                    </div> -->

                    <!-- <div *ngIf="moduleTypeControl?.value==emailType.core" class="col-md-4 col-lg-4 mb-5">
                        <label class="form-label">Core Mail Type </label>
                        <ng-select formControlName="coreMailType" class=""
                         placeholder="Template Type">
                            <ng-option *ngFor="let item of coreMailTypeArray" [value]="item.value">{{item.text}}</ng-option>

                        </ng-select>

                    </div> -->

                    <!-- <div class="col-md-4 col-lg-4 mb-5">
                        <label class="form-label">Templates Name
                        </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="fname"
                        placeholder="Enter First Name" />
                    </div> -->
                    <div class="col-md-6 col-lg-4 mb-3">
                        <label class="form-label">Template Name
                        </label>
                        <span class="required-asterisk m-0">*</span>
                        <input [readOnly]="isDisabled" formControlName="customMailType" type="text"
                            class="form-control form-control-lg form-control-solid" placeholder="Template Name" />
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="createEmailTemplateForm.get('customMailType')?.invalid
                    && createEmailTemplateForm.get('customMailType')?.touched">
                        Please add template name.
                    </div>

                    <div class="col-md-6 col-lg-4 mb-3">
                        <label class="form-label">Subject
                        </label><span class="required-asterisk m-0">*</span>
                        <input formControlName="customMailSubject" type="text"
                            class="form-control form-control-lg form-control-solid" name="fname"
                            placeholder="Subject" />
                        <div class="invalid-feedback d-block" *ngIf="controlHasError('required', 'customMailSubject')">
                            Please add subject.
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 mb-3">
                        <label class="form-label">Foundation
                        </label>
                        <!--  (remove)="onRemove($event)" -->
                        <ng-select class="" [searchable]="true" [clearable]="true" placeholder="Foundation For Subject"
                            (change)="setSubjectFoundation($event)">
                            <ng-option *ngFor="let foundation of foundationArray" [value]="foundation.value">
                                {{foundation.label}}
                            </ng-option>
                        </ng-select>

                    </div>



                </div>
                <!-- </form> -->
            </div>
            <div class="mb-5 mb-xl-1">
                <!-- <h1>Branding</h1> -->

                <div class="d-flex mt-5 card mb-1 mb-xl-1">
                    <div class="card-body p-2">

                        <div class="row border-bottom border-top pt-2 pb-2 justify-content-between">
                            <!-- <div class="col-md-8 col-lg-8 mb-5">
                            <ul class=" nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">

                            <li class="nav-item">
                                <a class="nav-link text-active-primary me-6 cursor-pointer"
                                [class.active]="currentEditor=='header'" (click)="currentEditor='header'">
                                    Header
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-active-primary me-6 cursor-pointer"
                                [class.active]="currentEditor=='body'"  (click)="currentEditor='body'">
                                    Body
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-active-primary me-6 cursor-pointer"
                                [class.active]="currentEditor=='footer'" (click)="currentEditor='footer'">
                                    Footer
                                </a>
                            </li>

                        </ul>

                        </div> -->

                            <div class="col-md-1 col-lg-1 d-flex align-items-center">
                                <h3 class="mb-0 form-label"> Body </h3>
                            </div>

                            <div class="col-md-4 col-lg-4">
                                <ng-select class="" [searchable]="true" [clearable]="true"
                                    placeholder="Foundation For Body"
                                    (change)="setEditorValueOnFoundationChange($event)">
                                    <ng-option *ngFor="let foundation of foundationArray" [value]="foundation.value">
                                        {{foundation.label}}
                                    </ng-option>
                                </ng-select>

                            </div>

                        </div>



                        <div class="row mt-5">
                            <div class="col-12">
                                <div class="form-group row mb-5">
                                    <!-- <div *ngIf="currentEditor=='header'">
                                    <ckeditor formControlName="headerTemplate" [config]="editorConfig" [editor]="editor"
                                        (ready)="onReady($event)">
                                    </ckeditor>
                                </div> -->

                                    <div>
                                        <ckeditor formControlName="styleTemplate" [config]="editorConfig"
                                            [editor]="editor" (ready)="onReady($event)">
                                        </ckeditor>
                                    </div>

                                    <!-- <div *ngIf="currentEditor=='footer'">
                                    <ckeditor formControlName="footerTemplate" [config]="editorConfig" [editor]="editor"
                                        (ready)="onReady($event)">
                                    </ckeditor>
                                </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer p-5">
            <!-- Save -->
            <button type="submit" (click)="onSubmit()" class="btn btn-primary"><span
                    [inlineSVG]="'./assets/media/icons/normal/check.svg'"
                    class="svg-icon svg-icon-4 me-1"></span>Submit</button>
            <!-- Update -->
            <button type="cancel" (click)="onCancel()" class="btn btn-primary"><span
                    [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'" class="svg-icon svg-icon-4 me-1"></span>
                Cancel</button>

        </div>

    </div>

</form>