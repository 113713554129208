import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationService } from './modules/shared/i18n/translation.service';
// language list
import { locale as chLang } from './modules/shared/i18n/vocabs/ch';
import { locale as deLang } from './modules/shared/i18n/vocabs/de';
import { locale as enLang } from './modules/shared/i18n/vocabs/en';
import { locale as esLang } from './modules/shared/i18n/vocabs/es';
import { locale as frLang } from './modules/shared/i18n/vocabs/fr';
import { locale as jpLang } from './modules/shared/i18n/vocabs/jp';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  favIcon: any = document.querySelector('#appIcons');

  constructor(private translationService: TranslationService, private titleService: Title) {
    // register translations
    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
  }

  ngOnInit() {
    /// here favicon logic
    // this.favIcon.href = localStorage.getItem('favIconForApp');
    this.titleService.setTitle(localStorage.getItem('navBarText') || 'Fusion Broadband');
  }
}
