import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private cookie: CookieService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let getToken = this.cookie.get('authToken');
        request = request.clone({ setHeaders: { Authorization: `Bearer ${getToken}` } });
        request = request.clone({ setHeaders: { 'X-XSS-Protection': '1; mode=block' }, withCredentials: true });
        return next.handle(request);
    }
}
