<form class="form w-100 login_signin_form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate"
  [formGroup]="forgotPasswordForm" (ngSubmit)="submit()" id="kt_login_password_reset_form">
  <div class="text-center mb-10">
    <h1 class="text-dark mb-3" *ngIf="!isCreatePassword">Reset Password </h1>
    <h1 class="text-dark mb-3" *ngIf="isCreatePassword">Create Password </h1>
    <div class="text-gray-400 fw-bold fs-4">
      Create your New Password!
    </div>
  </div>
  <div class="fv-row mb-10">
    <div class="form-group inputtext-block mb-5 w-100">
      <label class="form-label fw-bolder text-gray-900 fs-6">New Password</label>
      <div class="password-input-block">
        <input class="form-control form-control-lg form-control-solid" [type]="show_newbutton ? 'text' : 'password'"
          formControlName="newPass" placeholder="Enter New Password Here" name="newPass" autocomplete="off" [ngClass]="{
                  'is-invalid': forgotPasswordForm.controls['newPass'].invalid,
                  'is-valid': forgotPasswordForm.controls['newPass'].valid
                }" autocomplete="disabled" />
        <i [class]="show_neweye ? 'fa fa-eye password_hide_show' : 'fa fa-eye-slash password_hide_show'"
          (click)="showNewPassword()"></i>
      </div>
      <small class="form-invalid-message">
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'required',
          message: 'New Password is required',
          control: forgotPasswordForm.controls['newPass']
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'minLength',
          message: 'New Password should have at least 8 characters',
          control: forgotPasswordForm.controls['newPass']
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'maxLength',
          message: 'New Password should not exceed 40 characters',
          control: forgotPasswordForm.controls['newPass']
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'pattern',
          message: 'New password should have atleast 2 capital letters, 3 lower case letters, 2 numbers and 1 special character.',
          control: forgotPasswordForm.controls['newPass']
        }"></ng-container>
      </small>
    </div>
  </div>
  <div class="fv-row mb-20">
    <div class="form-group inputtext-block mb-5 w-100">
      <label class="form-label fw-bolder text-gray-900 fs-6">Confirm Password</label>
      <div class="password-input-block">
        <input class="form-control form-control-lg form-control-solid" [type]="show_confirmbutton ? 'text' : 'password'"
          formControlName="confirmPass" placeholder="Enter Confirm Password Here" name="confirmPass" autocomplete="off"
          [ngClass]="{
              'is-invalid': forgotPasswordForm.controls['confirmPass'].invalid,
              'is-valid': forgotPasswordForm.controls['confirmPass'].valid
            }" autocomplete="disabled" />
        <i [class]="show_confirmeye ? 'fa fa-eye password_hide_show' : 'fa fa-eye-slash password_hide_show'"
          (click)="showConfirmPassword()"></i>
      </div>
      <small class="form-invalid-message">
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'mustMatch',
          message: 'Password Should Match',
          control: forgotPasswordForm.controls['confirmPass']
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'required',
          message: 'Confirm Password is required',
          control: forgotPasswordForm.controls['confirmPass']
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'minlength',
          message: 'Confirm Password should have at least 8 characters',
          control: forgotPasswordForm.controls['confirmPass']
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'maxlength',
          message: 'Confirm Password should not exceed 40 characters',
          control: forgotPasswordForm.controls['confirmPass']
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'pattern',
          message: 'Confirm password should have atleast 2 capital letters, 3 lower case letters, 2 numbers and 1 special character.',
          control: forgotPasswordForm.controls['confirmPass']
        }"></ng-container>
      </small>
    </div>
  </div>
  <div class="d-flex flex-wrap justify-content-center pb-lg-0">
    <button type="submit" [disabled]="forgotPasswordForm.invalid" id="kt_password_reset_submit"
      class="btn btn-lg btn-primary br-6 fw-bolder me-4">
      <span *ngIf="!isLoading" class="indicator-label">Submit</span>
      <ng-container *ngIf="isLoading">
        <span class="indicator-progress" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </ng-container>
    </button>
    <button (click)="onCancelClicked()" *ngIf="!isLoading" routerLink="/auth/login"
      id="kt_login_password_reset_form_cancel_button" class="btn btn-lg btn-light-primary fw-bolder">
      Cancel
    </button>
  </div>
</form>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>