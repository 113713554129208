<div class="d-flex flex-wrap justify-content-end my-3 gap-5">
    <div class="d-flex flex-wrap" [hidden]="isView">
        <div class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Click to save permission">
            <button type="button" class="btn btn-primary btn-style" (click)="onSave()">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                        class="svg-icon svg-icon-block mb-2"></span>
                </div> Save
            </button>
        </div>
    </div>
    <div class="d-flex flex-wrap">
        <div class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Click to go back">
            <button type="button" class="btn btn-primary btn-style" (click)="onBack()">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/angle-left-solid.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Back
            </button>
        </div>
    </div>
</div>
<div class="card mb-5 mb-xl-8">
    <div class="card-body p-5">
        <div class="table-responsive group-permission border">
            <table *ngFor="let permission of permissions"
                class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-3 mb-0 table-fixed">
                <thead class="align-middle table-light"
                    *ngIf="!(permission.title == 'Hosts' || permission.title == 'Routing groups' || permission.title == 'Spaces' || permission.title == 'Logs')">
                    <tr class="fw-bolder">
                        <th class="py-4 d-flex align-items-center" style="gap: 8px;">
                            <span> {{permission.title}} </span>
                            <span class="d-flex">
                                <input class="form-check-input title-checkbox" [(ngModel)]="permission.titleChecked"
                                    type="checkbox" value="{{(permission.titleChecked) ? true : false}}"
                                    (click)="checkedAll(permission)" data-kt-check="true"
                                    data-kt-check-target=".widget-9-check" />
                            </span>
                        </th>
                        <th class="py-4 text-center"> View </th>
                        <!-- <th class="py-4 text-center"> Create </th> -->
                        <th class="py-4 text-center">
                            <span>Update</span>
                        </th>
                        <th class="py-4 text-center">
                            <span *ngIf="permission.title !=='Private WAN'">Delete</span>
                        </th>
                    </tr>
                </thead>
                <tbody
                    *ngIf="!(permission.title == 'Hosts' || permission.title == 'Routing groups' || permission.title == 'Spaces' || permission.title == 'Logs')">
                    <tr *ngFor="let subPermission of permission.sub;let i=index">
                        <td>{{subPermission.name}}
                            <!-- {{(subPermission.action?.isViewDisabled) ? 'true' : 'false'}}
                    {{(subPermission.action?.isCreateDisabled) ? 'true' : 'false'}}
                    {{(subPermission.action?.isUpdateDisabled) ? 'true' : 'false'}}
                    {{(subPermission.action?.isDeleteDisabled) ? 'true' : 'false'}} -->
                        </td>
                        <td class="text-center">
                            <div *ngIf="subPermission.name !=='Add node to SD-WAN'"
                                class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                <input [disabled]="isView  || subPermission.action?.isViewDisabled == false"
                                    class="form-check-input" [(ngModel)]="subPermission.action.view" type="checkbox"
                                    value="{{(subPermission.action?.view) ? 'true' : 'false'}}" data-kt-check="true"
                                    (change)="uncheckTitleChecked(subPermission.action?.view,permission)"
                                    data-kt-check-target=".widget-9-check" />
                            </div>
                        </td>
                        <!-- <td class="text-center">
                            <div
                                class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                <input [disabled]="isView || subPermission.action?.isCreateDisabled == false"
                                    class="form-check-input" [(ngModel)]="subPermission.action.create" type="checkbox"
                                    value="{{(subPermission.action?.create) ? 'true' : 'false'}}" data-kt-check="true"
                                    (change)="uncheckTitleChecked(subPermission.action?.create,permission)"
                                    data-kt-check-target=".widget-9-check" />
                            </div>
                        </td> -->
                        <td class="text-center">
                            <div
                                class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                <input [disabled]="isView || subPermission.action?.isUpdateDisabled == false"
                                    class="form-check-input" [(ngModel)]="subPermission.action.update" type="checkbox"
                                    value="{{(subPermission.action?.update) ? 'true' : 'false'}}" data-kt-check="true"
                                    (change)="uncheckTitleChecked(subPermission.action?.update,permission)"
                                    data-kt-check-target=".widget-9-check" />
                            </div>
                        </td>
                        <td class="text-center">
                            <div *ngIf="permission.title !== 'Private WAN' && subPermission.name !=='Advance Alert Configuration'"
                                class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                <input [disabled]="isView ||  subPermission.action?.isDeleteDisabled == false"
                                    class="form-check-input" [(ngModel)]="subPermission.action.delete" type="checkbox"
                                    value="{{(subPermission.action?.delete) ? 'true' : 'false'}}" data-kt-check="true"
                                    (change)="uncheckTitleChecked(subPermission.action?.delete,permission)"
                                    data-kt-check-target=".widget-9-check" />
                            </div>
                        </td>
                        <!-- <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input [disabled]="isView && (!subPermission.action.view && !subPermission.action?.isViewDisabled)" class="form-check-input" [(ngModel)]="subPermission.action.view" type="checkbox" value="{{(subPermission.action?.view && subPermission.action?.isViewDisabled) ? 'true' : 'false'}}" data-kt-check="true"
                            data-kt-check-target=".widget-9-check" />
                        </div>
                </td>
                <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input [disabled]="isView && (!subPermission.action.create && !subPermission.action?.isCreateDisabled)" class="form-check-input" [(ngModel)]="subPermission.action.create" type="checkbox" value="{{(subPermission.action?.create && subPermission.action?.isCreateDisabled) ? 'true' : 'false'}}" data-kt-check="true"
                            data-kt-check-target=".widget-9-check" />
                        </div>
                </td>
                <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input [disabled]="isView && (!subPermission.action.update && !subPermission.action?.isUpdateDisabled)" class="form-check-input" [(ngModel)]="subPermission.action.update" type="checkbox" value="{{(subPermission.action?.update && subPermission.action?.isUpdateDisabled) ? 'true' : 'false'}}" data-kt-check="true"
                            data-kt-check-target=".widget-9-check" />
                        </div>
                </td>
                <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input [disabled]="isView && (!subPermission.action.delete && !subPermission.action?.isDeleteDisabled)" class="form-check-input" [(ngModel)]="subPermission.action.delete" type="checkbox" value="{{(subPermission.action?.delete && subPermission.action?.isDeleteDisabled) ? 'true' : 'false'}}" data-kt-check="true"
                            data-kt-check-target=".widget-9-check" />
                        </div>
                </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>