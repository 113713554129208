import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/shared/auth';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-notification-list',
  templateUrl: './shared-notification-list.component.html',
  styleUrls: ['./shared-notification-list.component.scss']
})
export class SharedNotificationListComponent implements OnInit {
  searchControl: FormControl = new FormControl();
index: number = 1;


  @Input() notificationTemplates:any[];
@Input() length:any;
@Input() sortBy: string;
@Input() sortOrder: number;
@Input() permissions: any;


@Output() search=new EventEmitter<any>();
@Output() send=new EventEmitter<any>();
@Output() create=new EventEmitter<any>();
@Output() resetFilter=new EventEmitter<any>();
@Output() delete=new EventEmitter<any>();
@Output() edit=new EventEmitter<any>();
@Output() receiveMessageEmitter=new EventEmitter<any>();
@Output() onSortFields = new EventEmitter<any>();
@Output() view = new EventEmitter<any>();
authService=AuthService;

constructor(private shared:SharedService,private router:Router,
  
  private cd:ChangeDetectorRef) { }
  
  ngOnInit(): void {
    this.onSearch();
  }

  ngOnChanges(): void {
      this.cd.detectChanges();
  }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder==1 ? 'up' : 'down'}.svg`;
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) {
      event.preventDefault();
    }
  }

  onSort(event: any) {
    this.onSortFields.emit(event);
  }

  onResetFilter() {
    this.searchControl.patchValue('');
    this.resetFilter.emit('');
  }

  onSearch(){
    this.searchControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(data => {
          if (data != null || data != undefined) {
            this.search.emit(data);
           // this.searchKey = data;
            //this.page=1;
           // this.getStaffList();
          }
        })
  }

  receiveMessage(data:any){
    this.receiveMessageEmitter.emit(data);
  }

  onAdd(){
    this.create.emit();
  }

  onSend(id:any){
    this.send.emit({templateId:id})
  }

  onDelete(id:any){
    if (id) {
      let questionTitle = 'Are you sure?';
      let text = "<b>You are deleting this notification template. <br>It can't be revert back! </b>"
      let confirmButtonText = "Yes, Delete it!"
      this.shared.swalQuestion(questionTitle, text, confirmButtonText).then((result:any) => {
        if (result.isConfirmed) {
          this.shared.showLoader();
          this.delete.emit({templateId:id});
        }
        this.cd.detectChanges();
      })
  
    }
  }

}
