import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { LayoutInitService } from './core/layout-init.service';
import { LayoutService } from './core/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  // Public variables
  selfLayout = 'default';
  asideSelfDisplay: true;
  asideMenuStatic: true;
  contentClasses = '';
  contentContainerClasses = '';
  toolbarDisplay = true;
  contentExtended: false;
  asideCSSClasses: string;
  asideHTMLAttributes: any = {};
  headerMobileClasses = '';
  headerMobileAttributes = {};
  footerDisplay: boolean;
  footerCSSClasses: string;
  headerCSSClasses: string;
  headerHTMLAttributes: any = {};
  // offcanvases
  extrasSearchOffcanvasDisplay = false;
  extrasNotificationsOffcanvasDisplay = false;
  extrasQuickActionsOffcanvasDisplay = false;
  extrasCartOffcanvasDisplay = false;
  extrasUserOffcanvasDisplay = false;
  extrasQuickPanelDisplay = false;
  extrasScrollTopDisplay = false;
  asideDisplay: boolean;
  showHeader: boolean = false;
  loginSessionSub: any;

  @ViewChild('ktAside', { static: true }) ktAside: ElementRef;
  @ViewChild('ktHeaderMobile', { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;

  constructor(
    private initService: LayoutInitService,
    private layout: LayoutService, private router: Router,
    private partnerBondingService: PartnerBondingService,
    private auth: AuthService, private cd: ChangeDetectorRef
  ) {
    this.initService.init();
  }

  ngOnInit(): void {
    this.auth.validateTokenExpiry();
    if (!this.loginSessionSub) {
      this.loginSessionSub = setInterval(() => {
        this.auth.validateTokenExpiry();
      }, 60000);
    }
    this.auth.showHeader$.subscribe(
      (data: any) => {
        if (data != null || data != undefined) {
          this.showHeader = data;
          this.cd.detectChanges();
        }
      }
    );
    this.partnerBondingService.setUpSocket();

    let menu = Number(localStorage.getItem('menu'));
    if (menu == 1) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
    this.auth.setNavOption();
    this.cd.detectChanges();
    // build view by layout config settings
    // this.selfLayout = this.layout.getProp('self.layout');
    // this.asideSelfDisplay = this.layout.getProp('aside.self.display');
    // this.asideMenuStatic = this.layout.getProp('aside.menu.static');
    this.toolbarDisplay = this.layout.getProp('toolbar.display') as boolean;
    // this.contentClasses = this.layout.getStringCSSClasses('content');
    this.contentContainerClasses =
      this.layout.getStringCSSClasses('contentContainer');
    // this.contentExtended = this.layout.getProp('content.extended');
    // this.asideHTMLAttributes = this.layout.getHTMLAttributes('aside');
    this.asideCSSClasses = this.layout.getStringCSSClasses('aside');
    // this.headerMobileClasses = this.layout.getStringCSSClasses('header_mobile');
    // this.headerMobileAttributes =
    //   this.layout.getHTMLAttributes('header_mobile');
    // this.footerDisplay = this.layout.getProp('footer.display');
    this.footerCSSClasses = this.layout.getStringCSSClasses('footer');
    this.headerCSSClasses = this.layout.getStringCSSClasses('header');
    this.headerHTMLAttributes = this.layout.getHTMLAttributes('headerMenu');
    this.asideDisplay = this.layout.getProp('aside.display') as boolean;
    this.asideDisplay ? true : this.asideCSSClasses += " d-lg-none";
    // // offcanvases
    // if (this.layout.getProp('extras.search.display')) {
    //   this.extrasSearchOffcanvasDisplay =
    //     this.layout.getProp('extras.search.layout') === 'offcanvas';
    // }
    // if (this.layout.getProp('extras.notifications.display')) {
    //   this.extrasNotificationsOffcanvasDisplay =
    //     this.layout.getProp('extras.notifications.layout') === 'offcanvas';
    // }
    // if (this.layout.getProp('extras.quickActions.display')) {
    //   this.extrasQuickActionsOffcanvasDisplay =
    //     this.layout.getProp('extras.quickActions.layout') === 'offcanvas';
    // }
    // if (this.layout.getProp('extras.cart.display')) {
    //   this.extrasCartOffcanvasDisplay =
    //     this.layout.getProp('extras.cart.layout') === 'offcanvas';
    // }
    // if (this.layout.getProp('extras.user.display')) {
    //   this.extrasUserOffcanvasDisplay =
    //     this.layout.getProp('extras.user.layout') === 'offcanvas';
    // }
    // this.extrasQuickPanelDisplay = this.layout.getProp(
    //   'extras.quickPanel.display'
    // );
    // this.extrasScrollTopDisplay = this.layout.getProp(
    //   'extras.scrolltop.display'
    // );
  }

  // setNavOption(){
  //   let img=localStorage.getItem('navBarImage');
  //   let text=localStorage.getItem('navBarText')
  //   if(img &&  img!=='undefined'){
  //     this.auth.navBar.next({ 'content': localStorage.getItem('navBarImage'), 'type': 1 });
  //   }
  //   else if(text &&  text!=='undefined'){
  //     this.auth.navBar.next({ 'content': localStorage.getItem('navBarText'), 'type': 2 });
  //   }
  //   else{
  //     this.auth.navBar.next({ 'content': './assets/media/logos/login-logo.svg', 'type': 1 });
  //   }
  // }

  ngAfterViewInit(): void {
    // if (this.ktAside) {
    //   for (const key in this.asideHTMLAttributes) {
    //     if (this.asideHTMLAttributes.hasOwnProperty(key)) {
    //       this.ktAside.nativeElement.attributes[key] =
    //         this.asideHTMLAttributes[key];
    //     }
    //   }
    // }
    // if (this.ktHeaderMobile) {
    //   for (const key in this.headerMobileAttributes) {
    //     if (this.headerMobileAttributes.hasOwnProperty(key)) {
    //       this.ktHeaderMobile.nativeElement.attributes[key] =
    //         this.headerMobileAttributes[key];
    //     }
    //   }
    // }
    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[key] =
            this.headerHTMLAttributes[key];
        }
      }
    }
    // // Init Content
    // KTLayoutContent.init('kt_content');
  }

  ngOnDestroy(): void {
    clearInterval(this.loginSessionSub)
  }
}
