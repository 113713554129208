import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription, timer } from 'rxjs';
import { PartnerManagmentService } from 'src/app/services/partner-management.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthHTTPService, AuthService } from '../..';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  isLoading$: Observable<boolean>;
  otpForm: FormGroup;
  userData: any;
  countDown: Subscription;
  counter: any;
  tick = 1000;
  showResend: boolean = false;
  showCounter: boolean = true;
  isLoading: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private partnerService: PartnerManagmentService,
    private authHTTPService: AuthHTTPService,
    private cookie: CookieService
  ) {
    this.isLoading$ = this.authService.isLoading$;
    this.route.queryParams.subscribe(params => {
      if (params.role == 1) this.isAdmin = true;
      else this.isAdmin = false;
    });
  }


  ngOnInit(): void {
    this.initForm();
    this.resendOtpTimer();
    this.authService.otpSubject.subscribe(res => {
      if (!res) {
        if (this.isAdmin) this.router.navigate(['/auth/login']);
        else this.router.navigate(['/auth/login/partner']);
        this.countDown.unsubscribe();
      } else {
        if (res?.message) this.sharedService.loggerSuccess(res?.message);
        this.userData = res;
      }
    })
  }

  get f() {
    return this.otpForm.controls;
  }

  initForm() {
    this.otpForm = this.fb.group({
      otp: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
    });
  }

  submit() {
    if (this.otpForm.invalid) {
      this.otpForm.markAsTouched();
      return;
    }
    if (this.isAdmin) this.submitAdmin();
    else this.submitPartner();
  }


  submitPartner() {
    if (!this.f.otp.value) return;
    if (!this.isLoading) {
      this.isLoading = true;
      let body = {
        email: this.userData.email,
        password: this.userData.pass,
        _id: this.userData._id,
        mainId: localStorage.getItem('mainId'),
        role: this.userData.role,
        otp: this.f.otp.value,
        v_type: this.userData.v_type,
        organizationId: this.userData.organizationId ?? "",
      }
      this.sharedService.showLoader();
      this.authService.partnerLoginWithOTP(body).subscribe((user: any) => {
        if (user) {
          if (Number(this.userData.role) != AppConst.partner) {
            let data: any = {
              userId: this.userData._id,
              LoginID: this.userData.createdBy,
              partner_role: this.userData.role.toString(),
            }
            if (localStorage.getItem('organization')) data["organizationId"] = localStorage.getItem('organization')
            this.onLoginAsUser(data);
          } else {
            this.router.navigate(['/partner/dashboard']).then(res => {
              this.sharedService.loggerSuccess('Successfully logged in');
              window.location.reload();
            });
          }
        } else this.otpForm.reset();
        this.isLoading = false;
        this.sharedService.hideLoader();
      }, err => {
        // this.otpForm.reset();
        this.isLoading = false;
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
      });
    }
  }

  submitAdmin() {
    if (!this.f.otp.value) return;
    if (!this.isLoading) {
      let payload = {
        email: this.userData.email,
        password: this.userData.pass,
        otp: this.f.otp.value,
        v_type: this.userData.v_type
      }
      this.isLoading = true;
      this.sharedService.showLoader();
      this.authService.loginWithOTPAdmin(payload).subscribe((user: any) => {
        if (user) {
          this.router.navigate(['/admin/dashboard']).then(res => {
            this.sharedService.loggerSuccess('Successfully logged in');
          });
        } else this.otpForm.reset();
        this.isLoading = false;
        this.sharedService.hideLoader();
      }, err => {
        // this.otpForm.reset();
        this.isLoading = false;
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
      });
    }
  }

  resendOTP() {
    let body = {
      email: this.userData.email,
      role: this.userData.role,
      fname: this.userData.fname,
      _id: this.userData._id,
      mainId: localStorage.getItem('mainId') || ''
    }
    this.sharedService.showLoader();
    this.authHTTPService.resendOTP(body).subscribe(res => {
      if (res.data) {
        this.sharedService.loggerSuccess(res.message);
        this.resendOtpTimer();
      }
      this.otpForm.reset();
      this.otpForm.markAsUntouched();
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.otpForm.reset();
      this.otpForm.markAsUntouched();
      this.sharedService.hideLoader();
      this.cd.detectChanges();
      this.sharedService.loggerError(err);
    })
  }

  resendOtpTimer() {
    this.showCounter = true;
    this.showResend = false;
    this.counter = AppConst.resendOTPCounter;
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter >= 1) --this.counter;
      else {
        this.countDown.unsubscribe();
        this.showResend = true;
        this.showCounter = false;
      }
      this.cd.detectChanges();
    });
  }

  onLoginAsUser(data: any) {
    if (!data?.organizationId) {
      localStorage.setItem('userId', data?.userId + "");
      if (localStorage.getItem('organization') && localStorage.getItem('orgdetails')) {
        let orgDetails = JSON.parse(localStorage.getItem('orgdetails') || '');
        localStorage.setItem('organization', orgDetails?._id);
      }
      if (data) {
        this.sharedService.showLoader();
        this.partnerService.loginAsUser(data).subscribe((res: any) => {
          if (res) {
            if (!res.homeSpace) localStorage.removeItem('homeSpace');
            this.setLocalStorage(res);
          }
          this.sharedService.hideLoader();
        }, (err) => {
          this.sharedService.loggerError(err);
          this.sharedService.hideLoader();
        });
      }
    } else {
      localStorage.setItem('organization', data?.organizationId + "");
      localStorage.setItem('loginOrgId', data?.organizationId + "");
      localStorage.setItem('userId', data?.userId + "");
      if (Number(localStorage.getItem('role')) == AppConst.user) localStorage.setItem('loginId', data?.userId + "");
      if (data) {
        this.sharedService.showLoader();
        this.partnerService.loginAsUser(data).subscribe((res: any) => {
          if (res) {
            this.setLocalStorage(res);
            if (res.homeSpace) localStorage.setItem('homeSpace', res.homeSpace);
          }
          this.sharedService.hideLoader();
        }, (err) => {
          this.sharedService.loggerError(err);
          this.sharedService.hideLoader();
        });
      }
    }
  }

  setLocalStorage(res: any) {
    localStorage.setItem('partnerId', localStorage.getItem('ID') + "");
    this.cookie.set('authToken', res.token);
    localStorage.setItem('ID', res.data._id);
    localStorage.setItem('loginId', res.data.LoginID);
    localStorage.setItem('expiresIn', res.data.expTime);
    localStorage.setItem('role', res.data.role);
    localStorage.setItem('isPartnerLoggedInAsUser', '1');
    localStorage.setItem('logInDuration', res.data.duration);
    localStorage.setItem('logInExpiresIn', res.data.expTime);
    localStorage.setItem('partnerMenu', localStorage.getItem('menu') || '');
    localStorage.setItem('partnerNavBarImage', localStorage.getItem('navBarImage') || '');
    localStorage.setItem('partnerNavBarText', localStorage.getItem('navBarText') || '');
    localStorage.setItem('partnerModuleAccess', localStorage.getItem('Module-Access') || '');
    localStorage.setItem('Module-Access', JSON.stringify(res?.moduleAccess || ''));
    localStorage.setItem('menu', res.brandSettingMenu);
    localStorage.setItem('navBarImage', res.brandSettingNavImg || '');
    localStorage.setItem('navBarText', res.brandSettingNavText || '');
    this.authService.setLayoutConfig();
    this.authService.showHeader();
    this.router.navigateByUrl('/partner/dashboard').then((res: any) => {
      window.location.reload();
    })
  }

  // helpers for View
  isControlValid(controlName: string): boolean {
    const control = this.otpForm.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.otpForm.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation: any, controlName: any): boolean {
    const control = this.otpForm.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName: any): boolean {
    const control = this.otpForm.controls[controlName];
    return control.dirty || control.touched;
  }

}


