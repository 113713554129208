import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedNetworkServiceCreateComponent } from './shared-network-service-create/shared-network-service-create.component';

@Component({
  selector: 'app-shared-network-services',
  templateUrl: './shared-network-services.component.html',
  styleUrls: ['./shared-network-services.component.scss']
})
export class SharedNetworkServicesComponent implements OnInit {
  authService = AuthService;
  latest_tuning: any;
  bondPermission: any;
  @Input() allservices: any[] = [];
  @Input() dnsservices: any[] = [];
  @Input() dHCPV6PDservices: any[] = [];
  @Input() dHCPV6NAservices: any[] = [];
  @Input() routerAdsservices: any[] = [];
  @Input() allConnectedIPS: any[] = [];
  @Input() bondId: any;
  @Input() permissionsObj: any;

  @Output() getservices = new EventEmitter<any>();
  @Output() getdnsservices = new EventEmitter<any>();
  @Output() getdhpv6PDService = new EventEmitter<any>();
  @Output() getdhpv6NAService = new EventEmitter<any>();
  @Output() getrouterAdsService = new EventEmitter<any>();

  constructor(public sharedService: SharedService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private partnerBondingService: PartnerBondingService,) { }

  ngOnInit(): void {
    this.partnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    if (localStorage.getItem('bondPermission')) {
      let data = JSON.parse(localStorage.getItem('bondPermission') || '');
      if (data && data.id == this.bondId) this.bondPermission = data.permission;
      this.cd.detectChanges();
    }
  }

  onServiceOptionChange(option: any, data: any) {
    let modal = this.modalService.open(SharedNetworkServiceCreateComponent, { size: 'lg' });
    modal.componentInstance.selectedOption = option;
    modal.componentInstance.type = data.type;
    modal.componentInstance.connectedIPs = this.allConnectedIPS;
    modal.componentInstance.bondId = this.bondId;
    if (data) {
      modal.componentInstance.isEdit = true;
      modal.componentInstance.serviceData = data;
    } else modal.componentInstance.isEdit = false;
    modal.result.then((data) => {
      if (data && data?.event) {
        if (data.event == 'DHCP') this.getservices.emit();
        else if (data.event == 'DHCPv6-PD') this.getdhpv6PDService.emit();
        else if (data.event == 'DHCPv6-NA') this.getdhpv6NAService.emit();
        else if (data.event == 'RouterAds') this.getrouterAdsService.emit();
        else if (data.event == 'dns') this.getdnsservices.emit();
      }
    }, () => { });
  }

  onEditService(data: any) {
    if (data.type == "DHCP") this.onServiceOptionChange(1, data);
    else if (data.type == "DNS caching service") this.onServiceOptionChange(2, data);
    else if (data.type == "DHCPv6-PD") this.onServiceOptionChange(3, data);
    else if (data.type == "DHCPv6-NA") this.onServiceOptionChange(4, data);
    else if (data.type == "Router advertisement") this.onServiceOptionChange(5, data);
  }

  onDeleteService(data: any) {
    let questionTitle = 'Are you sure you want to delete this service?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"

    let tempArr: any = [];
    if (data?.connectedips?.length > 0 && data?.type == "DNS caching service") {
      data?.connectedips.forEach((element: any) => {
        let arr = this.allConnectedIPS.find((array: any) => array.url == element);
        tempArr.push(arr.ip);
      });
      data.ip = tempArr.toString();
    }
    if (data?.type != "DNS caching service") {
      let arr = this.allConnectedIPS.find((array: any) => array.url == data?.connectedip);
      data.ip = arr?.ip;
    }
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
      if (result.isConfirmed) {
        let requestObj = {
          method: "DELETE",
          url: `bonds/${this.bondId}/dnsmasq_services/${data.id}/`,
          data: "",
          serviceType: data?.type,
          bondName: localStorage.getItem('bondName'),
          bondId: this.bondId,
          type: "Services",
          ip: data?.ip
        }
        if (data.type == "DHCP") requestObj.url = `bonds/${this.bondId}/dnsmasq_services/${data.id}/`
        else if (data.type == "DNS caching service") requestObj.url = `bonds/${this.bondId}/dns_cache_services/${data.id}/`
        else if (data.type == "DHCPv6-PD") requestObj.url = `bonds/${this.bondId}/dhcpv6_prefix_delegation_services/${data.id}/`
        else if (data.type == "DHCPv6-NA") requestObj.url = `bonds/${this.bondId}/dhcpv6_non_temporary_address_services/${data.id}/`
        else if (data.type == "Router advertisement") requestObj.url = `bonds/${this.bondId}/router_advertisement_services/${data.id}/`

        this.sharedService.showLoader();
        this.partnerBondingService.deleteData(requestObj).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          if (data.type == 'DHCP') this.getservices.emit();
          else if (data.type == 'DHCPv6-PD') this.getdhpv6PDService.emit();
          else if (data.type == 'DHCPv6-NA') this.getdhpv6NAService.emit();
          else if (data.type == 'Router advertisement') this.getrouterAdsService.emit();
          else if (data.type == 'DNS caching service') this.getdnsservices.emit();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            if (data.type == 'DHCP') this.getservices.emit();
            else if (data.type == 'DHCPv6-PD') this.getdhpv6PDService.emit();
            else if (data.type == 'DHCPv6-NA') this.getdhpv6NAService.emit();
            else if (data.type == 'Router advertisement') this.getrouterAdsService.emit();
            else if (data.type == 'DNS caching service') this.getdnsservices.emit();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

}
