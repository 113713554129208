import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators,AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AlertService } from 'src/app/services/alert.service';
import { CookieService } from 'ngx-cookie-service';
import { FormErrorService } from 'src/app/shared/services/fromError.service';

@Component({
  selector: 'app-shared-network-legs-create',
  templateUrl: './shared-network-legs-create.component.html',
  styleUrls: ['./shared-network-legs-create.component.scss']
})
export class SharedNetworkLegsCreateComponent implements OnInit {
  apiURL: any;
  getAllInterfaces: any; // parent function to refresh legsData
  bondDetails: any;
  legsData: any;
  allInterfaces: any[];
  providerProfiles: any[];
  bondId: any;
  isEdit: any;
  legOptionForm!: FormGroup
  addressForm!: FormGroup;
  mobileBroadBandForm!: FormGroup;
  optimizationForm!: FormGroup;
  tunnelBypassForm!: FormGroup;

  legId: any;
  selectedTab: any = 1;
  interfaceId: any;
  isDivVisible: any = false;
  isInterfaceChecked: any = true;
  noLegsConfigured: any = true;

  totalAddressCount: number = 0; // total req count
  closeAddressCount: number = 0; // success response count
  errorAddressCount: number = 0; // error response count

  errors: any;
  pppoeError: any;
  staticError: any = {};

  dropDownArray: any = [];
  modeOptions: any[] = [
    {
      title: 'Active',
      key: 'active'
    },
    {
      title: 'Idle',
      key: 'idle'
    },
    {
      title: 'Offline',
      key: 'offline'
    }
  ];

  accessModes: any[] = [
    {
      title: '2G',
      key: '2g'
    },
    {
      title: '3G',
      key: '3g'
    },
    {
      title: '4G',
      key: '4g'
    }
  ];

  radioBandModes: any[] = [
    "cdma-bc0-cellular-800",
    "cdma-bc1-pcs-1900",
    "cdma-bc10-secondary-800",
    "cdma-bc11-pamr-400",
    "cdma-bc12-pamr-800",
    "cdma-bc13-imt2000-2500",
    "cdma-bc14-pcs2-1900",
    "cdma-bc15-aws",
    "cdma-bc16-us-2500",
    "cdma-bc17-us-flo-2500",
    "cdma-bc18-us-ps-700",
    "cdma-bc19-us-lower-700",
    "cdma-bc2-tacs",
    "cdma-bc3-jtacs",
    "cdma-bc4-korean-pcs",
    "cdma-bc5-nmt450",
    "cdma-bc6-imt2000",
    "cdma-bc7-cellular-700",
    "cdma-bc8-1800",
    "cdma-bc9-900",
    "dcs",
    "egsm",
    "eutran-i",
    "eutran-ii",
    "eutran-iii",
    "eutran-iv",
    "eutran-ix",
    "eutran-v",
    "eutran-vi",
    "eutran-vii",
    "eutran-viii",
    "eutran-x",
    "eutran-xi",
    "eutran-xii",
    "eutran-xiii",
    "eutran-xiv",
    "eutran-xix",
    "eutran-xl",
    "eutran-xli",
    "eutran-xlii",
    "eutran-xliii",
    "eutran-xliv",
    "eutran-xvii",
    "eutran-xviii",
    "eutran-xx",
    "eutran-xxi",
    "eutran-xxii",
    "eutran-xxiii",
    "eutran-xxiv",
    "eutran-xxv",
    "eutran-xxvi",
    "eutran-xxxiii",
    "eutran-xxxiv",
    "eutran-xxxix",
    "eutran-xxxv",
    "eutran-xxxvi",
    "eutran-xxxvii",
    "eutran-xxxviii",
    "g850",
    "pcs",
    "u17iv",
    "u17ix",
    "u1800",
    "u1900",
    "u2100",
    "u2600",
    "u800",
    "u850",
    "u900"
  ];

  rowDropDownArray: any = [
    {
      text: 'DHCP',
      value: '0',
      isVisible: true
    },
    {
      text: 'Auto IPv6',
      value: '1',
      isVisible: true
    },
    {
      text: 'Static',
      value: '3',
      isVisible: true
    },
    {
      text: 'PPPoE',
      value: '2',
      isVisible: true
    },

  ]

  constructor(public activeModal: NgbActiveModal,
    public sharedService: SharedService, private cookie: CookieService,
    private fb: FormBuilder, private alertService: AlertService,
    private bondingService: PartnerBondingService,
    private formErrorHandler: FormErrorService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    let arr: any = [];
    this.apiURL = this.cookie.get('api_url');
    this.allInterfaces.forEach(element => {
      if (element.type != "Bridge") {
        arr.push(element);
      }
    });
    this.allInterfaces = arr;
    if (this.isEdit) {
      this.legId = this.legsData.id;
      this.legOptionForm = this.createLegOptionForm(this.legsData);
      this.optimizationForm = this.createOptimizationOptionForm(this.legsData);
      this.tunnelBypassForm = this.createTunnelBypassOptionForm(this.legsData);
      if (this.legsData.type == 'Interface') {
        this.isInterfaceChecked = true;
        this.addressForm = this.createAddressOptionForm(this.legsData);
        if (this.legsData.dhcpData) {
          this.noLegsConfigured = false;
          this.data.push(this.newAddressInfo({ ...this.legsData.dhcpData, dropDownOption: '0' }, false))
        }
        if (this.legsData.autov6Data) {
          this.noLegsConfigured = false;
          this.data.push(this.newAddressInfo({ ...this.legsData.autov6Data, dropDownOption: '1' }, false))
        }
        if (this.legsData.pppoeData) {
          this.noLegsConfigured = false;
          this.data.push(this.newAddressInfo({ ...this.legsData.pppoeData, dropDownOption: '2' }, false))
        }
        if (this.legsData.staticData) {
          this.noLegsConfigured = false;
          this.legsData.staticData.forEach((element: any) => {
            this.data.push(this.newAddressInfo({ ...element, dropDownOption: '3' }, false))
          });
        }
      } else {
        this.isInterfaceChecked = false;
        this.mobileBroadBandForm = this.createMobileBroadbandOptionForm(this.legsData);
      }
    } else {
      this.legOptionForm = this.createLegOptionForm();
      this.addressForm = this.createAddressOptionForm();
      this.optimizationForm = this.createOptimizationOptionForm();
      this.tunnelBypassForm = this.createTunnelBypassOptionForm();
      this.mobileBroadBandForm = this.createMobileBroadbandOptionForm();
    }
  }

  get packetLossDetection() {
    return this.optimizationForm.get('packet_loss_removal_threshold');
  }

  get interface() {
    return this.legOptionForm.get('interface');
  }

  onTypeOptionChange() {
    if (this.isEdit) return;
    let val = this.legOptionForm.get('type')?.value;
    if (val == 'Mobile broadband') this.isInterfaceChecked = false;
    else this.isInterfaceChecked = true;
  }

  onSave() {
    this.errors = {};
    this.pppoeError = null;
    this.staticError = [];
    if (this.isInterfaceChecked) {
      this.addressForm.markAllAsTouched();
    } else {
      this.mobileBroadBandForm.markAllAsTouched();
    }
    this.legOptionForm.markAllAsTouched();
    this.optimizationForm.markAllAsTouched();
    this.tunnelBypassForm.markAllAsTouched();
    if (this.legOptionForm.invalid) {
      this.selectedTab = 1;
      return;
    }
    if (this.optimizationForm.invalid) {
      this.selectedTab = 3;
      return;
    }
    if (this.tunnelBypassForm.invalid) {
      this.selectedTab = 4;
      return;
    }

    if (!this.isInterfaceChecked && this.mobileBroadBandForm.invalid) {
      this.selectedTab = 2;
      return;
    }

    if (this.addressForm?.invalid || this.mobileBroadBandForm?.invalid
      || this.legOptionForm.invalid || this.optimizationForm.invalid || this.tunnelBypassForm.invalid) {
      this.sharedService.loggerError('There were problems validating the form data. Please review and correct the issues. ')
      return;
    }
    if (!this.apiURL) return;
    let legData: any = { ...this.legOptionForm.value, bond: `${this.apiURL}bonds/${this.bondId}/` }

    if (this.tunnelBypassForm.value) legData = { ...legData, ...this.tunnelBypassForm.value }
    if (this.optimizationForm.value) legData = { ...legData, ...this.optimizationForm.value }
    if (this.isInterfaceChecked) legData = { ...legData, ...this.addressForm.value }
    else legData = { ...legData, ...this.mobileBroadBandForm.value }

    if (this.isEdit) this.editLegs(legData);
    else this.addLegs(legData);
  }

  editLegs(data: any) {
    this.sharedService.showLoader();
    if (this.isInterfaceChecked) {
      data.bondName = this.bondDetails?.bonder?.name || '';
      data.bondId = this.bondId || '';
      this.bondingService.updateInterface(this.bondId, this.legId, data)?.subscribe((res: any) => {
        if (res.code == 201 || res.code == 200) {
          if (res.data) {
            this.interfaceId = res.data.id;
            if (res.data.link_mode != 'active') this.removeMonitor(this.interfaceId);
            this.addDHCP();
            this.addAutoIPV6();
            this.addPPPOEaddress();
            this.addStaticAddresses();
          }
        } else this.sharedService.loggerError(res.message);
        if (this.totalAddressCount == 0) {
          this.sharedService.hideLoader();
          this.activeModal.close({ event: 'leg' });
          this.sharedService.loggerSuccess('Data update successfully');
        }
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          Object.keys(this.errors).forEach(x => {
            let legControl = this.legOptionForm.get(x);
            if (legControl) {
              this.legOptionForm.setErrors({ ...this.legOptionForm.errors, [x]: this.errors[x] })
            }
            let optimizationControl = this.optimizationForm.get(x);
            if (optimizationControl) {
              this.optimizationForm.setErrors({ ...this.optimizationForm.errors, [x]: this.errors[x] })
            }
            let tunnelControl = this.tunnelBypassForm.get(x);
            if (tunnelControl) {
              this.tunnelBypassForm.setErrors({ ...this.tunnelBypassForm.errors, [x]: this.errors[x] })
            }
            if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
            else this.sharedService.loggerError('Please correct the errors.');
            this.sharedService.hideLoader();
            this.cd.detectChanges();
          })
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    } else {
      if (!this.apiURL) return;
      data = { ...data, id: this.legId, url: `${this.apiURL}bonds/${this.bondId}/mobile_broadband_legs/${this.legId}` }
      data.bondName = this.bondDetails?.bonder?.name || '';
      data.bondId = this.bondId || '';
      this.bondingService.updateMobileData(this.bondId, this.legId, data)?.subscribe(
        (res: any) => {
          if (res.code == 201 || res.code == 200) {
            this.interfaceId = res.data.id;
            if (res.data.link_mode != 'active') this.removeMonitor(this.interfaceId);
          }
          this.sharedService.hideLoader();
          this.sharedService.loggerSuccess('Data update successfully');
          this.activeModal.close({ event: 'mobile' });
        }, (err) => {
          try {
            this.errors = JSON.parse(err);
            Object.keys(this.errors).forEach(x => {
              let legControl = this.legOptionForm.get(x);
              if (legControl) {
                this.legOptionForm.setErrors({ ...this.legOptionForm.errors, [x]: this.errors[x] })
              }
  
              let optimizationControl = this.optimizationForm.get(x);
              if (optimizationControl) {
                this.optimizationForm.setErrors({ ...this.optimizationForm.errors, [x]: this.errors[x] })
              }
  
              let broadbandControl = this.mobileBroadBandForm.get(x);
              if (broadbandControl) {
                this.mobileBroadBandForm.setErrors({ ...this.mobileBroadBandForm.errors, [x]: this.errors[x] })
              }
  
              let tunnelControl = this.tunnelBypassForm.get(x);
              if (tunnelControl) {
                this.tunnelBypassForm.setErrors({ ...this.tunnelBypassForm.errors, [x]: this.errors[x] })
              }
              if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
              else this.sharedService.loggerError('Please correct the errors.');
              this.sharedService.hideLoader();
              this.cd.detectChanges();
            })
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        }
      );
    }
  }

  addLegs(data: any) {
    this.sharedService.showLoader();
    if (this.isInterfaceChecked) {
      data.bondName = this.bondDetails?.bonder?.name || '';
      data.bondId = this.bondId || '';
      this.bondingService.addInterface(this.bondId, data)?.subscribe((res: any) => {
        if (res.code == 201 || res.code == 200) {
          if (res.data) {
            this.interfaceId = res.data.id;
            this.legId = res.data.id;
            this.addDHCP();
            this.addAutoIPV6();
            this.addPPPOEaddress();
            this.addStaticAddresses();
          }
        } else this.sharedService.loggerError(res.message);
        if (this.totalAddressCount == 0) {
          this.sharedService.hideLoader();
          this.activeModal.close({ event: 'leg' });
          this.sharedService.loggerSuccess('Data Add successfully');
        }
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          Object.keys(this.errors).forEach(x => {
            let legControl = this.legOptionForm.get(x);
            if (legControl)
              this.legOptionForm.setErrors({ ...this.legOptionForm.errors, [x]: this.errors[x] })
  
            let optimizationControl = this.optimizationForm.get(x);
            if (optimizationControl)
              this.optimizationForm.setErrors({ ...this.optimizationForm.errors, [x]: this.errors[x] })
  
            let tunnelControl = this.tunnelBypassForm.get(x);
            if (tunnelControl)
              this.tunnelBypassForm.setErrors({ ...this.tunnelBypassForm.errors, [x]: this.errors[x] })
          });
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
       
      });
    } else {
      data.bondName = this.bondDetails?.bonder?.name || '';
      data.bondId = this.bondId || '';
      this.bondingService.addMobileData(this.bondId, data)?.subscribe((res: any) => {
        if (res && res.data) this.interfaceId = res.data.id;
        this.sharedService.hideLoader();
        this.sharedService.loggerSuccess('Data Add successfully');
        this.activeModal.close({ event: 'mobile' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          Object.keys(this.errors).forEach(x => {
            let legControl = this.legOptionForm.get(x);
            if (legControl)
              this.legOptionForm.setErrors({ ...this.legOptionForm.errors, [x]: this.errors[x] })
  
  
            let broadbandControl = this.mobileBroadBandForm.get(x);
            if (broadbandControl)
              this.mobileBroadBandForm.setErrors({ ...this.mobileBroadBandForm.errors, [x]: this.errors[x] })
  
            let optimizationControl = this.optimizationForm.get(x);
            if (optimizationControl)
              this.optimizationForm.setErrors({ ...this.optimizationForm.errors, [x]: this.errors[x] })
  
            let tunnelControl = this.tunnelBypassForm.get(x);
            if (tunnelControl)
              this.tunnelBypassForm.setErrors({ ...this.tunnelBypassForm.errors, [x]: this.errors[x] })
          });
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  // updateMobileBroadbandData() {
  //   this.bondingService.updateMobileData(this.bondId, this.legId, this.mobileBroadBandForm.value).subscribe((res: any) => {
  //   }, (err) => {
  //     this.errors = { ...this.errors, ...JSON.parse(err) }
  //     Object.keys(this.errors).forEach(x => {
  //       let legControl = this.mobileBroadBandForm.get(x);
  //       if (legControl)
  //         this.mobileBroadBandForm.setErrors({ ...this.mobileBroadBandForm.errors, [x]: this.errors[x] })
  //       this.cd.detectChanges();
  //     })
  //   });
  // }

  // addMobileBroadbandData() {
  //   this.bondingService.addMobileData(this.bondId, this.mobileBroadBandForm.value).subscribe((res: any) => {
  //   }, (err) => {
  //     this.errors = { ...this.errors, ...JSON.parse(err) }
  //     Object.keys(this.errors).forEach(x => {
  //       let legControl = this.mobileBroadBandForm.get(x);
  //       if (legControl)
  //         this.mobileBroadBandForm.setErrors({ ...this.mobileBroadBandForm.errors, [x]: this.errors[x] })
  //       this.cd.detectChanges();
  //     })
  //   });
  // }

  addStaticAddresses() {
    if (!(this.data && this.data.controls && this.data.controls.length > 0)) return;
    this.data.controls.forEach((x: any, index: number) => {
      if (x.value.dropDownOption == '3') {
        if (!x.value.isDeleted && x.value.isNew) {
          if (!this.apiURL) return;
          let data = {
            ...x.value,
            leg: `${this.apiURL}bonds/${this.bondId}/interface_legs/${this.interfaceId}/`
          }
          this.totalAddressCount++;
          this.bondingService.addStaticData(this.bondId, data)?.subscribe((res: any) => {
            if (res.code === 204 || res.code === 200 || res.code === 201) {
              x.get('isNew')?.setValue(false);
              x.get('id')?.setValue(res.data.id);
            }
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            try {
              console.log('addStaticaddress', err);
              this.staticError[index] = JSON.parse(err);
              this.errorAddressCount++;
              this.errorHandler();
            } catch (e) {
              // JSON parsing failed, assume it's a plain error message
              this.sharedService.hideLoader();
              this.sharedService.loggerError(err);
              this.cd.detectChanges();
            }
          });
        } else if (!x.value.isDeleted && !x.value.isNew && x.value.isUpdate) {
          if (!this.apiURL) return;
          let url = `bonds/${this.bondId}/static_addressings/${x.value.id}/`
          let obj = {
            ...x.value,
            leg: `${this.apiURL}bonds/${this.bondId}/interface_legs/${this.interfaceId}/`,
          }
          this.totalAddressCount++;
          this.bondingService.updateStaticData(url, obj).subscribe((res: any) => {
            if (res.code === 204 || res.code === 200) x.get('isUpdate')?.setValue(false);
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            try {
              console.log('updateStaticData error', err);
              this.staticError[index] = JSON.parse(err);
              this.errorAddressCount++;
              this.errorHandler();
            } catch (e) {
              // JSON parsing failed, assume it's a plain error message
              this.sharedService.hideLoader();
              this.sharedService.loggerError(err);
              this.cd.detectChanges();
            }
          });
        } else if (x.value.isDeleted && !x.value.isNew) {
          if (!this.apiURL) return;
          this.totalAddressCount++;
          let url = `bonds/${this.bondId}/static_addressings/${x.value.id}/`;
          this.bondingService.deleteStaticData(url, {}).subscribe((res: any) => {
            if (res.code === 204 || res.code === 200) x.get('isDeleted')?.setValue(false);
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            console.log('deleteStaticData error', err);
            this.errorAddressCount++;
            this.errorHandler();
            this.sharedService.loggerError(err);
          });
        }
      }
    });
  }

  addPPPOEaddress() {
    if (!(this.data && this.data.controls && this.data.controls.length > 0)) return;
    this.data.controls.forEach(x => {
      if (x.value.dropDownOption == '2') {
        if (!x.value.isDeleted && x.value.isNew) {
          if (!this.apiURL) return;
          let data = {
            ...x.value,
            leg: `${this.apiURL}bonds/${this.bondId}/interface_legs/${this.interfaceId}/`
          }
          this.totalAddressCount++;
          this.bondingService.addPPPoEData(this.bondId, data)?.subscribe((res: any) => {
            if (res.code === 204 || res.code === 200 || res.code === 201) {
              x.get('isNew')?.setValue(false);
              x.get('id')?.setValue(res.data.id);
            }
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            try {
              console.log('addPPPOEaddress', err);
              this.pppoeError = JSON.parse(err);
              this.errorAddressCount++;
              this.errorHandler();
            } catch (e) {
              // JSON parsing failed, assume it's a plain error message
              this.sharedService.hideLoader();
              this.sharedService.loggerError(err);
              this.cd.detectChanges();
            }
          });
        } else if (!x.value.isDeleted && !x.value.isNew && x.value.isUpdate) {
          if (!this.apiURL) return;
          let url = `bonds/${this.bondId}/pppoe_addressings/${x.value.id}/`;
          let data = {
            ...x.value,
            url: url,
            leg: `${this.apiURL}bonds/${this.bondId}/interface_legs/${this.interfaceId}/`
          }
          this.totalAddressCount++;
          this.bondingService.updatePPPoEData(url, data).subscribe((res: any) => {
            if (res.code === 204 || res.code === 200) x.get('isUpdate')?.setValue(false);
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            try {
              console.log('updatePPPoEData error', err);
              this.pppoeError = JSON.parse(err);
              this.errorAddressCount++;
              this.errorHandler();
            } catch (e) {
              // JSON parsing failed, assume it's a plain error message
              this.sharedService.hideLoader();
              this.sharedService.loggerError(err);
              this.cd.detectChanges();
            }
          });
        } else if (x.value.isDeleted && !x.value.isNew) {
          if (!this.apiURL) return;
          let url = `bonds/${this.bondId}/pppoe_addressings/${x.value.id}/`;
          this.totalAddressCount++;
          this.bondingService.deletePPoEData(url, {}).subscribe((res: any) => {
            if (res.code === 204 || res.code === 200) x.get('isDeleted')?.setValue(false);
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            console.log('deletePPoEData error', err);
            this.errorAddressCount++;
            this.errorHandler();
            this.sharedService.loggerError(err);
          });
        }
      }
    })
  }

  addDHCP() {
    if (!(this.data && this.data.controls && this.data.controls.length > 0)) return;
    let data = {};
    this.data.controls.forEach(x => {
      if (x.value.dropDownOption == '0') {
        if (!x.value.isDeleted && x.value.isNew) {
          if (!this.apiURL) return;
          data = {
            enabled: x.value.enabled,
            leg: `${this.apiURL}bonds/${this.bondId}/interface_legs/${this.interfaceId}/`
          }
          this.totalAddressCount++;
          this.bondingService.addDHCPData(this.bondId, data)?.subscribe((res: any) => {
            if (res.code === 204 || res.code === 200 || res.code === 201) {
              x.get('isNew')?.setValue(false);
              x.get('id')?.setValue(res.data.id);
            }
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            console.log('addDHCP', err);
            this.errorAddressCount++;
            this.errorHandler();
            this.sharedService.loggerError(err);
          });
        } else if (!x.value.isDeleted && !x.value.isNew && x.value.isUpdate) {
          if (!this.apiURL) return;
          let url = `bonds/${this.bondId}/dhcp_addressings/${x.value.id}/`
          data = {
            enabled: x.value.enabled,
            id: x.value.id,
            url: url,
            leg: `${this.apiURL}bonds/${this.bondId}/interface_legs/${this.interfaceId}/`
          }
          this.totalAddressCount++;
          this.bondingService.updateDHCPData(url, data).subscribe((res: any) => {
            if (res.code === 204 || res.code === 200) x.get('isUpdate')?.setValue(false);
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            console.log('updateDHCP error', err);
            this.errorAddressCount++;
            this.errorHandler();
            this.sharedService.loggerError(err);
          });
        } else if (x.value.isDeleted && !x.value.isNew) {
          if (!this.apiURL) return;
          let url = `bonds/${this.bondId}/dhcp_addressings/${x.value.id}/`;
          this.totalAddressCount++;
          this.bondingService.deleteDHCPData(url, {}).subscribe((res: any) => {
            if (res.code === 204 || res.code === 200) x.get('isDeleted')?.setValue(false);
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            console.log('deleteDHCP error', err);
            this.errorAddressCount++;
            this.errorHandler();
            this.sharedService.loggerError(err);
          });
        }
      }
    });
  }

  addAutoIPV6() {
    if (!(this.data && this.data.controls && this.data.controls.length > 0)) return;
    let data = {};
    this.data.controls.forEach(x => {
      if (x.value.dropDownOption == '1') {
        if (!x.value.isDeleted && x.value.isNew) {
          if (!this.apiURL) return;
          data = {
            enabled: x.value.enabled,
            leg: `${this.apiURL}bonds/${this.bondId}/interface_legs/${this.interfaceId}/`
          }
          this.totalAddressCount++;
          this.bondingService.addIPV6Data(this.bondId, data)?.subscribe((res: any) => {
            if (res.code === 204 || res.code === 200 || res.code === 201) {
              x.get('isNew')?.setValue(false);
              x.get('id')?.setValue(res.data.id);
            }
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            console.log('addAutoIPV6', err);
            this.errorAddressCount++;
            this.errorHandler();
            this.sharedService.loggerError(err);
          });
        } else if (!x.value.isDeleted && !x.value.isNew && x.value.isUpdate) {
          if (!this.apiURL) return;
          let url = `bonds/${this.bondId}/autoipv6_addressings/${x.value.id}/`;
          data = {
            enabled: x.value.enabled,
            id: x.value.id,
            url: url,
            leg: `${this.apiURL}bonds/${this.bondId}/interface_legs/${this.interfaceId}/`
          }
          this.totalAddressCount++;
          this.bondingService.updateIPV6Data(url, data).subscribe((res: any) => {
            if (res.code === 204 || res.code === 200) x.get('isUpdate')?.setValue(false);
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            console.log('updateIPV6 error', err);
            this.errorAddressCount++;
            this.errorHandler();
            this.sharedService.loggerError(err);
          });
        } else if (x.value.isDeleted && !x.value.isNew) {
          let url = `bonds/${this.bondId}/autoipv6_addressings/${x.value.id}/`;
          this.totalAddressCount++;
          this.bondingService.deleteIPV6Data(url, {}).subscribe((res: any) => {
            if (res.code === 204 || res.code === 200) x.get('isDeleted')?.setValue(false);
            this.closeAddressCount++;
            this.errorHandler();
          }, (err) => {
            console.log('deleteDHCP error', err);
            this.errorAddressCount++;
            this.errorHandler();
            this.sharedService.loggerError(err);
          });
        }
      }
    });
  }

  errorHandler() {
    if (this.closeAddressCount + this.errorAddressCount >= this.totalAddressCount) {
      this.sharedService.hideLoader();
      if (this.errorAddressCount > 0) {
        if (this.totalAddressCount > 1) this.getAllInterfaces.emit();
        this.isEdit = true;
        this.totalAddressCount = 0;
        this.closeAddressCount = 0;
        this.errorAddressCount = 0;
      } else this.activeModal.close({ event: 'leg' });
    }
    this.cd.detectChanges();
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onTabChange(tabNumber: any) {
    this.selectedTab = tabNumber;
    this.cd.detectChanges();
  }

  onAddAddress() {
    this.noLegsConfigured = false;
    this.isDivVisible = true;
    this.data.push(this.newAddressInfo({}, true));
  }

  createLegOptionForm(data: any = {}) {
    return this.fb.group({
      type: [data.type || 'Interface'],
      link_mode: [data.link_mode || 'active'],
      interface: [data.interface],
      interface_mtu: [data.interface_mtu],
      provider: [data.provider],
      failover: [data.failover || false],
      down_speed: [data.down_speed || 10],
      up_speed: [data.up_speed || 10],
      minimum_mtu: [data.minimum_mtu || 1024],
      note: [data.note || '', [this.sharedService.xssValidator, Validators.maxLength(200)]]
    })
  }

  createMobileBroadbandOptionForm(data: any = {}) {
    return this.fb.group({
      imei: [data.imei || ""],
      roaming: [data.roaming == false ? false : true],
      sim_pin: [data.sim_pin || ""],
      access_modes: [data.access_modes || []],
      radio_bands: [data.radio_bands || []],
    })
  }

  createTunnelBypassOptionForm(data: any = {}) {
    return this.fb.group({
      tunnel_bypass_enabled: [data.tunnel_bypass_enabled || false],
      tunnel_bypass_percentage: [data.tunnel_bypass_percentage || '100'],
      tunnel_bypass_priority: [data.tunnel_bypass_priority || '0'],
    })
  }

  createOptimizationOptionForm(data: any = {}) {
    return this.fb.group({
      bandwidth_adaptation: [data.bandwidth_adaptation || false],
      bandwidth_adaptation_warn: [data.bandwidth_adaptation_warn || '90'],
      bandwidth_adaptation_limit: [data.bandwidth_adaptation_limit || '50'],
      bandwidth_adaptation_packet_loss_threshold: [data.bandwidth_adaptation_packet_loss_threshold || '10'],
      packet_loss_removal_threshold: [data.packet_loss_removal_threshold || '10', [Validators.required]],
    })
  }

  createAddressOptionForm(data: any = {}) {
    return this.fb.group({
      data: this.fb.array([])
    })
  }

  get data(): FormArray {
    return this.addressForm.get("data") as FormArray
  }

  getDropdownOptionName(i: any) {
    let value = this.data.controls[i].get('dropDownOption')?.value;
    switch (value) {
      case '0':
        return 'DHCP';
      case '1':
        return 'Auto IPv6';
      case '2':
        return 'PPPoE'
      case '3':
        return 'Static'
      default:
        return ''
    }
  }

  newAddressInfo(data: any = {}, isNew = false): FormGroup {
    let selectedValues = this.data.controls.map(x => x.value.dropDownOption).filter(x => x != '3');
    let dropdown = this.rowDropDownArray.filter((x: any) => !selectedValues.includes(x.value));
    this.dropDownArray.push(dropdown);
    let length = this.data.length;
    let group = this.fb.group({
      dropDownOption: [data.dropDownOption || dropdown[0]?.value],
      enabled: [data.enabled == false ? false : true],
      ip: [data.ip || ''],
      gateway: [data.gateway || ''],
      username: [data.username || ''],
      password: [data.password || ''],
      ip_version_preference: [data.ip_version_preference || '4'],
      isDeleted: false,
      isUpdate: false,
      isNew: [isNew],
      id: [data.id || 0],
      idx: [this.data.length]
    });
    group.get('enabled')?.valueChanges.subscribe((value: any) => {
      group.get('isUpdate')?.setValue(true);
    });
    group.get('username')?.valueChanges.subscribe((value: any) => {
      group.get('isUpdate')?.setValue(true);
      this.pppoeError = null;
    });
    group.get('password')?.valueChanges.subscribe((value: any) => {
      group.get('isUpdate')?.setValue(true);
      this.pppoeError = null;
    });
    group.get('ip_version_preference')?.valueChanges.subscribe((value: any) => {
      group.get('isUpdate')?.setValue(true);
      this.pppoeError = null;
    });
    group.get('gateway')?.valueChanges.subscribe((value: any) => {
      group.get('isUpdate')?.setValue(true);
      this.staticError = {};
    });
    group.get('ip')?.valueChanges.subscribe((value: any) => {
      group.get('isUpdate')?.setValue(true);
      this.staticError = {};
    });
    group.get('dropDownOption')?.valueChanges.subscribe((value: any) => {
      this.onOptionSelectionChange(value, length);
    })
    this.onOptionSelectionChange(dropdown[0]?.value, length);
    return group;
  }

  onToggle(index: number, data: any) {
    if (!this.data.controls[index].get('isNew')?.value) {
      let control = this.data.controls[index].get('isDeleted');
      control?.patchValue(!control?.value)
    }
    else {
      let val = data.controls['dropDownOption'].value;
      this.data.removeAt(index);
      if (this.data.controls.length == 0) {
        this.noLegsConfigured = true;
      }
      if (val == '3') {
        return;
      }
      setTimeout(() => {
        for (let i = 0; i < this.dropDownArray.length; i++) {
          this.dropDownArray[i].push(this.rowDropDownArray.find((a: any) => a.value == val))
        }
        this.cd.detectChanges();
      });
    }
  }

  onOptionSelectionChange(value: any, idx: any) {
    setTimeout(() => {
      let selectedValues = this.data.controls.map(x => x.value.dropDownOption).filter(x => x != '3');
      for (let i = 0; i < this.dropDownArray.length; i++) {
        this.dropDownArray[i] = this.rowDropDownArray.filter((x: any) => !selectedValues.includes(x.value));
        if (i == idx && value != '3') {
          this.dropDownArray[i].unshift(this.rowDropDownArray.find((a: any) => a.value == value))
        }
      }
      this.cd.detectChanges();
    });
  }

  removeMonitor(legId: any) {
    this.alertService.removeMonitor(this.bondId, legId)?.subscribe((res: any) => {
      if (res && res.code == 200) this.activeLegList(this.bondId);
    });
  }

  activeLegList(bondId: any) {
    if (!bondId) return;
    this.alertService.activeLegList(bondId)?.subscribe((res: any) => {
      if (res && res.data) this.alertService.activeAlertLegList.next(res.data);
    });
  }

  controlHasError(validation: any, controlName: string): boolean {
     return this.formErrorHandler.controlHasError(validation, controlName, this.legOptionForm);

  }
}
