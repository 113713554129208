import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationComponent } from '../components/pagination/pagination.component';
import { DateFormatPipe } from '../pipes/date-format-pipe.pipe';
import { SanitizeHtmlPipe } from '../pipes/sanitize-html.pipe';
import { TruncatePipe } from '../pipes/string-cut-pipe.pipe';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { InlineSVGModule } from 'ng-inline-svg';
import { ColorSketchModule } from 'ngx-color/sketch';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SharedActivityLogsComponent } from '../components/shared-activity-logs/shared-activity-logs.component';
import { SharedAutomationLogsComponent } from '../components/shared-automation-logs/shared-automation-logs.component';
import { SharedBondsCreateComponent } from '../components/shared-bonds/shared-bonds-create/shared-bonds-create.component';
import { RecursiveSpaceComponent } from '../components/shared-bonds/shared-bonds-listing/shared-bond-to-space-move/recursive-space/recursive-space.component';
import { SharedBondToSpaceMoveComponent } from '../components/shared-bonds/shared-bonds-listing/shared-bond-to-space-move/shared-bond-to-space-move.component';
import { SharedBondsListingComponent } from '../components/shared-bonds/shared-bonds-listing/shared-bonds-listing.component';
import { SharedSelectColumnFormComponent } from '../components/shared-bonds/shared-bonds-listing/shared-select-column-form/shared-select-column-form.component';
import { SharedBondsQuickViewComponent } from '../components/shared-bonds/shared-bonds-quick-view/shared-bonds-quick-view.component';
import { SharedBondsViewConfigurationDetailsComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-configuration-details/shared-bonds-view-configuration-details.component';
import { SharedNetworkConnectedIpEditComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-connected-ip/shared-network-connected-ip-edit/shared-network-connected-ip-edit.component';
import { SharedNetworkConnectedIPComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-connected-ip/shared-network-connected-ip.component';
import { SharedNetworkCpeComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-cpe/shared-network-cpe.component';
import { SharedNetworkFiltersComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-filters/shared-network-filters.component';
import { SharedNetworkInterdfaceAddComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-interface/shared-network-interdface-add/shared-network-interdface-add.component';
import { SharedNetworkInterfaceEditComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-interface/shared-network-interface-edit/shared-network-interface-edit.component';
import { SharedNetworkInterfaceComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-interface/shared-network-interface.component';
import { SharedNetworkLegsCreateComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-legs/shared-network-legs-create/shared-network-legs-create.component';
import { SharedNetworkLegsComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-legs/shared-network-legs.component';
import { SharedNetworkProtocolsComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-protocols/shared-network-protocols.component';
import { ShredNetworkProtocolsAddComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-protocols/shred-network-protocols-add/shred-network-protocols-add.component';
import { SharedNetworkRoutesEditComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-routes/shared-network-routes-edit/shared-network-routes-edit.component';
import { SharedNetworkRoutesComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-routes/shared-network-routes.component';
import { SharedNetworkServiceCreateComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-services/shared-network-service-create/shared-network-service-create.component';
import { SharedNetworkServicesComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-services/shared-network-services.component';
import { SharedBondsViewNodeDetailsComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-node-details/shared-bonds-view-node-details.component';
import { SharedBondsViewSpeedTestComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-speed-test/shared-bonds-view-speed-test.component';
import { SharedBondsViewStatusComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-status/shared-bonds-view-status.component';
import { SharedBrandSettingComponent } from '../components/shared-brand-setting/shared-brand-setting.component';
import { SharedEmailManagementComponent } from '../components/shared-email-management/shared-email-management.component';
import { SharedEmailSendFormComponent } from '../components/shared-email-management/shared-email-send-form/shared-email-send-form.component';
import { SharedEmailTemplateFormComponent } from '../components/shared-email-management/shared-email-template-form/shared-email-template-form.component';
import { SharedErrorLogsComponent } from '../components/shared-error-logs/shared-error-logs.component';
import { SharedApiComponent } from '../components/shared-general-configuration/shared-api/shared-api.component';
import { SharedMailConfigComponent } from '../components/shared-general-configuration/shared-mail-config/shared-mail-config.component';
import { SharedGroupManagementComponent } from '../components/shared-group-permission/shared-group-management/shared-group-management.component';
import { SharedGroupPermissionComponent } from '../components/shared-group-permission/shared-group-permission.component';
import { SharedPermissionManagementComponent } from '../components/shared-group-permission/shared-permission-management/shared-permission-management.component';
import { SharedModuleAccessListComponent } from '../components/shared-module-access/shared-module-access-list/shared-module-access-list.component';
import { SharedNotificationLogsComponent } from '../components/shared-notification-logs/shared-notification-logs.component';
import { SharedNotificationFormComponent } from '../components/shared-notification-template/shared-notification-form/shared-notification-form.component';
import { SharedNotificationListComponent } from '../components/shared-notification-template/shared-notification-list/shared-notification-list.component';
import { SharedOrgManagementListComponent } from '../components/shared-organization-management/shared-org-management-list/shared-org-management-list.component';
import { SharedSuspendedOrgListComponent } from '../components/shared-organization-management/shared-suspended-org-list/shared-suspended-org-list.component';
import { SharedRequestLogsComponent } from '../components/shared-request-logs/shared-request-logs.component';
import { SharedSuspendedUserListComponent } from '../components/shared-user-management/shared-suspended-user-list/shared-suspended-user-list.component';
import { SharedUserManagementListComponent } from '../components/shared-user-management/shared-user-management-list/shared-user-management-list.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { FormatTimePipe } from '../pipes/time-format-pipe.pipe';
// import {SharedNetworkInterfaceEditComponent} from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-interface/shared-network-interface-edit/shared-network-interface-edit.component';
// import { SharedMailConfigComponent } from '../components/shared-general-configuration/shared-mail-config/shared-mail-config.component';
// import { SharedApiComponent } from '../components/shared-general-configuration/shared-api/shared-api.component';
import { SharedNetworkCpeAddComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-cpe/shared-network-cpe-add/shared-network-cpe-add.component';
import { SharedNetworkFiltersAddComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-network-details/shared-network-filters/shared-network-filters-add/shared-network-filters-add.component';
import { SharedSpeedTestViewComponent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-speed-test/shared-speed-test-view/shared-speed-test-view.component';
import { SharedBondingApiComponent } from '../components/shared-general-configuration/shared-bonding-api/shared-bonding-api.component';
import { SharedAggregatorsComponent } from '../components/shared-hosts/shared-aggregators/shared-aggregators.component';
import { SharedCreateAggregatorComponent } from '../components/shared-hosts/shared-aggregators/shared-create-aggregator/shared-create-aggregator.component';
import { SharedHostsBondersComponent } from '../components/shared-hosts/shared-hosts-bonders/shared-hosts-bonders.component';
import { SharedHostsRoutersComponent } from '../components/shared-hosts/shared-hosts-routers/shared-hosts-routers.component';
import { SharedInheritedAggregatorsComponent } from '../components/shared-hosts/shared-inherited-aggregators/shared-inherited-aggregators.component';
import { SharedSpaceBrandingComponent } from '../components/shared-space/shared-space-create/shared-space-branding/shared-space-branding.component';
import { SharedSpaceConfigurationComponent } from '../components/shared-space/shared-space-create/shared-space-configuration/shared-space-configuration.component';
import { SharedSpaceCreateComponent } from '../components/shared-space/shared-space-create/shared-space-create.component';
import { SharedSpaceIpAddComponent } from '../components/shared-space/shared-space-create/shared-space-ip/shared-space-ip-add/shared-space-ip-add.component';
import { SharedSpaceIpDelegateComponent } from '../components/shared-space/shared-space-create/shared-space-ip/shared-space-ip-delegate/shared-space-ip-delegate.component';
import { SharedSpaceIpComponent } from '../components/shared-space/shared-space-create/shared-space-ip/shared-space-ip.component';
import { SharedSpaceNodeComponent } from '../components/shared-space/shared-space-create/shared-space-node/shared-space-node.component';
import { SharedSpaceStatisticsComponent } from '../components/shared-space/shared-space-create/shared-space-statistics/shared-space-statistics.component';
import { SharedSpaceSupportComponent } from '../components/shared-space/shared-space-create/shared-space-support/shared-space-support.component';
import { SharedSpaceWanAddGatewayComponent } from '../components/shared-space/shared-space-create/shared-space-wan/shared-space-wan-add-gateway/shared-space-wan-add-gateway.component';
import { SharedSpaceWanAddProtocolComponent } from '../components/shared-space/shared-space-create/shared-space-wan/shared-space-wan-add-protocol/shared-space-wan-add-protocol.component';
import { SharedSpaceWanAddVlanComponent } from '../components/shared-space/shared-space-create/shared-space-wan/shared-space-wan-add-vlan/shared-space-wan-add-vlan.component';
import { SharedSpaceWanComponent } from '../components/shared-space/shared-space-create/shared-space-wan/shared-space-wan.component';
import { SharedSpaceListingComponent } from '../components/shared-space/shared-space-listing/shared-space-listing.component';
import { SharedSpaceComponent } from '../components/shared-space/shared-space.component';

import { SharedSdwanCreateComponent } from '../components/shared-space/shared-sdwan-create/shared-sdwan-create.component';
import { SharedSdwanWanAddFilterComponent } from '../components/shared-space/shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-add-filter/shared-sdwan-wan-add-filter.component';
import { SharedSdwanWanAddGatewayComponent } from '../components/shared-space/shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-add-gateway/shared-sdwan-wan-add-gateway.component';
import { SharedSdwanWanAddProtocolComponent } from '../components/shared-space/shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-add-protocol/shared-sdwan-wan-add-protocol.component';
import { SharedSdwanWanAddVlanComponent } from '../components/shared-space/shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-add-vlan/shared-sdwan-wan-add-vlan.component';
import { SharedSdwanWanDeleteComponent } from '../components/shared-space/shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan-delete/shared-sdwan-wan-delete.component';
import { SharedSdwanWanComponent } from '../components/shared-space/shared-sdwan-create/shared-sdwan-wan/shared-sdwan-wan.component';
import { SharedSdwanListingComponent } from '../components/shared-space/shared-sdwan-listing/shared-sdwan-listing.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AlertConfigurationFormComponent } from 'src/app/modules/partner/bonds/partner-bond-view/alert-configuration/alert-configuration-form.component';
import { AlertHistoryComponent } from 'src/app/modules/partner/bonds/partner-bond-view/alert-history/alert-history.component';
import { PoliciesClassificationFormComponent } from 'src/app/modules/partner/policies/policies-classification-listing/policies-classification-form/policies-classification-form.component';
import { VersionCreateComponent } from 'src/app/modules/super-admin/configuration/general-config/version/version-create/version-create.component';
import { PreloaderTableListComponent } from '../components/preloader-table-list/preloader-table-list.component';
import { NgbModalBondClone, SdwanBondsListingComponent } from '../components/shared-bonds/sdwan-bonds-listing/sdwan-bonds-listing.component';
import { SharedSdwanSharingModalComponent } from '../components/shared-bonds/sdwan-bonds-listing/shared-sdwan-sharing-modal/shared-sdwan-sharing-modal.component';
import { SharedBondSharingComponent } from '../components/shared-bonds/shared-bond-sharing/shared-bond-sharing.component';
import { SharedBondAuthorizationModalComponent } from '../components/shared-bonds/shared-bonds-create/shared-bond-authorization-modal/shared-bond-authorization-modal.component';
import { SharedBondEditMultipleComponent } from '../components/shared-bonds/shared-bonds-listing/shared-bond-edit-multiple/shared-bond-edit-multiple.component';
import { SharedBondSharingModalComponent } from '../components/shared-bonds/shared-bonds-listing/shared-bond-sharing-modal/shared-bond-sharing-modal.component';
import { SharedBondViewDevDetailsComponent } from '../components/shared-bonds/shared-bonds-view/shared-bond-view-dev-details/shared-bond-view-dev-details.component';
import { SharedBondViewDeviceDetailsComponent } from '../components/shared-bonds/shared-bonds-view/shared-bond-view-device-details/shared-bond-view-device-details.component';
import { SharedBondsViewConfigModalComponent, WarningModalContent } from '../components/shared-bonds/shared-bonds-view/shared-bonds-view-config-modal/shared-bonds-view-config-modal.component';
import { SharedSdwanSharingListComponent } from '../components/shared-bonds/shared-sdwan-sharing-list/shared-sdwan-sharing-list.component';
import { CustomSharedEmailTemplateFormComponent } from '../components/shared-email-management/custom-shared-email-template-form/custom-shared-email-template-form.component';
import { SharedCustomEmailListComponent } from '../components/shared-email-management/shared-custom-email-list/shared-custom-email-list.component';
import { IlluminateDataModalComponent } from '../components/shared-module-access/illuminate-data-modal/illuminate-data-modal.component';
import { SharedAlertConfigModalComponent } from '../components/shared-module-access/shared-alert-config-modal/shared-alert-config-modal.component';
import { SharedBondingApiModalComponent } from '../components/shared-module-access/shared-bonding-api-modal/shared-bonding-api-modal.component';
import { SharedSpaceIpDelegateModalComponent } from '../components/shared-space/shared-space-create/shared-space-ip/shared-space-ip-delegate-modal/shared-space-ip-delegate-modal.component';
import { SharedSpaceWanAddFilterComponent } from '../components/shared-space/shared-space-create/shared-space-wan/shared-space-wan-add-filter/shared-space-wan-add-filter.component';
import { FileDragNDropDirective } from '../directives/file-drag-n-drop.directive';
import { FilterBySubstringPipe } from '../pipes/filterBySubstring.pipe';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        NgSelectModule,
        UiSwitchModule,
        CKEditorModule,
        ColorSketchModule,
        NgbPopoverModule,
        InlineSVGModule,
        DragDropModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        NgCircleProgressModule.forRoot({
            animationDuration: 300,
            titleFontSize: "16",
            subtitleFontSize: "14",
            showUnits: false,
            radius: 70,
            outerStrokeWidth: 12,
            innerStrokeWidth: 12,
            space: -12,
            titleFontWeight: "600"
        })
    ],

    exports: [
        FormsModule,
        ReactiveFormsModule,
        DateFormatPipe,
        TruncatePipe,
        SanitizeHtmlPipe,
        MatPaginatorModule,
        NgbModule,
        NgSelectModule,
        UiSwitchModule,
        PaginationComponent,
        FormatTimePipe,
        FilterBySubstringPipe,
        SharedBrandSettingComponent, FilterPipe,
        SharedEmailManagementComponent,
        SharedEmailTemplateFormComponent,
        InlineSVGModule,
        SharedActivityLogsComponent,
        SharedGroupPermissionComponent,
        SharedGroupManagementComponent,
        SharedPermissionManagementComponent,
        SharedUserManagementListComponent,
        SharedOrgManagementListComponent,
        SharedSuspendedUserListComponent,
        SharedSuspendedOrgListComponent,
        SharedModuleAccessListComponent,
        SharedNotificationListComponent,
        SharedNotificationFormComponent,
        SharedMailConfigComponent,
        SharedApiComponent,
        SharedModuleAccessListComponent,
        SharedErrorLogsComponent,
        SharedRequestLogsComponent,
        SharedAutomationLogsComponent,
        SharedNotificationLogsComponent,
        SharedBondsListingComponent,
        SharedBondSharingComponent,
        SdwanBondsListingComponent,
        SharedBondsCreateComponent,
        SharedBondsViewConfigurationDetailsComponent,
        SharedBondViewDeviceDetailsComponent,
        SharedBondViewDevDetailsComponent,
        SharedBondsViewNodeDetailsComponent,
        SharedBondsViewConfigModalComponent,
        WarningModalContent,
        SharedBondsViewSpeedTestComponent,
        SharedBondsViewStatusComponent,
        SharedNetworkConnectedIPComponent,
        SharedNetworkCpeComponent,
        SharedNetworkFiltersComponent,
        SharedNetworkInterfaceComponent,
        SharedNetworkLegsComponent,
        SharedNetworkRoutesComponent,
        SharedNetworkProtocolsComponent,
        SharedNetworkServicesComponent,
        SharedBondsQuickViewComponent,
        RecursiveSpaceComponent,
        SharedNetworkLegsCreateComponent,
        SharedNetworkInterdfaceAddComponent,
        ShredNetworkProtocolsAddComponent,
        SharedNetworkFiltersAddComponent,
        SharedSpeedTestViewComponent,
        SharedSpaceComponent,
        SharedSpaceListingComponent,
        SharedSpaceCreateComponent,
        SharedSdwanListingComponent,
        SharedSdwanCreateComponent,
        SharedSdwanWanComponent,
        SharedSpaceIpComponent,
        SharedSpaceWanComponent,
        PoliciesClassificationFormComponent,
        SharedSpaceBrandingComponent,
        SharedSpaceSupportComponent,
        SharedSpaceStatisticsComponent,
        SharedSpaceNodeComponent,
        SharedSpaceConfigurationComponent,
        SharedBondingApiComponent,
        SharedSpaceIpAddComponent,
        SharedSpaceWanAddVlanComponent,
        SharedSpaceWanAddProtocolComponent,
        SharedSpaceWanAddFilterComponent,
        SharedSdwanWanAddVlanComponent,
        SharedSdwanWanAddGatewayComponent,
        SharedSdwanWanAddProtocolComponent,
        SharedSdwanWanDeleteComponent,
        SharedSdwanWanAddFilterComponent,
        SharedSpaceIpAddComponent,
        SharedSpaceWanAddGatewayComponent,
        SharedAggregatorsComponent,
        SharedInheritedAggregatorsComponent,
        SharedHostsRoutersComponent,
        SharedHostsBondersComponent,
        SharedCreateAggregatorComponent,
        SharedSpaceWanAddGatewayComponent,
        SharedSpaceIpDelegateComponent,
        SharedBondingApiModalComponent,
        SharedBondEditMultipleComponent,
        SharedBondAuthorizationModalComponent,
        SharedSpaceIpDelegateModalComponent,
        SharedBondSharingModalComponent,
        IlluminateDataModalComponent,
        AlertConfigurationFormComponent,
        AlertHistoryComponent,
        CustomSharedEmailTemplateFormComponent,
        SharedCustomEmailListComponent,
        SharedSdwanSharingModalComponent,
        SharedSdwanSharingListComponent,
        SharedAlertConfigModalComponent,
        VersionCreateComponent,
        PreloaderTableListComponent,
        NgbModalBondClone
    ],
    entryComponents: [
        SharedEmailSendFormComponent,
        SharedSelectColumnFormComponent,
        SharedBondToSpaceMoveComponent,
        SharedNetworkInterfaceEditComponent,
        SharedNetworkServiceCreateComponent,
        SharedNetworkConnectedIpEditComponent,
        SharedNetworkCpeAddComponent,
        SharedNetworkRoutesEditComponent,
        SharedSdwanSharingModalComponent,
        SharedAlertConfigModalComponent
    ],

    declarations: [
        PaginationComponent,
        DateFormatPipe,
        FormatTimePipe,
        TruncatePipe,
        SanitizeHtmlPipe,
        FilterBySubstringPipe,
        SharedBrandSettingComponent, FilterPipe,
        SharedEmailManagementComponent,
        SharedEmailTemplateFormComponent,
        SharedActivityLogsComponent,
        SharedEmailSendFormComponent,
        SharedGroupPermissionComponent,
        SharedGroupManagementComponent,
        SharedPermissionManagementComponent,
        SharedUserManagementListComponent,
        SharedOrgManagementListComponent,
        SharedSuspendedUserListComponent,
        SharedSuspendedOrgListComponent,
        SharedModuleAccessListComponent,
        SharedNotificationListComponent,
        SharedNotificationFormComponent,
        SharedMailConfigComponent,
        SharedApiComponent,
        SharedModuleAccessListComponent,
        SharedErrorLogsComponent,
        SharedRequestLogsComponent,
        SharedAutomationLogsComponent,
        SharedNotificationLogsComponent,
        SharedBondsListingComponent,
        SharedBondSharingComponent,
        SdwanBondsListingComponent,
        SharedBondsCreateComponent,
        SharedBondsViewConfigurationDetailsComponent,
        SharedBondViewDeviceDetailsComponent,
        SharedBondViewDevDetailsComponent,
        SharedBondsViewNodeDetailsComponent,
        SharedBondsViewConfigModalComponent,
        WarningModalContent,
        SharedBondsViewSpeedTestComponent,
        SharedBondsViewStatusComponent,
        SharedNetworkConnectedIPComponent,
        SharedNetworkCpeComponent,
        SharedNetworkFiltersComponent,
        SharedNetworkInterfaceComponent,
        SharedNetworkLegsComponent,
        SharedNetworkRoutesComponent,
        SharedNetworkProtocolsComponent,
        SharedNetworkServicesComponent,
        SharedBondsQuickViewComponent,
        SharedSelectColumnFormComponent,
        SharedBondToSpaceMoveComponent,
        RecursiveSpaceComponent,
        SharedNetworkLegsCreateComponent,
        SharedNetworkInterdfaceAddComponent,
        SharedNetworkInterfaceEditComponent,
        SharedNetworkServiceCreateComponent,
        SharedNetworkConnectedIpEditComponent,
        SharedNetworkCpeAddComponent,
        SharedNetworkRoutesEditComponent,
        ShredNetworkProtocolsAddComponent,
        SharedNetworkFiltersAddComponent,
        SharedSpeedTestViewComponent,
        SharedSpaceComponent,
        SharedSpaceListingComponent,
        SharedSpaceCreateComponent,
        SharedSdwanListingComponent,
        SharedSdwanCreateComponent,
        SharedSdwanWanComponent,
        SharedSpaceIpComponent,
        SharedSpaceWanComponent,
        PoliciesClassificationFormComponent,
        SharedSpaceBrandingComponent,
        SharedSpaceSupportComponent,
        SharedSpaceStatisticsComponent,
        SharedSpaceNodeComponent,
        SharedSpaceConfigurationComponent,
        SharedBondingApiComponent,
        SharedSpaceIpAddComponent,
        SharedSpaceWanAddVlanComponent,
        SharedSpaceWanAddProtocolComponent,
        SharedSpaceWanAddFilterComponent,
        SharedSdwanWanAddVlanComponent,
        SharedSdwanWanAddGatewayComponent,
        SharedSdwanWanAddProtocolComponent,
        SharedSdwanWanDeleteComponent,
        SharedSdwanWanAddFilterComponent,
        SharedSpaceIpAddComponent,
        SharedSpaceWanAddGatewayComponent,
        SharedAggregatorsComponent,
        SharedInheritedAggregatorsComponent,
        SharedHostsRoutersComponent,
        SharedHostsBondersComponent,
        SharedCreateAggregatorComponent,
        SharedSpaceWanAddGatewayComponent,
        SharedSpaceIpDelegateComponent,
        SharedBondingApiModalComponent,
        SharedBondEditMultipleComponent,
        SharedBondAuthorizationModalComponent,
        SharedSpaceIpDelegateModalComponent,
        SharedBondSharingModalComponent,
        IlluminateDataModalComponent,
        AlertConfigurationFormComponent,
        AlertHistoryComponent,
        CustomSharedEmailTemplateFormComponent,
        SharedCustomEmailListComponent,
        SharedSdwanSharingModalComponent,
        SharedSdwanSharingListComponent,
        SharedAlertConfigModalComponent,
        FileDragNDropDirective,
        VersionCreateComponent,
        PreloaderTableListComponent,
        NgbModalBondClone
    ],

    providers: [
        DatePipe
    ],

    bootstrap: [
        PaginationComponent
    ],

    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]

})

export class SharedModule { }
