import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedNetworkLegsCreateComponent } from './shared-network-legs-create/shared-network-legs-create.component';
import { PartnerBondViewComponent } from 'src/app/modules/partner/bonds/partner-bond-view/partner-bond-view.component'
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-shared-network-legs',
  templateUrl: './shared-network-legs.component.html',
  styleUrls: ['./shared-network-legs.component.scss']
})

export class SharedNetworkLegsComponent implements OnInit {
  authService = AuthService;
  LegsArray: any[] = [];
  latest_tuning: any;
  monitorIcon: boolean = false;
  collapseStatus = false;
  // status: any;
  bondPermission: any;
  public isCollapsed = false;

  @Input() allLegs: any[] = [];
  @Input() allInterfaces: any[];
  @Input() allMobileBroadband: any[] = []
  @Input() providerProfiles: any[] = []
  @Input() permissionsObj: any;
  @Input() bondId: any;
  @Input() bondDetails: any;
  @Input() alertPermission: any

  @Output() getAllMobileBroadband = new EventEmitter<any>();
  @Output() getAllInterfaces = new EventEmitter<any>();

  constructor(private partnerBondingService: PartnerBondingService,
    private alertService: AlertService,
    private partnerBondView: PartnerBondViewComponent,
    public sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal) { }

  ngOnChanges() {
    if (this.allLegs.length) {
      this.allLegs.forEach(x => {
        if (!x.isCollapsed && !x.collapseStatus) {
          x.collapseStatus = true;
          x.isCollapsed = true;
        }
      });
      this.addFailbackMessage();
      this.cd.detectChanges();
    }
    if (this.allMobileBroadband.length) {
      this.allMobileBroadband.forEach(x => {
        if (!x.isCollapsed && !x.collapseStatus) {
          x.collapseStatus = true;
          x.isCollapsed = true;
        }
      });
      this.cd.detectChanges();
    }
    this.getAllAlertList();
  }

  ngOnInit(): void {
    this.partnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    })
    if (localStorage.getItem('bondPermission')) {
      let data = JSON.parse(localStorage.getItem('bondPermission') || '');
      if (data && data.id == this.bondId) this.bondPermission = data.permission;
      this.cd.detectChanges();
    }
    // this.getStatus();
    // this.cd.detectChanges();
  }

  getValueForProgressBar(id: any, start: number, end: number): number {
    let val = (end - start) / 3600;
    if (val < 0) { val = val * -1 }
    return val;
  }

  getTextForProgressBar(start: any, end: any): any {
    let text: string
    text = ((end - start) / 3600) + '';
    let arr: string[] = text.split('.');
    if (arr.length == 1) return `${arr[0]} hours`;
    else {
      if (arr[0] >= '24') { return `${arr[0]} hours` }
      else {
        let min = arr[1].substring(0, 1)
        return `${arr[0]} hours ${min} minutes`
      }
    }
  }

  // getStatus() {
  //   let legStatus: any;
  //   let url = `legs/63/`;
  //   this.partnerBondingService.getResponse(url, 'GET').subscribe((res: any) => {
  //     if (res.data) {
  //       legStatus = res.data;
  //       if (legStatus) {
  //         let d2 = new Date(legStatus?.aggregator_status?.rates_updated);
  //         let d1 = new Date(legStatus?.aggregator_status?.adjusted_tx_rate_updated);
  //         let diff = (d2.getTime() - d1.getTime()) / 1000;
  //         diff /= (60 * 60);
  //         let ans: number = Math.abs(Math.round(diff));
  //         if (ans > 24) {
  //           this.status = 'Up for 24 hours'
  //         } else {
  //           let downTime = 24 - ans;
  //           this.status = `Up for ${ans} hours and down for ${downTime} hours`
  //         }
  //       }
  //     }
  //   });
  // }

  onEdit(event: any) {
    let modal = this.modalService.open(SharedNetworkLegsCreateComponent, { size: 'lg' });
    if (event) {
      modal.componentInstance.legsData = event;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.componentInstance.allInterfaces = this.allInterfaces;
    modal.componentInstance.providerProfiles = this.providerProfiles;
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bondDetails = this.bondDetails;
    modal.componentInstance.getAllInterfaces = this.getAllInterfaces;
    modal.closed.subscribe((result: any) => {
      if (result.event == 'leg') setTimeout(() => { this.getAllInterfaces.emit() }, 500);
      else if (result.event == 'mobile') setTimeout(() => { this.getAllMobileBroadband.emit() }, 500);
    });
  }

  onDelete(item: any, legName: any, legDetails: any, isMobile: boolean = false) {
    let questionTitle = 'Are you sure you want to delete this leg?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        if (isMobile) {
          this.partnerBondingService.deleteMobile(this.bondId, item, this.bondDetails?.bonder?.name)?.subscribe((res: any) => {
            if (res.code == 204 || res.code == 200 || res.code == 201) {
              this.sharedService.loggerSuccess('Leg has been deleted successfully.');
              this.removeMonitor(item, false);
            } else this.sharedService.loggerError(res.message);
            this.sharedService.hideLoader();
            this.getAllMobileBroadband.emit();
          }, (err) => {
            try {
              let error = JSON.parse(err);
              if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
              else this.sharedService.loggerError(err);
              this.sharedService.hideLoader();
              this.getAllMobileBroadband.emit();
            } catch (e) {
              // JSON parsing failed, assume it's a plain error message
              this.sharedService.hideLoader();
              this.sharedService.loggerError(err);
              this.cd.detectChanges();
            }
          });
        } else {
          let data = {
            bondName: localStorage.getItem('bondName') ?? this.bondDetails?.bonder?.name,
            bondId: this.bondId,
            address: false,
            id: item,
            ifname: legDetails?.ifname
          }
          if (legDetails) {
            let arr: any = [];
            if (legDetails?.dhcp_addressings?.length > 0) arr.push("DHCP");
            if (legDetails?.auto_ipv6_addressings?.length > 0) arr.push("Auto IPV6");
            if (legDetails?.pppoe_addressings?.length > 0) arr.push("PPPoE");
            if (legDetails?.static_addressings?.length > 0) arr.push("Static");
            if (arr?.length > 0) {
              let address = arr.toString();
              data.address = address;
            }
          }

          this.partnerBondingService.deleteLegs(this.bondId, item, data)?.subscribe((res: any) => {
            if (res.code == 201 || res.code == 200 || res.code == 204) {
              this.sharedService.loggerSuccess('Leg has been deleted successfully.');
              this.removeMonitor(item, false);
            } else this.sharedService.loggerError(res.message);
            this.sharedService.hideLoader();
            this.getAllInterfaces.emit();
          }, (err) => {
            try {
              let error = JSON.parse(err);
              if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
              else this.sharedService.loggerError(err);
              this.sharedService.hideLoader();
              this.getAllInterfaces.emit();
            } catch (e) {
              // JSON parsing failed, assume it's a plain error message
              this.sharedService.hideLoader();
              this.sharedService.loggerError(err);
              this.cd.detectChanges();
            }
          });
        }
      }
    });
  }

  calculateDifferenceOfDate(date: any) {
    if (!date) return '-'
    let startDate = new Date(date);
    let endDate = new Date();
    if (startDate > endDate) {
      let swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    let startYear = startDate.getFullYear();
    let february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = -1;
    let minDiff = -1;
    let secDiff = -1;
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    } else hourDiff = endDate.getHours() - startDate.getHours();
    minDiff = endDate.getMinutes() - startDate.getMinutes();
    secDiff = endDate.getSeconds() - startDate.getSeconds();
    if (yearDiff == 1) return `About year ago`;
    else if (yearDiff > 1) return `${parseInt(yearDiff + '')} year ago`;
    else if (monthDiff == 1) return `About month ago`;
    else if (monthDiff > 1 && monthDiff <= 31) return `${parseInt(monthDiff + '')} month ago`;
    else if (dayDiff == 1) return `About day ago`;
    else if (dayDiff >= 1 && dayDiff <= 31) return `${parseInt(dayDiff + '')} day ago`;
    else if (hourDiff >= 1 && hourDiff <= 31) return `${parseInt(hourDiff + '')} hour ago`;
    else if (minDiff >= 1 && minDiff <= 59) return `${parseInt(minDiff + '')} minutes ago`;
    else if (secDiff >= 1 && secDiff <= 59) return `${parseInt(secDiff + '')} seconds ago`;
    else return '-';
  }

  getDetectMTUs(legId: number, type: string) {
    if (!this.bondId || !legId) return;
    let url;
    if (type == 'Interface') url = `bonds/${this.bondId}/interface_legs/${legId}/detect_mtus/`;
    else url = `bonds/${this.bondId}/mobile_broadband_legs/${legId}/detect_mtus/`;
    this.sharedService.showLoader();
    this.partnerBondingService.viewStatus('POST', url).subscribe((res: any) => {
      if (res && res.code == 200 && res.data && res.data.description) this.sharedService.loggerSuccess(`Leg ${this.bondId} MTUs are being detected.`);
      else res?.data?.detail ? this.sharedService.loggerError(`Failed to detect leg ${legId} Mtu.` + res?.data?.detail) :
        this.sharedService.loggerError(`Failed to detect leg ${legId} Mtu.` + res?.data?.error);
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  getDetectSpeed(legId: number, type: string) {
    if (!this.bondId || !legId) return;
    let url;
    if (type == 'Interface') url = `bonds/${this.bondId}/interface_legs/${legId}/tuners/`;
    else url = `bonds/${this.bondId}/mobile_broadband_legs/${legId}/tuners/`;
    let data = {
      bondName: localStorage.getItem('bondName') ?? this.bondDetails?.bonder?.name,
      type: 'LegTuning',
      bondId: this.bondId,
      legId: legId,
    }
    this.sharedService.showLoader();
    this.partnerBondingService.viewDynamicData('POST', url, data).subscribe((res: any) => {
      if (res && res.code != 400 && res.data && res.data.id) {
        this.sharedService.loggerSuccess(`Speeds are being detected on leg ${this.bondId}
      This will take a few minutes. You will get an email when the process is complete.`);
        this.partnerBondView.getRecentActivity();
        this.partnerBondView.getLatestTuning(res.data.id, legId);
      } else res?.data?.detail ? this.sharedService.loggerError(`Failed to detect leg ${legId} speeds.` + res?.data?.detail) :
        this.sharedService.loggerError(`Failed to detect leg ${legId} speeds.` + res?.data?.error);
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  viewTuning(legId: any) {
    this.partnerBondView.viewTuning(legId);
  }

  cancelTuning(legId: any) {
    this.partnerBondView.cancelTuning(legId);
  }

  getDHCPLease(data: any) {
    if (!this.bondId || !data) return;
    let url = `bonds/${this.bondId}/dhcp_addressings/${data.id}/renew/`
    this.sharedService.showLoader();
    this.partnerBondingService.viewStatus('POST', url).subscribe((res: any) => {
      if (res && res.code == 200 && res.data && res.data.description) this.sharedService.loggerSuccess(`Leg DHCP lease is being renewed.`);
      else this.sharedService.loggerError(res?.data?.detail);
      this.sharedService.hideLoader();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }


  addMonitor(legId: any, state: string) {
    this.allLegs?.forEach((leg: any) => {
      if (leg.id == legId) leg['isAlertAPI'] = true;
    });
    this.allMobileBroadband?.forEach((leg: any) => {
      if (leg.id == legId) leg['isAlertAPI'] = true;
    });
    this.cd.detectChanges();
    this.alertService.addMonitoring(this.bondId, legId, state)?.subscribe((res: any) => {
      if (res && res.code == 200) {
        this.sharedService.loggerSuccess(res.message);
        this.activeLegList(this.bondId);
      }
      this.allLegs?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
      this.allMobileBroadband?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
    }, (err) => {
      this.sharedService.loggerError(err);
      this.allLegs?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
      this.allMobileBroadband?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
    });
  }

  removeMonitor(legId: any, isLogger = true) {
    this.allLegs?.forEach((leg: any) => {
      if (leg.id == legId) leg['isAlertAPI'] = true;
    });
    this.allMobileBroadband?.forEach((leg: any) => {
      if (leg.id == legId) leg['isAlertAPI'] = true;
    });
    this.alertService.removeMonitor(this.bondId, legId)?.subscribe((res: any) => {
      if (res && res.code == 200) {
        if (isLogger) this.sharedService.loggerSuccess(res.message);
        this.activeLegList(this.bondId);
      }
      this.allLegs?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
      this.allMobileBroadband?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
    }, (err) => {
      this.sharedService.loggerError(err);
      this.allLegs?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
      this.allMobileBroadband?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
    });
  }

  activeLegList(bondId: any) {
    if (!bondId) return;
    this.alertService.activeLegList(bondId)?.subscribe((res: any) => {
      if (res && res.data) this.alertService.activeAlertLegList.next(res.data);
    });
  }

  addFailbackMessage() {
    this.partnerBondingService.failbackMessage$.subscribe((res: any) => {
      this.allLegs.forEach(element => {
        res.forEach((element1: any) => {
          if (element1.object_id == element.id && element1.object_type == 'leg' && element1.severity == 'warning') {
            element.failbackMessage = element1.message
          }
        });
      });
    }
    );
  }

  getAllAlertList() {
    this.alertService.alertGroupData$.subscribe((res: any) => {
      if (res && res.length > 0) {
        this.monitorIcon = res.some((alertData: any) => alertData.bondList.includes(+this.bondId));
        this.cd.detectChanges();
      }
      this.sharedService.hideLoader();
    });
  }

  calculateBytes(rate: number) {
    return this.sharedService.calculateBytes(rate);
  }
}
