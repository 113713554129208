export enum coreMailType {
    AdminCreatePassword = 1,
    AdminForgotPassword = 2,
    AdminResetPassword = 3,
    AdminPasswordChange = 4,
    PartnerCreatePassword = 5,
    PartnerForgotPassword = 6,
    PartnerResetPassword = 7,
    PartnerPasswordChange = 8
}

export enum emailType {
    core = 1,
    custom = 2
}

export class AppConst {
    public static trimPattern = /^\s+|\s+$/gm; // pattern for trimming
    public static pageSize = 10;
    public static pageSizeOptions: number[] = [10, 25, 50, 100];
    // public static emailVal = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    // public static emailVal = /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    public static emailVal = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    public static urlValidationPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    public static userNameVal = /^[^<>]{0,50}$/;
    public static addressValPattern = /^[a-zA-Z0-9-_,.#&()!@* ]+$/;
    public static mobileValidationPatter = /^((\+){0,1}91(\s){0,1}(\-){0,1}(\s){0,1}){0,1}98(\s){0,1}(\-){0,1}(\s){0,1}[1-9]{1}[0-9]{7}$/;
    // public static mobilePattern = /^\d{10}$/;
    public static mobilePattern = /^\+?\d{6,12}$/;
    // public static registrationPattern = /^\d{8}$/;
    public static registrationPattern = /^[a-zA-Z0-9-_,.#&()!@* ]+$/;
    public static postalCodePattern = /^[a-zA-Z0-9\s-]+$/
    public static NameFieldPattern = /^[^<>]{0,100}$/;
    // public static pwdPatternWithValidations = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/
    public static pwdPatternWithValidations = /^(?=.*[A-Z].*[A-Z])(?=.*[!-\/:-@\[-`{-~])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
    public static pngiconx = 19;
    public static pngicony = 35;
    public static stageWidth = 700;
    public static stageHeight = 600;
    public static countryImageUrl = 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/';
    public static admin = 0
    public static partner = 1
    public static reseller = 3
    public static user = 2
    public static endUser = 4
    public static basicUser = 5
    public static organization = 6
    public static resendOTPCounter = 121;
    // public static resendOTPCounter = 11;
    public static Languages = [
        {
            lang: 'en',
            name: 'English',
            flag: './assets/media/flags/united-states.svg',
        },
        {
            lang: 'zh',
            name: 'Mandarin',
            flag: './assets/media/flags/china.svg',
        },
        {
            lang: 'es',
            name: 'Spanish',
            flag: './assets/media/flags/spain.svg',
        },
        {
            lang: 'ja',
            name: 'Japanese',
            flag: './assets/media/flags/japan.svg',
        },
        {
            lang: 'de',
            name: 'German',
            flag: './assets/media/flags/germany.svg',
        },
        {
            lang: 'fr',
            name: 'French',
            flag: './assets/media/flags/france.svg',
        },
    ];

    public static modelOpenFunctionality: object = {
        size: 'lg',
        backdrop: 'static',
        centered: true,
        keyboard: false
    };

    public static questionTypeArray = [
        {
            value: "checkbox",
            name: "Checkbox"
        },
        {
            value: "textbox",
            name: "Textbox"
        },
        {
            value: "radio",
            name: "Radio Button"
        },
        {
            value: "dropdown",
            name: "Dropdown"
        },
    ];

    public static answerTypeArray = [
        {
            value: "string",
            name: "String"
        },
        {
            value: "number",
            name: "Number"
        },
    ];

    public static periodList = [
        {
            label: "TODAY",
            value: "1"
        },
        {
            label: "Yesterday",
            value: "2"
        },
        {
            label: "This Week",
            value: "3"
        },
        {
            label: "This Month",
            value: "4"
        },
        {
            label: "Custom",
            value: "5"
        }
    ];

    public static statusList = [
        {
            value: 'false',
            name: 'Active'
        },
        {
            value: 'true',
            name: 'Deactive'
        }
    ]

    public static devModeList = [
        {
            value: 'Production',
            label: 'Production'
        },
        {
            value: 'Development',
            label: 'Development'
        }
    ]

    public static providerList = [
        {
            value: 'Google Authenticator',
            label: 'Google Authenticator'
        },
        {
            value: 'Email Verification',
            label: 'Email Verification'
        }
    ];

    public static driverList = [
        {
            value: 'SMTP',
            label: 'SMTP'
        },
        {
            value: 'Send Mail',
            label: 'Send Mail'
        }
    ];

    public static encryptionList = [
        {
            value: 'None',
            label: 'None'
        },
        {
            value: 'SSL',
            label: 'SSL'
        },
        {
            value: 'TLS',
            label: 'TLS'
        }
    ];

    public static dateFormatList = [
        {
            value: 'yyyy-mm-dd',
            label: 'yyyy-mm-dd'
        },
        {
            value: 'dd-mm-yyyy',
            label: 'dd-mm-yyyy'
        }
    ];

    public static timeFormatList = [
        {
            value: 'h:i:s a (12h)',
            label: 'h:i:s a (12h)'
        },
        {
            value: 'H:i:s (24h)',
            label: 'H:i:s (24h)'
        }
    ];

    public static foundationList = [
        {
            value: '{{name}}',
            label: 'Name'
        },
        {
            value: '{{time}}',
            label: 'Time'
        },
        {
            value: '{{date}}',
            label: 'Date'
        },
        {
            value: '{{lastname}}',
            label: 'Last Name'
        },
        {
            value: '{{companyname}}',
            label: 'Company Name'
        },
        {
            value: '{{mobileno}}',
            label: 'Mobile Number'
        },
        {
            value: '{{email}}',
            label: 'Email'
        },
        // {
        //     value: '{{user.fullName}}',
        //     label: 'User Fullname'
        // },
        // {
        //     value: '{{user.status}}',
        //     label: 'User Status'
        // },
        // {
        //     value: '{{brand.id}}',
        //     label: 'Brand Id'
        // },
        // {
        //     value: '{{brand.name}}',
        //     label: 'Brand Name'
        // }
    ];

    public static timeDurationList = [
        { value: 5, label: '5 Minutes' },
        { value: 60, label: '1 Hour' },
        { value: 480, label: '8 Hours' },
        { value: 960, label: '16 Hours' },
        { value: 1440, label: '24 Hours' },
        { value: 10080, label: '7 Days' },
        { value: 21600, label: '15 Days' },
        { value: 43200, label: '30 Days' },
    ];

    public static adminMailList = [
        { key: "staff_create", value: "staff create mail", disable: false },
        { key: "staff_reset_password", value: "staff reset password mail", disable: false },
        { key: "staff_login_otp", value: "staff login otp mail", disable: false },
        { key: "staff_login_reotp", value: "staff login re-send otp mail", disable: false },
        { key: "partner_create", value: "partner create mail", disable: false },
        { key: "partner_reset_password", value: "partner reset password mail", disable: false },
        { key: "partner_login_otp", value: "partner login otp mail", disable: false },
        { key: "partner_login_reotp", value: "partner login re-send otp mail", disable: false },
    ];

    public static partnerMailList = [
        { key: "org_create", value: "organization create mail", disable: false },
        { key: "org_reset_password", value: "Organization reset password mail", disable: false },
        { key: "org_login_otp", value: "Organization login otp mail", disable: false },
        { key: "org_login_reotp", value: "Organization login re-send otp mail", disable: false },
        { key: "user_create", value: "user create mail", disable: false },
        { key: "user_reset_password", value: "user reset password mail", disable: false },
        { key: "user_login_otp", value: "User login otp mail", disable: false },
        { key: "user_login_reotp", value: "User login re-send otp mail", disable: false },
        { key: "alert_notification", value: "alert notification mail", disable: false },
    ]

    public static moduleList = {
        userModule: 'User Management',
        orgModule: 'Organization Mgmt',
        groupModule: 'Groups',
        FBModule: 'Node & SD-WAN Management',
        emailModule: 'Email Management',
        configModule: 'General Configuration',
        accessModule: 'Module Access',
        brandModule: 'Branding',
        notificationModule: 'Notification Templates',
        alertModule: 'Alert',
        illuminateModule: 'Illuminate',
    }

    public static socketEmitEventList = {
        startDashboardBullMqEvent: "startDashboardBullMqEvent",
        stopDashboardBullMqEvent: "stopDashboardBullMqEvent",
        startAdminDashboardEvent: "startAdminDashboardEvent",
        stopAdminDashboardEvent: "stopAdminDashboardEvent",
        startActivityLogsEvent: "startActivityLogsEvent",
        stopActivityLogsEvent: "stopActivityLogsEvent",
        startLatestNotificationEvent: "startLatestNotificationEvent",
        stopLatestNotificationEvent: "stopLatestNotificationEvent",
        startActiveBondListEvent: "startActiveBondListEvent",
        stopActiveBondListEvent: "stopActiveBondListEvent",
        startOnlineUserListEvent: "startOnlineUserListEvent",
        stopOnlineUserListEvent: "stopOnlineUserListEvent",
        startBondStatusEvent: "startBondStatusEvent",
        stopBondStatusEvent: "stopBondStatusEvent",
    }

    public static socketListenerEventList = {
        activityLogsEvent: "activityLogsEvent",
        latestNotificationEvent: "latestNotificationEvent",
        activeBondListEvent: "activeBondListEvent",
        onlineUserListEvent: "onlineUserListEvent",
        bondStatusEvent: "bondStatusEvent",
        adminDashboardEvent: "adminDashboardEvent",
    }

    public static qolKeyList = {
        nodeList: "nodeList",
        sharedNodeList: "sharedNodeList",
        sharedSDWANList: "sharedSDWANList",
        sharedQOSList: "sharedQOSList",
        sharedClassificationList: "sharedClassificationList",
        classificationList: "classificationList",
        qosList: "qosList",
        auditList: "auditList",
        customeMailList: "customeMailList",
        gpList: 'gpList',
        activeOrgList: 'activeOrgList',
        suspendOrgList: 'suspendOrgList',
        activeUserList: 'activeUserList',
        suspendUserList: 'suspendUserList',
        activePartnerList: 'activePartnerList',
        suspendPartnerList: 'suspendPartnerList',
        staffList: 'staffList',
    }

    public static graphColorPalette = [
        {
            name: 'Cool Blues',
            colorList: ['#48cae4', '#03045e', '#4952eb', '#023e8a', '#1a68d0', '#0096c7', '#04ccf1', '#0077b6', '#0798b2', '#00b4d8']
        },
        {
            name: 'Warm Vibes',
            colorList: ['#f77f00', '#d62828', '#df7bbb', '#929982', '#94d44c', '#dda85e', '#003049', '#0496ff', '#b03a3a', '#fcbf49']
        },
        // {
        //     name: 'Nature Greens',
        //     colorList: ['#1e6091', '#1a759f', '#184e77', '#34a0a4', '#d9ed92', '#99d98c', '#52b69a', '#168aad', '#76c893', '#b5e48c']
        // },
        {
            name: 'Vivid Brights',
            colorList: ['#FF5733', '#3357FF', '#FF33A6', '#A633FF', '#FFD700', '#48cae4', '#33FF57', '#FF7F50', '#762BE2', '#88E14C']
        },
        {
            name: 'Bold Contrast',
            colorList: ['#E53935', '#1E88E5', '#DF2E92', '#8E24AA', '#FFD311', '#00ACC1', '#15EC1E', '#FB8C00', '#3949AB', '#7CB342']
        },
    ]
}
