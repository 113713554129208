<div class="card mb-5 border-1 border border-top-0" *ngIf="permissionsObj?.view">
    <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50">
        <div class="card-title m-0">
            <h4 class="fw-bolder m-0 fs-4">Connected IP's</h4>
        </div>
        <div class="card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Add" *ngIf="permissionsObj?.update">
            <button type="button" class="btn btn-primary btn-style"
                [ngClass]="(latest_tuning && latest_tuning?.status == 'running')  || (bondPermission && bondPermission.isUpdateDisable) ? 'disable-tune':''"
                (click)="(latest_tuning && latest_tuning?.status == 'running')  || (bondPermission && bondPermission.isUpdateDisable) ? '': editIp('')">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Add Connected IP
            </button>
        </div>
    </div>

    <div class="card-body p-2">
        <div class="row">
            <div class="col-md-12 col-lg-12 mb-8 mt-0">
                <div *ngIf="permissionsObj?.view" class="table-responsive border">
                    <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                        <thead class="table-light">
                            <tr class="fw-bolder text-dark fs-7">
                                <th class="py-4 min-w-75px w-75px"> ID </th>
                                <th class="py-4 min-w-100px w-100px">Enabled </th>
                                <th class="py-4 min-w-300px w-300px">IP </th>
                                <th class="py-4 min-w-300px w-300px">Interface </th>
                                <th class="py-4">Routing </th>
                                <th class="py-4">Note </th>
                                <th class="py-4 min-w-50px w-50px text-center">
                                    <span *ngIf="(permissionsObj.update || permissionsObj.delete)">Actions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody
                            *ngIf="!(sharedService?.Loader?.isIPLoader) && allConnectedIPS && allConnectedIPS.length > 0">
                            <tr *ngFor="let connectedIPS of allConnectedIPS;">
                                <td> {{ connectedIPS.id }} </td>
                                <td class="ps-8">
                                    <a *ngIf="connectedIPS.enabled" title="true">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/check.svg'"
                                            class="svg-icon svg-icon-3">
                                        </span>
                                    </a>
                                    <a *ngIf="!connectedIPS.enabled" title="false">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/close.svg'"
                                            class="svg-icon svg-icon-4 svg-icon-danger">
                                        </span>
                                    </a>
                                </td>
                                <td>
                                    {{ connectedIPS?.use_ipv6_link_local_address ? 'IPv6 link local' :
                                    connectedIPS?.ip}}
                                </td>
                                <td> {{ connectedIPS.ifname }} </td>
                                <td>
                                    <span *ngIf="connectedIPS.aggregator_routing_decision
                                        && !connectedIPS.include_private_wan"> Aggregator </span>
                                    <span *ngIf="connectedIPS.include_private_wan"> Private WAN </span>
                                    <span *ngIf="connectedIPS.aggregator_routing !== 'always'  && !connectedIPS.include_private_wan
                                        && !connectedIPS.aggregator_routing_decision"> Local </span>
                                </td>
                                <td> {{ connectedIPS?.note ? connectedIPS?.note : '' }} </td>
                                <td class="text-center" *ngIf="(permissionsObj.update || permissionsObj.delete)">
                                    <a title="Click to view Action" [matMenuTriggerFor]="actionMenu"
                                        *ngIf="!(latest_tuning && latest_tuning?.status == 'running')"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <a title="Disable" *ngIf="latest_tuning && latest_tuning?.status == 'running'"
                                        class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm h-30px w-30px disable-tune">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen052.svg'"
                                            class="svg svg-icon svg-transparent">
                                        </span>
                                    </a>
                                    <mat-menu #actionMenu="matMenu">
                                        <button mat-menu-item class="menu-list-block" title="Edit"
                                            [disabled]="permissionsObj.update ? false: true" title="Edit"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.update  ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable)  || !permissionsObj.update ? '': editIp(connectedIPS)">
                                            <div class="clone-icon">
                                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                                    class="svg svg-icon svg-icon-warning">
                                                </span>
                                            </div>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item class="menu-list-block" title="Delete"
                                            [disabled]="permissionsObj.delete ? false: true" title="Delete"
                                            [ngClass]="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? 'disable-tune':''"
                                            (click)="(bondPermission && bondPermission.isUpdateDisable) || !permissionsObj.delete ? '': deleteIp(connectedIPS,connectedIPS?.ifname)">
                                            <div>
                                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                                    class="svg svg-icon svg-icon-danger">
                                                </span>
                                            </div>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-preloader-table-list *ngIf="sharedService?.Loader?.isIPLoader"
                        [length]="2"></app-preloader-table-list>
                    <div *ngIf="!(sharedService?.Loader?.isIPLoader) && allConnectedIPS  && allConnectedIPS.length == 0"
                        class="text-center font16 mt15 mb15 pt-4">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                        </h3>
                    </div>
                </div>
                <div *ngIf="!permissionsObj?.view">
                    You have no permission to view the data. Kindly contact the authority.
                </div>
            </div>
        </div>
    </div>
</div>