import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConst } from '../shared/constants/app.constant';


const BACKEND_URL = environment.apiUrl + "/partnerPanel/groupPermissionPanel";
@Injectable({
  providedIn: 'root'
})
export class PartnerGroupPermissionService {

  mainId = localStorage.getItem('mainId');
  loginId = localStorage.getItem('ID');

  constructor(private http: HttpClient) { }
  getAllGroups(search: string,
    page: string,
    limit: number,
    name: any,
    sort: any
  ) {
    // let URL = BACKEND_URL + `/list`;

    let params = new HttpParams()
      .set('fname', search ? search : '')
      .set('email', search ? search : '').
      set('page', page)
      .set('pageSize', limit)
      .set('name', name ? name : 'createdAt')
      .set('sort', sort ? sort : -1)
    // .set('page', page)
    // .set('pageSize', limit)
    let loginId = this.loginId;
    if (!loginId) {
      let userIds = JSON.parse(localStorage.getItem('userIds') || '')
      loginId = userIds[userIds.length - 1];
    }
    return this.http.get<any>(BACKEND_URL + "/getGroup/" + this.mainId + "/" + loginId, { params });

  }

  getAllUsers() {
    let parentId;
    let url;

    //  if(Number(localStorage.getItem('role')) == AppConst.user){
    //     parentId = localStorage.getItem('ID');
    //     url = environment.apiUrl + `/partnerPanel/usersPanel/selectuser/${this.mainId}/${parentId}`;
    //   } else {
    url = environment.apiUrl + `/partnerPanel/usersPanel/selectuser/${this.mainId}`;
    // }
    return this.http.get<any>(url);
  }

  addNewGroup(body: any) {
    return this.http.post<any>(BACKEND_URL + "/addGroup/" + this.mainId + "/", body);
  }

  getAllPermissions() {
    return this.http.get<any>(BACKEND_URL + "/getPermission/" + this.mainId + "/",);

  }

  getGroupDetailsById(id: any) {
    // return this.http.get<any>(BACKEND_URL+"/getPermissionByGroup/"+id+"/"+this.mainId+"/");
    let loginId = this.loginId;
    let url;
    // if(Number(localStorage.getItem('role')) == AppConst.user){
    //   // loginId = localStorage.getItem('orgUserId');
    //   url = BACKEND_URL+"/getPermissionByGroup/"+id+"/"+this.mainId+"/"+loginId;
    // } else {
    url = BACKEND_URL + "/getPermissionByGroup/" + id + "/" + this.mainId + "/";
    // }
    return this.http.get<any>(url);
  }

  editGroup(id: any, body: any) {
    return this.http.put<any>(BACKEND_URL + "/updateGroup/" + id + "/" + this.mainId + "/", body);
  }

  deleteGroup(id: any) {
    return this.http.put<any>(BACKEND_URL + "/deleteGroup/" + id + "/" + this.mainId + "/", {});
  }

  getPermissionByGroup(id: any) {
    let loginId;
    let url;
    // if(Number(localStorage.getItem('role')) == AppConst.user){
    //   loginId = localStorage.getItem('ID');
    //   url = BACKEND_URL+"/getPermissionByGroup/"+id+"/"+this.mainId+"/"+loginId;
    // } else {
    url = BACKEND_URL + "/getPermissionByGroup/" + id + "/" + this.mainId + "/";
    // }
    return this.http.get<any>(url);
  }
}
