import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime } from 'rxjs/operators';
import { PartnerOrganizationManagementService } from 'src/app/services/partner-organization-management.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PaginationComponent } from '../../pagination/pagination.component';

@Component({
  selector: 'app-shared-bond-sharing',
  templateUrl: './shared-bond-sharing.component.html',
  styleUrls: ['./shared-bond-sharing.component.scss']
})
export class SharedBondSharingComponent implements OnInit, OnDestroy {
  @ViewChild(PaginationComponent) paginationComponent: PaginationComponent;
  searchControl: FormControl = new FormControl();
  allBonds: any[] = [];
  bondsList: any[] = [];
  searchBondList: any[] = [];
  apiURL: any = null;
  page: any = 1;
  limit: number = AppConst.pageSize;
  searchKey: any = '';
  sortBy: any;
  sortOrder: number = -1;
  isSearch: boolean = false;
  preLoaderScreen: boolean = false;

  constructor(private sharedService: SharedService,
    private cd: ChangeDetectorRef, private cookie: CookieService,
    private orgService: PartnerOrganizationManagementService,) { }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  ngOnDestroy(): void {
    this.sharedService.setQOLParams(AppConst.qolKeyList.sharedNodeList, {
      page: this.page,
      pageSize: this.limit,
      searchKey: this.searchKey,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    });
  }

  ngOnInit(): void {
    this.sharedService.hideLoader();
    if (this.cookie.check('api_url')) this.apiURL = this.cookie.get('api_url');
    if (this.apiURL) {
      this.setQolParams();
      this.getSharedBonds();
    }
    if (this.searchKey) {
      this.isSearch = true;
      this.searchControl.setValue(this.searchKey);
    }
    this.searchControl.valueChanges.pipe(debounceTime(900)).subscribe((data: any) => {
      if (!this.paginationComponent) new PaginationComponent(this.cd);
      if (data != null || data != undefined) {
        this.searchKey = data;
        if (data == "") this.isSearch = false;
        else this.isSearch = true;
        this.onSort(this.sortBy);
        if (this.paginationComponent) this.paginationComponent.getNext(1);
        // else {
        //   this.paginationComponent = new PaginationComponent(this.cd);
        //   this.searchControl.setValue('1');
        //   this.onResetFilter();
        // }
        this.cd.detectChanges();
      }
    });
  }

  setQolParams() {
    let qolParams = this.sharedService.getQOLParams(AppConst.qolKeyList.sharedNodeList);
    if (qolParams) {
      if (qolParams.page) this.page = qolParams.page;
      if (qolParams.pageSize) this.limit = qolParams.pageSize;
      if (qolParams.searchKey) this.searchKey = qolParams.searchKey;
      if (qolParams.sortBy) this.sortBy = qolParams.sortBy;
      if (qolParams.sortOrder) this.sortOrder = qolParams.sortOrder;
    }
  }

  getSharedBonds() {
    this.preLoaderScreen = true;
    this.orgService.shareList().subscribe((res: any) => {
      if (res.code == 200 || res.code == 201) {
        if (res.finalArray) this.allBonds = res?.finalArray;
        this.allBonds = this.allBonds.filter(bond => bond && bond.id);
        this.onSort(this.sortBy);
      } else this.sharedService.loggerError(res.message);
      this.preLoaderScreen = false;
      this.cd.detectChanges();
    }, (err) => {
      this.preLoaderScreen = false;
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  onRevoked(bondId: any, shareId: any) {
    let questionTitle = 'Revoke Shared Node';
    let text = "Are you sure? you are revoking this bonds from organization";
    let confirmButtonText = "Yes, Revoke it!";
    let payload = { "bondId": bondId }
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.orgService.revokeSharedAssets(shareId, payload).subscribe((res: any) => {
          if (res.code == 200 || res.code == 201) this.sharedService.loggerSuccess('bond has been revoked successfully');
          else this.sharedService.loggerError(res.message);
          this.sharedService.hideLoader();
          this.cd.detectChanges();
          this.getSharedBonds();
        }, (err) => {
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        });
      }
    });
  }

  receiveMessage(event: any) {
    this.limit = event.pageSize;
    this.page = event.pageIndex;
    if (this.isSearch) this.bondsList = this.searchBondList.slice(this.limit * (this.page - 1), this.limit * this.page);
    else this.bondsList = this.allBonds.slice(this.limit * (this.page - 1), this.limit * this.page);
  }

  onSort(event: any, isOrderChange: boolean = false) {
    if (this.allBonds?.length <= 0) return;
    let arrAllBonds = [];
    if (this.isSearch) {
      this.searchBondList = this.allBonds.filter(item => item?.name.toLowerCase().includes(this.searchKey.toLowerCase()));
      this.bondsList = this.searchBondList.slice(0, this.limit);
      arrAllBonds = this.searchBondList;
    } else {
      this.searchBondList = [];
      arrAllBonds = this.allBonds;
    }
    if (event) {
      if (this.sortBy == event) this.sortOrder = this.sortOrder == 1 ? -1 : 1;
      else this.sortOrder = 1;
      this.sortBy = event;
      if (!isOrderChange) this.sortOrder = this.sortOrder == 1 ? -1 : 1;
      if (this.sortOrder == 1) {
        if (this.sortBy == "id") arrAllBonds.sort((a, b) => a?.id - b?.id);
        if (this.sortBy == "organizationName") arrAllBonds.sort((a, b) => a?.organizationName.localeCompare(b?.organizationName));
        if (this.sortBy == "space") arrAllBonds.sort((a, b) => a?.space?.name.localeCompare(b?.space?.name)); // sort by profile in ascending order
        if (this.sortBy == "name") arrAllBonds.sort((a, b) => a?.name.localeCompare(b?.name));
      }
      if (this.sortOrder == -1) {
        if (this.sortBy == "id") arrAllBonds.sort((a, b) => b?.id - a?.id);
        if (this.sortBy == "organizationName") arrAllBonds.sort((a, b) => b?.organizationName.localeCompare(a?.organizationName));
        if (this.sortBy == "space") arrAllBonds.sort((a, b) => b?.space?.name.localeCompare(a?.space?.name)); // sort by profile in ascending order
        if (this.sortBy == "name") arrAllBonds.sort((a, b) => b?.name.localeCompare(a?.name));
      }
    }
    if (this.page) {
      if (arrAllBonds?.length > this.limit) this.bondsList = arrAllBonds.slice(this.limit * (this.page - 1), this.limit * this.page);
      else this.bondsList = arrAllBonds;
    } else this.bondsList = arrAllBonds.slice(0, this.limit);
    this.cd.detectChanges();
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) {
      event.preventDefault();
    }
  }

  onResetFilter() {
    if (!this.searchControl.value) return;
    this.searchControl.patchValue('');
    // this.onReset.emit();
  }
}
