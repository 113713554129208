<!--begin::Form-->
<form class="form w-100 login_signin_form" [formGroup]="otpForm" novalidate="novalidate" id="kt_login_signin_form"
    (ngSubmit)="submit()">
    <div class="text-center mb-5">
        <div class="mb-1 fw-bolder fs-4 text-dark d-flex align-items-center  justify-content-center">
            <img src="./assets/media/img/gmail.png" alt="" class="max-w-40" style="max-width: 20px; margin-right:10px;">
            <h3 class="fw-bold fs-3 mb-0">Email OTP</h3>
        </div>
        <p class="mb-0 fw-bold">Enter the OTP you received in Mail </p>
    </div>
    <div class="fv-row mb-6">
        <div class="d-flex justify-content-between mt-5">
            <div class="d-flex flex-stack">
                <label class="form-label text-dark fs-6 mb-0 fw-bold">OTP</label>
            </div>
            <div *ngIf="showCounter">
                <h4 class="mb-0">
                    {{counter | formatTime}}
                </h4>
            </div>
        </div>
        <div class="form-group inputtext-block mb-5">
            <input class="form-control form-control-lg form-control-solid" [type]="'text'" autocomplete="off"
                formControlName="otp" [ngClass]="{ 'is-invalid': otpForm.controls['otp'].invalid }" />
            <small class="form-invalid-message">
                <span class="text-danger" *ngIf="controlHasError('required', 'otp')">
                    OTP is required
                </span>
                <span class="text-danger"
                    *ngIf="controlHasError('minlength', 'otp') || controlHasError('maxlength', 'otp')">
                    OTP should be 6 characters
                </span>
            </small>
        </div>
    </div>
    <div class="text-center d-flex justify-content-between">
        <div class="d-flex flex-stack mb-2">
            <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary br-6 w-100 mb-0"
                [disabled]="otpForm.invalid">
                <ng-container *ngIf="isLoading$ | async">
                    <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <!-- <span class="spinner-border spinner-border-sm align-middle ms-2"></span> -->
                    </span>
                </ng-container>
                <ng-container *ngIf="!(isLoading$ | async)">
                    <span class="indicator-label">Verify</span>
                </ng-container>
            </button>
        </div>
        <div>
            <button [disabled]="!showResend" type="button" id="kt_sign_in_submit_0"
                class="btn btn-lg btn-primary br-6 w-100 mb-0">
                <span class="indicator-label" (click)="resendOTP()">Resend OTP</span>
            </button>
        </div>
    </div>
</form>