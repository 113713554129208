import { ChangeDetectorRef, Injectable } from '@angular/core';
import { BrandSettingModel } from 'src/app/models/brand-setting.model';
import { AuthService } from 'src/app/modules/shared/auth';
import { BrandingService } from 'src/app/services/branding.service';
import { ProfileService } from 'src/app/services/profile.service';
import { CookieComponent } from 'src/app/_metronic/kt/components';
import { LayoutService } from 'src/app/_metronic/layout';
import { DefaultLayoutConfig, ILayout } from 'src/app/_metronic/layout/core/default-layout.config';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class SharedBrandSettingService {

  defaultConfigs: any;
  fieldsToUpdate: Partial<ILayout>;
  leftMenuflag: boolean;
  topMenuflag: boolean;
  state: any;
  navbgColour: any = '#000000';
  navLinkColour: any = '#000000';
  navLinkHoverColour: any = '#000000'
  navLinkActiveColour: any = '#000000';
  navLinkbackgroundColour: any = '#000000';
  brandingTextColour: any = '#000000';

  textColour: any = '#000000'
  linkColour: any = '#000000';
  linkHoverColour: any = '#000000';
  successColour: any = '#000000';
  infoColour: any = '#000000';
  warningColour: any = '#000000';
  dangerColour: any = '#000000';
  bodyBackgroundColour: any = '#000000';
  buttonPrimaryColour: any = '#0095e8';
  buttonTextColour: any = '#ffffffff';
  buttonSecondaryColour: any = '#6c757d';
  buttonDangerColour: any = '#dd3333';
  loginPageUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  faviconUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  navBarUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  menu: any = 1;
  loginPageAPIUrl: any;
  navBarAPIUrl: any;
  faviconAPIUrl: any;
  customCSSText: any = '';
  brandingId: any;
  brandSettings: any;
  isPreview: boolean = false;
  apiBrandingData: any;



  constructor(private layout: LayoutService,
    private authService: AuthService,
    private brandSettingService: BrandingService,
    private sharedService: SharedService,) { }


  getDefaultBrandSettings(viewURL: any) {
    if (!CookieComponent.get('preview')) {
      this.brandSettingService.getBrandSettingData(viewURL).subscribe(
        (res: any) => {
          this.brandSettings = res.data;
          this.brandingId = res.data._id;
          if (this.brandSettings.navbgColour) this.navbgColour = this.brandSettings.navbgColour;
          if (this.brandSettings.navLinkColour) this.navLinkColour = this.brandSettings.navLinkColour;
          if (this.brandSettings.navLinkHoverColour) this.navLinkHoverColour = this.brandSettings.navLinkHoverColour;
          if (this.brandSettings.navLinkActiveColour) this.navLinkActiveColour = this.brandSettings.navLinkActiveColour;
          if (this.brandSettings.navLinkbackgroundColour) this.navLinkbackgroundColour = this.brandSettings.navLinkbackgroundColour;
          if (this.brandSettings.brandingTextColour) this.brandingTextColour = this.brandSettings.brandingTextColour;

          if (this.brandSettings.body.bodyBackgroundColour) this.bodyBackgroundColour = this.brandSettings.body.bodyBackgroundColour;
          if (this.brandSettings.body.textColour) this.textColour = this.brandSettings.body.textColour;
          if (this.brandSettings.body.linkColour) this.linkColour = this.brandSettings.body.linkColour;
          if (this.brandSettings.body.linkHoverColour) this.linkHoverColour = this.brandSettings.body.linkHoverColour;
          if (this.brandSettings.body.buttonPrimaryColour) this.buttonPrimaryColour = this.brandSettings.body.buttonPrimaryColour;
          if (this.brandSettings.body.buttonSecondaryColour) this.buttonSecondaryColour = this.brandSettings.body.buttonSecondaryColour;
          if (this.brandSettings.body.buttonDangerColour) this.buttonDangerColour = this.brandSettings.body.buttonDangerColour;
          if (this.brandSettings.body.buttonTextColour) this.buttonTextColour = this.brandSettings.body.buttonTextColour;
          if (this.brandSettings.body.successColour) this.successColour = this.brandSettings.body.successColour;
          if (this.brandSettings.body.infoColour) this.infoColour = this.brandSettings.body.infoColour;
          if (this.brandSettings.body.warningColour) this.warningColour = this.brandSettings.body.warningColour;
          if (this.brandSettings.body.dangerColour) this.dangerColour = this.brandSettings.body.dangerColour;
          this.loginPageAPIUrl = this.brandSettings.loginPageImg;
          this.faviconAPIUrl = this.brandSettings.favicon;
          this.navBarAPIUrl = this.brandSettings.navbarImg;
          this.menu = this.brandSettings.menu;
          this.customCSSText = this.brandSettings.customCss;

          let obj1 = {
            navbgColour: this.navbgColour,
            navLinkColour: this.navLinkColour,
            navLinkHoverColour: this.navLinkHoverColour,
            navLinkActiveColour: this.navLinkActiveColour,
            navLinkbackgroundColour: this.navLinkbackgroundColour,
            brandingTextColour: this.brandingTextColour,
            bodyBackgroundColour: this.bodyBackgroundColour,
            textColour: this.textColour,
            linkColour: this.linkColour,
            linkHoverColour: this.linkHoverColour,
            buttonPrimaryColour: this.buttonPrimaryColour,
            buttonSecondaryColour: this.buttonSecondaryColour,
            buttonDangerColour: this.buttonDangerColour,
            buttonTextColour: this.buttonTextColour,
            successColour: this.successColour,
            infoColour: this.infoColour,
            warningColour: this.warningColour,
            dangerColour: this.dangerColour
          }

          //   this.authService.navBarImage.next(this.navBarAPIUrl);
          this.showHeader();
          this.setCSSProperties(obj1);
          this.setLayoutConfig();
          // this.cd.detectChanges();
          this.sharedService.hideLoader();
          window.location.reload();
        }
      );
    }
    else {
      this.setBrandSettings();
    }

  }

  deleteCookies() {
    CookieComponent.delete('preview');
    CookieComponent.delete('brandSetting');
  }

  setLayoutConfig() {
    if (this.menu == 1) {
      this.fieldsToUpdate = {
        header: {
          ...DefaultLayoutConfig.header,
          left: 'page-title',
          display: false
        },
        aside: {
          ...DefaultLayoutConfig.aside,
          display: true
        },
      }
      // this.authService.showHeaderSubject.next(false);
    }
    else {
      this.fieldsToUpdate = {
        header: {
          ...DefaultLayoutConfig.header,
          left: 'menu',
          display: true
        },
        aside: {
          ...DefaultLayoutConfig.aside,
          display: false
        },
      }
      // this.authService.showHeaderSubject.next(true);
    }
    this.layout.setConfig(this.fieldsToUpdate);
  }

  setCSSProperties(obj: any) {

    // this.setCssProperty('--background_colour', obj.navbgColour);
    this.setCssProperty('--navbgColour', obj.navbgColour);
    this.setCssProperty('--navLinkColour', obj.navLinkColour);
    this.setCssProperty('--navLinkbackgroundColour', obj.navLinkbackgroundColour);
    this.setCssProperty('--navLinkHoverColour', obj.navLinkHoverColour);
    this.setCssProperty('--navLinkActiveColour', obj.navLinkActiveColour);
    this.setCssProperty('--brandingTextColour', obj.brandingTextColour);
    this.setCssProperty('--bodyBackgroundColour', obj.bodyBackgroundColour);
    this.setCssProperty('--textColour', obj.textColour);
    this.setCssProperty('--linkColour', obj.linkColour);
    this.setCssProperty('--linkHoverColour', obj.linkHoverColour);
    this.setCssProperty('--buttonPrimaryColour', obj.buttonPrimaryColour);
    this.setCssProperty('--buttonSecondaryColour', obj.buttonSecondaryColour);
    this.setCssProperty('--buttonDangerColour', obj.buttonDangerColour);
    this.setCssProperty('--buttonTextColour', obj.buttonTextColour);
    this.setCssProperty('--successColour', obj.successColour);
    this.setCssProperty('--infoColour', obj.infoColour);
    this.setCssProperty('--warningColour', obj.warningColour);
    this.setCssProperty('--dangerColour', obj.dangerColour);
  }


  setCssProperty(variable: string, value: any) {
    document.documentElement.style.setProperty(variable, value);
  }

  setBrandSettings() {
    if (CookieComponent.get('preview')) {
      let obj = JSON.parse(CookieComponent.get('brandSetting') + '')
      this.navbgColour = obj.navbgColour;
      this.navLinkColour = obj.navLinkColour;
      this.navLinkHoverColour = obj.navLinkHoverColour;
      this.navLinkActiveColour = obj.navLinkActiveColour;
      this.navLinkbackgroundColour = obj.navLinkbackgroundColour;
      this.brandingTextColour = obj.brandingTextColour;
      this.bodyBackgroundColour = obj.bodyBackgroundColour;
      this.textColour = obj.textColour;
      this.linkColour = obj.linkColour;
      this.linkHoverColour = obj.linkHoverColour;
      this.buttonPrimaryColour = obj.buttonPrimaryColour;
      this.buttonSecondaryColour = obj.buttonSecondaryColour;
      this.buttonDangerColour = obj.buttonDangerColour;
      this.buttonTextColour = obj.buttonTextColour;
      this.successColour = obj.successColour;
      this.infoColour = obj.infoColour;
      this.warningColour = obj.warningColour;
      this.dangerColour = obj.dangerColour;
      this.loginPageAPIUrl = obj.loginPageAPIUrl;
      // this.loginPageUrl = obj.loginPageAPIUrl;
      this.faviconAPIUrl = obj.faviconAPIUrl;
      // this.faviconUrl = obj.faviconAPIUrl;
      this.navBarAPIUrl = obj.navBarAPIUrl;
      // this.navBarUrl = obj.navBarAPIUrl;
      this.brandingId = obj.brandingId;
      this.customCSSText = obj.customCssText;
      this.menu = obj.menu;
      // this.authService.navBarImage.next(this.navBarAPIUrl);
      this.setCSSProperties(obj);
      this.deleteCookies();
      this.showHeader();
      //  this.cd.detectChanges();
    }
    return;
  }

  showHeader() {
    if (this.menu == 1) {
      this.authService.showHeaderSubject.next(false);
    } else {
      this.authService.showHeaderSubject.next(true);
    }
  }
}
