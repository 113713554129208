<form class="form w-100 login_signin_form" [formGroup]="loginForm" novalidate="novalidate" id="kt_login_signin_form"
  (ngSubmit)="submit()">
  <div class="text-center mb-5">
    <div class="fs-4 fw-bolder text-primary super-admin-text"> Super Admin </div>
    <div class="text-gray-400 fs-4 text-italic text-start">
      <small>
        Enter your email and password below.
      </small>
    </div>
  </div>
  <div class="fv-row mb-5">
    <div class="form-group inputtext-block mb-5">
      <label class="form-label fs-6 text-dark">Email address<span class="required-asterisk m-0">*</span></label>
      <input class="form-control form-control-lg form-control-solid" type="email" name="email" formControlName="email"
        autocomplete="off" [ngClass]="{
        'is-invalid': loginForm.controls['email'].invalid,
        'is-valid': loginForm.controls['email'].valid
      }" />
      <small class="form-invalid-message">
        <span class="text-success" *ngIf="isControlValid('email')">
          Email was entered correct
        </span>
        <span class="text-danger"
          *ngIf="!controlHasError('minlength', 'email') && !controlHasError('maxlength', 'email') && controlHasError('pattern', 'email')">
          E-mail Address is Invalid
        </span>
        <span class="text-danger" *ngIf="controlHasError('required', 'email')">
          Email is required
        </span>
        <span class="text-danger" *ngIf="controlHasError('minlength', 'email')">
          Email should have at least 3 characters
        </span>
        <span class="text-danger" *ngIf="controlHasError('maxlength', 'email')">
          Email should have maximum 50 characters
        </span>
      </small>
    </div>
  </div>
  <div class="fv-row mb-5">
    <div class="d-flex justify-content-between mt-5">
      <div class="form-group inputtext-block mb-5 w-100">
        <label class="form-label">Password <span class="required-asterisk m-0">*</span>
        </label>
        <div class="password-input-block">
          <input class="form-control form-control-lg form-control-solid" [type]="show_button ? 'text' : 'password'"
            [ngClass]="{ 'is-invalid': loginForm.controls['password'].invalid }" name="password" autocomplete="off"
            formControlName="password" />
          <i [class]="show_eye ? 'fa fa-eye password_hide_show' : 'fa fa-eye-slash password_hide_show'"
            (click)="showPassword()"></i>
        </div>
        <small class="form-invalid-message">
          <span class="text-danger" *ngIf="controlHasError('required', 'password')">
            Password is required
          </span>
          <span class="text-danger" *ngIf="!controlHasError('minlength', 'password') 
            && !controlHasError('maxlength', 'password') && controlHasError('pattern', 'password')">
            Password should have atleast 2 capital letters, 3 lower case letters, 2 numbers and 1 special character.
          </span>
          <span class="text-danger" *ngIf="controlHasError('maxlength', 'password')">
            Password should not exceed 40 characters.
          </span>
          <span class="text-danger" *ngIf="controlHasError('minlength', 'password')">
            Password should have at least 8 characters.
          </span>
        </small>
      </div>
    </div>
    <div class="d-flex my-5 align-items-center justify-content-between">
      <div class="form-check form-check-sm form-check-custom form-check-solid">
        <!-- <input class="form-check-input" type="checkbox" name="compression" id="">
        <label class="m-2 form-label"> Keep me signed in </label> -->
      </div>
      <div>
        <a (click)="OnForgotPassword()" style="float:right"
          class="link-primary cursor-pointer fs-7 fw-bolder text-primary mt-1 text-underline" id="kt_login_forgot">
          Forgot Password ?
        </a>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
    </div>
  </div>
  <div class="text-center">
    <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary bg-success text-white w-100 mb-5 br-6"
      [disabled]="loginForm.invalid || !isCaptcha">
      <ng-container *ngIf="isLoading$ | async">
        <span class="indicator-progress" [style.display]="'block'">
          Please wait...
          <!-- <span class="spinner-border spinner-border-sm align-middle ms-2"></span> -->
        </span>
      </ng-container>
      <ng-container *ngIf="!(isLoading$ | async)">
        <span class="indicator-label">Login</span>
      </ng-container>
    </button>
  </div>
</form>