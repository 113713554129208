<div class="menu-item px-5  mt-2">
    <h4 class="mb-0">
        Granted {{accessTimeForAdmin}} minutes of access.
    </h4>
</div>


<div class="separator my-2"></div>

<div class="menu-item px-5">
    <a (click)="revokePermission()" class="menu-link px-5">
        Revoke Permission
    </a>
</div>

<div class="menu-item px-5" [ngClass]="toolbarButtonMarginClass">
    <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass" data-kt-menu-trigger="click"
        data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
        Change Duration
    </div>
    <app-quick-links-inner></app-quick-links-inner>
    <!-- <span>
    </span> -->
</div>