import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedNetworkCpeAddComponent } from './shared-network-cpe-add/shared-network-cpe-add.component';
@Component({
  selector: 'app-shared-network-cpe',
  templateUrl: './shared-network-cpe.component.html',
  styleUrls: ['./shared-network-cpe.component.scss']
})
export class SharedNetworkCpeComponent implements OnInit {
  authService = AuthService;
  latest_tuning: any;
  bondPermission: any;
  isEdit: boolean = false;

  @Input() allCpe: any[] = [];
  @Input() bondId: any;
  @Input() permissionsObj: any;

  @Output() getCpe = new EventEmitter<any>();

  constructor(private cd: ChangeDetectorRef,
    private PartnerBondingService: PartnerBondingService,
    public sharedService: SharedService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.PartnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    })
    if (localStorage.getItem('bondPermission')) {
      let data = JSON.parse(localStorage.getItem('bondPermission') || '');
      if (data && data.id == this.bondId) this.bondPermission = data.permission;
      this.cd.detectChanges();
    }
  }

  deleteCpe(data: any) {
    let questionTitle = 'Are you sure you want to delete this ip?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"
    let reqObject = {
      method: "DELETE",
      url: `bonds/${this.bondId}/cpe_nat_ips/${data.id}/`,
      "data": {},
      type: "CPE NAT IP",
      bondName: localStorage.getItem('bondName'),
      bondId: this.bondId,
      ip: data?.ip,
      id: data?.id,
      enabled: data?.enabled,
    };
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.PartnerBondingService.deleteData(reqObject).subscribe((addRes) => {
          if (addRes.code == 204 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
          else this.sharedService.loggerError(addRes.message);
          this.sharedService.hideLoader();
          this.getCpe.emit();
          this.cd.detectChanges();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getCpe.emit();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  editcpe(data: any) {
    let modal = this.modalService.open(SharedNetworkCpeAddComponent, { size: 'lg' });
    if (data) {
      modal.componentInstance.bondsInfo = data;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.componentInstance.bondId = this.bondId;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getCpe.emit();
      this.cd.detectChanges();
    }, () => { });
  }
}
