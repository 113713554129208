<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Send Email</h4>
    <button type="button" class="close bg-black tect-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" class="text-white">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="sendEmailTemplateForm">
    <form [formGroup]="sendEmailTemplateForm" class="form form-label-right">
        <div class="form-group row">

            <!-- <div class="col-md-6 col-lg-6 mb-5">
                <label class="form-label">Template Name
                </label>
                <span class="required-asterisk">*</span>
                <input [readOnly]="isDisabled" formControlName="customMailType" type="text"
                    class="form-control form-control-lg form-control-solid" placeholder="Enter template name" />
            </div>
            <div class="invalid-feedback d-block" *ngIf="sendEmailTemplateForm.get('customMailType')?.invalid
            && sendEmailTemplateForm.get('customMailType')?.touched">
                Please add template name.
            </div> -->
            <!--
            <div class="col-md-6 col-lg-6 mb-5">
                <label class="form-label">Custom Email
                </label>
                <input type="text" formControlName="customEmail" (keyup.enter)="onEnter($event)"
                    class="form-control form-control-lg form-control-solid" name="fname"
                    placeholder="Enter Custom Email" />
                <div class="invalid-feedback d-flex" *ngIf="controlHasError('pattern', 'customEmail')">
                    E-mail Address is invalid.
                </div>
                <div class="invalid-feedback d-flex" *ngIf="isDuplicated">
                    E-mail Address is already there.
                </div>
                <div class="invalid-feedback d-flex" *ngIf="isNoValue">
                    Please add email address.
                </div>
            </div> -->

            <div class="row">

                <div class="col-md-12 col-lg-12 mb-7">
                    <label class="form-label"> Select Partner</label>
                    <span class="required-asterisk">*</span>

                    <ng-select formControlName="partnerIds" [multiple]="true" class="p-0 partner_multiselect">
                        <ng-option *ngFor="let recipient of partnerList" [value]="recipient._id"> {{recipient.fname + '
                            ' +
                            recipient.lname}}</ng-option>

                    </ng-select>
                    <div class="invalid-feedback d-block"
                        *ngIf="controlHasError('required', 'partnerIds') && !isNoOptionSelected">
                        Please select at least one partner.
                    </div>

                    <div class="invalid-feedback d-block" *ngIf="isNoOptionSelected">
                        Please select/add at least one email address to send an email.
                    </div>

                </div>


                <div class="col-md-12 mb-5">
                    <label class="form-label">Custom Email
                    </label>
                    <div class="chip-container">
                        <label class="chip mr-2" *ngFor="let item of emailItems;let i=index;">
                            <!--<i  class="fal fa-times icon-cursor"></i>-->
                            <a (click)="removeEmailItem(item)" class="text-black me-1 pe-1"
                                style="border-right: 1px solid #b8dbff;cursor: pointer;"> × </a>
                            {{item}}
                        </label>
                        <input (keyup.enter)="onEnter($event)" (keydown.backspace)="onEmailBackspace($event)"
                            class="email-input " type="text" id="email-input" placeholder="Type and Enter Email">
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="isInvalidEmail">
                        Please enter valid email.
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="isDuplicated">
                        Email address is already there.
                    </div>
                </div>
            </div>



            <!-- <div class="invalid-feedback d-block"
        *ngIf="sendEmailTemplateForm.get('customEmail')?.invalid
        && sendEmailTemplateForm.get('customEmail')?.touched">
           Email Id is invalid.
       </div> -->



        </div>

        <div class="form-group row mb-5">

            <div class="col-md-8 col-lg-8 mb-5">
                <label class="form-label">Subject
                </label>
                <span class="required-asterisk">*</span>
                <input formControlName="customMailSubject" type="text"
                    class="form-control form-control-lg form-control-solid" placeholder="Enter subject" />
            </div>
            <div class="invalid-feedback d-block" *ngIf="sendEmailTemplateForm.get('customMailSubject')?.invalid
            && sendEmailTemplateForm.get('customMailSubject')?.touched">
                Please add subject.
            </div>

            <div class="col-md-4 col-lg-4 mb-5">
                <label class="form-label">Foundation
                </label>
                <ng-select class="" [searchable]="true" [clearable]="true" placeholder="Foundation For Subject"
                    (change)="setSubjectFoundation($event)">
                    <ng-option *ngFor="let foundation of foundationArray" [value]="foundation.value">
                        {{foundation.label}}
                    </ng-option>
                </ng-select>

            </div>

        </div>

        <div class="row border-bottom border-top pt-5 pb-5 justify-content-between">
            <div class="col-md-1 col-lg-1 d-flex align-items-center">
                <h3 class="mb-0"> Body </h3>
            </div>

            <div class="col-md-4 col-lg-4">
                <ng-select class="" [searchable]="true" [clearable]="true" placeholder="Foundation For Body"
                    (change)="setEditorValueOnFoundationChange($event)">
                    <ng-option *ngFor="let foundation of foundationArray" [value]="foundation.value">
                        {{foundation.label}}
                    </ng-option>
                </ng-select>

            </div>
        </div>

        <div class="row">



            <div class="d-flex card mb-1 mb-xl-1">
                <div class="card-body p-0">

                    <div class="row mt-5">
                        <!-- <div class="col-md-8 col-lg-8 mb-5">
                       <ul class=" nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">

                       <li class="nav-item">
                           <a class="nav-link text-active-primary me-6 cursor-pointer"
                           [class.active]="currentEditor=='header'" (click)="currentEditor='header'">
                               Header
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link text-active-primary me-6 cursor-pointer"
                           [class.active]="currentEditor=='body'"  (click)="currentEditor='body'">
                               Body
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link text-active-primary me-6 cursor-pointer"
                           [class.active]="currentEditor=='footer'" (click)="currentEditor='footer'">
                               Footer
                           </a>
                       </li>

                   </ul>

                   </div> -->

                    </div>
                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="form-group row mb-5">
                                <!-- <div *ngIf="currentEditor=='header'">
                               <ckeditor formControlName="headerTemplate" [config]="editorConfig" [editor]="editor"
                                   (ready)="onReady($event)">
                               </ckeditor>
                           </div> -->

                                <div>
                                    <ckeditor formControlName="styleTemplate" [config]="editorConfig" [editor]="editor"
                                        (ready)="onReady($event)">
                                    </ckeditor>
                                </div>

                                <!-- <div *ngIf="currentEditor=='footer'">
                               <ckeditor formControlName="footerTemplate" [config]="editorConfig" [editor]="editor"
                                   (ready)="onReady($event)">
                               </ckeditor>
                           </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
    <button type="button" class="btn btn-primary"  data-bs-dismiss="modal">Submit</button> -->
    <button type="button" class="btn btn-primary" (click)="send()">Send</button>
</div>