import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NotificationTemplateModel } from '../models/notification-template.model';

const CACHE_SIZE = 1;

const BACKEND_URL = environment.apiUrl + "/notification";
@Injectable({
  providedIn: 'root'
})

export class NotificationTemplateService {

  private cache$: Observable<Array<any>>;
  search: string = '';
  status: string = '';
  page: string = '';
  limit: number = 0;

  constructor(private http: HttpClient) { }


  // caching Listing
  cacheList(
    search: string,
    status: string,
    page: string,
    limit: number
  ) {
    this.search == '' ? this.search = search : this.search = this.search;
    this.status == '' ? this.status = status : this.status = this.status;
    this.page == '' ? this.page = page : this.page = this.page;
    this.limit == null ? this.limit = limit : this.limit = this.limit;

    // console.log(this.cache$);
    if (this.search != search) {
      this.cache$ = this.getNotificationList(search, status, page, limit).pipe(
        shareReplay<any>(CACHE_SIZE)
      );
    }
    if (this.status != status) {
      this.cache$ = this.getNotificationList(search, status, page, limit).pipe(
        shareReplay<any>(CACHE_SIZE)
      );
    }
    if (this.page != page) {
      this.cache$ = this.getNotificationList(search, status, page, limit).pipe(
        shareReplay<any>(CACHE_SIZE)
      );
    }
    if (this.limit != limit) {
      this.cache$ = this.getNotificationList(search, status, page, limit).pipe(
        shareReplay<any>(CACHE_SIZE)
      );
    }
    if (!this.cache$) {
      this.cache$ = this.getNotificationList(search, status, page, limit).pipe(
        shareReplay<any>(CACHE_SIZE)
      );
    }

    return this.cache$;
  }

  getNotificationList(
    search: string,
    status: string,
    page: string,
    limit: number
  ) {
    let URL = BACKEND_URL + `/list`;

    let params = new HttpParams()
      .set('subject', search ? search : '')
      .set('page', page)
      .set('pageSize', limit)

    return this.http.get<NotificationTemplateModel>(URL, { params });
  }

  createNotificationTemplate(formData: NotificationTemplateModel) {
    return this.http.post<NotificationTemplateModel>(BACKEND_URL + `/add`, formData)
      .pipe(
        finalize(() => this.cacheList('', this.status, this.page, this.limit))
      );
  }

  deleteNotification(notificationID: string) {
    return this.http.delete(BACKEND_URL + `/delete/${notificationID}`)
      .pipe(
        finalize(() => this.cacheList('', this.status, this.page, this.limit))
      );
  }
}
