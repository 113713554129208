import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { PartnerBondNetworkDetailsService } from 'src/app/services/partner-bond-network-details.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'app-shared-network-cpe-add',
  templateUrl: './shared-network-cpe-add.component.html',
  styleUrls: ['./shared-network-cpe-add.component.scss']
})
export class SharedNetworkCpeAddComponent implements OnInit {
  configurationForm: FormGroup;
  errors: any;
  isEdit: any;
  bondsInfo: any;
  bondId: any
  submitted: any = false;
  isSpace: boolean = true;

  constructor(private partnerBondingService: PartnerBondingService,
    private partnerBondService: PartnerBondNetworkDetailsService,
    private fb: FormBuilder, private cd: ChangeDetectorRef, private cookie: CookieService,
    public activeModal: NgbActiveModal, private sharedService: SharedService) { }

  ngOnInit(): void {
    // this.getSuccess();
    if ((localStorage.getItem('homeSpace') == "0")) this.isSpace = false;
    if (this.isEdit) this.configurationForm = this.createConfigurationForm(this.bondsInfo);
    else this.configurationForm = this.createConfigurationForm();
  }

  createConfigurationForm(data: any = {}) {
    return this.fb.group({
      ip: [data.ip || ""],
      dest_nat_ip: [data.dest_nat_ip || ""],
      enabled: [data.enabled == false ? false : true],
    });
  }

  getSuccess() {
    this.partnerBondingService.successForConnectedIp$.subscribe((res: any) => {
      if (res && res.success) this.activeModal.close();
    });
  }

  onSave() {
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    let body = {
      ip: this.configurationForm.value.ip,
      dest_nat_ip: this.configurationForm.value.dest_nat_ip,
      enabled: this.configurationForm.value.enabled,
      bond: `${apiURL}bonds/${this.bondId}/`,
      bondName: localStorage.getItem('bondName'),
      bondId: this.bondId,
      type: 'CPE NAT IP'
    }
    if (this.isEdit) {
      let reqObject = {
        method: "PATCH",
        url: `bonds/${this.bondId}/cpe_nat_ips/${this.bondsInfo.id}/`,
        data: body
      }
      this.sharedService.showLoader();
      this.partnerBondingService.addData(reqObject).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) {
          this.partnerBondingService.successForCPE.next({ success: true })
          this.sharedService.loggerSuccess(addRes.message);
        } else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.partnerBondingService.successForCPE.next({ success: false })
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    } else {
      let url = `bonds/${this.bondId}/cpe_nat_ips/`
      this.sharedService.showLoader()
      this.partnerBondService.addReq(url, 'POST', body).subscribe((res: any) => {
        if (res.code == 201 || res.code == 200) {
          this.partnerBondingService.successForCPE.next({ success: true })
          this.sharedService.loggerSuccess(res.message);
        } else this.sharedService.loggerError(res.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' });
      }, (err: string) => { 
        try {
          this.errors = JSON.parse(err);
          this.partnerBondingService.successForCPE.next({ success: false })
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}
