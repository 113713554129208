import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-sdwan-listing',
  templateUrl: './shared-sdwan-listing.component.html',
  styleUrls: ['./shared-sdwan-listing.component.scss']
})
export class SharedSdwanListingComponent implements OnInit {
  spaceList: any[] = [];

  @Input() spaces: any;
  @Input() isSpacePreLoader: boolean = false;
  @Input() isSharedSpacePreLoader: boolean = false;

  constructor(private router: Router,
    private spaceService: PartnerSpaceService,
    private cd: ChangeDetectorRef,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.spaceList = this.spaces;
    this.cd.detectChanges();
  }

  ngOnChanges() {
    this.spaceList = this.spaces;
    this.cd.detectChanges();
  }

  onEdit(item: any) {
    // this.spaceService.spaceParentId.next({parentId:item.key})
    this.spaceService.setSpaceParentId(item.key);
    this.spaceService.setSpaceData(item);
    this.sharedService.setCipherText(item.key, 'space')
    this.cd.detectChanges();
    this.router.navigate([`/partner/sd-wan/sd-network/edit`], item?.isShared ? { queryParams: { isShared: true } } : {});
  }
}
