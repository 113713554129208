import { Component, HostBinding } from '@angular/core';
import { AuthService } from '../../../../../../modules/shared/auth';
import { SharedService } from '../../../../../../shared/services/shared.service';

@Component({
  selector: 'app-quick-links-inner',
  templateUrl: './quick-links-inner.component.html',
})
export class QuickLinksInnerComponent {
  @HostBinding('class') class =
    'menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px';
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  constructor(
    private auth: AuthService,
    private shared: SharedService
  ) { }

  setAdminPartnerAccess(value: any) {
    this.shared.showLoader();
    console.log(value);
    let data = {
      LoginID: localStorage.getItem('ID'),
      id: localStorage.getItem('mainId'),
      mainId: localStorage.getItem('mainId'),
      duration: value,
      role: localStorage.getItem('role')
    }
    this.auth.setPartnerLP(data).subscribe((res: any) => {
      console.log(res);
      if (res.code == 200) {
        this.auth.isShowTimerAccessSubject.next(false);
        this.auth.accessTimeSubject.next(value);
        this.shared.hideLoader();
        this.shared.loggerSuccess(res?.message);
      }
    })
  }
}
