<form class="form w-100" [formGroup]="tfaForm" novalidate="novalidate" id="kt_login_signin_form" (ngSubmit)="submit()">
    <div class="text-center mb-5">
        <div class="text-center">
            <div class="mb-1 fw-bolder fs-4 text-dark d-flex align-items-center  justify-content-center">
                <img src="./assets/media/img/google_authentication.png" alt="" class="max-w-40"
                    style="max-width: 40px; margin-right:5px;">
                <h3 class="fw-bold fs-3 mb-0">Google Authenticator</h3>
            </div>
            <p class="mb-0 fw-bold">Enter the OTP you received in Google Authenticator</p>
        </div>
        <span>
        </span>
    </div>
    <div class="fv-row mb-6">
        <div class="form-group inputtext-block mb-5">
            <input class="form-control form-control-lg form-control-solid" type="text" name="otp" autocomplete="off"
                formControlName="otp" [ngClass]="{ 'is-invalid': tfaForm.controls['otp'].invalid }" />
            <small class="form-invalid-message">
                <span class="text-danger" *ngIf="controlHasError('required', 'otp')">
                    OTP is required
                </span>
                <span class="text-danger"
                    *ngIf="controlHasError('minlength', 'otp') || controlHasError('maxlength', 'otp')">
                    OTP should be 6 characters
                </span>
            </small>
        </div>
        <div class="d-flex mt-5 mb-3 align-items-center justify-content-between">
            <div class="form-check form-check-sm form-check-custom form-check-solid">
            </div>
            <div>
                <a (click)="OnForgotPassword()" style="float:right"
                    class="link-primary cursor-pointer fs-7 fw-bolder text-primary mb-5 mt-1 text-underline">
                    Reset 2FA Code ?
                </a>
            </div>
        </div>
    </div>
    <div class="text-center">
        <div class="mb-0">
            <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary br-6 w-100 mb-0"
                [disabled]="tfaForm.invalid">
                <ng-container *ngIf="isLoading$ | async">
                    <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <!-- <span class="spinner-border spinner-border-sm align-middle ms-2"></span> -->
                    </span>
                </ng-container>
                <ng-container *ngIf="!(isLoading$ | async)">
                    <span class="indicator-label">Verify OTP</span>
                </ng-container>
            </button>
        </div>
    </div>
</form>