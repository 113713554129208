import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5exporting from "@amcharts/amcharts5/plugins/exporting";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PartnerBondNetworkDetailsService } from 'src/app/services/partner-bond-network-details.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AppConst } from "src/app/shared/constants/app.constant";
@Component({
  selector: 'app-shared-speed-test-view',
  templateUrl: './shared-speed-test-view.component.html',
  styleUrls: ['./shared-speed-test-view.component.scss']
})
export class SharedSpeedTestViewComponent implements OnInit, OnDestroy {
  colorPaletteList = AppConst.graphColorPalette;
  selectedColorPalette: any = null;
  public isCollapsed = false;
  public resultIsCollapsed = false;
  speedTestData: any;
  throughputChart: any;
  latencyChart: any;
  queueSizeChart: any;
  bonderCPUChart: any;
  aggregatorCPUChart: any;

  @Input() bondId: any;
  @Input() bonderId: any;
  @Input() speedTestId: any;

  @Output() navigateToSpeedTest = new EventEmitter<any>();
  @Output() onRepeat = new EventEmitter<any>();

  constructor(private cd: ChangeDetectorRef,
    private sharedService: SharedService,
    private partnerNetworkService: PartnerBondNetworkDetailsService) { }

  ngOnInit(): void {
    am5.addLicense("AM5C356006604");
    let graph_color_palate = localStorage.getItem('graph_color_palate');
    if (graph_color_palate) {
      this.colorPaletteList.forEach((color: any) => {
        if (color && color.name == graph_color_palate) this.selectedColorPalette = color;
      })
    }
    if (!this.selectedColorPalette || !this.selectedColorPalette.name) this.selectedColorPalette = null;
    if (this.speedTestId) this.getSpeedTestById(true);
    else this.navigateToSpeedTest.emit();
  }

  getSpeedTestById(isLoader: boolean = false) {
    if (isLoader) this.sharedService.showLoader();
    this.partnerNetworkService.getSpeedTestById(this.bondId, this.speedTestId)?.subscribe((res: any) => {
      if (res.data) this.speedTestData = res.data;
      if (isLoader) this.sharedService.hideLoader();
      this.cd.detectChanges();
      if (res.data && (res.data.status == 'running' || res.data.status == 'queued') && res.data.id == this.speedTestId && !res.data.error_message) this.getData()
      if (res.data && res.data.status == 'completed' && res.data.id == this.speedTestId) this.showChart();
    }, (err) => {
      this.sharedService.loggerError(err);
      if (isLoader) this.sharedService.hideLoader();
    });
  }

  getData() {
    setTimeout(() => { this.getSpeedTestById() }, 1000);
  }

  showChart() {
    if (this.speedTestData?.progress_results?.throughput && this.speedTestData?.progress_results?.throughput.length > 0)
      this.renderThroughputChart();
    if (this.speedTestData?.progress_results?.latency && this.speedTestData?.progress_results?.latency.length > 0)
      this.renderLatencyChart();
    if (this.speedTestData?.progress_results?.queue_size && this.speedTestData?.progress_results?.queue_size.length > 0)
      this.renderQueueSizeChart();
    if (this.speedTestData?.slave_cpu_load && ((this.speedTestData?.slave_cpu_load[0] && this.speedTestData?.slave_cpu_load[0].length > 0)
      || (this.speedTestData?.slave_cpu_load[1] && this.speedTestData?.slave_cpu_load[1].length > 0)))
      this.renderBonderCPUChart();
    // if (this.speedTestData?.master_cpu_load && ((this.speedTestData?.master_cpu_load[0] && this.speedTestData?.master_cpu_load[0].length > 0) ||
    //   (this.speedTestData?.master_cpu_load[1] && this.speedTestData?.master_cpu_load[1].length > 0)))
    //   this.renderAggregatorCPUChart();
  };

  renderThroughputChart() {
    if (this.throughputChart) this.throughputChart.dispose();
    this.throughputChart = am5.Root.new("throughputChart");
    this.throughputChart.setThemes([am5themes_Animated.new(this.throughputChart)]);
    let exporting = am5exporting.Exporting.new(this.throughputChart, {
      menu: am5exporting.ExportingMenu.new(this.throughputChart, {}),
      title: "Throughput Chart",
      pdfOptions: { addURL: false }
    });
    exporting.export('png');
    let data: any[] = [];
    let maxX = 0;
    let minX = 9999;
    this.speedTestData?.progress_results?.throughput.forEach((item: any) => {
      data.push({ x: Math.round(item[0] * 100) / 100, y: Math.round(item[1] * 100) / 100 });
      if (Math.round(item[0]) > maxX) maxX = Math.round(item[0]);
      if (Math.round(item[0]) < minX) minX = Math.round(item[0]);
    })

    let chart = this.throughputChart.container.children.push(am5xy.XYChart.new(this.throughputChart, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(this.throughputChart, {
      min: minX,
      max: maxX,
      strictMinMax: true,
      renderer: am5xy.AxisRendererX.new(this.throughputChart, { minGridDistance: 50 }),
      tooltip: am5.Tooltip.new(this.throughputChart, {})
    }));
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.throughputChart, {
      min: 0,
      strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(this.throughputChart, {})
    }));

    let series = chart.series.push(am5xy.LineSeries.new(this.throughputChart, {
      name: 'Throughput in Mbps',
      xAxis: xAxis,
      yAxis: yAxis,
      stacked: true,
      valueYField: 'y',
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.throughputChart, {
        pointerOrientation: "horizontal",
        labelText: "[bold]Throughput[/]\n{valueX}: {valueY}"
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      series.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
      series.set("fill", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
    }
    series.fills.template.setAll({ fillOpacity: 0.5, visible: true });
    series.strokes.template.setAll({ strokeWidth: 1.5 });
    series.data.setAll(data);
    series.appear(100);
    chart.appear(100, 10);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(this.throughputChart, { behavior: "none" }));
    cursor.lineY.set("visible", false);

    let legend = chart.children.push(am5.Legend.new(this.throughputChart, {}));
    legend.data.setAll(chart.series.values);
  }

  renderLatencyChart() {
    if (this.latencyChart) this.latencyChart.dispose();
    this.latencyChart = am5.Root.new("latencyChart");
    this.latencyChart.setThemes([am5themes_Animated.new(this.latencyChart)]);
    let exporting = am5exporting.Exporting.new(this.latencyChart, {
      menu: am5exporting.ExportingMenu.new(this.latencyChart, {}),
      title: "Latency Chart",
      pdfOptions: { addURL: false }
    });
    exporting.export('png');
    let data: any[] = [];
    let maxX = 0;
    let minX = 9999;
    this.speedTestData?.progress_results?.latency.forEach((item: any) => {
      data.push({ x: Math.round(item[0] * 100) / 100, y: Math.round(item[1] * 100) / 100 });
      if (Math.round(item[0]) > maxX) maxX = Math.round(item[0]);
      if (Math.round(item[0]) < minX) minX = Math.round(item[0]);
    })

    let chart = this.latencyChart.container.children.push(am5xy.XYChart.new(this.latencyChart, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(this.latencyChart, {
      min: minX,
      max: maxX,
      strictMinMax: true,
      renderer: am5xy.AxisRendererX.new(this.latencyChart, { minGridDistance: 50 }),
      tooltip: am5.Tooltip.new(this.latencyChart, {})
    }));
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.latencyChart, {
      renderer: am5xy.AxisRendererY.new(this.latencyChart, {})
    }));

    let series = chart.series.push(am5xy.LineSeries.new(this.latencyChart, {
      name: 'Latency in ms',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'y',
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.latencyChart, {
        pointerOrientation: "horizontal",
        labelText: "[bold]latency[/]\n{valueX}: {valueY}"
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      series.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
    }
    // series.fills.template.setAll({ fillOpacity: 0.5, visible: true });
    series.strokes.template.setAll({ strokeWidth: 2 });
    series.data.setAll(data);
    series.bullets.push(() => {
      var circle = am5.Circle.new(this.latencyChart, {
        radius: 4,
        fill: series.get("fill"),
        stroke: this.latencyChart.interfaceColors.get("background"),
        strokeWidth: 2
      });

      return am5.Bullet.new(this.latencyChart, {
        sprite: circle
      });
    });

    series.appear(100);
    chart.appear(100, 10);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(this.latencyChart, { behavior: "none" }));
    cursor.lineY.set("visible", false);

    let legend = chart.children.push(am5.Legend.new(this.latencyChart, {}));
    legend.data.setAll(chart.series.values);
  }

  renderQueueSizeChart() {
    if (this.queueSizeChart) this.queueSizeChart.dispose();
    this.queueSizeChart = am5.Root.new("queueSizeChart");
    this.queueSizeChart.setThemes([am5themes_Animated.new(this.queueSizeChart)]);
    let exporting = am5exporting.Exporting.new(this.queueSizeChart, {
      menu: am5exporting.ExportingMenu.new(this.queueSizeChart, {}),
      title: "Send queue size",
      pdfOptions: { addURL: false }
    });
    exporting.export('png');
    let data: any[] = [];
    let maxX = 0;
    let minX = 9999;
    this.speedTestData?.progress_results?.queue_size.forEach((item: any) => {
      data.push({ x: Math.round(item[0] * 100) / 100, y: Math.round(item[1] * 100) / 100 });
      if (Math.round(item[0]) > maxX) maxX = Math.round(item[0]);
      if (Math.round(item[0]) < minX) minX = Math.round(item[0]);
    })

    let chart = this.queueSizeChart.container.children.push(am5xy.XYChart.new(this.queueSizeChart, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(this.queueSizeChart, {
      min: minX,
      max: maxX,
      strictMinMax: true,
      renderer: am5xy.AxisRendererX.new(this.queueSizeChart, { minGridDistance: 50 }),
      tooltip: am5.Tooltip.new(this.queueSizeChart, {})
    }));
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.queueSizeChart, {
      min: 0,
      strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(this.queueSizeChart, {})
    }));

    let series = chart.series.push(am5xy.LineSeries.new(this.queueSizeChart, {
      name: 'Queue size in Kbps',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'y',
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.queueSizeChart, {
        pointerOrientation: "horizontal",
        labelText: "[bold]Queue size[/]\n{valueX}: {valueY}"
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      series.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
    }
    // series.fills.template.setAll({ fillOpacity: 0.5, visible: true });
    series.strokes.template.setAll({ strokeWidth: 2 });
    series.data.setAll(data);
    series.bullets.push(() => {
      var circle = am5.Circle.new(this.queueSizeChart, {
        radius: 4,
        fill: series.get("fill"),
        stroke: this.latencyChart.interfaceColors.get("background"),
        strokeWidth: 2
      });

      return am5.Bullet.new(this.queueSizeChart, {
        sprite: circle
      });
    });

    series.appear(100);
    chart.appear(100, 10);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(this.queueSizeChart, { behavior: "none" }));
    cursor.lineY.set("visible", false);

    let legend = chart.children.push(am5.Legend.new(this.queueSizeChart, {}));
    legend.data.setAll(chart.series.values);
  }

  renderBonderCPUChart() {
    if (this.bonderCPUChart) this.bonderCPUChart.dispose();
    this.bonderCPUChart = am5.Root.new("bonderCPUChart");
    this.bonderCPUChart.setThemes([am5themes_Animated.new(this.bonderCPUChart)]);
    let exporting = am5exporting.Exporting.new(this.bonderCPUChart, {
      menu: am5exporting.ExportingMenu.new(this.bonderCPUChart, {}),
      title: "Bonder CPU usage Chart",
      pdfOptions: { addURL: false }
    });
    exporting.export('png');
    let maxX = 0;
    let minX = 9999;
    let master: any[] = [];
    this.speedTestData?.slave_cpu_load[0]?.forEach((item: any) => {
      master.push({ x: Math.round(item[0]), y: Math.round(item[1] * 100) / 100 });
      if (Math.round(item[0]) > maxX) maxX = Math.round(item[0]);
      if (Math.round(item[0]) < minX) minX = Math.round(item[0]);
    });
    let slave: any[] = [];
    this.speedTestData?.slave_cpu_load[1]?.forEach((item: any) => {
      slave.push({ x: Math.round(item[0]), y: Math.round(item[1] * 100) / 100 });
      if (Math.round(item[0]) > maxX) maxX = Math.round(item[0]);
      if (Math.round(item[0]) < minX) minX = Math.round(item[0]);
    });

    let chart = this.bonderCPUChart.container.children.push(am5xy.XYChart.new(this.bonderCPUChart, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(this.bonderCPUChart, {
      min: minX,
      max: maxX,
      strictMinMax: true,
      renderer: am5xy.AxisRendererX.new(this.bonderCPUChart, { minGridDistance: 50 }),
      tooltip: am5.Tooltip.new(this.bonderCPUChart, {})
    }));
    xAxis.data.setAll(master && master.length ? master : slave);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.bonderCPUChart, {
      min: 0,
      strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(this.bonderCPUChart, {})
    }));

    let masterSeries = chart.series.push(am5xy.LineSeries.new(this.bonderCPUChart, {
      name: 'master',
      xAxis: xAxis,
      yAxis: yAxis,
      stacked: true,
      valueYField: 'y',
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.bonderCPUChart, {
        pointerOrientation: "horizontal",
        labelText: "[bold]{valueX}[/]: {valueY}"
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      masterSeries.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
      masterSeries.set("fill", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
    }
    masterSeries.fills.template.setAll({ fillOpacity: 0.2, visible: true });
    masterSeries.strokes.template.setAll({ strokeWidth: 1.5 });
    masterSeries.data.setAll(master);
    masterSeries.appear(100);

    let slaveSeries = chart.series.push(am5xy.LineSeries.new(this.bonderCPUChart, {
      name: 'slave',
      xAxis: xAxis,
      yAxis: yAxis,
      stacked: true,
      valueYField: 'y',
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.bonderCPUChart, {
        pointerOrientation: "horizontal",
        labelText: "[bold]{valueX}[/]: {valueY}"
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      slaveSeries.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[1]));
      slaveSeries.set("fill", am5.Color.fromString(this.selectedColorPalette?.colorList[1]));
    }
    slaveSeries.fills.template.setAll({ fillOpacity: 0.5, visible: true });
    slaveSeries.strokes.template.setAll({ strokeWidth: 1.5 });
    slaveSeries.data.setAll(slave);
    slaveSeries.appear(100);
    chart.appear(100, 10);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(this.bonderCPUChart, { behavior: "none" }));
    cursor.lineY.set("visible", false);
    // let legend = chart.children.push(am5.Legend.new(this.bonderCPUChart, {}));
    // legend.data.setAll(chart.series.values);
  }

  renderAggregatorCPUChart() {
    if (this.aggregatorCPUChart) this.aggregatorCPUChart.dispose();
    this.aggregatorCPUChart = am5.Root.new("aggregatorCPUChart");
    this.aggregatorCPUChart.setThemes([am5themes_Animated.new(this.aggregatorCPUChart)]);
    let maxX = 0;
    let minX = 9999;
    let master: any[] = [];
    this.speedTestData?.master_cpu_load[0]?.forEach((item: any) => {
      master.push({ x: Math.round(item[0]), y: Math.round(item[1] * 100) / 100 });
      if (Math.round(item[0]) > maxX) maxX = Math.round(item[0]);
      if (Math.round(item[0]) < minX) minX = Math.round(item[0]);
    });
    let slave: any[] = [];
    this.speedTestData?.master_cpu_load[1]?.forEach((item: any) => {
      slave.push({ x: Math.round(item[0]), y: Math.round(item[1] * 100) / 100 });
      if (Math.round(item[0]) > maxX) maxX = Math.round(item[0]);
      if (Math.round(item[0]) < minX) minX = Math.round(item[0]);
    });

    let chart = this.aggregatorCPUChart.container.children.push(am5xy.XYChart.new(this.aggregatorCPUChart, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(this.aggregatorCPUChart, {
      min: minX,
      max: maxX,
      strictMinMax: true,
      renderer: am5xy.AxisRendererX.new(this.aggregatorCPUChart, { minGridDistance: 50 }),
      tooltip: am5.Tooltip.new(this.aggregatorCPUChart, {})
    }));
    xAxis.data.setAll(master && master.length > 0 ? master : slave);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.aggregatorCPUChart, {
      min: 0,
      strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(this.aggregatorCPUChart, {})
    }));

    let masterSeries = chart.series.push(am5xy.LineSeries.new(this.aggregatorCPUChart, {
      name: 'master',
      xAxis: xAxis,
      yAxis: yAxis,
      stacked: true,
      valueYField: 'y',
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.aggregatorCPUChart, {
        pointerOrientation: "horizontal",
        labelText: "[bold]{valueX}[/]: {valueY}"
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      masterSeries.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
      masterSeries.set("fill", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
    }
    masterSeries.fills.template.setAll({ fillOpacity: 0.2, visible: true });
    masterSeries.strokes.template.setAll({ strokeWidth: 1.5 });
    masterSeries.data.setAll(master);
    masterSeries.appear(100);

    let slaveSeries = chart.series.push(am5xy.LineSeries.new(this.aggregatorCPUChart, {
      name: 'slave',
      xAxis: xAxis,
      yAxis: yAxis,
      stacked: true,
      valueYField: 'y',
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.aggregatorCPUChart, {
        pointerOrientation: "horizontal",
        labelText: "[bold]slave[/]\n{valueX}: {valueY}"
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      slaveSeries.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[1]));
      slaveSeries.set("fill", am5.Color.fromString(this.selectedColorPalette?.colorList[1]));
    }
    slaveSeries.fills.template.setAll({ fillOpacity: 0.2, visible: true });
    slaveSeries.strokes.template.setAll({ strokeWidth: 1.5 });
    slaveSeries.data.setAll(slave);
    slaveSeries.appear(100);
    chart.appear(100, 10);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(this.aggregatorCPUChart, { behavior: "none" }));
    cursor.lineY.set("visible", false);
    // let legend = chart.children.push(am5.Legend.new(this.aggregatorCPUChart, {}));
    // legend.data.setAll(chart.series.values);
  }

  onRepeatClick() {
    if (this.speedTestData && this.speedTestData.id) {
      this.sharedService.showLoader;
      this.onRepeat.emit(this.speedTestData);
    }
  }

  navigateSpeed() {
    this.navigateToSpeedTest.emit();
  }

  getSubmitDate(data: any) {
    if (!data) return
    let date = data.split('T');
    let time = date[1];
    time = time.split('.')[0];
    return `${date[0]} ${time}`;
  }

  ngOnDestroy(): void {
    if (this.throughputChart) this.throughputChart.dispose();
    if (this.latencyChart) this.latencyChart.dispose();
    if (this.queueSizeChart) this.queueSizeChart.dispose();
    if (this.bonderCPUChart) this.bonderCPUChart.dispose();
    if (this.aggregatorCPUChart) this.aggregatorCPUChart.dispose();
    this.speedTestId = null;
  }
}
