import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConst } from '../shared/constants/app.constant';

const BACKEND_URL = environment.apiUrl + "/partnerPanel/organization";
const NEW_BACKEND_URL = environment.apiUrl + "/partnerPanel/usersPanel";

@Injectable({ providedIn: 'root' })
export class PartnerOrganizationManagementService {
  mainId: any = 0;
  loginId: any = 0;

  constructor(private http: HttpClient) {
    let mainId = localStorage.getItem('mainId');
    let loginId = localStorage.getItem('ID');
    this.mainId = mainId;
    this.loginId = (loginId) ? loginId : '0';
  }
  onInit() { }

  getAllOrganisations(search: string,
    page: string,
    limit: number,
    name: any,
    sort: any,
    status: any
  ) {
    this.mainId = localStorage.getItem('mainId')
    this.loginId = localStorage.getItem('ID')
    let params = new HttpParams()
      .set('fname', search ? search : '')
      .set('status', status ? status : '')
      .set('page', page)
      .set('pageSize', limit)
      .set('name', name ? name : 'createdAt')
      .set('sort', sort ? sort : -1)
    return this.http.get<any>(BACKEND_URL + "/organizationList/" + this.mainId + "/" + this.loginId, { params });
  }

  getAllActiveOrg() {
    let mainId = localStorage.getItem('mainId');
    let loginId = localStorage.getItem('ID')
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/allorganizationList/" + mainId + "/" + loginId);
  }

  getAllUsers() {
    let parentId;
    let url;
    //  if (Number(localStorage.getItem('role')) == AppConst.user) {
    //   parentId = localStorage.getItem('ID');
    //   url = environment.apiUrl + `/partnerPanel/usersPanel/selectuser/${this.mainId}/${parentId}`;
    // } else {
    //   url = environment.apiUrl + `/partnerPanel/usersPanel/selectuser/${this.mainId}`;
    // }
    url = environment.apiUrl + `/partnerPanel/usersPanel/selectuser/${this.mainId}`; return this.http.get<any>(url);
  }

  getOrganizationById(id: any) {
    return this.http.get<any>(BACKEND_URL + "/getOrganizationById/" + id + "/" + this.mainId + "/");
  }

  suspendOrganization(id: any) {
    return this.http.put<any>(BACKEND_URL + "/deleteOrganization/" + id + "/" + this.mainId + "/", {});
  }

  deleteUser(id: any) {
    return this.http.delete<any>(`${BACKEND_URL}/delete/${id}/${this.mainId}`);
  }

  getAllModules() {
    return this.http.get<any>(BACKEND_URL + "/allModuleAccess/" + this.mainId + "/");
  }

  addOrganization(body: any) {
    let payload: any = body;
    return this.http.post<any>(NEW_BACKEND_URL + "/addUserOrg/" + this.mainId + "/" + this.loginId, payload);
  }

  updateOrganization(userId: any, organizationId: any, body: any) {
    return this.http.put<any>(NEW_BACKEND_URL + "/updateUserOrg/" + userId + "/" + organizationId + "/" + this.mainId, body);
  }

  revokeSharedAssets(shareId: any, body: any) {
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    return this.http.put<any>(BACKEND_URL + "/revokeSharedAssets/" + shareId + "/" + partnerId, body);
  }

  sharedBond(orgId: any, body: any) {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.post<any>(BACKEND_URL + "/shareBond/" + loginId + "/" + orgId + "/" + partnerId, body);
  }

  shareList() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/haveSharedBondList/" + loginId + "/" + partnerId);
  }

  sharedBondList() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/sharedBondList/" + loginId + "/" + partnerId);
  }

  sharedSdwan(orgId: any, body: any) {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.post<any>(BACKEND_URL + "/shareSdwan/" + loginId + "/" + orgId + "/" + partnerId, body);
  }

  sharedSdwanEdit(orgId: any, body: any) {
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    return this.http.put<any>(BACKEND_URL + "/editSharedSdwan/" + orgId + "/" + partnerId, body);
  }

  sharedSdwanList() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/sharedSdwanList/" + loginId + "/" + partnerId);
  }

  haveShareSdwanList() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/haveSharedSdwanList/" + loginId + "/" + partnerId);
  }

  sharedClassification(orgId: any, body: any) {
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    let loginId = localStorage.getItem('ID');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.post<any>(BACKEND_URL + "/shareClassification/" + loginId + "/" + orgId + "/" + partnerId, body);
  }

  sharedClassificationList() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/sharedClassificationList/" + loginId + "/" + partnerId);
  }

  haveShareClassificationList() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/haveSharedClassificationList/" + loginId + "/" + partnerId);
  }

  sharedQOS(orgId: any, body: any) {
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    let loginId = localStorage.getItem('ID');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.post<any>(BACKEND_URL + "/shareQOS/" + loginId + "/" + orgId + "/" + partnerId, body);
  }

  sharedQOSList() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/sharedQOSList/" + loginId + "/" + partnerId);
  }

  haveShareQOSList() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.get<any>(BACKEND_URL + "/haveSharedQOSList/" + loginId + "/" + partnerId);
  }


  saveGeoMap(body: any) {
    let partnerId = localStorage.getItem('mainId') || localStorage.getItem('rootID');
    if (!partnerId) return;
    return this.http.post<any>(BACKEND_URL + "/saveGeoMap/" + partnerId, body);
  }

  getGeoMap(body: any) {
    let partnerId = localStorage.getItem('mainId') || localStorage.getItem('rootID');
    if (!partnerId) return;
    return this.http.post<any>(BACKEND_URL + "/getGeoMap/" + partnerId, body);
  }

  getBondGeoMap(body: any) {
    let partnerId = localStorage.getItem('mainId') || localStorage.getItem('rootID');
    if (!partnerId) return;
    return this.http.post<any>(BACKEND_URL + "/getBondCoordinate/" + partnerId, body);
  }

  saveBondGeoMap(body: any) {
    let partnerId = localStorage.getItem('mainId') || localStorage.getItem('rootID');
    if (!partnerId) return;
    return this.http.post<any>(BACKEND_URL + "/saveBondCoordinate/" + partnerId, body);
  }

  getAuditList() {
    let partnerId = localStorage.getItem('mainId') || localStorage.getItem('rootID');
    return this.http.get<any>(BACKEND_URL + "/getAudit/" + partnerId);
  }

  getAllAuditList() {
    return this.http.get<any>(BACKEND_URL + "/getAllAudit");
  }

}
