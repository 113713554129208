import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-space-ip-delegate',
  templateUrl: './shared-space-ip-delegate.component.html',
  styleUrls: ['./shared-space-ip-delegate.component.scss']
})
export class SharedSpaceIpDelegateComponent implements OnInit {
merge:any;
delegate:any;
delete:any;
ipDetail:any;
parentId:any;
spaceList:any;
GroupAllocation:FormGroup;
delegated:any;
edit:any;
details: any;
detailsgroup: any;
detailUrl: "";
spaceDisabled:boolean;
errors:any={}
currData: any;

  constructor(private cd: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    private spaceService:PartnerSpaceService, 
    private sharedService: SharedService,
    private router : Router) { }

  ngOnInit(): void {
    // if(this.ipDetail){
    //   this.GroupAllocation=this.createForm(true, this.ipDetail);
    // }
    // else{
    //   this.GroupAllocation=this.createForm(false);
    // }
    // this.cd.detectChanges()
    // this.getSuccess()

    if(this.details){
      if(this.delegate)
        this.getDelegateGroup();
      else if(!this.delegate && !this.delete)
        this.getDelegateDetails();
    }
    else if(this.detailsgroup){
      this.getGroupDetails();
    }
    else if(this.merge){
      this.getMergedList();
    }
  }
  // getSuccess(){
  //   this.spaceService.successForDelegateIp$.subscribe(
  //     (res:any)=>{
  //       if(res){
  //         if(res.success)
  //         this.spaceService.successForDelegateIp.next({success:false})
  //         this.activeModal.close({});
  //       }
  //     }
  //   );
  // }

  dropDownArray:any = [];
  createForm(isData:boolean, data:any=[]){
    if(isData == true){
      return new FormGroup({
        network:new FormControl(data?.network || '') ,
        routing_group:new FormControl(this.currData?.space.name || '',),
        subnet:new FormControl(this.currData?.network || '',)
      })
    }
    else {
      this.dropDownArray = [];
      if(this.details.delegations.length > 0){
        this.dropDownArray = this.spaceList.filter((spc:any) => {
          return spc.key == this.details.delegations[0].space.key;
        });
      }
      return new FormGroup({
        network:new FormControl(null) ,
        routing_group:new FormControl(this.dropDownArray[0].key),
        subnet:new FormControl('')
      })
    }
  }
  onClose() {
    console.log("on close====")
    this.activeModal.close({ event: 'close' });
  }
  deleteRecord(){
    this.activeModal.close({event:'save'});
  }
  mergeRecord(){
    this.activeModal.close({event:'save'});
  }
  delegateRecord(){
    let url = this.dropDownArray[0].delegated_ip_allocations;
    let space = this.dropDownArray[0];
    let reqObj={
      "url": `${url}`,
      "method": "POST",
      "data":{
        "network":this.GroupAllocation.value.network,
        "parent_ip_allocation":`${this.details.url}`,
        "space":space
      }}
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.cd.detectChanges();
        this.activeModal.close();
      }
    },
    (err) => {
      this.errors = err;
      this.errors = {
        ...this.errors,
        ...JSON.parse(err)
      };

      this.cd.detectChanges();
    });
    // this.activeModal.close({event:'save', data: reqObj});
  }
  editRecord(){
    let reqObj={
      "url":`${this.currData.url}`,
      "method": "PATCH",
      "data":{
        "id":`${this.ipDetail.id}`,
        "network":this.GroupAllocation.value.subnet,
        "space":this.GroupAllocation.value.routing_group,
        "url":`${this.currData.url}`
      }
    }

    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.cd.detectChanges();
        this.activeModal.close();
      }
    },
    (err) => {
      this.errors = err;
      this.errors = {
        ...this.errors,
        ...JSON.parse(err)
      };
      this.cd.detectChanges();
    });
    this.activeModal.close({event:'save', data: reqObj});
  }

  getDelegateGroup(){
    // let dropDownArray:any = [];
    // if(this.details.delegations.length > 0){
    //   dropDownArray = this.spaceList.filter((spc:any) => {
    //     return spc.key == this.details.delegations[0].space.key;
    //   });
    // }
    // this.GroupAllocation.patchValue({
    //   subnet: [''],
    //   routing_group: [dropDownArray[0].key || '']
    // })

    this.cd.detectChanges();
  }

  availabilityList:any[]=[];
  getDelegateDetails(){
    this.availabilityList = [];
    this.cd.detectChanges();
    let reqObj={
      "url":`${this.detailUrl}`,
      "method": "GET",
      "data":{}
    }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.availabilityList = addRes.data;
        this.cd.detectChanges();
      }
    },
    (err) => {
      this.cd.detectChanges();
    });
  }
  getGroupDetails(){
    this.availabilityList = [];
    this.cd.detectChanges();
    let reqObj={
      "url":`${this.detailUrl}`,
      "method": "GET",
      "data":{}
    }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.availabilityList = addRes.data;
        this.cd.detectChanges();
      }
    },
    (err) => {
      this.cd.detectChanges();
    });
  }
  onPopupSpaceEdit(key:any){
    // this.spaceService.spaceParentId.next({parentId:key})
    this.spaceService.setSpaceParentId(key);
    this.sharedService.setCipherText(key,'space')
    this.cd.detectChanges();
    this.router.navigate([`/partner/space/edit`]);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  mergeList:any[]=[];
  getMergedList(){
    this.mergeList = [];
    this.cd.detectChanges();
    let reqObj={
      "url":`${this.detailUrl}`,
      "method": "GET",
      "data":{}
    }
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.mergeList = addRes.data;
        this.cd.detectChanges();
      }
    },
    (err) => {
      this.cd.detectChanges();
    });
  }

}
