import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';
import { MailConfigModel } from '../models/mail-configuration.model';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/mailConfig/';
@Injectable({ providedIn: 'root' })
export class PartnerMailConfigService {
  partnerId = localStorage.getItem('mainId');

  constructor(private http: HttpClient) { }
  // Fetch Mail Data
  getMailViewData() {
    return this.http.get<MailConfigModel>(BACKEND_URL + `view/${this.partnerId}`);
  }

  // Create Mail 
  createMail(data: any) {
    return this.http.post<MailConfigModel>(BACKEND_URL + `add/${this.partnerId}`, data);
  }

  // update Mail
  updateMail(data: any, id: any) {
    return this.http.put<MailConfigModel>(BACKEND_URL + `update/${id}/${this.partnerId}`, data);
  }

  sendTestMail(mailBody: any) {
    return this.http.post<MailConfigModel>(BACKEND_URL + `/test/${this.partnerId}`, mailBody);
  }

  // sendTestMail(mailBody:any){
  //     return this.http.post<MailConfigModel>(BACKEND_URL + 'test', mailBody);
  // }
}
