<div class="row-reverse mt-10 mb-1">
    <div *ngIf="BondFailbackMessageArr?.length > 0">
        <ul class="col-md-12 messages list-unstyled"
            *ngFor="let bondFailbackMsg of BondFailbackMessageArr; let i=index">
            <li class="alert alert-warning animated fadeIn d-flex align-items-center justify-content-between bg-light-warning px-3 py-1 br-6"
                *ngIf="bondFailbackMsg?.object_type == 'bond' && bondFailbackMsg?.severity == 'warning' &&
                    bondFailbackMsg?.message !='Bond has no legs. Please add a leg before deploying.'">
                {{getFormattedMessage(bondFailbackMsg?.message)}} <button type="button"
                    class="close text-dark border-0 fs-1 bg-light-warning p-0" data-dismiss="alert"
                    (click)="removeBondFailbackMessage(i)">×</button>
            </li>
        </ul>
        <ul class="col-md-12 messages list-unstyled" *ngIf="isNodeProvisionData">
            <li
                class="alert alert-info animated fadeIn d-flex align-items-center justify-content-between bg-info px-3 py-2 br-6">
                <span class="text-white fw-bold">
                    Node provisioning is pending for Device with MAC-Address : {{nodeProvisionData.mac}}.
                </span>
                <div class="d-flex align-items-center">
                    <span *ngIf="nodeProvisionTimer.hour || nodeProvisionTimer.minutes || nodeProvisionTimer.seconds"
                        class="me-2">
                        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen013.svg'"
                            class="svg-icon svg-icon-1 svg-icon-block svg-clock-white mb-2"></span>
                        <span class="text-danger fw-bolder me-2 timer-w">
                            {{nodeProvisionTimer.hour}}:{{nodeProvisionTimer.minutes}}:{{nodeProvisionTimer.seconds}}
                        </span>
                    </span>
                    <button (click)="cancelProvisioning()" type="button" class="btn btn-danger btn-style cancel-btn">
                        Cancel Provisioning
                    </button>
                </div>
            </li>
        </ul>
        <ul class="col-md-12 messages list-unstyled"
            *ngIf="latest_tuning?.bond && latest_tuning.status && latest_tuning.id">
            <li class="alert animated fadeIn d-flex align-items-center justify-content-between px-3 py-1 br-6"
                [ngClass]="latest_tuning.status === 'tuned' ? 'alert-success border-success':'alert-primary border-primary'">
                <span class="text-primary fw-bold" *ngIf="latest_tuning.status === 'running'">
                    {{bondStatus?.bonder?.name}} is being automatically tuned. Network service will be interrupted
                    occasionally and bond settings cannot be changed until this process is complete.
                </span>
                <span class="text-primary fw-bold" *ngIf="latest_tuning.status === 'scheduled' 
                    && !latest_tuning?.schedule_at">
                    {{bondStatus?.bonder?.name}} is scheduled to be automatically tuned once all active legs are online.
                </span>
                <span class="text-primary fw-bold" *ngIf="latest_tuning.status === 'scheduled' 
                && latest_tuning?.schedule_at">
                    {{bondStatus?.bonder?.name}} is scheduled to be automatically tuned at
                    {{latest_tuning?.schedule_at | date: 'dd-MMM, h:mm a'}}.
                </span>
                <span class="text-black fw-bold" *ngIf="latest_tuning.status === 'tuned'">
                    {{bondStatus?.bonder?.name}} has been tuned automatically but the configuration recommendations have
                    not yet been accepted.
                </span>
                <div class="d-flex align-items-center"
                    *ngIf="latest_tuning.status === 'running' || latest_tuning.status==='scheduled'">
                    <button (click)="cancelTuning('')" type="button" class="btn btn-danger btn-style cancel-btn">
                        Cancel Tuning
                    </button>
                </div>
                <div class="d-flex align-items-center" *ngIf="latest_tuning.status === 'tuned'">
                    <button (click)="viewTuning()" type="button" class="btn btn-success btn-style cancel-btn">
                        View Recommendations
                    </button>
                </div>
            </li>
        </ul>
        <ul class="col-md-12 messages list-unstyled" *ngIf="latest_tuning?.leg && latest_tuning.status && !latest_tuning?.bond">
            <li class="alert animated fadeIn d-flex align-items-center justify-content-between px-3 py-1 br-6"
                [ngClass]="latest_tuning.status === 'tuned' ? 'alert-success border-success':'alert-primary border-primary'">
                <span class="text-primary fw-bold" *ngIf="latest_tuning.status === 'running'">
                    Leg {{legId}} is being automatically tuned. Bond settings cannot be changed until this process is
                    complete.
                </span>
                <span class="text-black fw-bold" *ngIf="latest_tuning.status === 'tuned'">
                    Leg {{legId}} has been tuned automatically but the configuration recommendations have not yet been
                    accepted.
                </span>
                <div class="d-flex align-items-center" *ngIf="latest_tuning.status === 'running'">
                    <button (click)="cancelTuning(legId)" type="button" class="btn btn-danger btn-style cancel-btn">
                        Cancel Tuning
                    </button>
                </div>
                <div class="d-flex align-items-center" *ngIf="latest_tuning.status === 'tuned'">
                    <button (click)="viewTuning()" type="button" class="btn btn-success btn-style cancel-btn">
                        View Recommendations
                    </button>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="d-flex flex-wrap flex-stack mb-3">
    <div class="fw-bolder my-2 d-flex div-position align-items-center">
        <div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
            title="Click to go back">
            <button (click)="onBack()" type="button" class="btn btn-primary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/icon/angle-left-solid.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Back
            </button>
        </div>
        <h2 class="mb-0 ms-3">{{bondStatus?.bonder?.name}}
            <a class="fs-5 mt-2 ms-1" *ngIf="(!isSharedBond || isPartner) && bondStatus?.space?.private_wan_enabled"
                (click)="navigateToSpace(bondStatus?.space)">
                <span class="mx-2">-</span> <span [inlineSVG]="'./assets/media/icons/duotune/icon/chart-network.svg'"
                    class="svg-icon svg-icon-5 ml-2 me-1 svg-icon-primary"></span>{{bondStatus?.space?.name}}</a>
        </h2>
    </div>

    <div class="d-flex flex-wrap my-2">
        <!-- <div class="me-4 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="Delete">
            <a class="btn btn-sm btn-primary p-3">
                <span [inlineSVG]="'./assets/media/icons/duotune/icon/trash-alt.svg'"
                    class="svg-icon svg-icon-2 me-0"></span>
            </a>
        </div>
        <div *ngIf="permissionsObj?.delete"
            class="me-4 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
            data-bs-placement="top" data-bs-trigger="hover" title="reset">
            <a class="btn btn-sm btn-primary p-3">
                <span [inlineSVG]="'./assets/media/icons/duotune/icon/sync-alt.svg'"
                    class="svg-icon svg-icon-2 me-0"></span>
            </a>
        </div> -->

        <div class="card-toolbar d-flex align-items-center justify-content-end">
            <button *ngIf="alertPermission" type="button" class="btn btn-primary btn-style me-2" (click)="onAlert()">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/communication/com004.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div>Configure Alert
            </button>
            <button *ngIf="alertPermission" type="button" class="btn btn-primary btn-style me-2"
                (click)="getAlertHistory()">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Alert History
            </button>
            <button *ngIf="isIlluminate" type="button" class="btn btn-primary btn-style me-2"
                (click)="loginToIlluminate()">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs022.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Illuminate
            </button>
            <!-- <button *ngIf="isIlluminate" type="button" class="btn btn-primary btn-style me-2"
                [ngClass]="(lastInstalledLog && lastProvisionedLog) ? '':'disable'"
                [disabled]="(lastInstalledLog && lastProvisionedLog) ? false : true"
                (click)="lastInstalledLog && lastProvisionedLog ? loginToIlluminate(): ''">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs022.svg'"
                        class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Illuminate
            </button> -->
            <button type="button" class="btn btn-primary btn-style me-2" placement="bottom-end"
                [ngClass]="(latest_tuning && latest_tuning?.status == 'running') ? 'disable-tune':''"
                [ngbPopover]="(latest_tuning && latest_tuning?.status == 'running') ? '': bondAction">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/cog.svg'" class="svg-icon svg-icon-block mb-2">
                    </span>
                </div> Action
            </button>
            <ng-template #bondAction>
                <ul class="p-0 mb-0">
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="getRestart()"> Restart </span>
                    </li>
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="getDetectLegMTU()"> Detect leg MTUs </span>
                    </li>
                    <li class="menu-item list-style-none">
                        <span class="menu-link px-5" (click)="latest_tuning?.bond ? '' :openTuneSetting()"
                            [ngClass]="latest_tuning?.bond ? 'disable-action':''"> Tune bond settings </span>
                    </li>
                    <li class="menu-item list-style-none"
                        *ngIf="bondPermissionsObj?.clone && !(isSharedBond && !isSdwanSharedBond)">
                        <span class="menu-link px-5" [ngClass]="!isAdminMail ? 'disable-action':''"
                            (click)="isAdminMail ? cloneBond(bondStatus) : ''"> Clone Bond </span>
                    </li>
                    <li class="menu-item list-style-none" *ngIf="bondPermissionsObj?.update">
                        <span class="menu-link px-5" [ngClass]="isNodeProvisionData ? 'disable-action':''"
                            (click)="isNodeProvisionData ? '' :nodeProvisioning()"> Node Provisioning
                        </span>
                    </li>
                </ul>
            </ng-template>
        </div>
    </div>
</div>

<div class="mb-0 mb-xl-0">
    <div class="overflow-auto mt-0 card border-1 border bg-light border-bottom-0">
        <div
            class="card-header px-0 card-header-stretch overflow-auto min-h-45px border-bottom-0 d-flex justify-content-between">
            <ul class="nav nav-stretch nav-line-tabs border-transparent flex-nowrap fs-6">
                <li class="nav-item">
                    <a [class.active]="selectedTab==1" (click)="onTabChange(1)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span
                            *ngIf="!sharedService?.Loader?.isBondStatusLoader && (status == 'up' || status == 'ssl up')"
                            [inlineSVG]="'./assets/media/icons/duotune/icon/green-circle.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-2"></span>
                        <span *ngIf="!sharedService?.Loader?.isBondStatusLoader && status == 'down'"
                            [inlineSVG]="'./assets/media/icons/duotune/icon/red-circle.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-2"></span>
                        <span
                            *ngIf="!sharedService?.Loader?.isBondStatusLoader && !(status == 'down' || status == 'up' || status == 'ssl up')"
                            [inlineSVG]="'./assets/media/icons/duotune/icon/gray-circle.svg'"
                            class="svg-icon svg-icon-8 ml-2 me-2"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isBondStatusLoader"></span>
                        Status
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==2" (click)="onTabChange(2)"
                        class="nav-link text-active-primary mx-0 px-5"> <span
                            [inlineSVG]="'./assets/media/icons/normal/cog.svg'"
                            class="svg-icon svg-icon-5 me-2 "></span> Details & Optimization
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==3" (click)="onTabChange(3)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/network-wired.svg'"
                            class="svg-icon svg-icon-5 me-2 "></span>
                        <span class="icon-badge" *ngIf="selectedTab != 3 
                            && latest_tuning?.leg && latest_tuning?.status === 'tuned'">
                            <div class="badge-circle-icon pulsate"></div>
                        </span>
                        Network Configuration
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==4" (click)="onTabChange(4)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/repeat.svg'" class="svg-icon svg-icon-5 me-2 "
                            *ngIf="!sharedService?.Loader?.isBondUpdateLogLoader"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isBondUpdateLogLoader"></span>
                        Updates
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==5" (click)="onTabChange(5)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/chart-line.svg'"
                            class="svg-icon svg-icon-5 me-2 "
                            *ngIf="!sharedService?.Loader?.isBondPerformanceLoader"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isBondPerformanceLoader"></span>
                        Performance
                    </a>
                </li>
                <li class="nav-item align-items-center"><span
                        class="h-40px border-start border-muted mx-0 border-1"></span></li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==6" (click)="onTabChange(6)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/normal/tachometer.svg'"
                            class="svg-icon svg-icon-5 me-2 " *ngIf="!sharedService?.Loader?.isBondStatusLoader"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isBondStatusLoader"></span>
                        Speed tests
                    </a>
                </li>
                <li class="nav-item align-items-end">
                    <span class="h-40px border-start border-muted mx-0 border-1"></span>
                </li>
                <li class="nav-item" *ngIf="isIlluminate">
                    <a [class.active]="selectedTab==7" (click)="onTabChange(7)"
                        class="nav-link text-active-primary mx-0 px-5">
                        <span [inlineSVG]="'./assets/media/icons/duotune/coding/cod009.svg'" *ngIf="!sharedService?.Loader?.isIlluminatePlanListLoader 
                            && !(lastInstalledLog && illuminateMac && lastInstalledLog?.mac != illuminateMac)"
                            class="svg-icon svg-icon-5 me-2 "></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/loader.svg'" class="me-2 "
                            *ngIf="sharedService?.Loader?.isIlluminatePlanListLoader"></span>
                        <span [inlineSVG]="'./assets/media/icons/normal/exclamation-triangle.svg'"
                            class="svg-icon svg-icon-warning svg-icon-5 me-2 " *ngIf="!sharedService?.Loader?.isIlluminatePlanListLoader && 
                            lastInstalledLog && illuminateMac && lastInstalledLog?.mac != illuminateMac"></span>
                        Add On
                    </a>
                </li>
                <li class="nav-item align-items-end">
                </li>
                <!-- <li class="nav-item align-items-center">
                    <span class="h-40px border-start border-muted mx-0 border-1"></span>
                </li>
                <li class="nav-item">
                    <a [class.active]="selectedTab==7" (click)="onTabChange(7)"
                        class="nav-link text-active-primary mx-0 px-5">
                        Tunings
                    </a>
                </li> -->
            </ul>
            <!-- <div class="px-5">
                <div class="preload" *ngIf="isCachedData">
                    <div class="loader-frame">
                        <div class="loader1"></div>
                        <div class="loader2"></div>
                        <div class="wrapper-wheel">
                            <div class="leftHalf"></div>
                            <div class="rightHalf"></div>
                            <div class="spinner-wheel"></div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div [hidden]="selectedTab != 1">
    <app-partner-bond-view-status *ngIf="bondStatus && bonderData" [bondId]="bondId" [bonderId]="bonderId"
        [alertPermission]="alertPermission" [bondStatus]="bondStatus" [bonderData]="bonderData" [isQuickView]="false"
        [nodeDetails]="allNodeDetails">
    </app-partner-bond-view-status>
</div>

<div *ngIf="selectedTab == 2">
    <app-partner-bond-view-configuration-details *ngIf="bondStatus && bonderData" (getBondStatus)="getBondStatus()"
        (getNodes)="getNodes()" [bondId]="bondId" [bonderId]="bonderId" [bondData]="bondStatus"
        [bonderData]="bonderData" [nodeDetails]="allNodeDetails">
    </app-partner-bond-view-configuration-details>
</div>

<div *ngIf="selectedTab == 3">
    <app-partner-bond-view-network-details *ngIf="bondId && bonderId" [bondId]="bondId" [bonderId]="bonderId"
        [alertPermission]="alertPermission" [bondData]="bondStatus">
    </app-partner-bond-view-network-details>
</div>

<div *ngIf="selectedTab == 4">
    <app-partner-bond-view-updates *ngIf="bondId" [bondId]="bondId" [bondActivityArr]="bondActivityArr"
        [bondActivityLength]="bondActivityLength" [deviceActivityArr]="deviceActivityArr"
        [deviceActivityLength]="deviceActivityLength"
        [illuminatePlanAccessList]="illuminatePlanAccessList"></app-partner-bond-view-updates>
</div>

<div *ngIf="selectedTab == 5">
    <app-partner-bond-view-performance *ngIf="bondStatus && bonderData" [bondId]="bondId" [bonderId]="bonderId"
        [bondData]="bondStatus" [bonderData]="bonderData">
    </app-partner-bond-view-performance>
</div>

<div *ngIf="selectedTab == 6">
    <app-shared-bonds-view-speed-test *ngIf="bondStatus" [bondId]="bondId" [bonderId]="bonderId"
        [bondData]="bondStatus">
    </app-shared-bonds-view-speed-test>
</div>

<div *ngIf="selectedTab == 7">
    <app-partner-package-manager *ngIf="bondStatus" [bondId]="bondId" [bonderId]="bonderId" [bondData]="bondStatus"
        [illuminatePlanAccessList]="illuminatePlanAccessList" [selectedIlluminatePlan]="selectedIlluminatePlan"
        [deviceActivityArr]="deviceActivityArr" [lastInstalledLog]="lastInstalledLog" [illuminateMac]="illuminateMac"
        [lastProvisionedLog]="lastProvisionedLog" [permissionObj]="{illuminatePermission: isIlluminate}"
        (getRecentActivity)="getRecentActivity()"></app-partner-package-manager>
</div>

<div *ngIf="selectedTab == 8">
    <app-partner-bond-view-tuning></app-partner-bond-view-tuning>
</div>

<!-- <div class="engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2">
    <button _ngcontent-ehy-c57="" id="kt_engage_demos_toggle" title="" data-bs-toggle="tooltip" data-bs-placement="left"
        data-bs-dismiss="click" data-bs-trigger="hover" data-bs-original-title="Check out 20 more demos"
        class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0">
        <span _ngcontent-ehy-c57="" id="kt_engage_demos_label">Quick View</span>
    </button>
</div> -->
<div id="kt_engage_demos" data-kt-drawer="true" data-kt-drawer-name="explore" data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'350px', 'lg': '475px'}" data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_engage_demos_toggle" data-kt-drawer-close="#kt_engage_demos_close"
    class="bg-body drawer drawer-end drawer-on" style="width: 475px !important;">
    <div class="card shadow-none rounded-0 w-100 quick-view">
        <app-partner-bond-view-tuning [bondData]="bondStatus"></app-partner-bond-view-tuning>
        <!-- <app-partner-bond-view-status [alertPermission]="alertPermission" *ngIf="bondStatus && bonderData"
            [bondId]="bondId" [bonderId]="bonderId" [bondStatus]="bondStatus" [bonderData]="bonderData"
            [isQuickView]="true">
        </app-partner-bond-view-status>
        <app-shared-bonds-quick-view></app-shared-bonds-quick-view> -->
    </div>
</div>