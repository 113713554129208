<div class="h-100" style="background-color: #f4f4f4;">
  <div class="h-100 d-flex flex-column flex-column-fluid bgi-position-y-bottom
    position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed "
    style="background-image: url('./assets/media/img/login-bg.svg')">
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10">
      <a routerLink="#" class="mb-5 login-page-logo">
        <img alt="Logo" *ngIf="!loginPageImg && isResult" src="./assets/media/logos/login-logo.svg" class="" />
        <img alt="Logo" *ngIf="loginPageImg" [src]="loginPageImg" class="" />
      </a>
      <!--begin::Content body-->
      <div class="w-lg-450px br-6 p-10 mx-auto border">
        <router-outlet></router-outlet>
      </div>
      <!--end::Content body-->
    </div>
    <!--end::Content-->
    <!-- begin::Footer -->
    <!-- <div class="d-flex flex-center flex-column-auto p-10">
    <div class="d-flex align-items-center fw-bold fs-6">
      <a class="text-muted text-hover-primary px-2 cursor-pointer">About</a><a
        class="text-muted text-hover-primary px-2 cursor-pointer">Contact</a><a
        class="text-muted text-hover-primary px-2 cursor-pointer">Contact Us</a>
    </div>
  </div> -->
    <!-- end::Footer -->
  </div>
</div>