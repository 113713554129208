<div class="card">

    <div class="card-header d-flex justify-content-between align-items-center">
      <h2 class="mb-0"> Delete routing group VLAN assignment 1 </h2>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="card-body">
      <p class="mb-0"> Are you sure you want to delete this assignment? This will deconfigure the VLAN interfaces, addresses, and protocols running for this space on aggregators in this routing group, and will remove this routing group from any space protocols.
      </p>
    </div>

    <div class="card-footer">
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" (click)="deletevlan()">Delete</button>
  </div>

  </div>
