import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, timer } from 'rxjs';
import { TranslationService } from 'src/app/modules/shared/i18n';
import { VersionModalComponent } from 'src/app/modules/super-admin/configuration/general-config/version/version-modal/version-modal.component';
import { PartnerManagmentService } from 'src/app/services/partner-management.service';
import { VersionService } from 'src/app/services/version.service';
import { AuthService } from '../../../../modules/shared/auth';
import { AppConst } from '../../../../shared/constants/app.constant';
import { SharedService } from '../../../../shared/services/shared.service';
import { LayoutService } from '../../core/layout.service';

enum User {
  admin,
  partner,
  business,
  endUser
}

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  imageUrl: any = './assets/media/avatars/blank.png';
  toolbarButtonMarginClass = 'ms-1 ms-lg-2';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-30px';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  headerLeft: string = 'menu';
  isTimerOptionVisible = false;
  showCounter: boolean = false;
  counter: any;
  tick = 1000;
  countDown: Subscription;
  isShowAssignTime$: Observable<boolean>;
  showTimerAccess: boolean = false;
  showRevertAccess: boolean = false;
  partnerDetails: any;
  mainId: any;
  loginId: any;
  accessTimeForAdmin: any;
  isChangeDuration: boolean = false;
  language: LanguageFlag;
  user$: Observable<any>;
  langs = AppConst.Languages;
  private unsubscribe: Subscription[] = [];
  _user: any;
  showSignOut: boolean = true;
  isForeverPermission: boolean;
  isShowChangeDuration: boolean = true;
  isAdmin: boolean = false;
  isPartner: boolean;
  isUser: boolean;
  durationValue: any;
  breadCrumbs: any[] = [];
  userIds: any[] = [];
  logoutId: any;
  technicalSupportObj: any;
  technicalSupportVisible: boolean = false;
  constructor(
    private layout: LayoutService,
    private auth: AuthService,
    private cd: ChangeDetectorRef,
    private sharedService: SharedService,
    private partnerService: PartnerManagmentService,
    private translationService: TranslationService,
    private router: Router,
    private versionService: VersionService,
    private modalService: NgbModal
  ) {
    if (Number(localStorage.getItem('role')) == AppConst.partner) {
      this.setPartnerAccessTime();
    }
    else if (Number(localStorage.getItem('role')) == AppConst.user) {
      this.setUserAccessTime();
    }
    else if (Number(localStorage.getItem('role')) == AppConst.organization) {
      this.setUserAccessTime();
    }
  }




  ngOnInit(): void {
    this.auth.currentUserSubject.asObservable().subscribe(data => { this._user = data; });
    this.setLanguage(this.translationService.getSelectedLanguage());
    this.mainId = localStorage.getItem('mainId');
    this.loginId = localStorage.getItem('ID');
    if (Number(localStorage.getItem('role')) == AppConst.admin
      || Number(localStorage.getItem('role')) == AppConst.basicUser) {
      this.isAdmin = true;
    } else if (Number(localStorage.getItem('role')) == AppConst.partner) {
      this.isPartner = true;
      this.setPartnerSetting();
    } else if (Number(localStorage.getItem('role')) == AppConst.user) {
      this.isUser = true;
      this.setUserSetting();
    } else if (Number(localStorage.getItem('role')) == AppConst.organization) {
      this.isUser = true;
      this.setUserSetting();
    }

    this.auth.currentUserSubject.subscribe((res: any) => {
      if (res?.imageUrl) {
        this.imageUrl = res?.imageUrl;
      } else {
        this.imageUrl = this.imageUrl;
      }
    })
    this.versionService.openVersionModal$.subscribe((val) => {
      if (val) this.openVersionList();
    });
    this.auth.technicalSupport$.subscribe((val) => {
      this.technicalSupportObj = val;
      if (this.technicalSupportObj?.technicalSupportURL || this.technicalSupportObj?.technicalSupportEmail || this.technicalSupportObj?.technicalSupportContact) {
        this.technicalSupportVisible = true;
      }
      this.cd.detectChanges();
    });

    this.headerLeft = this.layout.getProp('header.left') as string;
    if (localStorage.getItem('userIds')) this.userIds = JSON.parse(localStorage.getItem('userIds') || '');
    if (this.userIds?.length == 1 && !localStorage.getItem('rootID')) {
      this.showSignOut = true;
      this.isForeverPermission = true;
      this.showCounter = false;
      return;
    }
  }

  setPartnerSetting() {
    if (localStorage.getItem('breadCrumbs')) {
      this.breadCrumbs = JSON.parse(localStorage.getItem('breadCrumbs') || '')
    }
    // let find = this.breadCrumbs.find(x => x == name)
    let find = localStorage.getItem('find');
    if (find == '0') this.breadCrumbs.push(localStorage.getItem('Name'));

    localStorage.setItem('breadCrumbs', JSON.stringify(this.breadCrumbs));
    this.auth.nameBreadcrumb$.subscribe(res => {
      if (res != null && res != 'null' && res != 'undefined' && res != undefined) {
        this.breadCrumbs = JSON.parse(localStorage.getItem('breadCrumbs') || '')
        if (this.breadCrumbs?.length > 0) {
          this.breadCrumbs[this.breadCrumbs?.length - 1] = res;
          localStorage.setItem('breadCrumbs', JSON.stringify(this.breadCrumbs));
        }
      }
    });
    if (localStorage.getItem('isAdminLoggedInAsPartner') == '1') {
      //   this.getUserIDs();
      this.showSignOut = false;
    }

    this.auth.isShowTimerAccess$.subscribe(res => {
      if (res != null) {
        this.showTimerAccess = res;
        this.showRevertAccess = !res;
        this.cd.detectChanges();
      }
    });
    if (localStorage.getItem('isAdminLoggedInAsPartner') == '1') {
      this.setTimer();
    }
    let role = Number(localStorage.getItem('role'));
    if (role != AppConst.admin && role != AppConst.basicUser && role) {
      this.isTimerOptionVisible = true;
      let obj = {
        mainId: localStorage.getItem('mainId'),
        LoginID: localStorage.getItem('ID')
      }
      this.sharedService.getPartnerLPDetails(obj).subscribe((res: any) => {
        // if(res.data){
        this.durationValue = res.data?.duration;
        if (res.data?.duration == -1) {
          this.isForeverPermission = true;
          this.isShowChangeDuration = false;
        }
        if (res.success == 0) {
          this.showTimerAccess = true;
          this.showRevertAccess = false;
          // this.isChangeDuration=!this.isChangeDuration
        }
        else {
          this.auth.accessTimeSubject.next(res.data?.duration);
          this.showTimerAccess = false;
          this.showRevertAccess = true;
        }
        //  }
        this.cd.detectChanges();
      }, (err: any) => {
        this.showTimerAccess = true;
        this.showRevertAccess = false;
        this.cd.detectChanges();
      })
      this.cd.markForCheck();
    }
  }

  // getUserIDs(){
  //   let userId:any;
  //   if (localStorage.getItem('userIds')) {
  //     this.userIds = JSON.parse(localStorage.getItem('userIds') || '')
  //   }
  //   //this.userIds=JSON.parse(localStorage.getItem('userIds')||'');
  //   if(Number(localStorage.getItem('role'))==AppConst.user){
  //     userId=localStorage.getItem('userId');
  //   }
  //   else{
  //     userId=localStorage.getItem('ID');
  //   }
  //   let userIDFind=this.userIds.find(x=>x==userId);
  //   if(!userIDFind){
  //     this.userIds.push(userId);
  //     localStorage.setItem('userIds', JSON.stringify(this.userIds));
  //   }
  // }

  setUserSetting() {
    // this.getUserIDs();
    if (localStorage.getItem('breadCrumbs')) {
      this.breadCrumbs = JSON.parse(localStorage.getItem('breadCrumbs') || '')
    }
    // let find = this.breadCrumbs.find(x => x == name)
    let find = localStorage.getItem('find');
    if (find == '0') this.breadCrumbs.push(localStorage.getItem('Name'));
    localStorage.setItem('breadCrumbs', JSON.stringify(this.breadCrumbs));
    this.auth.nameBreadcrumb$.subscribe(res => {
      if (res != null && res != 'null' && res != 'undefined' && res != undefined) {
        this.breadCrumbs = JSON.parse(localStorage.getItem('breadCrumbs') || '')
        if (this.breadCrumbs?.length > 0) {
          this.breadCrumbs[this.breadCrumbs?.length - 1] = res;
          localStorage.setItem('breadCrumbs', JSON.stringify(this.breadCrumbs));
        }
      }
    });
    if (localStorage.getItem('isPartnerLoggedInAsUser') == '1') {
      this.showSignOut = false;
    }

    this.auth.isShowTimerAccess$.subscribe(res => {
      if (res != null) {
        this.showTimerAccess = res;
        this.showRevertAccess = !res;
        this.cd.detectChanges();
      }
    });
    if (localStorage.getItem('isPartnerLoggedInAsUser') == '1') {
      this.setUserTimer();
    }
    let role = Number(localStorage.getItem('role'));
    if (role != AppConst.admin && role != AppConst.basicUser) {
      this.isTimerOptionVisible = true;
      let obj = {
        userId: localStorage.getItem('ID'),
        LoginID: localStorage.getItem('ID'),
        organizationId: localStorage.getItem('organization'),
        partner_role: localStorage.getItem('role')
      }
      this.sharedService.getUserLPDetails(obj).subscribe((res: any) => {
        //  if(res.data){
        this.durationValue = res.data?.duration;
        if (res.data?.duration == -1) {
          this.isForeverPermission = true;
          this.isShowChangeDuration = false;
        }
        if (res.success == 0) {
          this.showTimerAccess = true;
          this.showRevertAccess = false;
          // this.isChangeDuration=!this.isChangeDuration
        } else {
          this.auth.accessTimeSubject.next(res.data?.duration);
          this.showTimerAccess = false;
          this.showRevertAccess = true;
        }
        //  }
        this.cd.detectChanges();
      }, (err: any) => {
        this.showTimerAccess = true;
        this.showRevertAccess = false;
        this.cd.detectChanges();
      })
      this.cd.markForCheck();
    }
  }

  selectLanguage(lang: string) {
    this.translationService.setLanguage(lang);
    this.setLanguage(lang);
    // document.location.reload();
  }

  logout() {
    // this.auth.logout();
    let role = Number(localStorage.getItem('role'));
    console.log('logout-topbar');
    this.auth.clearCookies();
    localStorage.clear();
    let logoutPath = "";
    if (role == AppConst.admin || role == AppConst.basicUser) {
      logoutPath = "/auth/login";
      // this.router.navigate(['/auth/login'], {
      //   queryParams: {},skipLocationChange:true
      // }).then(()=>{
      //   //this.sharedService.hideLoader();
      // })
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/auth/login']);
      // });
      // window.location.replace('/#/auth/login');
      // window.location.href = "/#/auth/login";
      // window.location.reload();
    } else if (role == AppConst.partner || role == AppConst.user || role == AppConst.organization) {
      logoutPath = "/auth/login/partner";
      //     this.router.navigate(['/auth/login/partner'], {
      //       queryParams: {}, skipLocationChange: true
      //     }).then(()=>{
      //       document.location.reload();
      //     })
      // window.location.href = "/#/auth/login/partner";
      // window.location.reload();
      // window.location.replace('/#/auth/login/partner');
    }
    localStorage.setItem('logoutPath', logoutPath)
    window.location.reload();
  }

  setLanguage(lang: string) {
    this.langs.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
  }

  redirectToProfile() {
    let currentUser;
    if (Number(this.sharedService.getRole()) == AppConst.basicUser) {
      currentUser = 'admin';
    } else if (Number(this.sharedService.getRole()) == AppConst.user) {
      currentUser = 'partner'
    } else if (Number(this.sharedService.getRole()) == AppConst.organization) {
      currentUser = 'partner'
    } else {
      currentUser = User[this.sharedService.getRole()];
    }
    this.router.navigateByUrl(`${currentUser}/profile`);
  }

  logoutToAdmin() {
    this.sharedService.showLoader();
    this.auth.logoutFromPartner();
  }

  logoutToPartner() {
    let data = {
      // AdminId: localStorage.getItem('rootAdminID'),
      // userId: localStorage.getItem('userId'),
      //  LoginID: localStorage.getItem('loginId'),
      LoginID: this.logoutId
      // partner_role: localStorage.getItem('role'),
    }
    this.sharedService.showLoader();
    this.auth.logoutFromUser(data);
  }

  setPartnerAccessTime() {
    this.auth.accessTimeSubject.subscribe(res => {
      if (res == -1) {
        this.isForeverPermission = true;
      } else {
        this.accessTimeForAdmin = res;
        this.isForeverPermission = false;
      }
      this.cd.markForCheck();
    });
  }

  setUserAccessTime() {
    this.auth.accessTimeSubject.subscribe(res => {
      if (res == -1) {
        this.isForeverPermission = true;
      } else {
        this.accessTimeForAdmin = res;
        this.isForeverPermission = false;
      }
      this.cd.markForCheck();
    });
  }

  revokePermission() {
    let questionTitle = 'Are you sure you want to revoke this permission?';
    let text = ``
    let confirmButtonText = "Yes"
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        if (Number(localStorage.getItem('role')) == AppConst.partner) {
          let obj = {
            LoginID: this.loginId,
            mainId: this.mainId,
            admin_role: localStorage.getItem('role')
          }
          this.partnerService.revokePermission(obj).subscribe((res: any) => {
            if (res) {
              this.sharedService.hideLoader();
              this.sharedService.loggerSuccess(res.message)
              this.auth.isShowTimerAccessSubject.next(true);
            }
          }, (err) => {
            //  this.sharedService.loggerError(err.message)
            this.showTimerAccess = true;
            this.showRevertAccess = false;
            this.cd.detectChanges();
          });
        } else if (Number(localStorage.getItem('role')) == AppConst.user) {
          let obj = {
            organizationId: localStorage.getItem('organization'),
            LoginID: this.loginId,
            userId: this.loginId,
            partner_role: localStorage.getItem('role'),
            duration: this.durationValue
          }
          this.partnerService.revokePartnerPermission(obj).subscribe((res: any) => {
            if (res) {
              this.sharedService.hideLoader();
              this.sharedService.loggerSuccess(res.message)
              this.auth.isShowTimerAccessSubject.next(true);
            }
          }, (err) => {
            //  this.sharedService.loggerError(err.message)
            this.showTimerAccess = true;
            this.showRevertAccess = false;
            this.cd.detectChanges();
          });
        }
      }
    });
  }

  changeDuration() {
    this.isChangeDuration = !this.isChangeDuration;
    // this.showTimerAccess=true;
    // this.showRevertAccess=false;
    this.cd.detectChanges();
  }

  openPop() { }

  back() {
    this.isChangeDuration = false;
  }

  setAdminPartnerAccess(value: any) {
    this.sharedService.showLoader();
    if (Number(localStorage.getItem('role')) == AppConst.partner) {
      let data = {
        LoginID: localStorage.getItem('ID'),
        id: localStorage.getItem('mainId'),
        mainId: localStorage.getItem('mainId'),
        duration: value,
        role: localStorage.getItem('role')
      }
      this.auth.setPartnerLP(data).subscribe((res: any) => {
        if (res.code == 200) {
          this.auth.isShowTimerAccessSubject.next(false);
          this.auth.accessTimeSubject.next(value);
          this.sharedService.hideLoader();
          this.sharedService.loggerSuccess(res?.message);
          this.isChangeDuration = false;
          if (value == -1) {
            this.isForeverPermission = true;
            this.isShowChangeDuration = false;
          } else {
            this.isForeverPermission = false;
            this.isShowChangeDuration = true;
          }
          // this.isChangeDuration=false;
        }
      })
    } else if (Number(localStorage.getItem('role')) == AppConst.user) {
      let data = {
        LoginID: localStorage.getItem('ID'),
        organizationId: localStorage.getItem('organization'),
        userId: localStorage.getItem('ID'),
        duration: value,
        partner_role: localStorage.getItem('role')
      }
      this.auth.updateUserLP(data).subscribe((res: any) => {
        if (res.code == 200) {
          this.auth.isShowTimerAccessSubject.next(false);
          this.auth.accessTimeSubject.next(value);
          this.sharedService.hideLoader();
          this.sharedService.loggerSuccess(res?.message);
          this.isChangeDuration = false;
          if (value == -1) {
            this.isForeverPermission = true;
            this.isShowChangeDuration = false;
          } else {
            this.isForeverPermission = false;
            this.isShowChangeDuration = true;
          }
          // this.isChangeDuration=false;
        }
      })
    }
  }

  updateAdminPartnerAccess(value: any) {
    this.sharedService.showLoader();
    let data = {
      LoginID: localStorage.getItem('ID'),
      id: localStorage.getItem('mainId'),
      mainId: localStorage.getItem('mainId'),
      duration: value,
      role: localStorage.getItem('role')
    }
    this.auth.updatePartnerLP(data).subscribe((res: any) => {
      if (res.code == 200) {
        if (value == -1) {
          this.isForeverPermission = true;
        }
        this.auth.isShowTimerAccessSubject.next(false);
        this.auth.accessTimeSubject.next(value);
        this.sharedService.hideLoader();
        this.sharedService.loggerSuccess(res?.message);
        this.isChangeDuration = false;
      }
    })
  }

  setUserTimer() {
    this.showCounter = true;
    if (Number(localStorage.getItem('logInExpiresIn')) == -1) {
      this.isForeverPermission = true;
      return;
    } else {
      this.isForeverPermission = false;
      // get currentTime and expiresTime for comparision
      let currentTime = new Date().getTime() / 1000;
      let expiresTime = new Date(Number(localStorage.getItem('logInExpiresIn'))).getTime();
      var seconds = (expiresTime - currentTime);
      this.counter = seconds;
      this.countDown = timer(0, this.tick).subscribe(() => {
        if (this.counter >= 1) {
          --this.counter;
        } else {
          this.countDown.unsubscribe();
          this.showCounter = false;
          let data = {
            userId: localStorage.getItem('userId'),
            LoginID: localStorage.getItem('ID'),
            partner_role: localStorage.getItem('role'),
            organizationId: localStorage.getItem('organization'),
            duration: localStorage.getItem('logInDuration'),
            // AdminId: localStorage.getItem('rootAdminID')
          }
          this.auth.logoutFromUser(data);
          // this.auth.logoutFromUser();
        }
        this.cd.detectChanges();
      });
    }
  }

  setTimer() {
    this.showCounter = true;
    if (Number(localStorage.getItem('logInExpiresIn')) == -1) {
      this.isForeverPermission = true;
      return;
    } else {
      this.isForeverPermission = false;
      // get currentTime and expiresTime for comparision
      let currentTime = new Date().getTime() / 1000;
      let expiresTime = new Date(Number(localStorage.getItem('logInExpiresIn'))).getTime();
      var seconds = (expiresTime - currentTime);
      // console.log(seconds);
      this.counter = seconds;
      this.countDown = timer(0, this.tick).subscribe(() => {
        if (this.counter >= 1) {
          --this.counter;
        } else {
          this.countDown.unsubscribe();
          this.showCounter = false;
          this.auth.logoutFromPartner();
        }
        this.cd.detectChanges();
      });
    }
  }

  logoutFromProfile() {
    if (localStorage.getItem('homeSpace')) localStorage.removeItem('homeSpace');
    this.breadCrumbs.splice(this.breadCrumbs.length - 1, 1)
    localStorage.setItem('breadCrumbs', JSON.stringify(this.breadCrumbs));
    this.userIds = JSON.parse(localStorage.getItem('userIds') || '');
    if (this.userIds.length) {
      this.userIds.splice(this.userIds.length - 1, 1);
      let id = this.userIds[this.userIds.length - 1];
      if (!id) {
        this.logoutId = localStorage.getItem('loginId');
      } else {
        this.logoutId = id;
      }
      localStorage.setItem('userIds', JSON.stringify(this.userIds));
    } else {
      this.logoutId = localStorage.getItem('loginId');
    }
    if (this.isPartner) {
      this.logoutToAdmin();
    } else if (this.isUser) {
      this.logoutToPartner();
    }
  }

  openVersionList() {
    let versionData = JSON.parse(localStorage.getItem('version') || '')
    if (versionData && versionData?.status) {
      let modal = this.modalService.open(VersionModalComponent, { size: 'lg' });
      modal.componentInstance.versionData = versionData;
      modal.result.then((data) => {
        if (data && data?.event === 'close' && data.isChecked && (Number(localStorage.getItem('role')) !== AppConst.admin && Number(localStorage.getItem('role')) !== AppConst.basicUser)) {
          this.versionService.updateLoginVersion(versionData?._id)?.subscribe((res) => {
          }, (err) => console.log(err));
        }
      }, () => { });
    }
  }

  openDoc() {
    window.open(`https://${window.location.host}/docs`, '_blank')
  }

}


interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

