import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-user-management-list',
  templateUrl: './shared-user-management-list.component.html',
  styleUrls: ['./shared-user-management-list.component.scss']
})
export class SharedUserManagementListComponent implements OnInit {
  userListIds: any = [];
  role: any;

  @Input() usersList: any[] = [];
  @Input() length: any;
  @Input() sortBy: string;
  @Input() sortOrder: number;
  @Input() page: number;
  @Input() limit: number;
  @Input() permissionsObj: any;
  @Input() defaultOrgName: any;
  @Input() preLoaderScreen: boolean = false;

  @Output() edit = new EventEmitter<any>();
  @Output() receiveMessageEmitter = new EventEmitter<any>();
  @Output() onSortFields = new EventEmitter<any>();
  @Output() suspend = new EventEmitter<any>();
  @Output() resetPassword = new EventEmitter<any>();
  @Output() loginAsUser = new EventEmitter<any>();

  constructor(private shared: SharedService, private cd: ChangeDetectorRef) {
    this.userListIds = localStorage.getItem('userIds');
    this.role = localStorage.getItem('role');
  }

  ngOnInit(): void { }

  ngOnChanges(): void {
    this.cd.detectChanges();
  }

  onResetPassword(email: any, _id: any) {
    if (!email) return;
    let mainId = localStorage.getItem('mainId');
    if (mainId) this.resetPassword.emit({ email: email, _id: _id, mainId: mainId });
  }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  onSort(event: any) {
    this.onSortFields.emit(event);
  }

  receiveMessage(data: any) {
    this.receiveMessageEmitter.emit(data);
  }

  onEdit(id: any) {
    this.edit.emit({ userId: id })
  }

  onSuspend(id: any) {
    if (id) {
      let questionTitle = 'Are you sure?';
      let text = "<b>You are suspending this user. </b>"
      let confirmButtonText = "Yes, Suspend it!"
      this.shared.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
        if (result.isConfirmed) {
          this.suspend.emit(id);
        }
        this.cd.detectChanges();
      })
    }
  }

  onLoginAsUser(user: any) {
    if (user) this.loginAsUser.emit({ user: user });
  }

  findUseById(Id: any) {
    if (Id && this.userListIds && this.userListIds.length > 0) return this.userListIds.includes(Id);
    else return false;
  }

}
