<!-- begin::Header -->
<!-- <div class="border-0 pt-5 mb-5">
    <div class="col-12">
        <div class="row mb-5">
            <div class="col-9">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label  fs-3 mb-1">notification Management</span>
                </h3>
            </div>
            <div class="col-3 card-toolbar justify-content-end d-flex" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-trigger="hover" title="Click to add a Template">
                <a routerLink="/admin/configuration/notification-template/create" class="btn btn-sm btn-primary">
                    Create Notification Template
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-1 me-0"></span>
                </a>
            </div>
        </div>
    </div>
</div> -->

<div *ngIf="permissions.view" class="d-flex flex-wrap mb-2 justify-content-end">
    <div class="fw-bolder mb-2 div-position col-md-2 me-4">
        <input type="text" class="form-control pe-10" [formControl]="searchControl" name="searchText"
            placeholder="Search" (keydown)="preventSpace($event)" value="" />
        <span (click)="onResetFilter()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
            class="svg-icon svg-icon-1 me-0 span-input"></span>
    </div>

    <div class="d-flex flex-wrap mb-2">
        <div class="me-0 card-toolbar justify-content-end d-flex" data-bs-toggle="tooltip" data-bs-placement="top"
            data-bs-trigger="hover" title="Click to add Notification Template">
            <a *ngIf="permissions.update" (click)="onAdd()" class="btn btn-sm btn-primary">
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                    class="svg-icon svg-icon-3 mx-1"></span>Create Notification Template
            </a>
        </div>
    </div>
</div>
<div *ngIf="permissions.view" class="card mb-5 mb-xl-8">
    <div class="card-body p-xxl-2 p-md-2">
        <!-- begin::Table container -->
        <div class="table-responsive border">
            <!-- begin::Table -->
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                <!--<table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" >-->
                <!-- begin::Table head -->
                <thead class="align-middle table-light">
                    <tr class="fw-bolder text-dark fs-7">
                        <th class="py-4" (click)="onSort('subject')"> Notification Subject
                            <span *ngIf="sortBy == 'subject'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4">Recipients</th>
                        <th class="py-4" (click)="onSort('body')">Description
                            <span *ngIf="sortBy == 'body'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4"> <span *ngIf="permissions.delete || permissions.update">Actions</span></th>
                    </tr>
                </thead>
                <!-- end::Table head -->
                <!-- begin::Table body -->
                <tbody *ngIf="length > 0">
                    <tr *ngFor="let notification of notificationTemplates; let i = index" class="fs-7">
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="d-flex justify-content-start flex-column">
                                    <a class="text-dark">
                                        {{notification?.subject}}
                                    </a>
                                </div>
                            </div>
                        </td>
                        <td>
                            <a class="text-dark" *ngFor="let item of notification?.recepients;let last=last">
                                {{item.fname }}
                                <span *ngIf="!last">, </span>
                            </a>
                        </td>
                        <td>
                            <a class="text-dark  d-block text-start">
                                {{notification?.body}}
                            </a>
                        </td>
                        <td>
                            <a *ngIf="permissions.update" title="Send" (click)="onSend(notification?._id)"
                                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen016.svg'"
                                    class="svg-icon svg-icon-3 svg-icon-primary">
                                </span>
                            </a>
                            <a *ngIf="permissions.delete" title="Delete" (click)="onDelete(notification?._id)"
                                class="btn btn-icon btn-bg-light br-6 btn-active-color-primary btn-sm me-2 h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                    class="svg-icon svg-icon-3 svg-icon-danger">
                                </span>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <!-- end::Table body -->
            </table>
            <div *ngIf="length == 0" class="text-center font16 mt15 mb15 py-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>
            </div>
        </div>
        <!-- end: Table -->
        <app-pagination *ngIf="length > 0" [childMessage]="length" [index]="index"
            (messageEvent)="receiveMessage($event)">
        </app-pagination>
        <!-- end::Table container -->
    </div>
</div>

<div *ngIf="!permissions.view">
    <span>You have no permission to view the data. Kindly contact the authority.</span>
</div>