import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
import { AppConst } from 'src/app/shared/constants/app.constant';
const BACKEND_URL = environment.apiUrl + '/partnerPanel/';

const LIVE_BACKEND_URL = environment.apiUrl + '/#/api/bonds';

@Injectable({
  providedIn: 'root'
})
export class PartnerBondingService {

  socket: io.Socket;
  bondId: BehaviorSubject<any>;
  bonderId: BehaviorSubject<any>;
  bondData: BehaviorSubject<any>;
  legData: BehaviorSubject<any>;
  mobileData: BehaviorSubject<any>;
  systemPerformanceData: BehaviorSubject<any>;
  activeBondList: BehaviorSubject<any>;
  activityBondList: BehaviorSubject<any>;
  latestNotificationList: BehaviorSubject<any>;
  onlineUserList: BehaviorSubject<any>;
  repeatForSpeedtest: BehaviorSubject<any>;
  latestTuningData: BehaviorSubject<any>;
  terminalData: BehaviorSubject<any>;
  illuminateAgentData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  bond_eth0_mac: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  legTuningList: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  bondTuningList: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  deviceLogList: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  successForInterface: BehaviorSubject<any>;
  successForProtocol: BehaviorSubject<any>;
  successForFilter: BehaviorSubject<any>;
  successForConnectedIp: BehaviorSubject<any>;
  successForCPE: BehaviorSubject<any>;
  successForRoutes: BehaviorSubject<any>;
  failbackMessage: BehaviorSubject<any>;

  mainId = localStorage.getItem('mainId');

  constructor(private http: HttpClient) {
    this.bondId = new BehaviorSubject<any>(null);
    this.bonderId = new BehaviorSubject<any>(null);
    this.bondData = new BehaviorSubject<any>(null);
    this.legData = new BehaviorSubject<any>(null);
    this.systemPerformanceData = new BehaviorSubject<any>(null);
    this.mobileData = new BehaviorSubject<any>(null);
    this.activeBondList = new BehaviorSubject<any>(null);
    this.activityBondList = new BehaviorSubject<any>(null);
    this.latestNotificationList = new BehaviorSubject<any>(null);
    this.onlineUserList = new BehaviorSubject<any>(null);
    this.terminalData = new BehaviorSubject<any>(null);
    this.repeatForSpeedtest = new BehaviorSubject<any>(null);
    this.successForInterface = new BehaviorSubject<any>(null);
    this.successForConnectedIp = new BehaviorSubject<any>(null);
    this.successForRoutes = new BehaviorSubject<any>(null);
    this.successForCPE = new BehaviorSubject<any>(null);
    this.successForProtocol = new BehaviorSubject<any>(null);
    this.successForFilter = new BehaviorSubject<any>(null);
    this.latestTuningData = new BehaviorSubject<any>(null);
    this.failbackMessage = new BehaviorSubject<any>(null);
  }

  get bondId$() {
    return this.bondId.asObservable();
  }
  get bonderId$() {
    return this.bonderId.asObservable();
  }

  get bondData$() {
    return this.bondData.asObservable();
  }

  get legData$() {
    return this.bondData.asObservable();
  }

  get systemPerformanceData$() {
    return this.systemPerformanceData.asObservable();
  }

  get mobileData$() {
    return this.bondData.asObservable();
  }

  get activeBondList$() {
    return this.activeBondList.asObservable();
  }

  get latestNotificationList$() {
    return this.latestNotificationList.asObservable();
  }

  get onlineUserList$() {
    return this.onlineUserList.asObservable();
  }

  get terminalData$() {
    return this.terminalData.asObservable();
  }

  get bond_eth0_mac$() {
    return this.bond_eth0_mac.asObservable();
  }

  get illuminateAgentData$() {
    return this.illuminateAgentData.asObservable();
  }

  get activityBondList$() {
    return this.activityBondList.asObservable();
  }

  get successForInterface$() {
    return this.successForInterface.asObservable();
  }
  get successForFilter$() {
    return this.successForFilter.asObservable();
  }
  get successForProtocol$() {
    return this.successForProtocol.asObservable();
  }
  get successForConnectedIp$() {
    return this.successForConnectedIp.asObservable();
  }
  get successForCPE$() {
    return this.successForCPE.asObservable();
  }
  get successForRoutes$() {
    return this.successForRoutes.asObservable();
  }

  get repeatForSpeedtest$() {
    return this.repeatForSpeedtest.asObservable();
  }

  get latestTuningData$() {
    return this.latestTuningData.asObservable();
  }

  get legTuningList$() {
    return this.legTuningList.asObservable();
  }

  get bondTuningList$() {
    return this.bondTuningList.asObservable();
  }

  get deviceLogList$() {
    return this.deviceLogList.asObservable();
  }

  get failbackMessage$() {
    return this.failbackMessage.asObservable();
  }

  addBond(data: any) {
    return this.http.post<any>(`${BACKEND_URL}addBond/${this.mainId}`, data)
  }

  cloneBond(data: any, bondId: number) {
    let role = localStorage.getItem('role');
    this.mainId = localStorage.getItem('mainId');
    if (Number(role) == AppConst.user) {
      if (Number(localStorage.getItem('parentRole')) == AppConst.organization) data.orgUserId = localStorage.getItem('orgUserId');
    }
    return this.http.post<any>(`${BACKEND_URL}cloneBond/${bondId}/${this.mainId}`, data)
  }

  addNodeProvisioning(data: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    data['type'] = "node";
    return this.http.post<any>(`${BACKEND_URL}nodeProvisioning/add/${this.mainId}`, data)
  }

  viewNodeProvisioning(bondId: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.get<any>(`${BACKEND_URL}nodeProvisioning/view/${bondId}/${this.mainId}`)
  }

  deleteNodeProvisioning(templateId: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.delete<any>(`${BACKEND_URL}nodeProvisioning/delete/${templateId}/${this.mainId}`)
  }

  illuminateInstallation(data: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    data['type'] = "illuminate";
    return this.http.post<any>(`${BACKEND_URL}nodeProvisioning/add/${this.mainId}`, data)
  }

  getInstalledFirewall(bondId: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.get<any>(`${BACKEND_URL}nodeProvisioning/getFirewall/${bondId}/${this.mainId}`)
  }

  installFirewall(data: any, bondId: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(`${BACKEND_URL}nodeProvisioning/installFirewall/${bondId}/${this.mainId}`, data)
  }

  connectFirewall(data: any, bondId: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(`${BACKEND_URL}nodeProvisioning/connectFirewall/${bondId}/${this.mainId}`, data)
  }

  updateFirewall(data: any, bondId: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(`${BACKEND_URL}nodeProvisioning/updateFirewall/${bondId}/${this.mainId}`, data)
  }

  uninstallFirewall(data: any, bondId: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(`${BACKEND_URL}nodeProvisioning/uninstallFirewall/${bondId}/${this.mainId}`, data)
  }

  columnFilter(search?: string,
    page?: string,
    limit?: number,
    sortBy?: any,
    sortOrder?: any,
    colums?: Array<string>) {
    let Id = this.mainId

    if (this.mainId == null) {
      this.mainId = localStorage.getItem('mainId');
      Id = this.mainId
    }
    return this.http.get<any>(`${BACKEND_URL}getAvailableColumn/${Id}`)
  }

  getResponse(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  saveColumn(columns: any, spaceOption: any, bondPageSize: any) {
    return this.http.put<any>(`${BACKEND_URL}savebondingColumn/${this.mainId}`, { columns, spaceOption, bondPageSize })
  }

  updateChecks(monitorChecks: any, monitorInterval: any) {
    let mainId = localStorage.getItem('mainId');
    let userId = localStorage.getItem('ID');
    if (!mainId || !userId) return;
    return this.http.put<any>(`${environment.apiUrl}/partner/updateChecks/${mainId}`, { userId, monitorChecks, monitorInterval })
  }

  getChecks() {
    let mainId = localStorage.getItem('mainId');
    let userId = localStorage.getItem('ID');
    if (!mainId || !userId) return;
    return this.http.post<any>(`${environment.apiUrl}/partner/getMonitorChecks/${mainId}`, { userId })
  }

  getCacheInterval() {
    let mainId = localStorage.getItem('mainId');
    if (!mainId) return;
    return this.http.get<any>(`${environment.apiUrl}/partner/getPartnerCacheInterval/${mainId}`);
  }

  moveBondToSpace(data: any, bondId: any, bonderId: any, url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}updateBond/${bondId}/${bonderId}/${this.mainId}`, data)
  }

  getAllSpaces() {
    let url = `spaces`;
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: 'GET' })
  }

  getSettingData() {
    let mainId = localStorage.getItem('mainId');
    if (!mainId) return;
    let url = `settings/`
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${mainId}`, { url, method: 'GET' })
  }

  getCurrentSpaces() {
    let url = `spaces/?home_space=__current__`;
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: 'GET' })
  }

  viewBond(method: any, url: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url: url, method: method })
  }

  viewStatus(method: any, url: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url: url, method: method })
  }

  viewDynamicData(method: any, url: any, data: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { url, method, data })
  }

  viewBonderStatus(method: any, url: any, bondId: any) {
    let role = localStorage.getItem('role');
    let params = {
      url: url,
      bondId: bondId,
      role: role,
      method: method
    };
    return this.http.post<any>(`${BACKEND_URL}getBondData/${this.mainId}`, params)
  }

  getBondDetailsById(id: any) {
    let url = `bonds/${id}/`;
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url: url, method: 'GET' })
  }

  getBonderDetailsById(id: any) {
    let url = `bonders/${id}/`;
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url: url, method: 'GET' })
  }

  getClassificationProfile(url: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url: url, method: 'GET' })
  }

  updateBond(data: any, bondId: any, bonderId: any) {
    return this.http.post<any>(`${BACKEND_URL}updateBond/${bondId}/${bonderId}/${this.mainId}`, data)
  }

  addData(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, body)
  }

  deleteData(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${this.mainId}`, body)
  }

  getAllProviderProfile(id: any) {
    let url = `bonds/${id}/valid_mobile_provider_profiles/`;
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url: url, method: 'GET' })
  }

  getAllFilters(bonderId: any) {
    let url = `dynamic_routing/bonder/filters/?bonder_id=${bonderId}`;
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url: url, method: 'GET' })
  }

  addIPV6Data(id: any, data: any) {
    let url = `bonds/${id}/autoipv6_addressings/`;
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { data: data, url: url, method: 'POST' })
  }

  addDHCPData(id: any, data: any) {
    let url = `bonds/${id}/dhcp_addressings/`;
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { data: data, url: url, method: 'POST' })
  }

  addStaticData(id: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    let url = `bonds/${id}/static_addressings/`
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { data: data, url: url, method: 'POST' }, { headers: headers })
  }

  addPPPoEData(id: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    let url = `bonds/${id}/pppoe_addressings/`;
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { data: data, url: url, method: 'POST' }, { headers: headers })
  }

  addInterface(id: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    let url = `bonds/${id}/interface_legs/`;
    data.role = localStorage.getItem('role');
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyLegs/${this.mainId}`, { data: data, url: url, method: 'POST' }, { headers: headers })
  }

  addMobileData(id: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    let url = `bonds/${id}/mobile_broadband_legs/`;
    data.role = localStorage.getItem('role');
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyLegs/${this.mainId}`, { data: data, url: url, method: 'POST' }, { headers: headers })
  }

  updateInterface(bondId: any, legId: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    let url = `bonds/${bondId}/interface_legs/${legId}/`;
    data.role = localStorage.getItem('role');
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyLegs/${this.mainId}`, { data: data, url: url, method: 'PATCH' }, { headers: headers })
  }

  updateMobileData(bondId: any, legId: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    let url = `bonds/${bondId}/mobile_broadband_legs/${legId}/`;
    data.role = localStorage.getItem('role');
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyLegs/${this.mainId}`, { data: data, url: url, method: 'PATCH' }, { headers: headers })
  }

  updateIPV6Data(url: any, data: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { data: data, url: url, method: 'PATCH' })
  }

  deleteIPV6Data(url: any, data: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${this.mainId}`, { data: data, url: url, method: 'DELETE' })
  }

  updateDHCPData(url: any, data: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { data: data, url: url, method: 'PATCH' })
  }

  deleteDHCPData(url: any, data: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${this.mainId}`, { data: data, url: url, method: 'DELETE' })
  }

  deletePPoEData(url: any, data: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${this.mainId}`, { data: data, url: url, method: 'DELETE' })
  }

  updateStaticData(url: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { data: data, url: url, method: 'PATCH' }, { headers: headers })
  }

  deleteStaticData(url: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${this.mainId}`, { data: data, url: url, method: 'DELETE' }, { headers: headers })
  }


  updatePPPoEData(url: any, data: any) {
    let headers = new HttpHeaders().set('noError', 'true')
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, { data: data, url: url, method: 'PATCH' }, { headers: headers })
  }

  deleteLegs(bondId: any, legId: any, data: any) {
    data.url = `bonds/${bondId}/interface_legs/${legId}/`;
    data.method = 'DELETE';
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyLegDelete/${this.mainId}`, data)
  }

  deleteMobile(bondId: any, legId: any, bondName: any) {
    let url = `bonds/${bondId}/mobile_broadband_legs/${legId}/`;
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${this.mainId}`, {
      url: url, method: 'DELETE', type: 'Mobile roadband',
      legId: legId, bondName: bondName, bondId: bondId
    })
  }

  getRestart(bondId: number) {
    let payload = { url: `bonds/${bondId}/restart/`, method: "POST" }
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, payload);
  }

  getDetectLegMTU(bondId: number) {
    let payload = { url: `bonds/${bondId}/detect_leg_mtus/`, method: "POST" }
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, payload);
  }

  saveTuneSettings(bondId: number, data: any) {
    let payload = { url: `bonds/${bondId}/tuners/`, method: "POST", data: data }
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, payload);
  }

  cancelTuning(bondId: number, tuneId: number, legId: any) {
    let payload = { url: `bonds/${bondId}/tuners/${tuneId}/cancel/`, method: "POST", data: { type: legId ? 'LegTuning' : 'BondTuning', operation: 'cancelled', bondId: bondId, legId: legId, tuneId: tuneId, bondName: localStorage.getItem('bondName') } }
    if (legId) payload.url = `bonds/${bondId}/interface_legs/${legId}/tuners/${tuneId}/cancel/`;
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, payload);
  }

  acknowledgementTuning(payload: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, payload);
  }

  getLatestTuning(bondId: number, tuneId: number, legId: any) {
    let payload = { url: `bonds/${bondId}/tuners/${tuneId}/`, method: "GET" }
    if (legId) payload.url = `bonds/${bondId}/interface_legs/${legId}/tuners/${tuneId}/`;
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, payload);
  }

  getBondAuth(bonderId: number) {
    let payload = { url: `bonders/${bonderId}/authorization/`, method: "GET" }
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, payload);
  }

  updateBondAuth(bonderId: number, data: any) {
    let payload = { url: `bonders/${bonderId}/authorization/`, method: "PATCH", data: data }
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, payload);
  }

  updateBonder(bonderId: number, data: any) {
    let payload = { url: `bonders/${bonderId}/`, method: "PATCH", data: data }
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, payload);
  }

  updateBondData(bondId: number, data: any) {
    let payload = { url: `bonds/${bondId}/`, method: "PATCH", data: data }
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdParty/${this.mainId}`, payload);
  }

  setUpSocket() {
    if (this.socket) return;
    let role = localStorage.getItem('role');
    let userIds = localStorage.getItem('userIds');
    let userData = {
      mainId: localStorage.getItem('mainId'),
      role: role,
      userId: role && Number(role) === AppConst.user ? localStorage.getItem('userId') : localStorage.getItem('ID'),
      isLoginUser: role && (Number(role) === AppConst.admin || Number(role) === AppConst.basicUser || !(userIds && userIds.length > 1)) ? true : false,
    }
    if (role) this.socket = io.connect(`${environment.apiUrl}`, { transports: ['websocket'], query: userData })
  }

  disconnectSocket() {
    this.socket.disconnect();
  }

  // bond status cached data event
  startGetCachedData(bondId: any) {
    if (!localStorage.getItem('mainId') || !bondId) return;
    this.socket.emit(AppConst.socketEmitEventList.startBondStatusEvent, { partnerId: localStorage.getItem('mainId'), bondId: bondId });
    this.listenCachedDataSocket();
  }

  listenCachedDataSocket() {
    this.socket.on(AppConst.socketListenerEventList.bondStatusEvent, (res: any) => {
      if (res && res.data && res.data.bondDetails) this.bondData.next(res.data.bondDetails);
      if (res && res.data && res.data.legDataList) this.legData.next(res.data.legDataList);
    });
  }

  stopGetCachedData(bondId: any) {
    if (!localStorage.getItem('mainId') || !bondId) return;
    this.socket.emit(AppConst.socketEmitEventList.stopBondStatusEvent, { partnerId: localStorage.getItem('mainId'), bondId: bondId });
    this.socket.off(AppConst.socketListenerEventList.bondStatusEvent);
    this.bondData.next(null);
    this.legData.next(null);
  }

  // Dashboard BullMq Event
  startDashboardBullMqEvent() {
    if (!localStorage.getItem('mainId')) return;
    this.socket.emit(AppConst.socketEmitEventList.startDashboardBullMqEvent, { partnerId: localStorage.getItem('mainId') });
  }

  stopDashboardBullMqEvent() {
    if (!localStorage.getItem('mainId')) return;
    this.socket.emit(AppConst.socketEmitEventList.stopDashboardBullMqEvent, { partnerId: localStorage.getItem('mainId') });
  }

  // Admin Dashboard Event
  startAdminDashboardEvent() {
    this.socket.emit(AppConst.socketEmitEventList.startAdminDashboardEvent);
    this.listenAdminDashboardEventSocket();
  }

  listenAdminDashboardEventSocket() {
    this.socket.on(AppConst.socketListenerEventList.adminDashboardEvent, (res: any) => {
      if (res && res.data) this.systemPerformanceData.next(res.data);
    });
  }

  stopAdminDashboardEvent() {
    this.socket.emit(AppConst.socketEmitEventList.stopAdminDashboardEvent);
    this.socket.off(AppConst.socketListenerEventList.adminDashboardEvent);
    this.systemPerformanceData.next(null);
  }

  // active bond list socket
  emitActiveBondSocket() {
    if (!localStorage.getItem('mainId')) return;
    let payload: any = { partnerId: localStorage.getItem('mainId'), role: localStorage.getItem('role') };
    let space = localStorage.getItem('homeSpace');
    payload['homeSpace'] = space && space != 'undefined' ? space : null;
    if (payload.role == '6') payload['org_id'] = localStorage.getItem('ID');
    if (payload.role == '2') payload['parentRole'] = localStorage.getItem('parentRole');
    if (payload.role == '2' && payload['parentRole'] == '6' && localStorage.getItem('orgUserId')) payload['org_id'] = localStorage.getItem('orgUserId');
    this.socket.emit(AppConst.socketEmitEventList.startActiveBondListEvent, payload);
    this.listenActiveBondSocket();
  }

  emitSpaceBondSocket(key: string) {
    if (!localStorage.getItem('mainId')) return;
    let payload: any = { partnerId: localStorage.getItem('mainId'), role: localStorage.getItem('role'), space: key };
    this.socket.emit(AppConst.socketEmitEventList.startActiveBondListEvent, payload);
    this.listenActiveBondSocket();
  }

  listenActiveBondSocket() {
    this.socket.on(AppConst.socketListenerEventList.activeBondListEvent, (res: any) => {
      if (res && res.data) this.activeBondList.next(res.data);
    });
  }

  disconnectActiveBondSocket() {
    this.socket.emit(AppConst.socketEmitEventList.stopActiveBondListEvent);
    this.socket.off(AppConst.socketListenerEventList.activeBondListEvent);
    this.activeBondList.next(null);
  }

  // activity log socket
  emitActivityLogs() {
    let loginId = localStorage.getItem('ID');
    let partnerId = localStorage.getItem('rootID') || localStorage.getItem('mainId');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    this.socket.emit(AppConst.socketEmitEventList.startActivityLogsEvent, { partnerId: partnerId, loginId: loginId });
    this.listenActivitySocket();
  }

  listenActivitySocket() {
    this.socket.on(AppConst.socketListenerEventList.activityLogsEvent, (res: any) => {
      if (res && res.data) this.activityBondList.next(res.data);
    });
  }

  disconnectActivityBondSocket() {
    this.socket.emit(AppConst.socketEmitEventList.stopActivityLogsEvent);
    this.socket.off(AppConst.socketListenerEventList.activityLogsEvent);
    this.activityBondList.next(null);
  }

  // latest notification list socket
  emitLatestNotificationSocket(filterDate: any) {
    if (!localStorage.getItem('mainId')) return;
    let userId = localStorage.getItem('ID') ?? "";
    let partnerId = localStorage.getItem('mainId');
    let params = {
      userId: userId,
      partnerId: partnerId,
      filterDate: filterDate
    }
    this.socket.emit(AppConst.socketEmitEventList.startLatestNotificationEvent, params);
    this.listenLatestNotificationSocket();
  }

  listenLatestNotificationSocket() {
    this.socket.on(AppConst.socketListenerEventList.latestNotificationEvent, (res: any) => {
      if (res && res.data) this.latestNotificationList.next(res.data);
    });
  }

  disconnectLatestNotificationSocket() {
    this.socket.emit(AppConst.socketEmitEventList.stopLatestNotificationEvent);
    this.socket.off(AppConst.socketListenerEventList.latestNotificationEvent);
    this.latestNotificationList.next(null);
  }

  // online user list socket
  emitOnlineUserSocket() {
    let role = localStorage.getItem('role') ?? "";
    if (!localStorage.getItem('mainId') && (Number(role) != AppConst.admin && Number(role) != AppConst.basicUser)) return;
    let partnerId = localStorage.getItem('mainId');
    let userId = localStorage.getItem('ID');
    let params = {
      role: role,
      partnerId: partnerId,
      userId: userId,
    }
    this.socket.emit(AppConst.socketEmitEventList.startOnlineUserListEvent, params);
    this.listenOnlineUserSocket();
  }

  listenOnlineUserSocket() {
    this.socket.on(AppConst.socketListenerEventList.onlineUserListEvent, (res: any) => {
      if (res && res.data) this.onlineUserList.next(res.data);
    });
  }

  disconnectOnlineUserSocket() {
    this.socket.emit(AppConst.socketEmitEventList.stopOnlineUserListEvent);
    this.socket.off(AppConst.socketListenerEventList.onlineUserListEvent);
    this.onlineUserList.next(null);
  }

  // terminal socket
  emitTerminalSocket(data: any) {
    let role = localStorage.getItem('role') ?? "";
    if (!localStorage.getItem('mainId')) return;
    let partnerId = localStorage.getItem('mainId');
    let userId = localStorage.getItem('ID');
    let params = {
      role: role,
      partnerId: partnerId,
      userId: userId,
      bondId: data.bondId,
      bondName: data.bondName,
      nodeKey: data.nodeKey,
      mac: data.mac,
    }
    this.socket.emit(AppConst.socketEmitEventList.startBondTerminalEvent, params);
    this.listenTerminalSocket();
  }

  sendBondCommandEvent(command: string) {
    let role = localStorage.getItem('role') ?? "";
    if (!localStorage.getItem('mainId') && (Number(role) != AppConst.admin && Number(role) != AppConst.basicUser)) return;
    let partnerId = localStorage.getItem('mainId');
    let userId = localStorage.getItem('ID');
    let params = { role, partnerId, userId, command }
    this.socket.emit(AppConst.socketEmitEventList.sendBondCommandEvent, params);
  }

  listenTerminalSocket() {
    this.socket.on(AppConst.socketListenerEventList.terminalBondEvent, (res: any) => {
      if (res && res.data) this.terminalData.next(res.data);
    });
  }

  disconnectTerminalSocket() {
    this.socket.emit(AppConst.socketEmitEventList.stopBondTerminalEvent);
    this.socket.off(AppConst.socketListenerEventList.terminalBondEvent);
    this.terminalData.next(null);
  }

  getBondActivity(bondId: any, type: any = null) {
    let loginId = localStorage.getItem('ID');
    this.mainId = localStorage.getItem('mainId') || localStorage.getItem('rootID');
    if (Number(localStorage.getItem('role')) == AppConst.user && Number(localStorage.getItem('parentRole')) == AppConst.organization && localStorage.getItem('orgUserId')) {
      loginId = localStorage.getItem('orgUserId');
    }
    return this.http.post<any>(`${BACKEND_URL}bondActivity/${this.mainId}`, { loginId: loginId, bondId: bondId, type: type })
  }


  getFailbackMessage(method: any, url: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url: url, method: method })
  }

  generateIlluminateToken(key: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(`${environment.apiUrl}/partnerPanel/generateIlluminateJwtToken/${this.mainId}`, { agentKey: key })
  }

  getIlluminateData() {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.get<any>(`${environment.apiUrl}/partnerPanel/getIlluminateData/${this.mainId}`)
  }

  saveIlluminateData(data: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(`${environment.apiUrl}/partnerPanel/saveIlluminateData/${this.mainId}`, data)
  }

  illuminateProvisioning(data: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(`${environment.apiUrl}/partnerPanel/illuminateProvisioning/${this.mainId}`, data)
  }

  illuminateUpdateProvisioning(data: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(`${environment.apiUrl}/partnerPanel/updateIlluminateProvisioning/${this.mainId}`, data)
  }

  checkIlluminateAgent(bondKey: any) {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.get<any>(`${environment.apiUrl}/partnerPanel/checkIlluminateAgent/${bondKey}/${this.mainId}`)
  }

  getIlluminatePlanListUser() {
    this.mainId = localStorage.getItem('mainId');
    if (!this.mainId) return;
    return this.http.get<any>(`${environment.apiUrl}/partnerPanel/illuminatePlanList/${this.mainId}`)
  }
}
