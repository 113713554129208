import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'app-shared-space-wan-add-vlan',
  templateUrl: './shared-space-wan-add-vlan.component.html',
  styleUrls: ['./shared-space-wan-add-vlan.component.scss']
})
export class SharedSpaceWanAddVlanComponent implements OnInit {
  vlanForm: FormGroup;
  groupList: any = [];
  vlanData: any;
  parentId: any;
  errors: any;
  isEdit: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef, private cookie: CookieService,
    private sharedService: SharedService,
    private spaceService: PartnerSpaceService,) { }

  ngOnInit(): void {
    if (this.isEdit) this.vlanForm = this.createForm(this.vlanData);
    else this.vlanForm = this.createForm();
    this.vlanForm.valueChanges.subscribe(x => { this.errors = null; });
  }

  createForm(data: any = {}) {
    return new FormGroup({
      id: new FormControl(data?.id || null),
      ipv4_address_pool: new FormControl(data?.ipv4_address_pool || null),
      ipv4_excluded_addresses: new FormControl(data?.ipv4_excluded_addresses?.join(',') || []),
      ipv6_address_pool: new FormControl(data?.ipv6_address_pool || null),
      ipv6_excluded_addresses: new FormControl(data?.ipv6_excluded_addresses?.join(',') || []),
      routing_group: new FormControl(data?.routing_group || ''),
      url: new FormControl(data?.url || null),
      vlan_id: new FormControl(data?.vlan_id || null)
    })
  }

  get f() {
    return this.vlanForm.controls;
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onSave() {
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    this.errors = null;
    let body: any = {
      id: this.vlanForm.value.id,
      ipv4_address_pool: this.vlanForm.value.ipv4_address_pool,
      ipv4_excluded_addresses: (this.vlanForm.value.ipv4_excluded_addresses && this.vlanForm.value.ipv4_excluded_addresses.length > 0) ?
        this.vlanForm.value.ipv4_excluded_addresses.split(',') : [],
      ipv6_address_pool: this.vlanForm.value.ipv6_address_pool,
      ipv6_excluded_addresses: (this.vlanForm.value.ipv6_excluded_addresses && this.vlanForm.value.ipv6_excluded_addresses.length > 0) ?
        this.vlanForm.value.ipv6_excluded_addresses.split(',') : [],
      routing_group: this.vlanForm.value.routing_group,
      space_private_wan: `${apiURL}spaces/${this.parentId}/private_wan/`,
      url: this.vlanForm.value.url,
      vlan_id: this.vlanForm.value.vlan_id
    }

    let IpObject = {
      method: "POST",
      url: `spaces/${this.parentId}/private_wan/space_routing_group_vlan_assignments/`,
      data: body
    }
    if (this.isEdit) {
      IpObject.url = `spaces/${this.parentId}/private_wan/space_routing_group_vlan_assignments/${this.vlanData.id}/`;
      IpObject.method = 'PATCH';
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(IpObject).subscribe((addRes) => {
      if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess(addRes.message);
      else this.sharedService.loggerError(addRes.message);
      this.activeModal.close({ event: 'save' });
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    })
  }
}
