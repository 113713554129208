import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedBondAuthorizationModalComponent } from './shared-bond-authorization-modal/shared-bond-authorization-modal.component';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
@Component({
  selector: 'app-shared-bonds-create',
  templateUrl: './shared-bonds-create.component.html',
  styleUrls: ['./shared-bonds-create.component.scss']
})
export class SharedBondsCreateComponent implements OnInit {
  configurationForm!: FormGroup
  detailsForm!: FormGroup;
  flowCollectors: any[] = [];
  errors: any;
  space: any;
  bonderId: any;
  bondId: any;
  configurationData: any;
  isHomeSpace: boolean = true;
  isConfig: boolean = false;
  isSpace: boolean = false;
  SDSpace: any;
  encryptionEnabled: boolean = false;
  isFlowletSelected: boolean = false;
  isLegMTUSelected: boolean = true;
  isReorderMaxSelected: boolean = true;
  isTCPProxySelected: boolean = false;
  NameFieldValidation: any = AppConst.NameFieldPattern;

  tunnelSecurity = [
    {
      key: 'encryption',
      title: 'Encryption'
    },
    {
      key: 'hmac',
      title: 'HMAC'
    },
    {
      key: 'none',
      title: 'No Security'
    },
  ];

  cpuGoverners: any[] = [
    {
      key: 'conservative',
      title: 'Conservative'
    },
    {
      key: 'powersave',
      title: 'Powersave'
    },
    {
      key: 'ondemand',
      title: 'On Demand'
    },
    {
      key: 'performance',
      title: 'Performance'
    },
  ];

  encyptionCipher: any[] = [
    {
      key: 'AES128',
      title: 'AES 128'
    },
    {
      key: 'AES256',
      title: 'AES 256'
    }
  ];

  packetDistribution: any[] = [
    {
      key: 'wrr',
      title: 'Weighted Round Robin'
    },
    {
      title: 'Flowlet',
      key: 'flowlet'
    },
    {
      key: 'idmpq',
      title: 'IDMPQ'
    }
  ];

  conntrackTableSize: any[] = [
    {
      key: '4096',
      title: '4096'
    },
    {
      key: '8192',
      title: '8192'
    },
    {
      key: '16384',
      title: '16384'
    },
    {
      key: '32768',
      title: '32768'
    },
    {
      key: '65536',
      title: '65536'
    },
    {
      key: '131072',
      title: '131072'
    },
    {
      key: '262144',
      title: '262144'
    },
    {
      key: '524288',
      title: '524288'
    },
    {
      key: '1048576',
      title: '1048576'
    },
  ];

  bridgeCongetionAlgorithms: any[] = [
    {
      key: 'bbr',
      title: 'BBR (Recommended)'
    },
    {
      title: 'CUBIC',
      key: 'cubic'
    },
    {
      title: 'BIC',
      key: 'bic'
    },
    {
      title: 'CDG',
      key: 'cdg'
    },
    {
      title: 'Datacenter TCP',
      key: 'dctcp'
    },
    {
      title: 'Hamilton TCP',
      key: 'htcp'
    },
    {
      title: 'Highspeed TCP',
      key: 'highspeed'
    },
    {
      title: 'Hybla',
      key: 'hybla'
    },
    {
      title: 'Illinois',
      key: 'illinois'
    },
    {
      title: 'Low Priority',
      key: 'lp'
    },
    {
      title: 'Reno',
      key: 'reno'
    },
    {
      title: 'Scalable TCP',
      key: 'scalable'
    },
    {
      title: 'Vegas',
      key: 'vegas'
    },
    {
      title: 'Veno',
      key: 'veno'
    },
    {
      title: 'Westwood',
      key: 'westwood'
    },
    {
      title: 'Yeah TCP',
      key: 'yeah'
    },
  ];

  @Input() spaces: any[] = [];
  @Input() allQoSProfile: any[] = [];
  @Input() allClassificationProfile: any[] = [];
  @Input() allAggregators: any[] = [];
  @Input() bondsInfo: any;
  @Input() isEdit: any;

  @Output() onBackEvent = new EventEmitter<any>();
  @Output() onCancelEvent = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private bondingService: PartnerBondingService,
    private router: Router, private spaceService: PartnerSpaceService,
    private sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private formErrorHandler: FormErrorService,
    private route: ActivatedRoute) { }


  ngOnInit(): void {
    this.isConfig = this.route.snapshot.queryParamMap.get('isconfig') ? true : false;
    this.isSpace = this.route.snapshot.queryParamMap.get('isSpace') ? true : false;
    this.route.params.subscribe((params: any) => {
      if (params.bondId) {
        this.bondId = params.bondId;
        this.bonderId = params.bonderId;
      }
    });
    if (localStorage.getItem('homeSpace') == '0') this.isHomeSpace = false;
    this.configurationForm = this.createConfigurationForm(this.bondsInfo);
    this.detailsForm = this.createDetailsForm(this.bondsInfo);
    this.space = this.bondsInfo?.space?.key;
    this.onEncryptionChange();
    this.onPacketDistributionChange();
    this.onLegMTUDetectionChnage();
    this.onReorderMaxChnage();
    this.onTCPProxyChange();
    setTimeout(() => {
      if (!this.space) {
        this.spaces?.forEach((item: any) => {
          if (item && item.level == 0) this.space = item.key;
        });
        this.cd.detectChanges();
      }
    }, 5000);
  }

  onBack() {
    this.onBackEvent.emit();
  }

  get aggregator() {
    return this.configurationForm.get('aggregator');
  }

  get name() {
    return this.detailsForm.get('name');
  }

  createDetailsForm(data: any = {}) {
    return this.fb.group({
      metric_collection_interval: [data?.bonderInfo?.metric_collection_interval || 10],
      administrative_profile: [data?.administrative_profile || 'default'],
      note: [data?.note || '', [this.sharedService.xssValidator]],
      circuit_id: [data?.circuit_id || '', [this.sharedService.xssValidator, Validators.maxLength(50)]],
      product: [data?.product || '', [this.sharedService.xssValidator, Validators.maxLength(200)]],
      proof_of_concept: [data?.proof_of_concept || false],
      metric_reporting_interval: [data?.bonder?.metric_reporting_interval || 60],
      cpu_governor: [data?.bonderInfo?.cpu_governor || ''],
      tcp_congestion_control_algorithm: [data?.bonderInfo?.tcp_congestion_control_algorithm || 'bbr'],
      conntrack_table_size: [data?.bonderInfo?.conntrack_table_size || 131072],
      manage_process_affinity: [data?.bonderInfo?.manage_process_affinity == false ? false : true],
      tunnel_affinity_core: [data?.bonderInfo?.tunnel_affinity_core || 2],
      dns_servers: [data?.bonderInfo?.dns_servers && data?.bonderInfo?.dns_servers.length > 0 ? data?.bonderInfo?.dns_servers.join() : ''],
      automatic_source_ip: [data?.bonderInfo?.automatic_source_ip || false],
      web_server: [data?.bonderInfo?.web_server == false ? false : true],
      name: [data?.bonder?.name, [this.sharedService.xssValidator, Validators.pattern(this.NameFieldValidation)]],
      asset_tag: [data?.bonder?.asset_tag || '', [this.sharedService.xssValidator, Validators.maxLength(100)]],
      serial_number: [data?.bonder?.serial_number || '', [this.sharedService.xssValidator, Validators.maxLength(50)]],
      debug: [data?.bonder?.debug || false]
    })
  }

  createConfigurationForm(data: any = {}) {
    setTimeout(() => {
      if (data && data.qos_profile) {
        this.allQoSProfile.forEach((item: any) => {
          if (item && item.url && item.url == data.qos_profile?.url) this.configurationForm.controls['qos_profile'].setValue(item.id);
        });
      }
      if (!this.configurationForm.controls['qos_profile'].value && !this.isEdit) {
        this.allQoSProfile.forEach((item: any) => {
          if (item && item.id && item.id == 1) this.configurationForm.controls['qos_profile'].setValue(item.id);
        });
      }
    }, 4000);
    return this.fb.group({
      aggregator: [data?.aggregator, [Validators.required]],
      secondary_aggregator: [data?.secondary_aggregator],
      aggregator_failback: [data?.aggregator_failback || false],
      qos_profile: [''],
      flow_collectors: [data?.flow_collectors],
      classification_profile: [data?.classification_profile],
      compression: [data?.compression == false ? false : true],
      tunnel_security: [data?.tunnel_security || 'hmac'],
      encryption_cipher: [data?.encryption_cipher || 'AES128'],
      encryption_handshake_interval: [data?.encryption_handshake_interval || 3600],
      packet_distribution: [data?.packet_distribution || 'wrr'],
      flowlet_delta: [data?.flowlet_delta || 0],
      batched_leg_tx: [data?.batched_leg_tx || false],
      clamp_tcp: [data?.clamp_tcp == false ? false : true],
      encryption_replay_protection: [data?.encryption_replay_protection == false ? false : true],
      automatic_ping_timing: [data?.automatic_ping_timing == false ? false : true],
      regular_leg_ping_time: [data?.regular_leg_ping_time || 100],
      regular_leg_fail_time: [data?.regular_leg_fail_time || 300],
      failover_leg_ping_time: [data?.failover_leg_ping_time || 1000],
      failover_leg_fail_time: [data?.failover_leg_fail_time || 3000],
      automatic_reorder_max_hold: [data?.automatic_reorder_max_hold == false ? false : true],
      reorder_max_hold: [data?.reorder_max_hold || 30],
      packet_loss_detection: [data?.packet_loss_detection == false ? false : true],
      flap_detection: [data?.flap_detection == false ? false : true],
      leg_mtu_detection: [data?.leg_mtu_detection == false ? false : true],
      leg_mtu_detection_time: [data?.leg_mtu_detection_time || 1],
      send_jitter_buffer: [data?.send_jitter_buffer || false],
      source_address_verification: [data?.source_address_verification || false],
      replify_enabled: [data?.replify_enabled || false],
      debug: [data?.debug || false],
      proof_of_concept: [data?.proof_of_concept || false],
      bridge_enabled: [data?.bridge_enabled || false],
      bridge_ports: [data?.bridge_ports || '80,443,8080'],
      bridge_concurrency: [data?.bridge_concurrency || 4],
      bridge_congestion_control_algorithm: [data?.bridge_congestion_control_algorithm || 'bbr'],
      bridge_file_descriptor_limit: [data?.bridge_file_descriptor_limit || 16384],
    })
  }

  onSave() {
    this.errors = null;
    if (this.configurationForm.invalid || this.detailsForm.invalid) {
      this.configurationForm.markAllAsTouched();
      this.detailsForm.markAllAsTouched();
      return;
    }
    let bond = this.configurationForm.value;
    let bonder = this.detailsForm.value;
    let qp = this.configurationForm.get('qos_profile')?.value;
    let selectedQP = this.allQoSProfile.find((x: any) => x.id == qp);
    let spaceSelected = this.spaces.find((x: any) => x.key == this.space);
    let dnsList = this.detailsForm.controls['dns_servers'].value;
    if (dnsList) dnsList = dnsList.split(',');
    else dnsList = [];
    bonder = { ...bonder, dns_servers: dnsList };

    bond = {
      ...bond,
      qos_profile: qp ? { url: selectedQP?.url || '', name: selectedQP?.name || '' } : null,
      note: this.detailsForm.get('note')?.value,
      circuit_id: this.detailsForm.get('circuit_id')?.value,
      product: this.detailsForm.get('product')?.value,
      proof_of_concept: this.detailsForm.get('proof_of_concept')?.value,
      asset_tag: this.detailsForm.get('asset_tag')?.value,
    }
    if (this.isHomeSpace) bond = {
      ...bond,
      space: {
        url: spaceSelected?.url || '',
        name: spaceSelected?.name || '',
        key: spaceSelected?.key || '',
        private_wan_enabled: false
      }
    };
    if (this.isEdit) this.onEditBond({ bond, bonder });
    else this.onAddBond({ ...bond, bonder: bonder });
  }

  onAddBond(event: any) {
    console.log('event', event)
    this.sharedService.showLoader()
    this.bondingService.addBond(event).subscribe((addRes) => {
      console.log('code', addRes)
      if (addRes.code != 400) {
        this.sharedService.loggerSuccess(addRes.message);
        if (this.isSpace) {
          this.spaceService.spaceParentId$.subscribe((data: any) => {
            if (data && data.parentId) {
              this.sharedService.setCipherText(this.spaceService.spaceParentId, 'space')
              this.router.navigate([`/partner/sd-wan/sd-network/edit`]);
            } else {
              let route = '/partner/bonds';
              if (this.isConfig) {
                if (!this.bondId || !this.bonderId) return;
                this.sharedService.setCipherText(this.bondId, 'bondId');
                this.sharedService.setCipherText(this.bonderId, 'bonderId');
                route = '/partner/bonds/view';
              }
              this.router.navigate([route], (this.isConfig) ? { queryParams: { isconfig: true } } : {});
            }
          });
        } else {
          let route = '/partner/bonds';
          if (this.isConfig) {
            if (!this.bondId || !this.bonderId) return;
            this.sharedService.setCipherText(this.bondId, 'bondId');
            this.sharedService.setCipherText(this.bonderId, 'bonderId');
            route = '/partner/bonds/view';
          }
          this.router.navigate([route], (this.isConfig) ? { queryParams: { isconfig: true } } : {});
        }
      }
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        Object.keys(this.errors).forEach(x => {
          console.log(x);
          let configurationFormControl = this.configurationForm.get(x);
          if (configurationFormControl) {
            this.configurationForm.setErrors({ ...this.configurationForm.errors, [x]: this.errors[x] })
          }
          let detailsFormControl = this.detailsForm.get(x);
          if (detailsFormControl) {
            this.detailsForm.setErrors({ ...this.detailsForm.errors, [x]: this.errors[x] })
          }
        })
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    }
    )
  }

  onEditBond(data: any) {
    this.sharedService.showLoader();
    this.bondingService.updateBond(data, this.bondId, this.bonderId).subscribe((res: any) => {
      if (res.code != 400) {
        if (res.data && res.data.bondCode == 200 && res.data.bonderCode == 200) {
          this.sharedService.loggerSuccess('bond update successfully');
          if (this.isSpace) {
            this.spaceService.spaceParentId$.subscribe((data: any) => {
              if (data && data.parentId) {
                this.sharedService.setCipherText(this.spaceService.spaceParentId, 'space')
                this.router.navigate([`/partner/sd-wan/sd-network/edit`]);
              } else{
                let route = '/partner/bonds';
                if (this.isConfig) {
                  if (!this.bondId || !this.bonderId) return;
                  this.sharedService.setCipherText(this.bondId, 'bondId');
                  this.sharedService.setCipherText(this.bonderId, 'bonderId');
                  route = '/partner/bonds/view';
                }
                this.router.navigate([route], (this.isConfig) ? { queryParams: { isconfig: true } } : {});
              }
            });
          } else {
            let route = '/partner/bonds';
            if (this.isConfig) {
              if (!this.bondId || !this.bonderId) return;
              this.sharedService.setCipherText(this.bondId, 'bondId');
              this.sharedService.setCipherText(this.bonderId, 'bonderId');
              route = '/partner/bonds/view';
            }
            this.router.navigate([route], (this.isConfig) ? { queryParams: { isconfig: true } } : {});
          }
        } else {
          if (res.data.bondCode != 200) {
            this.errors = res.data.bond;
            Object.keys(this.errors).forEach(x => {
              let configurationFormControl = this.configurationForm.get(x);
              if (configurationFormControl) this.configurationForm.setErrors({ ...this.configurationForm.errors, [x]: this.errors[x] });
            });
          }
          if (res.data.bonderCode != 200) {
            this.errors = res.data.bonder;
            Object.keys(this.errors).forEach(x => {
              let detailsFormControl = this.detailsForm.get(x);
              if (detailsFormControl) this.detailsForm.setErrors({ ...this.detailsForm.errors, [x]: this.errors[x] });
            });
          }
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
        }
      } else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        Object.keys(this.errors).forEach(x => {
          console.log(x);
          let configurationFormControl = this.configurationForm.get(x);
          if (configurationFormControl) {
            this.configurationForm.setErrors({ ...this.configurationForm.errors, [x]: this.errors[x] })
          }
          let detailsFormControl = this.detailsForm.get(x);
          if (detailsFormControl) {
            this.detailsForm.setErrors({ ...this.detailsForm.errors, [x]: this.errors[x] })
          }
        })
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError(err);
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }


  popupChangePasswordModal() {
    this.sharedService.showLoader();
    this.bondingService.getBondAuth(this.bonderId)?.subscribe((res: any) => {
      this.sharedService.hideLoader();
      if (res.code == 200) {
        let modal = this.modalService.open(SharedBondAuthorizationModalComponent, { size: 'lg' });
        modal.componentInstance.bonderId = this.bonderId;
        modal.componentInstance.authData = res.data;
      } else this.sharedService.loggerError(res.message);
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  onEncryptionChange() {
    let value = this.configurationForm.get('tunnel_security')?.value;
    if (value == 'encryption') this.encryptionEnabled = true;
    else this.encryptionEnabled = false;
    this.cd.detectChanges();
  }

  onPacketDistributionChange() {
    let value = this.configurationForm.get('packet_distribution')?.value;
    if (value == 'flowlet') this.isFlowletSelected = true;
    else this.isFlowletSelected = false;
    this.cd.detectChanges();
  }


  onLegMTUDetectionChnage() {
    let value = this.configurationForm.get('leg_mtu_detection')?.value;
    if (value) this.isLegMTUSelected = true;
    else this.isLegMTUSelected = false;
    this.cd.detectChanges();
  }

  onReorderMaxChnage() {
    let value = this.configurationForm.get('automatic_reorder_max_hold')?.value;
    if (value) this.isReorderMaxSelected = true;
    else this.isReorderMaxSelected = false;
    this.cd.detectChanges();
  }

  onTCPProxyChange() {
    let value = this.configurationForm.get('bridge_enabled')?.value;
    if (value) this.isTCPProxySelected = true;
    else this.isTCPProxySelected = false;
    this.cd.detectChanges();
  }

  onCancel() {
    this.onCancelEvent.emit();
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.detailsForm);

  }
}
