import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { environment } from 'src/environments/environment';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root', })
export class AuthHTTPService {

  constructor(private http: HttpClient) { }

  login(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/admin/login`, data);
  }

  resendOTP(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/admin/reSendOtp`, data);
  }

  partnerLogin(body: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/partnerPanel/partnerLogin`, body);
  }

  refreshToken(): Observable<any> {
    let mainId = localStorage.getItem('mainId');
    return this.http.get(`${API_USERS_URL}/partner/refreshToken/${mainId}`);
  }

  getParent(email: string, mainId: string) {
    return this.http.post(`${API_USERS_URL}/admin/getParent`, { email, mainId })
  }

  getOrganization(mainId: string, parentId: any, email: any) {
    return this.http.post(`${API_USERS_URL}/admin/getOrganization/${mainId}/${parentId}`, { email })
  }

  getOrganizationId(id: string, orgId: any) {
    return this.http.get(`${API_USERS_URL}/admin/getOrgId/${id}/${orgId}`)
  }

  forgotPassword(payload: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/admin/forgotPassword`, payload);
  }

  forgotPartnerPassword(payload: any): Observable<any> {
    let mainId = localStorage.getItem('mainId');
    return this.http.post(`${API_USERS_URL}/partnerPanel/forgotPasswordLink/${mainId}`, payload);
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/admin/reset`, data);
  }

  getBrandSettingBySubdomain(subDomain: string): Observable<any> {
    return this.http.post(`${API_USERS_URL}/partner/getBrandSettingBySubdomain`, { subDomain });
  }

  createPassword(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/partner/reset`, data);
  }

  getUserByToken(token: string): Observable<any> {
    let role = localStorage.getItem('role')
    const httpHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` });
    if (Number(role) == AppConst.admin || Number(role) == AppConst.basicUser) {
      return this.http.get<any>(`${API_USERS_URL}/admin/getAdminProfile`, {
        headers: httpHeaders,
      });
    } else if (Number(role) == AppConst.partner) {
      return this.http.post<any>(`${API_USERS_URL}/partnerPanel/getProfile`, {
        headers: httpHeaders,
        id: localStorage.getItem('mainId'),
      });
    } else if (Number(role) == AppConst.user) {
      return this.http.post<any>(`${API_USERS_URL}/partnerPanel/getProfile`, {
        headers: httpHeaders,
        id: localStorage.getItem('mainId'),
        organizationId: localStorage.getItem('organization')
      });
    } else if (Number(role) == AppConst.organization) {
      return this.http.post<any>(`${API_USERS_URL}/partnerPanel/getProfile`, {
        headers: httpHeaders,
        id: localStorage.getItem('mainId'),
        organizationId: localStorage.getItem('organization')
      });
    } else {
      return of(null);
    }
  }

  logoutFromPartner(): Observable<any> {
    let adminID = localStorage.getItem('rootAdminID');
    let mainId = localStorage.getItem('mainId');
    let LoginID = localStorage.getItem('ID');
    let data = { adminID, LoginID, mainId }
    return this.http.post(`${API_USERS_URL}/partnerPanel/PartnerLPLogout`, data);
  }

  logoutFromUser(data: any): Observable<any> {
    let mainId = localStorage.getItem('mainId')
    return this.http.post(`${API_USERS_URL}/partnerPanel/usersPanel/UserLPLogout/${mainId}`, data);
  }

  setPartnerAdminTimer(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/partnerPanel/partnerLP`, {
      LoginID: data.LoginID,
      id: data.id,
      mainId: data.mainId,
      duration: data.duration,
      role: data.role
    });
  }

  setUserPartnerTimer(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/partnerPanel/userLP`, {
      LoginID: data.LoginID,
      id: data.id,
      mainId: data.mainId,
      duration: data.duration,
      role: data.role
    });
  }

  updatePartnerAdminTimer(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/partnerPanel/partnerupdateLP`, {
      LoginID: data.LoginID,
      id: data.id,
      mainId: data.mainId,
      duration: data.duration,
      role: data.role
    });
  }

  updateUserPartnerTimer(data: any): Observable<any> {
    let mainId = localStorage.getItem('mainId');
    return this.http.put(`${API_USERS_URL}/partnerPanel/usersPanel/updateUserLPDetail/${mainId}`, data
      //  {
      //   LoginID: data.LoginID,
      //   id: data.id,
      //   mainId: data.mainId,
      //   duration: data.duration,
      //   role: data.role
      // }
    );
  }

}
