import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { CookieComponent } from "src/app/_metronic/kt/components";
import { LayoutService } from 'src/app/_metronic/layout';
import { DefaultLayoutConfig, ILayout } from 'src/app/_metronic/layout/core/default-layout.config';
import { BrandingService } from 'src/app/services/branding.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { VersionService } from 'src/app/services/version.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import Swal from 'sweetalert2';
import { AuthHTTPService } from './auth-http';
export type UserType = any | undefined;
export type imageType = string | null;
// import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({ providedIn: 'root', })
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  otpSubject: BehaviorSubject<any>;
  tfaSubject: BehaviorSubject<any>;
  forgotPwdSubject$: BehaviorSubject<any>;
  accessTimeSubject: BehaviorSubject<any>;
  imageUrl$: Observable<any>;
  imageUrlSubject: BehaviorSubject<imageType>;
  isShowTimerAccessSubject: BehaviorSubject<boolean>;
  isShowTimerAccess$: Observable<boolean>;
  navBar: BehaviorSubject<any>;
  defaultConfigs: any;
  fieldsToUpdate: Partial<ILayout>;
  showHeaderSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  nameBreadcrumb: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  navBarTextSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  brandingFileIdSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  technicalSupportObj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userIds: any[] = [];
  versionId: any;
  appVersion: any = '0.0.0.'
  leftMenuflag: boolean;
  topMenuflag: boolean;
  state: any;
  navbgColour: any = '#000000';
  navLinkColour: any = '#000000';
  navLinkHoverColour: any = '#000000'
  navLinkActiveColour: any = '#000000';
  navLinkbackgroundColour: any = '#000000';
  brandingTextColour: any = '#000000';

  textColour: any = '#000000'
  linkColour: any = '#000000';
  linkHoverColour: any = '#000000';
  successColour: any = '#000000';
  infoColour: any = '#000000';
  warningColour: any = '#000000';
  dangerColour: any = '#000000';
  bodyBackgroundColour: any = '#000000';
  buttonPrimaryColour: any = '#0095e8';
  buttonTextColour: any = '#ffffffff';
  buttonSecondaryColour: any = '#6c757d';
  buttonDangerColour: any = '#dd3333';
  loginPageUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  faviconUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  navBarUrl: string | ArrayBuffer | null = './assets/media/logos/login-logo.svg';
  menu: any = 1;
  loginPageAPIUrl: any;
  navBarAPIUrl: any;
  faviconAPIUrl: any;
  customCSSText: any = '';
  brandingId: any;
  brandSettings: any;
  isPreview: boolean = false;
  apiBrandingData: any;
  favIcon: any = document.querySelector('#appIcons');

  get navBar$() {
    return this.navBar.asObservable();
  }

  get navBarText$() {
    return this.navBarTextSubject.asObservable();
  }

  get technicalSupport$() {
    return this.technicalSupportObj.asObservable();
  }

  get brandingFileId$() {
    return this.brandingFileIdSubject.asObservable();
  }

  get currentImageUrl() {
    return this.imageUrlSubject.value;
  }

  set currentImageUrl(image: any) {
    this.imageUrlSubject.next(image);
  }

  get showHeader$() {
    return this.showHeaderSubject.asObservable();
  }

  get nameBreadcrumb$() {
    return this.nameBreadcrumb.asObservable();
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private partnerBondingService: PartnerBondingService,
    private router: Router, private cookie: CookieService,
    public spinner: NgxSpinnerService,
    private layout: LayoutService,
    private brandSettingService: BrandingService,
    private versionService: VersionService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.imageUrlSubject = new BehaviorSubject<imageType>(null);
    this.otpSubject = new BehaviorSubject<any>(null);
    this.tfaSubject = new BehaviorSubject<any>(null);
    this.forgotPwdSubject$ = new BehaviorSubject<any>(null);
    this.accessTimeSubject = new BehaviorSubject<any>(null);
    this.navBar = new BehaviorSubject<any>('./assets/media/logos/login-logo.svg');
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.imageUrl$ = this.imageUrlSubject.asObservable();
    this.isShowTimerAccessSubject = new BehaviorSubject<any>(null);
    this.isShowTimerAccess$ = this.isShowTimerAccessSubject.asObservable();
    this.defaultConfigs = DefaultLayoutConfig;
    // changes for profile API call.
    // const subscr = this.getUserByToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  loginWithOTPAdmin(data: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(data).pipe(
      map((auth: any) => this.setAuthFromLocalStorage(auth)),
      switchMap(() => this.getUserByToken()),
      catchError((err) => throwError(err)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  login(data: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(data).pipe(
      map((auth: any) => {
        if (auth.data.v_type == 0) {
          return this.setAuthFromLocalStorage(auth);
        } else if (auth.data.v_type == 5) {
          this.tfaSubject.next({
            email: data.email,
            pass: data.password,
            role: auth.data.role,
            fname: auth.data.fname,
            _id: auth.data._id,
            createdBy: auth.data.createdBy,
            v_type: auth.data.v_type,
            message: auth.message
          });
          this.router.navigate(['/auth/tfa'], { queryParams: { role: 1 } });
        } else if (auth.data.v_type == 4) {
          this.otpSubject.next({
            email: data.email,
            pass: data.password,
            role: auth.data.role,
            fname: auth.data.fname,
            _id: auth.data._id,
            createdBy: auth.data.createdBy,
            v_type: auth.data.v_type,
            message: auth.message,
          });
          this.router.navigate(['/auth/otp'], { queryParams: { role: 1 } });
        }
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => throwError(err)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  partnerLoginWithOTP(body: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.partnerLogin(body).pipe(
      map((auth: any) => this.setAuthFromLocalStorage(auth)),
      switchMap(() => this.getUserByToken()),
      catchError((err) => throwError(err)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  partnerLogin(body: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.partnerLogin(body).pipe(
      map((auth: any) => {
        if (auth.data.v_type == 0) {
          return this.setAuthFromLocalStoragePartner(auth, body.email);
        } else if (auth.data.v_type == 5) {
          this.tfaSubject.next({
            email: body.email,
            pass: body.password,
            role: auth.data.role,
            fname: auth.data.fname,
            _id: auth.data._id,
            createdBy: auth.data.createdBy,
            v_type: auth.data.v_type,
            message: auth.message,
            organizationId: body?.organizationId
          });
          this.router.navigate(['/auth/tfa'], { queryParams: { role: 2 } });
        } else if (auth.data.v_type == 4) {
          this.otpSubject.next({
            email: body.email,
            pass: body.password,
            role: auth.data.role,
            fname: auth.data.fname,
            _id: auth.data._id,
            createdBy: auth.data.createdBy,
            v_type: auth.data.v_type,
            message: auth.message,
            organizationId: body?.organizationId
          });
          this.router.navigate(['/auth/otp'], { queryParams: { role: 2 } });
        }
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => throwError(err)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    let role = Number(localStorage.getItem('role'));
    console.log('logout-auth');
    localStorage.clear();
    this.cookie.deleteAll();
    //localStorage.removeItem(this.authLocalStorageToken);
    if (role == AppConst.admin || role == AppConst.basicUser) {
      this.router.navigate(['/auth/login'], { queryParams: {} }).then((res) => window.location.reload());
    } else if (role == AppConst.partner || role == AppConst.user || role == AppConst.organization || role == AppConst.basicUser) {
      this.router.navigate(['/auth/login/partner'], { queryParams: {} }).then((res) => window.location.reload());
    }
    // this.spinner.hide();
  }

  logoutWithErrorHandler() {
    console.log('logout-handler');
    this.clearCookies()
    this.router.navigate(['/auth/login'], { queryParams: { isRedirect: true } });
    Swal.fire({
      position: 'top-right',
      width: 300,
      icon: 'error',
      text: 'Token Expired! Login Again'
    });
  }

  logoutFromPartner() {
    this.authHttpService.logoutFromPartner().subscribe((result: any) => {
      if (result && result.code == 200) {
        console.log('logout-partner');
        this.clearCookies()
        this.setAuthAfterPartnerLogin(result);
        this.router.navigateByUrl('/admin/dashboard').then((res: any) => {
          localStorage.removeItem(`view_qol_params`);
          window.location.reload();
        });
      } else console.log("logoutFromPartner", result)
    }, (err) => {
      console.log("logoutFromPartner", err)
    })
  }

  logoutFromUser(data: any) {
    this.authHttpService.logoutFromUser(data).subscribe((result: any) => {
      console.log('logout-user');
      this.clearCookies();
      this.setAuthAfterUserLogin(result.data);
      this.router.navigateByUrl('/partner/dashboard').then((res: any) => {
        localStorage.removeItem(`view_qol_params`);
        window.location.reload();
      });
    })
  }

  // Set Partner time for admin login
  setPartnerLP(data: any) {
    this.isShowTimerAccess$ = new BehaviorSubject<boolean>(false);
    return this.authHttpService.setPartnerAdminTimer(data);
  }

  setUserLP(data: any) {
    this.isShowTimerAccess$ = new BehaviorSubject<boolean>(false);
    return this.authHttpService.setUserPartnerTimer(data);
  }

  updatePartnerLP(data: any) {
    this.isShowTimerAccess$ = new BehaviorSubject<boolean>(false);
    return this.authHttpService.updatePartnerAdminTimer(data);
  }

  updateUserLP(data: any) {
    this.isShowTimerAccess$ = new BehaviorSubject<boolean>(false);
    return this.authHttpService.updateUserPartnerTimer(data);
  }

  getUserByToken(): Observable<any> {
    this.spinner.show();
    const auth = this.getAuthFromLocalStorage();
    if (!auth.token) {
      this.spinner.hide();
      // this.logout();
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: any) => {
        this.spinner.hide();
        if (user) {
          this.getUserIDs(user.data._id);
          if (user.data?.homeSpace) localStorage.setItem('homeSpace', user.data?.homeSpace);
          else localStorage.removeItem('homeSpace')
          if (user.data?.organizationId?.length == 1 && !user?.data?.isOrgAssociate) localStorage.setItem('organization', user.data?.organizationId[0]);
          //if(Number(localStorage.getItem('role'))!=AppConst.user){
          localStorage.setItem('loginId', user.data.parentId)
          // }
          if (Number(localStorage.getItem('role')) == AppConst.user) {
            if (user.data.parentRole) localStorage.setItem('parentRole', user.data.parentRole);
            else localStorage.removeItem('parentRole');
            localStorage.setItem('ID', user.data.parentId);
          } else localStorage.setItem('ID', user.data._id);
          this.partnerBondingService.setUpSocket();
          this.currentUserSubject.next(user.data);
          if (Number(localStorage.getItem('role')) == AppConst.admin || Number(localStorage.getItem('role')) == AppConst.basicUser) {
            if (user.data.partnerPageSize) localStorage.setItem('partnerPageSize', user.data.partnerPageSize);
            this.getDefaultBrandSettings('/admin/branding/view');
          } else {
            let mainId = localStorage.getItem('mainId');
            this.getDefaultBrandSettings(`/partnerPanel/branding/view/${mainId}/`);
          }
          localStorage.setItem('Name', `${user.data.fname}`)
          if (user.data.graph_color_palate) localStorage.setItem('graph_color_palate', `${user.data.graph_color_palate}`)
          localStorage.setItem('Module-Access', JSON.stringify(user.moduleAccessData));
          localStorage.setItem('group-permission', JSON.stringify(user.groupPermission));
          this.versionId = user?.data?.versionId;
          if (Number(localStorage.getItem('role')) != AppConst.admin && Number(localStorage.getItem('role')) != AppConst.basicUser) this.getActiveVersion();
          if (Number(localStorage.getItem('role')) != AppConst.admin && Number(localStorage.getItem('role')) != AppConst.basicUser) this.getCachingInterval();
        } else this.logout();
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getActiveVersion() {
    this.versionService.getActiveVersion().subscribe((res: any) => {
      if (res && res.data) localStorage.setItem('version', JSON.stringify(res.data));
      if (res?.data?._id && this.versionId !== res?.data?._id) this.versionService.openVersion.next(true);
    }, (err) => {
      localStorage.removeItem('version');
      console.log(err);
      // this.sharedService.loggerError(err);
    });
  }

  getCachingInterval() {
    this.partnerBondingService.getCacheInterval()?.subscribe((res: any) => {
      if (res && res.data) localStorage.setItem('cacheInterval', JSON.stringify(res.data.cachedInterval));
    }, (err) => {
      localStorage.removeItem('cacheInterval');
      console.log(err);
      // this.sharedService.loggerError(err);
    });
  }

  // private methods
  public setAuthFromLocalStorage(auth: any): boolean {
    // let role = (auth.data.role).toLowerCase();
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    let JWTdecoded: any = jwt_decode(auth.data.token);
    if (auth && JWTdecoded) {
      // this.getUserIDs(JWTdecoded.sub);
      this.cookie.set('authToken', auth.data.token);
      localStorage.setItem('ID', JWTdecoded.sub);
      localStorage.setItem('rootAdminID', JWTdecoded.sub);
      localStorage.setItem('role', auth.data.role);
      localStorage.setItem('menu', auth.data.brandSettingMenu);
      localStorage.setItem('navBarImage', auth.data.brandSettingNavImg || '');
      localStorage.setItem('navBarText', auth.data.brandSettingNavText || '')
      this.setLayoutConfig();
      this.setNavOption();
      return true;
    }
    return false;
  }

  getUserIDs(id: any) {
    // let userId:any;
    if (localStorage.getItem('userIds')) {
      this.userIds = JSON.parse(localStorage.getItem('userIds') || '')
    }
    //this.userIds=JSON.parse(localStorage.getItem('userIds')||'');
    // if(Number(localStorage.getItem('role'))==AppConst.user){
    //   userId=localStorage.getItem('userId');
    // } else{
    //   userId=localStorage.getItem('ID');
    // }
    let userIDFind = this.userIds.find(x => x == id);
    if (!userIDFind) {
      localStorage.setItem('find', '0');
      let find = localStorage.getItem('find');
      this.userIds.push(id);
      localStorage.setItem('userIds', JSON.stringify(this.userIds));
    } else {
      let find = localStorage.getItem('find');
      localStorage.setItem('find', '1');
    }
  }


  // private methods
  public setAuthFromLocalStoragePartner(auth: any, email: string): boolean {
    //console.log('auth: ', auth);
    // let role = (auth.data.role).toLowerCase();
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    let JWTdecoded: any = jwt_decode(auth.data.token);
    if (auth && JWTdecoded) {
      // this.getUserIDs(JWTdecoded.sub);
      this.cookie.set('authToken', auth.data.token);
      localStorage.setItem('ID', JWTdecoded.sub);
      localStorage.setItem('role', auth.data.role);
      localStorage.setItem('menu', auth.data.brandSettingMenu);
      localStorage.setItem('navBarImage', auth.data.brandSettingNavImg || '');
      localStorage.setItem('navBarText', auth.data.brandSettingNavText || '');
      this.setNavOption();
      this.setLayoutConfig();
      return true;
    }
    return false;
  }

  public setAuthAfterPartneUserLogin(auth: any) {
    // let role = (auth.data.role).toLowerCase();
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    let JWTdecoded: any = jwt_decode(auth.token);
    if (auth && JWTdecoded) {
      this.cookie.set('authToken', auth.token);
      localStorage.setItem('ID', auth.data.superAdminId);
      localStorage.setItem('role', auth.data.partner_role);
      localStorage.setItem('menu', localStorage.getItem('partnerMenu') + '');
      localStorage.setItem('navBarImage', localStorage.getItem('partnerNavBarImage') || '');
      localStorage.setItem('navBarText', localStorage.getItem('partnerNavBarText') || '');
      localStorage.setItem('Module-Access', localStorage.getItem('partnerModuleAccess') || '');
      // localStorage.removeItem('adminNavBarImage');
      //  localStorage.removeItem('adminNavBarText');
      this.setNavOption();
      this.setLayoutConfig();
      this.showHeader();
      // return auth;
    }
  }

  public setAuthAfterPartnerLogin(auth: any) {
    // let role = (auth.data.role).toLowerCase();
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    let JWTdecoded: any = jwt_decode(auth.token);
    if (auth && JWTdecoded) {
      this.cookie.set('authToken', auth.token);
      localStorage.setItem('ID', auth.data.superAdminId);
      localStorage.setItem('role', auth.data.admin_role);
      localStorage.setItem('menu', localStorage.getItem('adminMenu') + '');
      if (localStorage.getItem('closeFrom') === 'partner') {
        localStorage.setItem('navBarImage', localStorage.getItem('adminNavBarImageFOrLogo') || '');
      } else localStorage.setItem('navBarImage', localStorage.getItem('adminNavBarImage') || '');
      localStorage.setItem('navBarText', localStorage.getItem('adminNavBarText') || '');
      localStorage.setItem('Module-Access', localStorage.getItem('adminModuleAccess') || '');
      // localStorage.removeItem('adminNavBarImage');
      //  localStorage.removeItem('adminNavBarText');
      this.setNavOption();
      this.setLayoutConfig();
      this.showHeader();
      // return auth;
    }
  }

  public setAuthAfterUserLogin(auth: any) {
    // let role = (auth.data.role).toLowerCase();
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    let JWTdecoded: any = jwt_decode(auth.token);
    if (auth && JWTdecoded) {
      this.cookie.set('authToken', auth.token);
      localStorage.setItem('ID', auth.partnerID);
      localStorage.setItem('role', auth.role);
      localStorage.setItem('mainId', auth.mainId);
      localStorage.setItem('menu', auth.brandSettingMenu);
      localStorage.setItem('navBarImage', auth.brandSettingNavImg);
      localStorage.setItem('navBarText', auth.brandSettingNavText);
      localStorage.setItem('Module-Access', localStorage.getItem('partnerModuleAccess') || '');
      if (auth.orgID) {
        localStorage.setItem('organization', auth.orgID);
        localStorage.setItem('loginOrgId', auth.orgID);
      }
      // localStorage.setItem('homeSpace', auth.homeSpace?.homeSpace)
      // localStorage.setItem('rootAdminID', JWTdecoded.sub);
      // localStorage.removeItem('adminNavBarImage');
      //  localStorage.removeItem('adminNavBarText');
      this.setNavOption();
      this.setLayoutConfig();
      this.showHeader();
      // return auth;
    }
  }

  setNavOption() {
    let img = localStorage.getItem('navBarImage');
    let text = localStorage.getItem('navBarText');
    // this.navBar.next({ 'image': img, 'text': text });
    // if(img &&  img!=='undefined'){
    //   this.navBar.next({ 'content': localStorage.getItem('navBarImage'), 'type': 1 });
    // } else if(text && text!=='undefined'){
    //   this.navBar.next({ 'content': localStorage.getItem('navBarText'), 'type': 2 });
    // } else{
    //   this.navBar.next({ 'content': './assets/media/logos/login-logo.svg', 'type': 1 });
    // }
  }


  setLayoutConfig() {
    if (Number(localStorage.getItem('menu')) == 1) {
      this.fieldsToUpdate = {
        header: {
          ...DefaultLayoutConfig.header,
          left: 'page-title',
          display: false
        },
        aside: {
          ...DefaultLayoutConfig.aside,
          display: true
        },
      }
      //  this.layout.setConfig(this.fieldsToUpdate);
      // window.location.reload();
    } else {
      this.fieldsToUpdate = {
        header: {
          ...DefaultLayoutConfig.header,
          left: 'menu',
          display: true
        },
        aside: {
          ...DefaultLayoutConfig.aside,
          display: false
        },
      }
      // window.location.reload();
    }
    this.showHeader();
    this.layout.setConfig(this.fieldsToUpdate);
  }

  showHeader() {
    if (Number(localStorage.getItem('menu')) == 1) {
      this.showHeaderSubject.next(false);
    } else {
      this.showHeaderSubject.next(true);
    }
  }

  public getAuthFromLocalStorage(): any {
    try {
      const authData = {
        token: this.cookie.get('authToken'),
        role: localStorage.getItem('role'),
        //userId: localStorage.getItem('ID')
      }
      return authData;
    } catch (error) {
      // console.error(error);
      return undefined;
    }
  }

  clearCookies() {
    console.log('logout-inside');
    CookieComponent.delete('preview');
    CookieComponent.delete('brandSetting');
    this.cookie.delete('authToken');
    localStorage.removeItem('ID');
    localStorage.removeItem('IpAddress');
    localStorage.removeItem('Module-Access');
    localStorage.removeItem('Name');
    localStorage.removeItem('graph_color_palate');
    localStorage.removeItem('role');
    localStorage.removeItem('menu');
    localStorage.removeItem('mainId');
    localStorage.removeItem('logInDuration');
    localStorage.removeItem('navBarText');
    localStorage.removeItem('navBarImage');
    localStorage.removeItem('groupName');
    localStorage.removeItem('selectedUsers');
    localStorage.removeItem('isNew');
    localStorage.removeItem('organization');
    localStorage.removeItem('loginOrgId');
    localStorage.removeItem('partnerId');
    localStorage.removeItem('loginId');
    localStorage.removeItem('partnerModuleAccess');
    localStorage.removeItem('partnerNavBarImage');
    localStorage.removeItem('partnerNavBarText');
    localStorage.removeItem('adminNavBarImage');
    // localStorage.removeItem('userId');
    // localStorage.removeItem('partnerMenu');
    // localStorage.removeItem('logInExpiresIn');
    //  localStorage.removeItem('group-permission');
    // localStorage.removeItem('req');
  }

  getDefaultBrandSettings(viewURL: any) {
    if (!CookieComponent.get('preview')) {
      this.brandSettingService.getBrandSettingData(viewURL).subscribe(
        (res: any) => {
          if (res?.data?.createdBy) this.brandingFileIdSubject.next(res?.data?.createdBy);
          this.brandSettings = res.data;
          this.brandingId = res.data._id;
          if (this.brandSettings.navbgColour) this.navbgColour = this.brandSettings.navbgColour;
          if (this.brandSettings.navLinkColour) this.navLinkColour = this.brandSettings.navLinkColour;
          if (this.brandSettings.navLinkHoverColour) this.navLinkHoverColour = this.brandSettings.navLinkHoverColour;
          if (this.brandSettings.navLinkActiveColour) this.navLinkActiveColour = this.brandSettings.navLinkActiveColour;
          if (this.brandSettings.navLinkbackgroundColour) this.navLinkbackgroundColour = this.brandSettings.navLinkbackgroundColour;
          if (this.brandSettings.brandingTextColour) this.brandingTextColour = this.brandSettings.brandingTextColour;

          if (this.brandSettings.body.bodyBackgroundColour) this.bodyBackgroundColour = this.brandSettings.body.bodyBackgroundColour;
          if (this.brandSettings.body.textColour) this.textColour = this.brandSettings.body.textColour;
          if (this.brandSettings.body.linkColour) this.linkColour = this.brandSettings.body.linkColour;
          if (this.brandSettings.body.linkHoverColour) this.linkHoverColour = this.brandSettings.body.linkHoverColour;
          if (this.brandSettings.body.buttonPrimaryColour) this.buttonPrimaryColour = this.brandSettings.body.buttonPrimaryColour;
          if (this.brandSettings.body.buttonSecondaryColour) this.buttonSecondaryColour = this.brandSettings.body.buttonSecondaryColour;
          if (this.brandSettings.body.buttonDangerColour) this.buttonDangerColour = this.brandSettings.body.buttonDangerColour;
          if (this.brandSettings.body.buttonTextColour) this.buttonTextColour = this.brandSettings.body.buttonTextColour;
          if (this.brandSettings.body.successColour) this.successColour = this.brandSettings.body.successColour;
          if (this.brandSettings.body.infoColour) this.infoColour = this.brandSettings.body.infoColour;
          if (this.brandSettings.body.warningColour) this.warningColour = this.brandSettings.body.warningColour;
          if (this.brandSettings.body.dangerColour) this.dangerColour = this.brandSettings.body.dangerColour;
          this.loginPageAPIUrl = this.brandSettings.loginPageImg;
          this.faviconAPIUrl = this.brandSettings.favicon;
          this.favIcon.href = this.faviconAPIUrl;
          this.navBarAPIUrl = this.brandSettings.navbarImg;
          this.menu = this.brandSettings.menu;
          localStorage.setItem('menu', this.menu);
          this.customCSSText = this.brandSettings.customCss;

          let obj1 = {
            navbgColour: this.navbgColour,
            navLinkColour: this.navLinkColour,
            navLinkHoverColour: this.navLinkHoverColour,
            navLinkActiveColour: this.navLinkActiveColour,
            navLinkbackgroundColour: this.navLinkbackgroundColour,
            brandingTextColour: this.brandingTextColour,
            bodyBackgroundColour: this.bodyBackgroundColour,
            textColour: this.textColour,
            linkColour: this.linkColour,
            linkHoverColour: this.linkHoverColour,
            buttonPrimaryColour: this.buttonPrimaryColour,
            buttonSecondaryColour: this.buttonSecondaryColour,
            buttonDangerColour: this.buttonDangerColour,
            buttonTextColour: this.buttonTextColour,
            successColour: this.successColour,
            infoColour: this.infoColour,
            warningColour: this.warningColour,
            dangerColour: this.dangerColour
          }
          this.navBar.next({ 'image': this.brandSettings?.navbarImg, 'text': this.brandSettings?.navBarText, 'favicon': this.brandSettings.favicon });
          //   this.authService.navBarImage.next(this.navBarAPIUrl);
          let technicalSupport = {
            technicalSupportURL: this.brandSettings?.technicalSupportURL,
            technicalSupportEmail: this.brandSettings?.technicalSupportEmail,
            technicalSupportContact: this.brandSettings?.technicalSupportContact
          }
          this.technicalSupportObj.next(technicalSupport);
          this.showHeader();
          this.setCSSProperties(obj1);
          this.setLayoutConfig();
          // this.cd.detectChanges();
          // window.location.reload();
        }
      );
    } else {
      this.setBrandSettings();
    }
  }

  setCSSProperties(obj: any) {
    // this.setCssProperty('--background_colour', obj.navbgColour);
    this.setCssProperty('--navbgColour', obj.navbgColour);
    this.setCssProperty('--navLinkColour', obj.navLinkColour);
    this.setCssProperty('--navLinkbackgroundColour', obj.navLinkbackgroundColour);
    this.setCssProperty('--brandingTextColour', obj.brandingTextColour);
    this.setCssProperty('--navLinkHoverColour', obj.navLinkHoverColour);
    this.setCssProperty('--navLinkActiveColour', obj.navLinkActiveColour);
    this.setCssProperty('--bodyBackgroundColour', obj.bodyBackgroundColour);
    this.setCssProperty('--textColour', obj.textColour);
    this.setCssProperty('--linkColour', obj.linkColour);
    this.setCssProperty('--linkHoverColour', obj.linkHoverColour);
    this.setCssProperty('--buttonPrimaryColour', obj.buttonPrimaryColour);
    this.setCssProperty('--buttonSecondaryColour', obj.buttonSecondaryColour);
    this.setCssProperty('--buttonDangerColour', obj.buttonDangerColour);
    this.setCssProperty('--buttonTextColour', obj.buttonTextColour);
    this.setCssProperty('--successColour', obj.successColour);
    this.setCssProperty('--infoColour', obj.infoColour);
    this.setCssProperty('--warningColour', obj.warningColour);
    this.setCssProperty('--dangerColour', obj.dangerColour);
  }

  setCssProperty(variable: string, value: any) {
    document.documentElement.style.setProperty(variable, value);
  }

  setBrandSettings() {
    if (CookieComponent.get('preview')) {
      let obj = JSON.parse(CookieComponent.get('brandSetting') + '')
      this.navbgColour = obj.navbgColour;
      this.navLinkColour = obj.navLinkColour;
      this.navLinkHoverColour = obj.navLinkHoverColour;
      this.navLinkActiveColour = obj.navLinkActiveColour;
      this.navLinkbackgroundColour = obj.navLinkbackgroundColour;
      this.brandingTextColour = obj.brandingTextColour;
      this.bodyBackgroundColour = obj.bodyBackgroundColour;
      this.textColour = obj.textColour;
      this.linkColour = obj.linkColour;
      this.linkHoverColour = obj.linkHoverColour;
      this.buttonPrimaryColour = obj.buttonPrimaryColour;
      this.buttonSecondaryColour = obj.buttonSecondaryColour;
      this.buttonDangerColour = obj.buttonDangerColour;
      this.buttonTextColour = obj.buttonTextColour;
      this.successColour = obj.successColour;
      this.infoColour = obj.infoColour;
      this.warningColour = obj.warningColour;
      this.dangerColour = obj.dangerColour;
      this.loginPageAPIUrl = obj.loginPageAPIUrl;
      // this.loginPageUrl = obj.loginPageAPIUrl;
      this.faviconAPIUrl = obj.faviconAPIUrl;
      // this.faviconUrl = obj.faviconAPIUrl;
      this.navBarAPIUrl = obj.navBarAPIUrl;
      // this.navBarUrl = obj.navBarAPIUrl;
      this.brandingId = obj.brandingId;
      this.customCSSText = obj.customCssText;
      this.menu = obj.menu;
      // this.authService.navBarImage.next(this.navBarAPIUrl);
      this.setCSSProperties(obj);
      this.deleteCookies();
      this.showHeader();
      //  this.cd.detectChanges();
    }
    return;
  }

  deleteCookies() {
    // CookieComponent.delete('preview');
    // CookieComponent.delete('brandSetting');
  }

  validateTokenExpiry() {
    try {
      let token = this.cookie.get('authToken');
      if (!token) {
        this.logout();
      }
      let data: any = jwt_decode(token)
      if (data && data.sub && data.exp) {
        let today: any = new Date();
        let expDate: any = new Date(data.exp);
        let minDiff = expDate - today;
        minDiff = Math.floor(minDiff / 60000);
        if (minDiff && minDiff > 0) {
          if (minDiff > 3) return;
          else {
            this.authHttpService.refreshToken().subscribe((res) => {
              if (res && res.data && res.data.refreshToken) {
                this.cookie.set('authToken', res.data.refreshToken);
              }
            }, (err) => {
              console.log('err', err)
            })
          }
        } else {
          this.logout();
        }
      } else {
        this.logout();
      }
    } catch (err) {
      console.error(err);
      this.logout();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
