<div class="card mb-5 mb-xl-8 mt-15" *ngIf="listingArray.length">
    <div class="card-body p-0">
        <div class="border-bottom px-3 px-md-5 d-flex align-items-center justify-content-between "
            *ngFor="let module of listingArray; let i = index">
            <div
                class="mr-2 d-flex flex-md-row flex-column col-sm-12 col-md-6 col-lg-6 align-items-start align-items-md-center">
                <div class="flex-column">
                    <h4 class="mt-5 text-capitalize min-w-225px mb-5 fs-6">
                        {{module.name}}
                    </h4>
                </div>
                <div class="d-flex align-items-center">
                    <div class="flex-row-reverse d-flex align-items-center justify-content-center mx-10">
                        <label for="bonding" class="ms-2 form-check-label text-capitalize fw-bold fs-6 text-nowrap">
                            Prod Mode</label>
                        <div class="switch-width">
                            <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" id="prodMode-{{i}}"
                                [checked]="module?.prodMode" [loading]="isLoadingArrayProd[i]"
                                (change)="onChangeProdSwitch($event, module, i)">
                                <i class="fa fa-spinner fa-pulse" *ngIf="isLoadingArrayProd[i]"></i>
                            </ui-switch>
                        </div>
                    </div>
                    <div class="flex-row-reverse d-flex align-items-center justify-content-center me-5">
                        <label for="bonding" class="ms-2 form-check-label text-capitalize fw-bold fs-6 text-nowrap">
                            Dev Mode</label>
                        <div class="switch-width">
                            <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" id="devMode-{{i}}"
                                [checked]="module?.devMode" [loading]="isLoadingArrayDev[i]"
                                (change)="onChangeDevSwitch($event, module, i)">
                                <i class="fa fa-spinner fa-pulse" *ngIf="isLoadingArrayDev[i]"></i>
                            </ui-switch>
                        </div>
                    </div>
                    <div class="flex-row-reverse d-flex align-items-center justify-content-center me-5">
                        <div *ngIf="module.name == FBModule && isPartner">
                            <button type="button" title="BA Setting" class="btn btn-primary btn-icon-1"
                                (click)="openBondingApi()">
                                <div>
                                    <span [inlineSVG]="'./assets/media/icons/duotune/design/cog.svg'"
                                        class="svg-icon svg-icon-block">
                                    </span>
                                </div>
                            </button>
                        </div>
                        <!-- <div *ngIf="module.name == illuminateModule && isPartner">
                            <button type="button" title="Illuminate Setting" class="btn btn-primary btn-icon-1"
                                (click)="openIlluminate()">
                                <div>
                                    <span [inlineSVG]="'./assets/media/icons/duotune/design/cog.svg'"
                                        class="svg-icon svg-icon-block">
                                    </span>
                                </div>
                            </button>
                        </div> -->
                        <!-- <div *ngIf="module.name == 'Alert' && isPartner">
                            <a title="Setting" class="btn btn-icon btn-bg-light btn-sm ml-1 me-2"
                                (click)="openAlertConfig()">
                                <span [inlineSVG]="'./assets/media/icons/duotune/design/cog.svg'"
                                    class="svg-icon svg-icon-3 svg-icon-info"></span>
                            </a>
                        </div> -->
                    </div>
                </div>
            </div>
            <span *ngIf="module.name == FBModule" class="text-muted fw-bold me-2">
                {{sdwanVersion || ''}}</span>
        </div>
    </div>
</div>

<div class="card mb-5 mb-xl-8 mt-15" *ngIf="!listingArray.length">
    <div class="card text-center">
        <div class="card-body p-0 ">
            <h4 class="mt-5 mb-5 "> <span> No Module Access! </span> </h4>
        </div>
    </div>
</div>