import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/modules/shared/auth';

@Component({
  selector: 'app-shared-permission-management',
  templateUrl: './shared-permission-management.component.html',
  styleUrls: ['./shared-permission-management.component.scss']
})
export class SharedPermissionManagementComponent implements OnInit {

  authService = AuthService;

  @Input() permissions: any[] = [];
  @Input() isView: any;
  @Input() isEdit: any;


  @Output() onSubmit = new EventEmitter<any>();
  @Output() onBackEvent = new EventEmitter<any>();

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {}

  onSave() {
    //this.sharedService.showLoader();
    // this.cd.detectChanges();
    this.permissions.forEach(x => {
      delete x.titleChecked;
      x.sub.forEach((element: any) => {
        if (!element.action.view) delete element.action.view
        if (!element.action.update) delete element.action.update
        if (!element.action.delete) delete element.action.delete
        delete element.action['isViewDisabled'];
        delete element.action['isCreateDisabled'];
        delete element.action['isUpdateDisabled'];
        delete element.action['isDeleteDisabled'];
      });
    })
    this.cd.detectChanges();
    this.onSubmit.emit({ permission: this.permissions, isEdit: this.isEdit });
  }

  onBack() {
    // this.router.navigate(['/partner/user-org-management/group-permission/create'])
    this.onBackEvent.emit();
  }

  checkedAll(permission: any) {
    if (!permission.titleChecked) {
      if (permission.sub && permission.sub.length > 0) {
        permission.sub.forEach((sub: any) => {
          if (sub.action.isViewDisabled != false) sub.action.view = true;
          if (sub.action.isUpdateDisabled != false) sub.action.update = true;
          if (sub.action.isDeleteDisabled != false) sub.action.delete = true;
        });
      }
    } else {
      if (permission.sub && permission.sub.length > 0) {
        permission.sub.forEach((sub: any) => {
          if (sub.action.isViewDisabled != false) sub.action.view = false;
          if (sub.action.isUpdateDisabled != false) sub.action.update = false;
          if (sub.action.isDeleteDisabled != false) sub.action.delete = false;
        });
      }
    }
  }

  uncheckTitleChecked(value: boolean, permission: any) {
    if (value) {
      let allChecked = true;
      permission.sub.forEach((sub: any) => {
        if (sub.action.isViewDisabled != false && sub.action.view == false) allChecked = false;
        if (sub.action.isUpdateDisabled != false && sub.action.update == false) allChecked = false;
        if (sub.action.isDeleteDisabled != false && sub.action.delete == false) allChecked = false;
      });
      permission.titleChecked = allChecked;
    } else permission.titleChecked = false;
  }

}
