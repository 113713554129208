import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { PartnerLoginComponent } from './components/partner-login/partner-login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TfaComponent } from './components/tfa/tfa.component';
import { LoginGuard } from './services/login.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        canActivate: [LoginGuard],
        component: LoginComponent,
      },
      {
        path: 'otp',
        component: OtpVerificationComponent,
      },
      {
        path: 'tfa',
        component: TfaComponent
      },
      {
        path: 'login/partner',
        canActivate: [LoginGuard],
        component: PartnerLoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'forgot-password/partner',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'create-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'reset-password/partner',
        component: ResetPasswordComponent,
      },
      {
        path: 'create-password/partner',
        component: ResetPasswordComponent,
      },
      { path: '', redirectTo: 'login/partner', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
