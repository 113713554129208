import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';

@Component({
  selector: 'app-shared-sdwan-wan-add-gateway',
  templateUrl: './shared-sdwan-wan-add-gateway.component.html',
  styleUrls: ['./shared-sdwan-wan-add-gateway.component.scss']
})
export class SharedSdwanWanAddGatewayComponent implements OnInit {
  apiURL: any;
  groupList: any;
  gatewayData: any;
  gatewayForm: FormGroup;
  edit: boolean;
  body: any;
  submitted: boolean;
  errors: any;
  parentId: any;
  aggregatorList: any = []

  constructor(public activeModal: NgbActiveModal, private cookie: CookieService, private cd: ChangeDetectorRef, private spaceService: PartnerSpaceService) { }

  ngOnInit(): void {
    this.apiURL = this.cookie.get('api_url');
    this.spaceService.spaceParentId$.subscribe((res: any) => {
      if (res) this.parentId = res.parentId
      this.cd.detectChanges();
    });
    this.getSuccess()
    if (this.edit) this.gatewayForm = this.createForm(this.gatewayData);
    else this.gatewayForm = this.createForm();
    this.cd.detectChanges()
  }

  getSuccess() {
    this.spaceService.successForGateway$.subscribe(
      (res: any) => {
        if (res) {
          console.log('res in suceess', res)
          if (res.success) this.activeModal.close({});
        }
      }
    );
  }

  createForm(data: any = {}) {
    return new FormGroup({
      aggregator: new FormControl(data?.aggregator || '')
    })
  }

  get f() {
    return this.gatewayForm.controls;
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onAdd() {
    if (!this.apiURL) return;
    // console.log("data",this.vlanForm.value)
    this.submitted = true;
    if (this.gatewayForm.invalid) return;
    this.body = {
      aggregator: this.gatewayForm.value.aggregator,
      space_private_wan: `${this.apiURL}spaces/${this.parentId}/private_wan/`
    }
    this.activeModal.dismiss({ event: 'save', data: this.body });
  }

  onEdit() {
    if (!this.apiURL) return;
    this.submitted = true;
    if (this.gatewayForm.invalid) return;
    this.body = {
      aggregator: `${this.apiURL}aggregators/43/`,
      space_private_wan: `${this.apiURL}spaces/${this.parentId}/private_wan/`
    }
    this.activeModal.dismiss({ event: 'save', data: this.body });
  }

}
