import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5exporting from "@amcharts/amcharts5/plugins/exporting";
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AlertService } from "src/app/services/alert.service";
import { PartnerBondsPerformanceService } from "src/app/services/partner-bonds-performance.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { AppConst } from "src/app/shared/constants/app.constant";
import { PartnerBondingService } from "src/app/services/partner-bonding.service";
import { PartnerBondViewComponent } from "src/app/modules/partner/bonds/partner-bond-view/partner-bond-view.component";

@Component({
  selector: 'app-shared-bonds-view-status',
  templateUrl: './shared-bonds-view-status.component.html',
  styleUrls: ['./shared-bonds-view-status.component.scss']
})
export class SharedBondsViewStatusComponent implements OnInit {
  colorPaletteList = AppConst.graphColorPalette;
  selectedColorPalette: any = null;
  showSVG = false;
  latest_tuning: any;
  username: any;
  password: any;
  key: any;
  spaceData: any;
  subTitle: any = "0 Mbps";
  monitorIcon: boolean = false;

  tunnelTrafficData: any = { send: [], received: [] }
  tunnelTrafficChart: am5.Root;
  tunnelTrafficCounter: number = 0;
  tunnelTrafficLoader: boolean = false;

  @Input() bondstatus: any;
  @Input() bonderData: any;
  @Input() bonderId: any;
  @Input() bondId: any;
  @Input() nodeDetails: any;
  @Input() allMobileBroadband: any;
  @Input() allLegs: any;
  @Input() downTimeData: any;
  @Input() isQuickView: boolean = false;
  @Input() alertPermission: any

  constructor(private shared: SharedService,
    private partnerBondingService: PartnerBondingService,
    private partnerBondView: PartnerBondViewComponent,
    private performanceService: PartnerBondsPerformanceService,
    private cd: ChangeDetectorRef,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.shared.hideLoader();
    this.partnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    })
    this.getAllAlertList();
    am5.addLicense("AM5C356006604");
    this.key = this.bonderData?.space?.key;
    if (this.bondstatus?.bonder?.status != 'unknown' && !this.isQuickView) this.getGraphCredentials();
    setTimeout(() => {
      this.showSVG = true;
    }, 500);
    let graph_color_palate = localStorage.getItem('graph_color_palate');
    if (graph_color_palate) {
      this.colorPaletteList.forEach((color: any) => {
        if (color && color.name == graph_color_palate) this.selectedColorPalette = color;
      })
    }
    if (!this.selectedColorPalette || !this.selectedColorPalette.name) this.selectedColorPalette = null;
  }

  getAllAlertList() {
    this.alertService.alertGroupData$.subscribe((res: any) => {
      if (res && res.length > 0) {
        this.monitorIcon = res.some((alertData: any) => alertData.bondList.includes(+this.bondId));
        this.cd.detectChanges();
      }
    });
  }

  getGraphCredentials() {
    this.tunnelTrafficLoader = true;
    this.cd.detectChanges();
    let url = `spaces/${this.key}/`;
    this.performanceService.getGraphCredentials(url, 'GET').subscribe((res: any) => {
      if (res.data) {
        this.spaceData = res.data;
        this.username = this.spaceData.influx_graph_username;
        this.password = this.spaceData.influx_graph_password;
        this.getChartData();
      }
    }, (err) => {
      this.shared.loggerError(err);
      this.tunnelTrafficLoader = false;
      this.cd.detectChanges();
    });
  }

  getChartData() {
    this.getTunnelTrafficReceiver();
    this.getTunnelTrafficSender();
  }

  getTunnelTrafficReceiver() {
    let date: any = new Date();
    let date1: any = new Date();
    date.setHours(date.getHours())
    date1.setHours(date1.getHours() - 6);
    let url = `metrics_api/query?db=bonding&u=${this.username}&p=${this.password}&epoch=ms&q=`;
    let query = `SELECT mean("rx") FROM "tunnel_bytes" WHERE "node" = '${this.bonderId}' AND "tunnel" = '${this.bondId}' 
      AND  time < ${Math.floor(date / 1000)}s AND time > ${Math.floor(date1 / 1000)}s  GROUP BY time(60s) fill(null) ORDER BY time ASC`;
    this.tunnelTrafficData.received = [];
    this.performanceService.getReceiverData(url + encodeURIComponent(query), 'GET').subscribe((res: any) => {
      if (res && res.data && res.data?.results[0]?.series && res.data?.results[0]?.series[0]?.values) {
        for (let r = 0; r < res.data?.results[0]?.series[0]?.values.length; r++) {
          this.tunnelTrafficData.received.push({
            date: res.data.results[0].series[0].values[r][0],
            value: Math.round(this.convertBytesToKBPS(res.data.results[0].series[0].values[r][1]) * 10) / 10,
          });
        }
      }
      this.tunnelTrafficCounter++;
      if (this.tunnelTrafficCounter >= 2) this.renderTrafficChart();
      this.cd.detectChanges();
    }, (err: any) => {
      this.tunnelTrafficCounter++;
      if (this.tunnelTrafficCounter >= 2) this.renderTrafficChart();
      this.shared.loggerError(err);
    });
  }

  getTunnelTrafficSender() {
    let date: any = new Date();
    let date1: any = new Date();
    date.setHours(date.getHours())
    date1.setHours(date1.getHours() - 6);
    let url = `metrics_api/query?db=bonding&u=${this.username}&p=${this.password}&epoch=ms&q=`;
    let query = `SELECT mean("tx") FROM "tunnel_bytes" WHERE "node" = '${this.bonderId}' AND "tunnel" = '${this.bondId}' 
      AND  time < ${Math.floor(date / 1000)}s AND time > ${Math.floor(date1 / 1000)}s  GROUP BY time(60s) fill(null) ORDER BY time ASC`;
    this.tunnelTrafficData.send = [];
    this.performanceService.getSenderData(url + encodeURIComponent(query), 'GET').subscribe((res: any) => {
      if (res && res.data && res.data?.results[0]?.series && res.data?.results[0]?.series[0]?.values) {
        for (let r = 0; r < res.data?.results[0]?.series[0]?.values.length; r++) {
          this.tunnelTrafficData.send.push({
            date: res.data.results[0].series[0].values[r][0],
            value: Math.round(this.convertBytesToKBPSMinus(res.data.results[0].series[0].values[r][1]) * 10) / 10,
          });
        }
      }
      this.tunnelTrafficCounter++;
      if (this.tunnelTrafficCounter >= 2) this.renderTrafficChart();
      this.cd.detectChanges();
    }, (err: any) => {
      this.tunnelTrafficCounter++;
      if (this.tunnelTrafficCounter >= 2) this.renderTrafficChart();
      this.shared.loggerError(err);
    });
  }

  renderTrafficChart() {
    this.tunnelTrafficLoader = false;
    this.cd.detectChanges();
    this.tunnelTrafficChart?.dispose();
    this.tunnelTrafficChart = am5.Root.new("tunnelChart");
    let root: any = this.tunnelTrafficChart;
    this.tunnelTrafficCounter = 0;
    root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
    let exporting = am5exporting.Exporting.new(root, {
      menu: am5exporting.ExportingMenu.new(root, {}),
      title: "Tunnel data traffic",
      pdfOptions: { addURL: false }
    });
    exporting.export('png');
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        layout: root.horizontalLayout
      })
    );
    // Create axes
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      baseValue: 0,
      numberFormat: "#.# b",
      renderer: am5xy.AxisRendererY.new(root, {})
    }));
    root.numberFormatter.setAll({
      numberFormat: "#.# b",
      bytePrefixes: [
        { "number": 1, "suffix": "Bps" },
        { "number": 1024, "suffix": "Kbps" },
        { "number": 1048576, "suffix": "Mbps" },
        { "number": 1073741824, "suffix": "Gbps" }
      ]
    });
    // Add series
    let received = chart.series.push(am5xy.LineSeries.new(root, {
      name: "Upload",
      xAxis: xAxis,
      yAxis: yAxis,
      // stacked: true,
      valueYField: "value",
      valueXField: "date",
      connect: false,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: `upload : {valueY.formatNumber('#.# b')} @ {valueX.formatDate('MMM dt, yyyy HH:mm')}`
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      received.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
      received.set("fill", am5.Color.fromString(this.selectedColorPalette?.colorList[0]));
    }
    received.fills.template.setAll({ fillOpacity: 0.5, visible: true });
    received.strokes.template.setAll({ strokeWidth: 1.5 });
    received.data.setAll(this.tunnelTrafficData.received);
    received.appear(200);

    let send = chart.series.push(am5xy.LineSeries.new(root, {
      name: "Download",
      xAxis: xAxis,
      yAxis: yAxis,
      // stacked: true,
      valueYField: "value",
      valueXField: "date",
      connect: false,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: `download: {valueY.formatNumber('#.# b')} @ {valueX.formatDate('MMM dt, yyyy HH:mm')}`
      })
    }));
    if (this.selectedColorPalette?.colorList?.length > 0) {
      send.set("stroke", am5.Color.fromString(this.selectedColorPalette?.colorList[1]));
      send.set("fill", am5.Color.fromString(this.selectedColorPalette?.colorList[1]));
    }
    send.fills.template.setAll({ fillOpacity: 0.5, visible: true });
    send.strokes.template.setAll({ strokeWidth: 1.5 });
    send.data.setAll(this.tunnelTrafficData.send);
    send.appear(200);
    // Make stuff animate on load
    chart.appear(100, 10);
    // Add legend
    let legend = chart.children.unshift(am5.Legend.new(root, {
      y: am5.percent(50),
      centerY: am5.percent(50),
      layout: root.verticalLayout,
      height: am5.percent(100),
      width: am5.percent(15),
      verticalScrollbar: am5.Scrollbar.new(root, { orientation: "vertical" })
    }));
    legend.labels.template.setAll({ fontSize: 12, fontWeight: "500" });
    legend.data.setAll(chart.series.values);
    // Add cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, { behavior: "none" }));
    cursor.lineY.set("visible", false);
  }

  addMonitor(legId: any, state: string) {
    this.allLegs?.forEach((leg: any) => {
      if (leg.id == legId) leg['isAlertAPI'] = true;
    });
    this.allMobileBroadband?.forEach((leg: any) => {
      if (leg.id == legId) leg['isAlertAPI'] = true;
    });
    this.cd.detectChanges();
    this.alertService.addMonitoring(this.bondId, legId, state)?.subscribe((res: any) => {
      if (res && res.code == 200) {
        this.shared.loggerSuccess(res.message);
        this.activeLegList(this.bondId);
      }
      this.allLegs?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
      this.allMobileBroadband?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
    }, (err) => {
      this.shared.loggerError(err);
      this.allLegs?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
      this.allMobileBroadband?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
    });
  }

  removeMonitor(legId: any, isLogger = true) {
    this.allLegs?.forEach((leg: any) => {
      if (leg.id == legId) leg['isAlertAPI'] = true;
    });
    this.allMobileBroadband?.forEach((leg: any) => {
      if (leg.id == legId) leg['isAlertAPI'] = true;
    });
    this.alertService.removeMonitor(this.bondId, legId)?.subscribe((res: any) => {
      if (res && res.code == 200) {
        if (isLogger) this.shared.loggerSuccess(res.message);
        this.activeLegList(this.bondId);
      }
      this.allLegs?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
      this.allMobileBroadband?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
    }, (err) => {
      this.shared.loggerError(err);
      this.allLegs?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
      this.allMobileBroadband?.forEach((leg: any) => {
        if (leg.id == legId) leg['isAlertAPI'] = false;
      });
    });
  }

  activeLegList(bondId: any) {
    if (!bondId) return;
    this.alertService.activeLegList(bondId)?.subscribe((res: any) => {
      if (res && res.data) this.alertService.activeAlertLegList.next(res.data);
    });
  }

  cancelTuning(legId: any) {
    this.partnerBondView.cancelTuning(legId);
  }

  viewTuning(legId: any) {
    this.partnerBondView.viewTuning(legId);
  }

  calculateDiff(dateSent: any) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }

  calculateHours(duration: any) {
    return Math.floor(duration / 3600);
  }

  calculateMinutes(duration: any) {
    return Math.floor(duration % 3600 / 60);
  }

  calculateDifferenceOfDate(date: any) {
    if (!date) return '-'
    let startDate = new Date(date);
    let endDate = new Date();
    if (startDate > endDate) {
      let swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    let startYear = startDate.getFullYear();
    let february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = -1;
    let minDiff = -1;
    let secDiff = -1;
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    } else hourDiff = endDate.getHours() - startDate.getHours();
    minDiff = endDate.getMinutes() - startDate.getMinutes();
    secDiff = endDate.getSeconds() - startDate.getSeconds();
    if (yearDiff == 1) return `About year ago`;
    else if (yearDiff > 1) return `${parseInt(yearDiff + '')} year ago`;
    else if (monthDiff == 1) return `About month ago`;
    else if (monthDiff > 1 && monthDiff <= 31) return `${parseInt(monthDiff + '')} month ago`;
    else if (dayDiff == 1) return `About day ago`;
    else if (dayDiff >= 1 && dayDiff <= 31) return `${parseInt(dayDiff + '')} day ago`;
    else if (hourDiff >= 1 && hourDiff <= 31) return `${parseInt(hourDiff + '')} hour ago`;
    else if (minDiff >= 1 && minDiff <= 59) return `${parseInt(minDiff + '')} minutes ago`;
    else if (secDiff >= 1 && secDiff <= 59) return `${parseInt(secDiff + '')} seconds ago`;
    else return '-';
  }

  calculateBytes(rate: number) {
    return this.shared.calculateBytes(rate);
  }

  getMaxLegSpeed(rate: any, uploadSpeed: any) {
    var totalLegSpeed = 0;
    this.allLegs.forEach((item: any) => {
      if ((item?.aggregator_status?.state == 'up' || item?.aggregator_status?.state == 'ssl up') && item.failover == false) {
        if (uploadSpeed) {
          totalLegSpeed += item?.up_speed;
          this.subTitle = totalLegSpeed.toFixed(1) + " Mbps";
        } else {
          totalLegSpeed += item?.down_speed;
          this.subTitle = totalLegSpeed.toFixed(1) + " Mbps";
        }
      }
    });
    let totalLegSpeedBytes = 1048576 * totalLegSpeed;
    return rate * 100 / totalLegSpeedBytes;
  }

  convertBytesToKBPS(bytes: any) {
    if (Number(bytes)) return parseFloat(bytes) / 0.124;
    else return 0;
  }

  convertBytesToKBPSMinus(bytes: any) {
    if (Number(bytes)) return (parseFloat(bytes) / 0.124) * -1.0;
    else return 0;
  }

  getValueForProgressBar(id: any, start: number, end: number): number {
    let val = (end - start) / 3600;
    if (val < 0) { val = val * -1 }
    return val;
  }

  getTextForProgressBar(start: any, end: any): any {
    let text: string
    text = ((end - start) / 3600) + '';
    let arr: string[] = text.split('.');
    if (arr.length == 1) return `${arr[0]} hours`;
    else {
      if (arr[0] >= '24') { return `${arr[0]} hours` }
      else {
        let min = arr[1].substring(0, 1)
        return `${arr[0]} hours ${min} minutes`
      }
    }
  }

  keyFormate(key: string) {
    if (!key) return;
    return key.match(/.{1,5}/g)?.join('-');
  }
}
