<form [formGroup]="versionForm">
    <div class="card">
        <div class="card-body p-5">
            <!-- <div class="row" *ngIf="errors?.non_field_errors">
                <div class="col-md-12">
                    <div class="alert alert-danger">{{errors?.non_field_errors}}</div>
                </div>
            </div>
            <div class="row" *ngIf="errors && !errors['non_field_errors']">
                <div class="col-md-12">
                    <div class="alert alert-danger">Please correct the errors below.</div>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-6 col-lg-6 mb-1">
                    <div class="form-group inputtext-block mb-5">
                        <label class="form-label">Name </label>
                        <input formControlName="version" name="version" type="text"
                            class="form-control form-control-lg form-control-solid" autocomplete="off" />
                        <small class="form-invalid-message">
                            <span class="text-danger" *ngIf="controlHasError('pattern', 'version')">
                                Name is invalid
                            </span>
                            <span class="text-danger" *ngIf="controlHasError('required', 'version')">
                                Name is required
                            </span>
                        </small>
                        <!-- <small class="text-danger"
                            *ngIf="addFilter.controls.version.errors && !controlHasError('pattern', 'version')">
                            {{addFilter.controls.version.errors | json}}
                        </small> -->
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 mb-1">
                    <label class="form-label"> Enabled </label>
                    <div class="d-flex flex-column pt-2">
                        <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" formControlName="status">
                        </ui-switch>
                    </div>
                </div>
                <!-- <small class="text-danger" *ngIf="addFilter?.controls?.rules?.errors">
                    {{addFilter.controls.rules.errors}}
                </small> -->
                <div formArrayName="featureUpdates">
                    <div [formGroupName]="i" class="row mb-3" *ngFor="let x of featureUpdatesObj?.controls;let i=index">
                        <div class="col-md-12 mt-5">
                            <div class="row border border-gray-300 m-0 p-3 mb-3">
                                <div class="col-md-12">
                                    <h3 class="mb-0 pt-5 pb-5"> Feature </h3>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="col-md-12 col-lg-12 mb-5">
                                        <div class="form-group inputtext-block mb-5">
                                            <label class="form-label">Title</label>
                                            <input formControlName="title" name="title" type="text"
                                                class="form-control form-control-lg form-control-solid" placeholder=""
                                                autocomplete="off" />
                                            <small class="form-invalid-message">
                                                <span class="text-danger" *ngIf="controlHasError('pattern', 'version')">
                                                    Name is invalid
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="controlHasError('required', 'version')">
                                                    Name is required
                                                </span>
                                                <span class="text-danger" *ngIf="(x?.controls?.title?.touched || x?.controls?.title?.dirty) 
                                                    && x?.controls?.title?.errors">
                                                    Title is required
                                                </span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12 mb-5">
                                        <div class="form-group row">
                                            <div>
                                                <ckeditor formControlName="content" [config]="editorConfig"
                                                    [editor]="editor" (ready)="onReady($event)">
                                                </ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 mt-8">
                                    <div class="row mx-2 border">
                                        <div class="col-md-12 p-0">
                                            <div class="my-5 bg-light p-5 py-4 mt-0">
                                                <h4 class="fw-bold text-dark mb-0">Image</h4>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6 my-5">
                                            <input style="display: none;" type='file' id="imageUpload"
                                                accept=".png, .jpg, .jpeg" #fileNavBarInput
                                                (change)="uploadFile($event,i)" onclick="this.value = null" />
                                            <div fileDragDrop (filesChangeEmiter)="uploadFile($event,i)"
                                                class="notice d-flex flex-column bg-light-primary rounded border-primary border border-dashed align-items-center p-6">
                                                <!--begin::Icon-->
                                                <!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg-->
                                                <span class="svg-icon svg-icon-2tx svg-icon-primary me-4"><svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                        height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none"
                                                            fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <path
                                                                d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                                                fill="#000000" fill-rule="nonzero" opacity="0.3">
                                                            </path>
                                                            <rect fill="#000000" opacity="0.3" x="11" y="2" width="2"
                                                                height="14" rx="1">
                                                            </rect>
                                                            <path
                                                                d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                                                fill="#000000" fill-rule="nonzero"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon--> <!--end::Icon-->
                                                <!--begin::Wrapper-->
                                                <div class="d-flex justify-content-center flex-wrap">
                                                    <!--begin::Content-->
                                                    <div class="mb-3 mb-md-0 fw-semibold text-center">
                                                        <h4 class="text-gray-900 fw-bold mb-3">Drop files here
                                                            or click to upload.</h4>
                                                        <button type="submit" class="btn btn-primary btn-style me-5"
                                                            (click)="fileNavBarInput.click()">
                                                            Browse File</button>
                                                    </div>
                                                    <!--end::Content-->
                                                </div>
                                                <!--end::Wrapper-->
                                            </div>
                                            <p class="mt-5">PNG files only, 20x20 px or larger. We recommend 20
                                                px tall </p>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-4 mb-5">
                                            <div class="dropzone dropzone-default dz-clickable border text-center p-5 position-relative w-150px h-150px m-auto mt-5"
                                                id="kt_dropzone_1">
                                                <div>
                                                    <img class="image-size" *ngIf="x?.controls?.image?.value"
                                                        src="{{x?.controls?.image?.value}}" />
                                                </div>
                                                <button type="cancel" (click)="onDeletePicture(i)"
                                                    class="btn btn-danger btn-style p-2"
                                                    style="position: absolute;top: -20px;    right: -20px;">
                                                    <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                                                        class="svg-icon svg-icon-block mx-1"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 d-flex">
                                    <button type="button" class="btn btn-danger btn-style"
                                        (click)="onRemoveFeatureUpdates(i)">
                                        <span [inlineSVG]="'./assets/media/icons/duotune/icon/trash.svg'"
                                            class="svg-icon svg-icon-block mb-2"></span> Remove Feature </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button type="button" class="btn btn-primary btn-style" (click)="onAddFeatureUpdates()">
                            <div>
                                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                                    class="svg-icon svg-icon-block mb-2"></span>
                            </div> Add Feature
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer px-5 py-3">
            <button type="submit" (click)="versionForm.invalid ? '' :onSubmit()" class="btn btn-primary btn-style"
                [ngClass]="versionForm.invalid  ? 'disable-tune':''">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                        class="svg-icon svg-icon-block mb-2"></span>
                </div> Save
            </button>
            <button type="cancel" (click)="onCancel.emit()" class="btn btn-secondary btn-style">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                        class="svg-icon svg-icon-block mb-2"></span>
                </div> Cancel
            </button>
            <button type="submit" class="btn btn-primary btn-style"
                (click)="featureUpdatesObj?.controls?.length > 0 ? onPreview():''"
                [ngClass]="featureUpdatesObj?.controls?.length > 0  ? '':'disable-tune'">
                <div>
                    <span [inlineSVG]="'./assets/media/icons/normal/eye.svg'"
                        class="svg-icon svg-icon-block mb-2"></span>
                </div> Preview
            </button>
        </div>
    </div>
</form>