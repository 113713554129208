import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedNetworkConnectedIpEditComponent } from './shared-network-connected-ip-edit/shared-network-connected-ip-edit.component';
@Component({
  selector: 'app-shared-network-connected-ip',
  templateUrl: './shared-network-connected-ip.component.html',
  styleUrls: ['./shared-network-connected-ip.component.scss']
})
export class SharedNetworkConnectedIPComponent implements OnInit {
  authService = AuthService;
  latest_tuning: any;
  bondPermission: any;
  errors: any;

  @Input() allConnectedIPS: any[] = [];
  @Input() InterfaceDetailArray: any[] = [];
  @Input() bondId: any;
  @Input() permissionsObj: any;

  @Output() getConnectedIPS = new EventEmitter<any>();

  constructor(private PartnerBondingService: PartnerBondingService,
    private cd: ChangeDetectorRef,
    public sharedService: SharedService,
    private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.PartnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    if (localStorage.getItem('bondPermission')) {
      let data = JSON.parse(localStorage.getItem('bondPermission') || '');
      if (data && data.id == this.bondId) this.bondPermission = data.permission;
      this.cd.detectChanges();
    }
  }

  deleteIp(ipData: any, name: any) {
    let questionTitle = 'Are you sure you want to delete this ip?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"
    let body = {
      method: "DELETE",
      url: `bonds/${this.bondId}/connected_ips/${ipData.id}/`,
      data: {},
      type: "Connected IP",
      bondName: localStorage.getItem('bondName'),
      bondId: this.bondId,
      ip: ipData?.ip,
      id: ipData?.id,
      name: name
    };
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.PartnerBondingService.deleteData(body).subscribe((data: any) => {
          if (data.code == 204 || data.code == 200) this.sharedService.loggerSuccess('connected ip deleted successfully.');
          else this.sharedService.loggerError(JSON.stringify(data?.data));
          this.sharedService.hideLoader();
          this.getConnectedIPS.emit();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getConnectedIPS.emit();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  editIp(ipData: any) {
    let modal = this.modalService.open(SharedNetworkConnectedIpEditComponent, { size: 'lg' });
    if (ipData) {
      modal.componentInstance.ipDetail = ipData;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.InterfaceDetailArray = this.InterfaceDetailArray;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getConnectedIPS.emit();
    }, () => { });
  }
}
