import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { PartnerManagmentService } from 'src/app/services/partner-management.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../../../../../modules/shared/auth';

@Component({
  selector: 'app-timer-inner',
  templateUrl: './timer-inner.component.html',
  styleUrls: ['./timer-inner.component.scss']
})
export class TimerInnerComponent implements OnInit {
  @HostBinding('class') class =
    'menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px';
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  toolbarButtonMarginClass = 'ms-1 ms-lg-2';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  accessTimeForAdmin: any;
  mainId:any;
  loginId:any;
  constructor(private auth: AuthService,
     private cd: ChangeDetectorRef,
     private partnerService:PartnerManagmentService,
     private sharedService:SharedService) {
    this.setAccessTime();
  }

  ngOnInit(): void {
    this.mainId=localStorage.getItem('mainId');
    this.loginId = localStorage.getItem('ID');
  }

  setAccessTime() {
    this.auth.accessTimeSubject.subscribe(res => {
      this.accessTimeForAdmin = res;
      this.cd.markForCheck();
    });
  }
  revokePermission() {


            let questionTitle = 'Are you sure you want to revoke this right?';
            let text = ``
            let confirmButtonText = "Yes"
            this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
              if (result.isConfirmed) {
                this.sharedService.showLoader();
                let obj={
                  LoginID:this.loginId,
                  mainId:this.mainId,
                  admin_role: localStorage.getItem('role')
                }
                this.partnerService.revokePermission(obj).subscribe(
                  (res: any) => {
                    if (res) {
                      this.sharedService.hideLoader();
                      this.sharedService.loggerSuccess(res.message)
                      this.auth.isShowTimerAccessSubject.next(true);
              }
            });
          }
        }

    );
  }

  changeDuration() {

  }

}
