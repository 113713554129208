<div *ngIf="permissionsObj.view" class="card mb-5 mb-xl-8">
    <div class="card-header cursor-pointer d-flex flex-wrap flex-stack px-3 min-h-50 justify-content-end">
        <div></div>
        <div class="fw-bolder mb-2 div-position col-md-2 me-4">
            <div class="fw-bolder my-2 d-flex div-position">
                <input type="text" class="form-control pe-10" [formControl]="searchControl" name="searchText"
                    placeholder="Search" (keydown)="preventSpace($event)" value="" />
                <span (click)="onResetFilter()" [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
                    class="svg-icon svg-icon-1 me-0 span-input"></span>
            </div>
        </div>
        <div *ngIf="permissionsObj.update" class="d-flex flex-wrap mb-2">
            <div class="me-0 card-toolbar d-flex align-items-center justify-content-end" data-bs-toggle="tooltip"
                data-bs-placement="top" data-bs-trigger="hover" title="Click to Create Template">
                <button class="btn btn-sm btn-primary me-0" (click)="onAddEmailTemplate()">
                    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
                        class="svg-icon svg-icon-3 mx-1"></span>Create Email Template

                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-2">
        <!-- begin::Table container -->
        <div class="table-responsive border">
            <!-- begin::Table -->
            <table class="table table-row-bordered table-row-gray-300 align-middle gs-3 gy-1 mb-0">
                <!-- begin::Table head -->
                <thead class="align-middle table-light">
                    <tr class="fw-bolder text-dark fs-7">
                        <th class="py-4" (click)="onSort('coreMailType')">
                            Template Name
                            <span *ngIf="sortBy == 'coreMailType'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-150px" (click)="onSort('createdBy')">
                            Created By
                            <span *ngIf="sortBy == 'createdBy'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-150px" (click)="onSort('createdAt')">
                            Created At
                            <span *ngIf="sortBy == 'createdAt'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th class="py-4 min-w-150px" (click)="onSort('updatedAt')">
                            Last Updated At
                            <span *ngIf="sortBy == 'updatedAt'" [inlineSVG]="arrow" class="svg-icon svg-icon-5"
                                style="height: 10px;"></span>
                        </th>
                        <th *ngIf="(permissionsObj.update || permissionsObj.delete)" class="min-w-150px">Action</th>
                    </tr>
                </thead>
                <!-- end::Table head -->
                <!-- begin::Table body -->
                <tbody>
                    <tr *ngFor="let template of emailTemplates">
                        <td *ngIf="template.coreMailType" class="text-dark fs-6">
                            {{shared.prettifyCamelCase( coreMailType[template.coreMailType])}}
                        </td>
                        <td *ngIf="!template.coreMailType && template.customMailType" class="text-dark fs-6">
                            {{template.customMailType}}
                        </td>
                        <td *ngIf="!template.customMailType && !template.coreMailType" class="text-dark fs-6">
                            -
                        </td>
                        <td class="text-dark fs-6" *ngIf="template.createdBy[0]?.lname != '-'">
                            {{template.createdBy[0]?.fname+' '+template.createdBy[0]?.lname}}
                        </td>
                        <td class="text-dark fs-6" *ngIf="template.createdBy[0]?.lname == '-'">
                            {{template.createdBy[0]?.fname}}
                        </td>

                        <td class="text-dark fs-6">
                            {{template.createdAt | date}}
                        </td>
                        <td>
                            {{template.updatedAt | date}}
                        </td>

                        <td>
                            <a *ngIf="template.moduleType==emailType.custom && (permissionsObj.update)" title="Send"
                                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 h-30px w-30px"
                                (click)="open(template._id)">
                                <span [inlineSVG]="'./assets/media/icons/duotune/icon/paper-plane.svg'"
                                    class="svg-icon svg-icon-3 svg-icon-primary">
                                </span>
                            </a>

                            <a *ngIf="permissionsObj.update" title="Edit" (click)="onEditTemplate(template._id)"
                                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                                    class="svg-icon svg-icon-3 svg-icon-warning">
                                </span>
                            </a>

                            <a *ngIf="template.moduleType==emailType.custom && (permissionsObj.delete)" title="Delete"
                                (click)="onDeleteTemplate(template._id)"
                                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 h-30px w-30px">
                                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                                    class="svg-icon svg-icon-3 svg-icon-danger">
                                </span>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <!-- end::Table body -->
            </table>
            <div *ngIf="length == 0" class="text-center font16 mt15 mb15 pt-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">No records found.</span>
                </h3>
            </div>
            <!-- end: Table -->
            <app-pagination *ngIf="length > 0" [childMessage]="length" [index]="page" [pagesize]="limit"
                (messageEvent)="receiveMessage($event)">
            </app-pagination>
            <!-- end::Table container -->
        </div>
    </div>
</div>

<div *ngIf="!permissionsObj.view">
    <span>You have no permission to view the data. Kindly contact the authority.</span>
</div>