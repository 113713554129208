<div class="card">

  <div class="card-header d-flex justify-content-between align-items-center px-5">
    <h2 class="mb-0"> Add protocol </h2>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>

  <div class="card-body p-5">
    <form>
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label">Name <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                ngbTooltip=" A short description of this protocol's purpose. "></span></label>
            <input type="text" name="Name" class="form-control" id="" placeholder="">
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label"> Routing groups <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                ngbTooltip="Apply this protocol to any relevant aggregators in these routing groups."></span> </label>
            <div class="d-flex flex-column">
              <small> </small>
              <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF" class="mt-5"></ui-switch>
              <small> Antaresv2 dev space-Default group</small>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label"> Protocol </label>
            <ng-select placeholder="">
              <ng-option> OSPF </ng-option>
              <ng-option> BGP </ng-option>
              <ng-option> Static </ng-option>
              <ng-option> Babel </ng-option>
            </ng-select>
            <small>The type of protocol.</small>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label"> Enabled </label>
            <div class="d-flex flex-column">
              <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"></ui-switch>
              <small> Enable protocol. </small>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label"> IPv4 import </label>
            <ng-select placeholder="">
              <ng-option> None </ng-option>
              <ng-option> Filter </ng-option>
            </ng-select>
            <small> What IPv4 routes should be kept when importing from the protocol to the routing table. </small>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label"> IPv4 export </label>
            <ng-select placeholder="">
              <ng-option> None </ng-option>
              <ng-option> Filter </ng-option>
            </ng-select>
            <small> What IPv4 routes should be kept when exporting from the routing table to the protocol. </small>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label"> IPv6 import </label>
            <ng-select placeholder="">
              <ng-option> None </ng-option>
              <ng-option> Filter </ng-option>
            </ng-select>
            <small> What IPv6 routes should be kept when importing from the protocol to the routing table. </small>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label"> IPv6 export </label>
            <ng-select placeholder="">
              <ng-option> None </ng-option>
              <ng-option> Filter </ng-option>
            </ng-select>
            <small> What IPv6 routes should be kept when exporting from the routing table to the protocol. </small>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-8">
            <label class="form-label"> Debug </label>
            <div class="d-flex flex-column">
              <ui-switch checkedLabel="ON" size="small" uncheckedLabel="OFF"></ui-switch>
              <small> Enable protocol debug logging. </small>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>

  <div class="card-footer p-5">
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Add</button>
  </div>

</div>