import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { AuthHTTPService } from '../../services/auth-http';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  siteKey: string = environment.siteKey;
  emailValidator: any = AppConst.emailVal;
  forgotPasswordForm: FormGroup;
  loading: boolean = false;
  isCaptcha: Boolean = false;
  isPartner: boolean = false;
  forgot_email: any;
  userData: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private authHTTPService: AuthHTTPService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('partner' || 'Partner')) {
      this.isPartner = true;
    }
    this.authService.forgotPwdSubject$.subscribe(res => {
      if (!res) {
        if (this.isPartner) this.router.navigate(['/auth/login/partner']);
        else this.router.navigate(['/auth/login']);
      } else {
        this.userData = res;
        if (this.userData.email) this.forgot_email = this.userData.email;
        this.initForm();
      }
    })
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  initForm() {
    this.forgotPasswordForm = this.fb.group({
      email: [this.forgot_email || '',
      Validators.compose([
        Validators.required,
        Validators.pattern(this.emailValidator),
        Validators.minLength(3),
        Validators.maxLength(320),
      ]),
      ],
    });
  }

  submit() {
    if (this.forgotPasswordForm.invalid || !this.isCaptcha) {
      this.sharedService.loggerError('invalid data');
      return;
    }
    if (!this.isCaptcha) {
      this.sharedService.loggerError('invalid captcha');
      return;
    }
    if (!this.isPartner) this.forgotAdmin()
    else this.forgotPartner()
  }

  forgotAdmin() {
    if (!this.userData.email) return;
    this.loading = true;
    this.authHTTPService.forgotPassword({ email: this.userData.email }).subscribe((result: any) => {
      if (result) {
        this.sharedService.loggerSuccess(result.message);
        this.router.navigate(['/auth/login']);
      } else this.sharedService.loggerError("internal server error");
      this.loading = false;
    }, (err: any) => {
      this.loading = false;
      let errorMessage = err
      this.sharedService.loggerError(errorMessage);
    });
  }

  forgotPartner() {
    this.loading = true;
    let payload = {
      _id: this.userData._id,
      email: this.userData.email,
      role: this.userData.role,
    }
    this.authHTTPService.forgotPartnerPassword(payload).subscribe((result: any) => {
      if (result) {
        this.sharedService.loggerSuccess(result.message);
        this.router.navigate(['/auth/login/partner']);
      } else this.sharedService.loggerError("internal server error");
      this.loading = false;
    }, (err: any) => {
      this.loading = false;
      let errorMessage = err
      this.sharedService.loggerError(errorMessage);
    });
  }

  cancel() {
    if (!this.isPartner) this.router.navigate(['/auth/login'])
    else this.router.navigate(['/auth/login/partner'])
  }

  resolved(res: any) {
    if (res) this.isCaptcha = true;
    else this.isCaptcha = false;
  }
}
