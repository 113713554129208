import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime } from 'rxjs/operators';
import { PartnerOrganizationManagementService } from 'src/app/services/partner-organization-management.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PaginationComponent } from '../../pagination/pagination.component';
import { SharedSdwanSharingModalComponent } from '../sdwan-bonds-listing/shared-sdwan-sharing-modal/shared-sdwan-sharing-modal.component';

@Component({
  selector: 'app-shared-sdwan-sharing-list',
  templateUrl: './shared-sdwan-sharing-list.component.html',
  styleUrls: ['./shared-sdwan-sharing-list.component.scss']
})
export class SharedSdwanSharingListComponent implements OnInit, OnDestroy {
  @ViewChild(PaginationComponent) paginationComponent: PaginationComponent;
  searchControl: FormControl = new FormControl();
  allSdwan: any[] = [];
  sdwansList: any[] = [];
  searchSDWanList: any[] = [];
  apiURL: any = null;
  page: any = 1;
  limit: number = AppConst.pageSize;
  searchKey: any = '';
  sortBy: any;
  sortOrder: number = -1;
  isSearch: boolean = false;
  preLoaderScreen: boolean = false;

  @Input() permissions: any = {};

  constructor(private sharedService: SharedService, private cookie: CookieService,
    private cd: ChangeDetectorRef, private modalService: NgbModal,
    private orgService: PartnerOrganizationManagementService,) { }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  ngOnDestroy(): void {
    this.sharedService.setQOLParams(AppConst.qolKeyList.sharedSDWANList, {
      page: this.page,
      pageSize: this.limit,
      searchKey: this.searchKey,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    });
  }

  ngOnInit(): void {
    this.sharedService.hideLoader();
    if (this.cookie.check('api_url')) this.apiURL = this.cookie.get('api_url');
    if (this.apiURL) {
      this.setQolParams();
      this.getSharedSDWAN();
    }
    if (this.searchKey) {
      this.isSearch = true;
      this.searchControl.setValue(this.searchKey);
    }
    this.searchControl.valueChanges.pipe(debounceTime(900)).subscribe((data: any) => {
      if (!this.paginationComponent) new PaginationComponent(this.cd);
      if (data != null || data != undefined) {
        this.searchKey = data;
        if (data == "") this.isSearch = false;
        else this.isSearch = true;
        this.onSort(this.sortBy);
        if (this.paginationComponent) this.paginationComponent.getNext(1);
        // else {
        //   this.paginationComponent = new PaginationComponent(this.cd);
        //   this.searchControl.setValue('1');
        //   this.onResetFilter();
        // }
        this.cd.detectChanges();
      }
    });
  }

  setQolParams() {
    let qolParams = this.sharedService.getQOLParams(AppConst.qolKeyList.sharedSDWANList);
    if (qolParams) {
      if (qolParams.page) this.page = qolParams.page;
      if (qolParams.pageSize) this.limit = qolParams.pageSize;
      if (qolParams.searchKey) this.searchKey = qolParams.searchKey;
      if (qolParams.sortBy) this.sortBy = qolParams.sortBy;
      if (qolParams.sortOrder) this.sortOrder = qolParams.sortOrder;
    }
  }

  getSharedSDWAN() {
    this.preLoaderScreen = true;
    this.orgService.haveShareSdwanList().subscribe((res: any) => {
      if (res.code == 200 || res.code == 201) {
        if (res.finalArray) this.allSdwan = res?.finalArray;
        this.onSort(this.sortBy);
      } else this.sharedService.loggerError(res.message);
      this.preLoaderScreen = false;
      this.cd.detectChanges();
    }, (err) => {
      this.preLoaderScreen = false;
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  onRevoked(homeSpace: any, shareId: any, homeSpaceName: any) {
    if (!homeSpace || !shareId) return;
    let questionTitle = 'Revoke Shared SD-WAN';
    let text = "Are you sure? you are revoking this sd-wan from organization";
    let confirmButtonText = "Yes, Revoke it!";
    let payload = { "homeSpace": homeSpace, "homeSpaceName": homeSpaceName }
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.orgService.revokeSharedAssets(shareId, payload).subscribe((res: any) => {
          if (res.code == 200 || res.code == 201) this.sharedService.loggerSuccess('sd-wan has been revoked successfully');
          else this.sharedService.loggerError(res.message);
          this.sharedService.hideLoader();
          this.cd.detectChanges();
          this.getSharedSDWAN();
        }, (err) => {
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        });
      }
    });
  }

  receiveMessage(event: any) {
    this.limit = event.pageSize;
    this.page = event.pageIndex;
    if (this.isSearch) this.sdwansList = this.searchSDWanList.slice(this.limit * (this.page - 1), this.limit * this.page);
    else this.sdwansList = this.allSdwan.slice(this.limit * (this.page - 1), this.limit * this.page);
  }

  editShareSdwan(sdwanData: any) {
    let modal = this.modalService.open(SharedSdwanSharingModalComponent, { size: 'md' });
    let permissionsObject: any = {};
    permissionsObject['view'] = this.permissions?.view || false;
    permissionsObject['update'] = this.permissions?.update || false;
    permissionsObject['delete'] = this.permissions?.delete || false;
    if (permissionsObject?.view !== true) permissionsObject['isViewDisable'] = true;
    if (permissionsObject?.update !== true) permissionsObject['isUpdateDisable'] = true;
    if (permissionsObject?.delete !== true) permissionsObject['isDeleteDisable'] = true;
    if (permissionsObject?.view == true && sdwanData.bondPermissions?.view == 'true') permissionsObject.view = true;
    else permissionsObject.view = false;
    if (permissionsObject?.update == true && sdwanData.bondPermissions?.update == 'true') permissionsObject.update = true;
    else permissionsObject.update = false;
    if (permissionsObject?.delete == true && sdwanData.bondPermissions?.delete == 'true') permissionsObject.delete = true;
    else permissionsObject.delete = false;
    modal.componentInstance.bondPermissions = permissionsObject;
    modal.componentInstance.sdwanData = sdwanData;
    modal.componentInstance.isEdit = true;
    modal.result.then((data) => {
      if (data && data?.event === 'save') {
        this.getSharedSDWAN();
        this.cd.detectChanges();
      }
    }, () => { });
  }

  onSort(event: any, isOrderChange: boolean = false) {
    if (this.allSdwan?.length <= 0) return;
    let arrAllSdwans;
    if (this.isSearch) {
      this.searchSDWanList = this.allSdwan.filter(item => item?.homeSpaceName.toLowerCase().includes(this.searchKey.toLowerCase()));
      this.sdwansList = this.searchSDWanList.slice(0, this.limit);
      arrAllSdwans = this.searchSDWanList;
    } else {
      this.searchSDWanList = [];
      arrAllSdwans = this.allSdwan;
    }
    if (event) {
      if (this.sortBy == event) this.sortOrder = this.sortOrder == 1 ? -1 : 1;
      else this.sortOrder = 1;
      this.sortBy = event;
      if (!isOrderChange) this.sortOrder = this.sortOrder == 1 ? -1 : 1;
      if (this.sortOrder == 1) {
        if (this.sortBy == "organizationName") arrAllSdwans.sort((a, b) => a.sharedTo.localeCompare(b.sharedTo));
        if (this.sortBy == "homeSpaceName") arrAllSdwans.sort((a, b) => a.homeSpaceName.localeCompare(b.homeSpaceName));
      }
      if (this.sortOrder == -1) {
        if (this.sortBy == "organizationName") arrAllSdwans.sort((a, b) => b.sharedTo.localeCompare(a.sharedTo));
        if (this.sortBy == "homeSpaceName") arrAllSdwans.sort((a, b) => b.homeSpaceName.localeCompare(a.homeSpaceName));
      }
    }
    if (this.page) {
      if (arrAllSdwans?.length > this.limit) this.sdwansList = arrAllSdwans.slice(this.limit * (this.page - 1), this.limit * this.page);
      else this.sdwansList = arrAllSdwans;
    } else this.sdwansList = arrAllSdwans.slice(0, this.limit);
    this.cd.detectChanges();
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) {
      event.preventDefault();
    }
  }

  onResetFilter() {
    if (!this.searchControl.value) return;
    this.searchControl.patchValue('');
    // this.onReset.emit();
  }
}
