<div class="menu-item mt-2">
  <h4 class="mb-0">
    Give access to Admin
  </h4>
</div>

<div class="separator my-2"></div>
<div class="menu-item">
  <a (click)="setAdminPartnerAccess(30)" class="menu-link cursor-pointer"> 30 Mins </a>
</div>

<div class="separator my-2"></div>

<div class="menu-item">
  <a (click)="setAdminPartnerAccess(45)" class="menu-link cursor-pointer"> 45 Mins </a>
</div>

<div class="separator my-2"></div>
<div class="menu-item">
  <a (click)="setAdminPartnerAccess(60)" class="menu-link cursor-pointer"> 60 Mins </a>
</div>