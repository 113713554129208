import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../../../../modules/shared/auth';
import { TranslationService } from '../../../../../../modules/shared/i18n';
import { AppConst } from '../../../../../../shared/constants/app.constant';
import { SharedService } from '../../../../../../shared/services/shared.service';
enum User {
  admin,
  partner,
  business,
  endUser
}

@Component({
  selector: 'app-user-inner',
  templateUrl: './user-inner.component.html',
})

export class UserInnerComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  class = `menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`;
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  language: LanguageFlag;
  user$: Observable<any>;
  langs = AppConst.Languages;
  private unsubscribe: Subscription[] = [];
  _user: any;
  showSignOut: boolean = true;
  constructor(
    private router: Router,
    private auth: AuthService, private cookie: CookieService,
    private translationService: TranslationService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('isAdminLoggedInAsPartner') == '1') {
      this.showSignOut = false;
    }
    this.auth.currentUserSubject.asObservable().subscribe(data => {
      this._user = data;
    });
    this.setLanguage(this.translationService.getSelectedLanguage());
  }

  logout() {
    // this.auth.logout();
    let role = Number(localStorage.getItem('role'));
    this.clearCookies()
    let logoutPath = "";
    if (role == AppConst.admin || role == AppConst.basicUser) {
      logoutPath = "/auth/login";
      // this.router.navigate(['/auth/login'], {
      //   queryParams: {},skipLocationChange:true
      // }).then(()=>{

      //   //this.sharedService.hideLoader();
      // })

      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/auth/login']);
      // });
      // window.location.replace('/#/auth/login');
      // window.location.href = "/#/auth/login";
      // window.location.reload();

    }
    else if (role == AppConst.partner || role == AppConst.user || role == AppConst.organization) {
      logoutPath = "/auth/login/partner";
      //     this.router.navigate(['/auth/login/partner'], {
      //       queryParams: {}, skipLocationChange: true
      //     }).then(()=>{
      //       document.location.reload();
      //     })
      // window.location.href = "/#/auth/login/partner";
      // window.location.reload();
      // window.location.replace('/#/auth/login/partner');
    }

    localStorage.setItem('logoutPath', logoutPath)
    window.location.reload();
  }
  clearCookies() {
    console.log('logout-insoide-user');
    this.cookie.delete('authToken')
    localStorage.removeItem('ID');
    localStorage.removeItem('IpAddress');
    localStorage.removeItem('Module-Access');
    localStorage.removeItem('Name');
    localStorage.removeItem('graph_color_palate');
    localStorage.removeItem('role');
  }
  logoutToAdmin() {
    this.sharedService.showLoader();
    this.auth.logoutFromPartner();
  }

  selectLanguage(lang: string) {
    this.translationService.setLanguage(lang);
    this.setLanguage(lang);
    // document.location.reload();
  }

  setLanguage(lang: string) {
    this.langs.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
  }

  redirectToProfile() {
    let currentUser;
    if (Number(this.sharedService.getRole()) == AppConst.basicUser) {
      currentUser = 'admin';
    } else if (Number(this.sharedService.getRole()) == AppConst.user) {
      currentUser = 'partner'
    } else if (Number(this.sharedService.getRole()) == AppConst.organization) {
      currentUser = 'partner'
    } else {
      currentUser = User[this.sharedService.getRole()];
    }
    this.router.navigateByUrl(`${currentUser}/profile`);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}


