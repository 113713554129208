import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';

@Component({
  selector: 'app-shared-sdwan-wan-add-vlan',
  templateUrl: './shared-sdwan-wan-add-vlan.component.html',
  styleUrls: ['./shared-sdwan-wan-add-vlan.component.scss']
})
export class SharedSdwanWanAddVlanComponent implements OnInit {
  vlanData: any;
  edit: boolean;
  vlanForm: FormGroup;
  submitted: boolean = false;
  body: any;
  errors: any = {};
  groupList: any = [];
  parentId: any;

  constructor(public activeModal: NgbActiveModal, private cookie: CookieService, private cd: ChangeDetectorRef, private spaceService: PartnerSpaceService,) { }

  ngOnInit(): void {
    this.spaceService.spaceParentId$.subscribe(
      (res: any) => {
        if (res) this.parentId = res.parentId;
        this.cd.detectChanges();
      }
    );
    this.getSuccess()
    if (this.edit) this.vlanForm = this.createForm(this.vlanData);
    else this.vlanForm = this.createForm();
    this.cd.detectChanges()
  }

  getSuccess() {
    this.spaceService.successForVlan$.subscribe((res: any) => {
      if (res && res.success) this.activeModal.close({});
      this.cd.detectChanges()
    });
  }

  createForm(data: any = {}) {
    return new FormGroup({
      // filename:new FormControl(data.filename || ''),
      id: new FormControl(data?.id || null),
      ipv4_address_pool: new FormControl(data?.ipv4_address_pool || ''),
      ipv4_excluded_addresses: new FormControl(data?.ipv4_excluded_addresses || ''),
      ipv6_address_pool: new FormControl(data?.ipv6_address_pool || ''),
      ipv6_excluded_addresses: new FormControl(data?.ipv6_excluded_addresses || ''),
      routing_group: new FormControl(data?.routing_group || ''),
      // space_private_wan:new FormControl(data?.space_private_wan || ''),
      url: new FormControl(data?.url || null),
      vlan_id: new FormControl(data?.vlan_id || '')
    })
  }

  get f() {
    return this.vlanForm.controls;
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onAdd() {
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    // console.log("data",this.vlanForm.value)
    this.submitted = true;
    if (this.vlanForm.invalid) return;
    this.body = {
      // filename:this.vlanForm.value.filename,
      id: this.vlanForm.value.id,
      ipv4_address_pool: this.vlanForm.value.ipv4_address_pool,
      ipv4_excluded_addresses: this.vlanForm.value.ipv4_excluded_addresses.split(','),
      ipv6_address_pool: this.vlanForm.value.ipv6_address_pool,
      ipv6_excluded_addresses: this.vlanForm.value.ipv6_excluded_addresses.split(','),
      routing_group: this.vlanForm.value.routing_group,
      space_private_wan: `${apiURL}spaces/${this.parentId}/private_wan/`,
      url: this.vlanForm.value.url,
      vlan_id: this.vlanForm.value.vlan_id
    }
    this.activeModal.dismiss({ event: 'save', data: this.body });
  }

  onUpdate() {
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    this.submitted = true;
    if (this.vlanForm.invalid) return;
    this.body = {
      // filename:this.vlanForm.value.filename,
      id: this.vlanForm.value.id,
      ipv4_address_pool: this.vlanForm.value.ipv4_address_pool,
      ipv4_excluded_addresses: this.vlanForm.value.ipv4_excluded_addresses.split(','),
      ipv6_address_pool: this.vlanForm.value.ipv6_address_pool,
      ipv6_excluded_addresses: this.vlanForm.value.ipv6_excluded_addresses.split(','),
      routing_group: this.vlanForm.value.routing_group,
      space_private_wan: `${apiURL}spaces/${this.parentId}/private_wan/`,
      url: this.vlanForm.value.url,
      vlan_id: this.vlanForm.value.vlan_id
    }
    this.activeModal.dismiss({ event: 'save', data: this.body });
  }
}
