import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-space-listing',
  templateUrl: './shared-space-listing.component.html',
  styleUrls: ['./shared-space-listing.component.scss']
})
export class SharedSpaceListingComponent implements OnInit {
  @Input() spaces: any;
  spaceList: any[] = [];

  constructor(private router: Router,
    private sharedService: SharedService,
    private spaceService: PartnerSpaceService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.spaceList = this.spaces;
    this.cd.detectChanges();
  }

  onAdd(item: any) {
    //  this.spaceService.spaceParentId.next({parentId:item.key})
    this.spaceService.setSpaceParentId(item.key);
    this.spaceService.setSpaceData(item);
    this.sharedService.setCipherText(item.key,'space')
    this.cd.detectChanges();
    this.router.navigate([`/partner/space/create`])
  }

  onEdit(item: any) {
    // this.spaceService.spaceParentId.next({parentId:item.key})
    this.spaceService.setSpaceParentId(item.key);
    this.spaceService.setSpaceData(item);
    this.sharedService.setCipherText(item.key,'space')
    this.cd.detectChanges();
    this.router.navigate([`/partner/space/edit`]);
    // ,{ queryParams: { id: `${item.key}`}}
  }
}
