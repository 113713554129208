import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-sdwan-wan-delete',
  templateUrl: './shared-sdwan-wan-delete.component.html',
  styleUrls: ['./shared-sdwan-wan-delete.component.scss']
})
export class SharedSdwanWanDeleteComponent implements OnInit {
  vlanData: any;
  parentId: any;
  constructor(private sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private spaceService: PartnerSpaceService) { }

  ngOnInit(): void { }

  deletevlan() {
    console.log("in delete")
    let reqObj = {
      method: "DELETE",
      url: `spaces/${this.parentId}/private_wan/space_routing_group_vlan_assignments/${this.vlanData.id}/`
    }
    this.sharedService.showLoader()
    this.spaceService.generalSpaceApi(reqObj).subscribe((addRes) => {
      if (addRes.code == 200) {
        this.sharedService.loggerSuccess(addRes.message);
        this.cd.detectChanges();
        this.sharedService.hideLoader();
      }
    }, (err) => {
      this.sharedService.hideLoader();
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    })
  }
}
