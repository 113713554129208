import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../modules/shared/auth';
import { AppConst } from '../shared/constants/app.constant';


const BACKEND_URL = environment.apiUrl + '/admin';
const PARTNER_BACKEND_URL = environment.apiUrl + '/partnerPanel';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    mainId = localStorage.getItem('mainId');

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) { }

    //Fetch Subject
    getData() {
        let params = new HttpParams().set('isDeleted', 'false')
        return this.http.get(BACKEND_URL + '/data', { params })
    }

    // update profile data 
    uploadProfileData(data: any) {
        let role = localStorage.getItem('role');
        if (Number(role) == AppConst.admin || Number(role) == AppConst.basicUser) {
            return this.http.put<any>(BACKEND_URL + `/updateProfile`, data);
        } else if (Number(role) == AppConst.partner) {
            return this.http.put<any>(PARTNER_BACKEND_URL + `/updateProfile/${this.mainId}`, data);
        } else if (Number(role) == AppConst.user || Number(role) == AppConst.organization) {
            return this.http.put<any>(PARTNER_BACKEND_URL + `/updateProfile/${this.mainId}`, data);
        } else return of({});
    }

    updateGraphColor(graph_color_palate: any) {
        this.mainId = localStorage.getItem('mainId');
        return this.http.put<any>(PARTNER_BACKEND_URL + `/updateGraphColor/${this.mainId}`, { graph_color_palate: graph_color_palate });
    }

    // Image upload directly
    uploadImage(data: any) {
        return this.http.post<any>(BACKEND_URL + '/uploadPhoto', data);
    }

    //Update Password
    updatePassword(data: any) {
        let role = localStorage.getItem('role');
        if (Number(role) == AppConst.admin || Number(role) == AppConst.basicUser) {
            return this.http.put<any>(BACKEND_URL + `/changePassword`, data);
        } else if (Number(role) == AppConst.partner) {
            return this.http.put<any>(PARTNER_BACKEND_URL + `/changePassword/${this.mainId}`, data);
        } else if (Number(role) == AppConst.user || Number(role) == AppConst.organization) {
            return this.http.put<any>(PARTNER_BACKEND_URL + `/changePassword/${this.mainId}`, data);
        } else return of({});
        // return this.http.put<ChangePassword>(BACKEND_URL + '/changePassword', data);
    }

    // Set User data after updation
    setUserSubscriber(img: string, fname?: string, lname?: string) {
        let oldUser: any;
        this.auth.currentUser$.subscribe((res: any) => { oldUser = res; });
        oldUser['imageUrl'] = img;
        if (fname && lname) {
            oldUser['fname'] = fname;
            oldUser['lname'] = lname;
        }
        this.auth.currentUserSubject.next(oldUser);
    }

}
