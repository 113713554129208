import { Router, Routes } from '@angular/router';
import { Error404CopyComponent } from '../../modules/shared/errors/error404-c/error404.component';
import { AdminPermissionGuard } from 'src/app/modules/shared/auth/services/admin-permission.guard';

// Set SubRouting to get sub value for route
const Routing: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AdminPermissionGuard],
    loadChildren: () => import('../../modules/shared/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'partner',
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('../../modules/partner/partner.module').then((m) => m.PartnerModule),
  },
  {
    path: 'partner-management',
    canActivate: [AdminPermissionGuard],
    data: { breadcrumb: 'Partners Management' },
    loadChildren: () => import('../../modules/super-admin/partner-managment/partner-managment.module').then((m) => m.PartnerManagmentModule),
  },
  {
    path: 'staff-management',
    canActivate: [AdminPermissionGuard],
    data: { breadcrumb: 'Staff Management' },
    loadChildren: () => import('../../modules/shared/staff-management/staff-management.module').then((m) => m.StaffManagementModule),
  },
  {
    path: 'module-access',
    canActivate: [AdminPermissionGuard],
    data: { breadcrumb: 'Modules' },
    loadChildren: () => import('../../modules/shared/module-access/module-access.module').then((m) => m.ModuleAccessModule),
  },
  {
    path: 'configuration',
    canActivate: [AdminPermissionGuard],
    data: { breadcrumb: 'Configuration' },
    loadChildren: () => import('../../modules/super-admin/configuration/configuration.module').then((m) => m.ConfigurationModule),
  },
  {
    path: 'profile',
    canActivate: [AdminPermissionGuard],
    data: { breadcrumb: 'My Profile' },
    loadChildren: () => import('../../modules/shared/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'logs',
    canActivate: [AdminPermissionGuard],
    data: { breadcrumb: 'Logs' },
    loadChildren: () => import('../../modules/shared/logs/logs.module').then((m) => m.LogsModule),
  },
  {
    path: '**',
    component: Error404CopyComponent,
    data: { breadcrumb: { skip: true } }
  }
];

export { Routing };
