import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as customEditor from 'src/app/ckeditor-build/build/ckeditor';
import { ProfileService } from 'src/app/services/profile.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { VersionModalComponent } from '../version-modal/version-modal.component';

@Component({
  selector: 'app-version-create',
  templateUrl: './version-create.component.html',
  styleUrls: ['./version-create.component.scss']
})
export class VersionCreateComponent implements OnInit {
  versionForm: FormGroup;
  NameFieldValidation: any = AppConst.NameFieldPattern;
  basicEditor: any;
  editor = customEditor;
  isNew: boolean;
  editorConfig = {
    toolbar: {
      items: [
        'heading', '|', "blockQuote", "bold", 'italic', "selectAll", "undo", "redo", "|",
        "link", "numberedList", "bulletedList"
      ],
      shouldNotGroupWhenFull: true
    },
    language: 'en',
  }

  @Output() onSave = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Input() versionData: any;

  constructor(private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private formErrorHandler: FormErrorService,
    private profileService: ProfileService,
    private modalService: NgbModal,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.versionForm = this.createNewForm();
    if (this.versionData) {
      this.isNew = false;
      this.setFormData();
    } else this.isNew = true;
    this.cd.detectChanges();
  }

  get featureUpdatesObj(): any {
    return this.versionForm.get("featureUpdates") as FormArray
  }

  createNewForm() {
    return this.fb.group({
      status: [false],
      version: ['', [Validators.required, this.sharedService.xssValidator]],
      featureUpdates: this.fb.array([]),
    })
  }

  setFormData() {
    this.versionForm.patchValue({
      status: this.versionData?.status,
      version: this.versionData?.version,
    });
    this.setFeatureUpdates(this.versionData?.featureUpdates);
    this.cd.detectChanges();
  }

  setFeatureUpdates(arrval: any) {
    if (arrval.length > 0) {
      arrval.forEach((element: any, i: any) => {
        let rules = this.newFeatureUpdates(element);
        let control = <FormArray>this.versionForm.controls.featureUpdates;
        control.push(rules);
      });
    }
  }

  onAddFeatureUpdates() {
    let rules = this.newFeatureUpdates();
    let control = <FormArray>this.versionForm.controls.featureUpdates;
    control.push(rules);
  }

  onRemoveFeatureUpdates(index: any) {
    this.featureUpdatesObj.removeAt(index);
  }

  newFeatureUpdates(data: any = {}): FormGroup {
    return this.fb.group({
      title: [data.title || '', [this.sharedService.xssValidator, Validators.required]],
      content: [data.content || '', [this.sharedService.xssValidator]],
      image: [data.image || ''],
    })
  }

  public onReady(editor: any) {
    this.basicEditor = editor;
  }

  onSubmit() {
    if (this.versionForm.invalid) {
      this.versionForm.markAllAsTouched();
      return;
    }
    this.onSave.emit({ value: this.versionForm.value, isNew: this.isNew })
  }

  onDeletePicture(index: any) {
    this.featureUpdatesObj.controls[index]?.controls.image.setValue('');
  }

  uploadFile(event: any, index: any) {
    let file;
    if (event?.target?.files) file = event.target.files;
    else file = event;
    if (file && file[0]) {
      if (file[0].type == 'image/jpeg' || file[0].type == 'image/png' || file[0].type == 'image/jpg') {
        if (file[0].size > 2000000) {
          this.sharedService.loggerError('File size should be less than 2mb');
          return;
        }
      } else {
        this.sharedService.loggerError('Profile image supports only png, jpg and jpeg format');
        return;
      }
      // show loader
      this.sharedService.showLoader();
      // Set formdata
      const formData = new FormData();
      formData.append('img', file[0]);
      this.profileService.uploadImage(formData).subscribe((res: any) => {
        if (res && res.data) {
          this.featureUpdatesObj.controls[index]?.controls.image.setValue(res.data[0]);
          // reader.readAsDataURL(file);
        }
        this.sharedService.hideLoader();
        this.cd.markForCheck();
      }, (err: any) => {
        this.sharedService.hideLoader();
        if(err?.error?.err) this.sharedService.loggerError(err?.error?.err); 
        else this.sharedService.loggerError(err); 
        console.log(err);
      });
    }
  }


  setEditorValueOnFoundationChange(event: any) {
    if (event) {
      const content = event;
      const viewFragment = this.basicEditor.data.processor.toView(content);
      const modelFragment = this.basicEditor.data.toModel(viewFragment);
      this.basicEditor.model.insertContent(modelFragment);
      this.onReady(this.basicEditor);
    }
  }

  onPreview() {
    let modal = this.modalService.open(VersionModalComponent, { size: 'lg' });
    modal.componentInstance.versionData = this.versionForm.value;
    modal.componentInstance.isPreview = true;
  }

  isControlValid(controlName: string): boolean {
    return this.formErrorHandler.isControlValid(controlName, this.versionForm);
  }

  isControlInvalid(controlName: string): boolean {
    return this.formErrorHandler.isControlInvalid(controlName, this.versionForm);
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.versionForm);
  }

  isControlTouched(controlName: string): boolean {
    return this.formErrorHandler.isControlTouched(controlName, this.versionForm);
  }
}
