<!-- <ng-container *ngIf="user$ | async as _user"> -->
  
<div *ngIf="_user" class="menu-item px-3">
  <div class="menu-content d-flex align-items-center px-3">
    <div class="symbol symbol-50px me-5">
      <img alt="Logo" *ngIf="_user.imageUrl" [src]="_user.imageUrl" />
      <img *ngIf="!_user.imageUrl" src="./assets/media/avatars/blank.png" alt="">
    </div>

    <div class="d-flex flex-column">
      <div class="fw-bolder d-flex align-items-center fs-5">
        {{_user.fname | truncate: ['15', '...'] }} {{_user.lname | truncate: ['15', '...']}}

      </div>
      <a class="fw-bold text-muted text-hover-primary fs-7 cursor-pointer user-email">
        {{ _user.email }}
      </a>
      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1" *ngIf="_user.role == 0">Super Admin</span>
      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1" *ngIf="_user.role == 1">Root Partner</span>
      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1" *ngIf="_user.role == 2">Reseller</span>
      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1" *ngIf="_user.role == 3">User</span>
      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1" *ngIf="_user.role == 4">End User</span>
      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1" *ngIf="_user.role == 5">Basic User</span>
    </div>
  </div>
</div>

<div class="separator my-2"></div>

<div class="menu-item px-5">
  <a (click)="redirectToProfile()" class="menu-link px-5">
    My Profile
  </a>
</div>

<!-- <div class="menu-item px-5">
    <a class="menu-link px-5 cursor-pointer"> My Statements </a>
  </div> -->

<div class="separator my-2"></div>
<div class="menu-item px-5" *ngIf="showSignOut">
  <a (click)="logout()" class="menu-link px-5 cursor-pointer"> Sign Out </a>
</div>
<!-- langauge -->
<!-- <div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start"
    data-kt-menu-flip="bottom">
    <a class="menu-link px-5 cursor-pointer">
      <span class="menu-title position-relative">
        Language
        <span class="
            fs-8
            rounded
            bg-light
            px-3
            py-2
            position-absolute
            translate-middle-y
            top-50
            end-0
          ">
          {{ language.name }}{{ " " }}
          <img class="w-15px h-15px rounded-1 ms-2" [src]="language.flag" alt="metronic" />
        </span>
      </span>
    </a>

    <div class="menu-sub menu-sub-dropdown w-175px py-4">
      <div *ngFor="let l of langs" class="menu-item px-3 cursor-pointer" (click)="selectLanguage(l.lang)">
        <a class="menu-link d-flex px-5" [ngClass]="l.lang === language.lang ? 'active' : ''">
          <span class="symbol symbol-20px me-4">
            <img class="rounded-1" [src]="l.flag" alt="metronic" />
          </span>
          {{ l.name }}
        </a>
      </div>
    </div>
  </div> -->

<!-- <div class="menu-item px-5 my-1">
    <a routerLink="/crafted/account/settings" class="menu-link px-5">
      Account Settings
    </a>
  </div> -->



<!-- <div class="menu-item px-5" *ngIf="!showSignOut">
  <a (click)="logoutToAdmin()" class="menu-link px-5 cursor-pointer"> Back to Admin </a>
</div> -->

<!-- </ng-container> -->


<!-- Without User profile Data -->
<!-- <ng-container>
  <div class="menu-item px-3">
    <div class="menu-content d-flex align-items-center px-3">
      <div class="symbol symbol-50px me-5">
        <img alt="Logo" src="./assets/media/avatars/blank.png" />
      </div>

      <div class="d-flex flex-column">
        <div class="fw-bolder d-flex align-items-center fs-5">
          Test User
          <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span>
        </div>
        <a class="fw-bold text-muted text-hover-primary fs-7 cursor-pointer">
          Test@gmail.com
        </a>
      </div>
    </div>
  </div>

  <div class="separator my-2"></div>

  <div class="menu-item px-5">
    <a routerLink="/crafted/pages/profile" class="menu-link px-5">
      My Profile
    </a>
  </div>

  <div class="menu-item px-5">
    <a class="menu-link px-5 cursor-pointer"> My Statements </a>
  </div>

  <div class="separator my-2"></div>

  <div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start"
    data-kt-menu-flip="bottom">
    <a class="menu-link px-5 cursor-pointer">
      <span class="menu-title position-relative">
        Language
        <span class="
            fs-8
            rounded
            bg-light
            px-3
            py-2
            position-absolute
            translate-middle-y
            top-50
            end-0
          ">
          {{ language.name }}{{ " " }}
          <img class="w-15px h-15px rounded-1 ms-2" [src]="language.flag" alt="metronic" />
        </span>
      </span>
    </a>

    <div class="menu-sub menu-sub-dropdown w-175px py-4">
      <div *ngFor="let l of langs" class="menu-item px-3 cursor-pointer" (click)="selectLanguage(l.lang)">
        <a class="menu-link d-flex px-5" [ngClass]="l.lang === language.lang ? 'active' : ''">
          <span class="symbol symbol-20px me-4">
            <img class="rounded-1" [src]="l.flag" alt="metronic" />
          </span>
          {{ l.name }}
        </a>
      </div>
    </div>
  </div>

  <div class="menu-item px-5 my-1">
    <a routerLink="/crafted/account/settings" class="menu-link px-5">
      Account Settings
    </a>
  </div>

  <div class="menu-item px-5">
    <a (click)="logout()" class="menu-link px-5 cursor-pointer"> Sign Out </a>
  </div>
</ng-container> -->