import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/shared/auth';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-shared-group-permission',
  templateUrl: './shared-group-permission.component.html',
  styleUrls: ['./shared-group-permission.component.scss']
})
export class SharedGroupPermissionComponent implements OnInit, OnChanges {
  authService = AuthService;
  searchControl: FormControl = new FormControl();
  userListIds: any = [];

  @Input() groups: any[];
  @Input() length: any;
  @Input() page: number;
  @Input() limit: number;
  @Input() sortBy: string;
  @Input() sortOrder: number;
  @Input() permissions: any;
  @Input() preLoaderScreen: boolean = false;

  @Output() resetFilter = new EventEmitter<any>();
  @Output() sendMail = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() receiveMessageEmitter = new EventEmitter<any>();
  @Output() sendPopUp = new EventEmitter<any>();
  @Output() onSortFields = new EventEmitter<any>();
  @Output() view = new EventEmitter<any>();

  constructor(private shared: SharedService, private router: Router, private cd: ChangeDetectorRef) {
    if (localStorage.getItem('userIds')) this.userListIds = JSON.parse(localStorage.getItem('userIds') || '');
  }

  ngOnInit(): void { }

  ngOnChanges(): void {
    this.cd.detectChanges();
  }

  onAddGroup() {
    this.router.navigate(['/partner/user-org-management/group-permission/create'])
  }

  onDelete(id: any) {
    if (id) {
      let questionTitle = 'Are you sure?';
      let text = "<b>You are deleting this group. <br>It can't be revert back! </b>"
      let confirmButtonText = "Yes, Delete it!"
      this.shared.swalQuestion(questionTitle, text, confirmButtonText).then((result: any) => {
        if (result.isConfirmed) {
          this.delete.emit({ groupId: id });
        }
        this.cd.detectChanges();
      })
    }
  }

  get arrow() {
    return `./assets/media/icons/duotune/arrows/long-arrow-alt-${this.sortOrder == 1 ? 'up' : 'down'}.svg`;
  }

  preventSpace(event: any) {
    if (event.keyCode == 32 && !this.searchControl.value) {
      event.preventDefault();
    }
  }

  onSort(event: any) {
    this.onSortFields.emit(event);
  }

  onResetFilter() {
    this.searchControl.setValue('');
    // this.resetFilter.emit('');
  }

  receiveMessage(data: any) {
    this.receiveMessageEmitter.emit(data);
  }
  onView(id: any) {
    this.view.emit({ groupId: id })
  }

  onEdit(id: any) {
    this.edit.emit({ groupId: id })
  }

  findUseById(Id: any) {
    let lastUserId = this.userListIds[this.userListIds.length - 1];
    if (lastUserId == Id) return true;
    else return false
  }
}
