import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { NotificationsInnerComponent } from './dropdown-inner/notifications-inner/notifications-inner.component';
import { QuickLinksInnerComponent } from './dropdown-inner/quick-links-inner/quick-links-inner.component';
import { UserInnerComponent } from './dropdown-inner/user-inner/user-inner.component';
import { LayoutScrollTopComponent } from './scroll-top/scroll-top.component';
import { TranslationModule } from '../../../../modules/shared/i18n';
import { SharedModule } from '../../../../shared/modules/shared.module';
import { TimerInnerComponent } from './dropdown-inner/timer-inner/timer-inner.component';

@NgModule({
  declarations: [
    NotificationsInnerComponent,
    QuickLinksInnerComponent,
    UserInnerComponent,
    LayoutScrollTopComponent,
    TimerInnerComponent,
  ],
  imports: [CommonModule, SharedModule, InlineSVGModule, RouterModule, TranslationModule],
  exports: [
    NotificationsInnerComponent,
    QuickLinksInnerComponent,
    UserInnerComponent,
    LayoutScrollTopComponent,
    TimerInnerComponent
  ],
})
export class ExtrasModule {}
