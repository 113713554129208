import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-space-wan-add-gateway',
  templateUrl: './shared-space-wan-add-gateway.component.html',
  styleUrls: ['./shared-space-wan-add-gateway.component.scss']
})
export class SharedSpaceWanAddGatewayComponent implements OnInit {
  gatewayForm: FormGroup;
  aggregatorList: any = []
  parentId: any;
  errors: any;

  constructor(public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef, private cookie: CookieService,
    private sharedService: SharedService,
    private spaceService: PartnerSpaceService) { }

  ngOnInit(): void {
    this.gatewayForm = this.createForm();
    this.gatewayForm.valueChanges.subscribe(x => { this.errors = null; });
  }

  createForm(data: any = {}) {
    return new FormGroup({
      aggregator: new FormControl(data?.aggregator || '')
    })
  }

  get f() {
    return this.gatewayForm.controls;
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onSave() {
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    this.errors = null;
    let body: any = {
      aggregator: this.gatewayForm.value.aggregator,
      space_private_wan: `${apiURL}spaces/${this.parentId}/private_wan/`
    }
    let payload = {
      method: "POST",
      url: `spaces/${this.parentId}/private_wan/managed_mesh_gateway_aggregators/`,
      data: body
    }
    this.sharedService.showLoader();
    this.spaceService.generalSpaceApi(payload).subscribe((addRes) => {
      if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess('gateway aggregators saved successfully.');
      else this.sharedService.loggerError(addRes.message);
      this.activeModal.close({ event: 'save' });
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.errors = JSON.parse(err);
        this.sharedService.hideLoader();
        if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
        else this.sharedService.loggerError('Please correct the errors.');
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }
}
