<div class="card">
  <div class="card-header d-flex justify-content-between align-items-center p-5">
    <h2 class="mb-0 fs-3" *ngIf="edit!=true"> Add routing group VLAN assignment </h2>
    <!-- {{groupList | json}} -->
    <h2 class="mb-0" *ngIf="edit==true"> Edit routing group VLAN assignment </h2>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
  </div>

  <div class="card-body p-5">
    <p> For aggregators in the specified routing group, automatically set up a VLAN interface in the space whenever the
      space becomes active. An IPv4/IPv6 pool and exclusion list may also be set up which will automatically assign an
      address on the VLAN interface.</p>
    <form [formGroup]="vlanForm" *ngIf="vlanForm">
      <p> Specify the IP subnet to delegate to a child space. </p>
      <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-6">
          <div class="form-group mb-5">
            <label class="form-label"> Routing group <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                ngbTooltip=" Which routing group the following configuration applies to."></span> </label>
            <ng-select class="" formControlName="routing_group" name="routing_group">
              <ng-option *ngFor="let group of groupList.results" [value]="group.url"> {{group.display_name}}
              </ng-option>
            </ng-select>
            <small> </small>
            <div>
              <small class="text-danger">
                {{errors['routing_group']?.join(' ')}}
              </small>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-6">
          <div class="form-group mb-5">
            <label class="form-label"> VLAN ID <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top"
                ngbTooltip="The VLAN ID that will be used for the VLAN interface on aggregators in the specified routing group."></span>
            </label>
            <input type="text" formControlName="vlan_id" name="vlan_id"
              class="form-control form-control-lg form-control-solid" id="" placeholder="">
            <div>
              <small class="text-danger">
                {{errors['vlan_id']?.join(' ')}}
              </small>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6">
          <div class="form-group mb-5">
            <label class="form-label"> IPv4 pool <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip=" A pool of IP addresses to assign to aggregator VLAN interfaces as the space becomes active on an
                aggregator. The address will use the prefix size of the pool."></span> </label>
            <input type="text" formControlName="ipv4_address_pool" name="ipv4_address_pool"
              class="form-control form-control-lg form-control-solid" id="" placeholder="">
            <div>
              <small class="text-danger">
                {{errors['ipv4_address_pool']?.join(' ')}}
              </small>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-6">
          <div class="form-group mb-5">
            <label class="form-label">IPv4 exclusions <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip=" A comma-separated list of addresses in the IPv4 pool to not assign to aggregators. Commonly this
                contains the IP of any routers on the VLAN used for integration."></span> </label>
            <input type="text" formControlName="ipv4_excluded_addresses" name="ipv4_excluded_addresses"
              class="form-control form-control-lg form-control-solid" id="" placeholder="">
            <div>
              <small class="text-danger">
                {{errors['ipv4_excluded_addresses']?.join(' ')}}
              </small>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-6">
          <div class="form-group mb-5">
            <label class="form-label">IPv6 pool <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip=" A pool of IPv6 addresses to assign to aggregator VLAN interfaces as the space becomes active on an
                aggregator. The address will use the prefix size of the pool."></span> </label>
            <input type="text" formControlName="ipv6_address_pool" name="ipv6_address_pool"
              class="form-control form-control-lg form-control-solid" id="" placeholder="">
            <div>
              <small class="text-danger">
                {{errors['ipv6_address_pool']?.join(' ')}}
              </small>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="form-group mb-5">
            <label class="form-label"> IPv6 exclusions <span [inlineSVG]="'./assets/media/icons/normal/info-circle.svg'"
                class="svg-icon svg-icon-6 svg-icon-primary ps-1 cursor-pointer" placement="top" ngbTooltip="A comma-separated list of addresses in the IPv6 pool to not assign to aggregators. Commonly this
                contains the IP of any routers on the VLAN used for integration."></span> </label>
            <input type="text" formControlName="ipv6_excluded_addresses" name="ipv6_excluded_addresses"
              class="form-control form-control-lg form-control-solid" id="" placeholder="">
            <div>
              <small class="text-danger">
                {{errors['ipv6_address_pool']?.join(' ')}}
              </small>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="card-footer p-5">

    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" *ngIf="edit!=true" (click)="onAdd()"><span
        [inlineSVG]="'./assets/media/icons/duotune/arrows/plus.svg'"
        class="svg-icon svg-icon-4 me-1"></span>Add</button>
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" *ngIf="edit==true" (click)="onUpdate()"><span
        [inlineSVG]="'./assets/media/icons/normal/repeat.svg'" class="svg-icon svg-icon-4 me-1"></span>Update</button>
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onClose()"><span
        [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
        class="svg-icon svg-icon-4 me-1"></span>Cancel</button>
  </div>

</div>