import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service'; //'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-shared-network-service-create',
  templateUrl: './shared-network-service-create.component.html',
  styleUrls: ['./shared-network-service-create.component.scss']
})
export class SharedNetworkServiceCreateComponent implements OnInit {
  errors: any;
  selectedOption: any;
  type: any;
  selectedTab: any = 1;
  connectedIPs: any;
  body: any;
  bondId: any;
  isEdit: any;
  serviceData: any;
  data: any;
  submitted = false;
  dnsServerOptionsForm: FormGroup;
  ipArray: any[] = [];

  addDHCP = new FormGroup({
    connectedip: new FormControl('', [Validators.required]),
    dhcp: new FormControl('local'),
    dhcp_range_start: new FormControl(),
    dhcp_range_end: new FormControl(),
    dhcp_lease_time: new FormControl('24h'),
    domain: new FormControl(),
    tftp_server: new FormControl(),
    relay_host_1: new FormControl(),
    relay_host_2: new FormControl(),
    relay_host_3: new FormControl(),
    dns: new FormControl(false),
    dns_server_1: new FormControl(),
    dns_server_2: new FormControl(),
    dns_server_3: new FormControl(),
    custom_configuration: new FormControl()
  })

  addDnsCaching = new FormGroup({
    connectedip: new FormControl([]),
    dns_servers: new FormArray([])
  })

  addDHCPV6 = new FormGroup({
    connectedip: new FormControl('', [Validators.required]),
    enabled: new FormControl(true),
    prefix: new FormControl(''),
    delegated_len: new FormControl('', [Validators.required]),
    excluded_prefix: new FormControl(),
    dns_servers: new FormArray([])
  })

  addDHCPNA = new FormGroup({
    connectedip: new FormControl('', [Validators.required]),
    enabled: new FormControl(true),
    pool: new FormControl(),
    dns_servers: new FormArray([])
  })

  addRouterAdv = new FormGroup({
    connectedip: new FormControl('', [Validators.required]),
    enabled: new FormControl(false),
    prefix: new FormControl(),
    min_advertise_interval: new FormControl(),
    max_advertise_interval: new FormControl(),
    set_managed_flag: new FormControl(false),
    set_other_config_flag: new FormControl(false),
    rdnss_lifetime: new FormControl(),
    rdnss_servers: new FormArray([]),
    dnssl_lifetime: new FormControl(),
    dnssl_domains: new FormArray([]),
  })

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef, private cookie: CookieService,
    private PartnerBondingService: PartnerBondingService,
    private sharedService: SharedService) { }

  createDetailsForm(data: any = {}) {
    return this.fb.group({
      connectedip: [data?.connectedip || '', [Validators.required]],
      dhcp: [data?.dhcp || 'local'],
      dhcp_range_start: [data?.dhcp_range_start],
      dhcp_range_end: [data?.dhcp_range_end],
      dhcp_lease_time: [data?.dhcp_lease_time || '24h'],
      domain: [data?.domain || '', [this.sharedService.xssValidator]],
      tftp_server: [data?.tftp_server, [this.sharedService.xssValidator]],
      relay_host_1: [data?.relay_host_1],
      relay_host_2: [data?.relay_host_2],
      relay_host_3: [data?.relay_host_3],
      dns: [data?.dns || false],
      dns_server_1: [data?.dns_server_1],
      dns_server_2: [data?.dns_server_2],
      dns_server_3: [data?.dns_server_3],
      custom_configuration: [data?.custom_configuration, [this.sharedService.xssValidator]]
    })
  }

  dhcpv6DetailsForm(data: any = {}) {
    let formData = this.fb.group({
      connectedip: [data?.connectedip],
      enabled: [data?.enabled == false ? false : true],
      prefix: [data?.prefix || ''],
      delegated_len: [data?.delegated_len],
      excluded_prefix: [data?.excluded_prefix],
      dns_servers: this.fb.array([])
    });
    if (data && data.dns_servers && data.dns_servers.length > 0) this.setdnsval(data.dns_servers, formData);
    return formData;
  }

  dhcpnaDetailsForm(data: any = {}) {
    let formData = this.fb.group({
      connectedip: [data?.connectedip],
      enabled: [data?.enabled == false ? false : true],
      pool: [data?.pool || ''],
      dns_servers: this.fb.array([])
    });
    if (data && data.dns_servers && data.dns_servers.length > 0) this.setdnsval(data.dns_servers, formData);
    return formData;
  }

  routeradvDetailsForm(data: any = {}) {
    let formData = this.fb.group({
      connectedip: [data?.connectedip],
      enabled: [data?.enabled == false ? false : true],
      prefix: [data?.prefix || null],
      min_advertise_interval: [data?.min_advertise_interval || 10],
      max_advertise_interval: [data?.max_advertise_interval || 30],
      set_managed_flag: [data?.set_managed_flag || false],
      set_other_config_flag: [data?.set_other_config_flag || false],
      rdnss_lifetime: [data?.rdnss_lifetime || 30],
      rdnss_servers: this.fb.array([]),
      dnssl_lifetime: [data?.dnssl_lifetime || 30],
      dnssl_domains: this.fb.array([]),
    });
    if (data && data.dnssl_domains && data.dnssl_domains.length > 0) this.setdnsslval(data.dnssl_domains, formData);
    if (data && data.rdnss_servers && data.rdnss_servers.length > 0) this.setrdnssval(data.rdnss_servers, formData);
    return formData;
  }

  dnsCachingDetailsForm(data: any = {}) {
    this.connectedIPs.forEach((ip: any) => {
      let checkIn = false;
      if (data && data.connectedips && data.connectedips.length > 0) {
        data.connectedips.forEach((url: any) => {
          if (ip.url == url) {
            this.ipArray.push(url);
            checkIn = true
          }
        });
      }
      ip.enable = checkIn;
    });
    let formData = this.fb.group({ dns_servers: this.fb.array([]) });
    if (data && data.dns_servers && data.dns_servers.length > 0) this.setdnsval(data.dns_servers, formData);
    return formData;
  }

  setdnsval(arrval: any, form: any) {
    arrval.forEach((element: any, i: any) => {
      let rules = this.newDNSInfo(element);
      let control = <FormArray>form.controls.dns_servers;
      control.push(rules);
    });
  }

  setdnsslval(arrval: any, form: any) {
    arrval.forEach((element: any, i: any) => {
      let rules = this.newDNSInfo(element);
      let control = <FormArray>form.controls.dnssl_domains;
      control.push(rules);
    });
  }

  setrdnssval(arrval: any, form: any) {
    arrval.forEach((element: any, i: any) => {
      let rules = this.newDNSInfo(element);
      let control = <FormArray>form.controls.rdnss_servers;
      control.push(rules);
    });
  }

  ngOnInit(): void {
    // if(this.isEdit==true){
    if (this.selectedOption == 1) { this.addDHCP = this.createDetailsForm(this.serviceData) }
    else if (this.selectedOption == 2) { this.addDnsCaching = this.dnsCachingDetailsForm(this.serviceData) }
    else if (this.selectedOption == 3) { this.addDHCPV6 = this.dhcpv6DetailsForm(this.serviceData) }
    else if (this.selectedOption == 4) { this.addDHCPNA = this.dhcpnaDetailsForm(this.serviceData) }
    else if (this.selectedOption == 5) { this.addRouterAdv = this.routeradvDetailsForm(this.serviceData) }
    // }
  }
  get f() {
    return this.addDHCP.controls;
  }

  get connectedIp() {
    return this.addDHCP.controls['connectedip'];
  }

  get fDHCPV6() {
    return this.addDHCPV6.controls;

  }
  get fDHCPNA() {
    return this.addDHCPNA.controls;

  }
  get fRouterAdv() {
    return this.addRouterAdv.controls;

  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  onTabChange(tabNumber: any) {
    this.selectedTab = tabNumber;
  }
  changeType(e: string) {
    this.addDHCP.value.dhcp = e;
    this.cd.detectChanges()
  }

  onSave() {
    this.submitted = true;
    console.log('form status ', this.addDHCP);
    if (this.addDHCP.invalid) {
      this.addDHCP.markAllAsTouched();
      return;
    }
    let requestObj = {
      method: "POST",
      url: `bonds/${this.bondId}/dnsmasq_services/`,
      data: ''
    }
    if (this.addDHCP.value.dhcp == 'local') {
      this.body = {
        connectedip: this.addDHCP.value.connectedip,
        dhcp: this.addDHCP.value.dhcp,
        dhcp_range_start: this.addDHCP.value.dhcp_range_start,
        dhcp_range_end: this.addDHCP.value.dhcp_range_end,
        dhcp_lease_time: this.addDHCP.value.dhcp_lease_time,
        domain: this.addDHCP.value.domain,
        tftp_server: this.addDHCP.value.tftp_server,
        dns: this.addDHCP.value.dns,
        dns_server_1: this.addDHCP.value.dns_server_1,
        dns_server_2: this.addDHCP.value.dns_server_2,
        dns_server_3: this.addDHCP.value.dns_server_3,
        custom_configuration: this.addDHCP.value.custom_configuration
      }
    } else if (this.addDHCP.value.dhcp == 'relay') {
      this.body = {
        connectedip: this.addDHCP.value.connectedip,
        dhcp: this.addDHCP.value.dhcp,
        relay_host_1: this.addDHCP.value.relay_host_1,
        relay_host_2: this.addDHCP.value.relay_host_2,
        relay_host_3: this.addDHCP.value.relay_host_3,
        dns: this.addDHCP.value.dns,
        dns_server_1: this.addDHCP.value.dns_server_1,
        dns_server_2: this.addDHCP.value.dns_server_2,
        dns_server_3: this.addDHCP.value.dns_server_3,
        custom_configuration: this.addDHCP.value.custom_configuration
      }
    } else {
      this.body = {
        connectedip: this.addDHCP.value.connectedip,
        dhcp: this.addDHCP.value.dhcp,
        dns: this.addDHCP.value.dns,
        dns_server_1: this.addDHCP.value.dns_server_1,
        dns_server_2: this.addDHCP.value.dns_server_2,
        dns_server_3: this.addDHCP.value.dns_server_3,
        custom_configuration: this.addDHCP.value.custom_configuration
      }
    }
    this.body.serviceType = this.type || 'DHCP';
    this.body.type = "Services";
    this.body.bondName = localStorage.getItem('bondName');
    this.body.bondId = this.bondId;
    if (this.body?.connectedip) {
      let arr = this.connectedIPs.find((array: any) => array.url == this.body?.connectedip);
      this.body.ip = arr.ip;
    }
    requestObj.data = this.body;

    if (this.isEdit != true) {
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 201 || addRes.code == 200) this.sharedService.loggerSuccess('service created successfully');
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'DHCP' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.sharedService.hideLoader();
          Object.keys(this.errors).forEach(x => {
            let addDHCPControl = this.addDHCP.get(x);
            if (addDHCPControl) {
              this.addDHCP.setErrors({ ...this.addDHCP.errors, [x]: this.errors[x] })
            }
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    }
    else {
      let requestObj = {
        method: "PATCH",
        url: `bonds/${this.bondId}/dnsmasq_services/${this.serviceData.id}/`,
        data: this.body
      }
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess('service updated successfully');
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'DHCP' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.sharedService.hideLoader();
          Object.keys(this.errors).forEach(x => {
            let addDHCPControl = this.addDHCP.get(x);
            if (addDHCPControl) {
              this.addDHCP.setErrors({ ...this.addDHCP.errors, [x]: this.errors[x] })
            }
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    }
  }

  onSaveDHCPV6() {
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    console.log("🚀 ~ file: shared-network-service-create.component.ts ~ line 306 ~ SharedNetworkServiceCreateComponent ~ onSaveDHCPV6 ~ this.addDHCPV6.value.connectedip", this.addDHCPV6.value.connectedip)
    this.submitted = true;
    // if (this.addDHCPV6.invalid) {
    //   return;
    // }
    let requestObj = {
      method: "POST",
      url: `bonds/${this.bondId}/dhcpv6_prefix_delegation_services/`,
      data: ''
    }
    let dnsArray: any = this.addDHCPV6.controls['dns_servers'];
    let dnsData: any[] = [];
    if (dnsArray && dnsArray.controls && dnsArray.length > 0) {
      dnsArray.controls.forEach((item: any) => {
        if (item && item.controls && item.controls.dns && item.controls.dns.value) dnsData.push(item.controls.dns.value)
      });
    }
    this.body = {
      connectedip: this.addDHCPV6.value.connectedip,
      enabled: this.addDHCPV6.value.enabled,
      prefix: this.addDHCPV6.value.prefix,
      delegated_len: this.addDHCPV6.value.delegated_len,
      excluded_prefix: this.addDHCPV6.value.excluded_prefix,
      dns_servers: dnsData,
      bond: `${apiURL}bonds/${this.bondId}/`
    }
    this.body.serviceType = this.type || 'DHCPv6-PD';
    this.body.type = "Services";
    this.body.bondName = localStorage.getItem('bondName');
    this.body.bondId = this.bondId;
    if (this.body?.connectedip) {
      let arr = this.connectedIPs.find((array: any) => array.url == this.body?.connectedip);
      this.body.ip = arr.ip;
    }
    requestObj.data = this.body;
    if (this.isEdit != true) {
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 201 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'DHCPv6-PD' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.sharedService.hideLoader();
          Object.keys(this.errors).forEach(x => {
            let addDHCPV6Control = this.addDHCPV6.get(x);
            if (addDHCPV6Control) {
              this.addDHCPV6.setErrors({ ...this.addDHCPV6.errors, [x]: this.errors[x] })
            }
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    } else {
      console.log("edit")
      let requestObj = {
        method: "PATCH",
        url: `bonds/${this.bondId}/dhcpv6_prefix_delegation_services/${this.serviceData.id}/`,
        data: this.body
      }
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'DHCPv6-PD' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.sharedService.hideLoader();
          Object.keys(this.errors).forEach(x => {
            let addDHCPV6Control = this.addDHCPV6.get(x);
            if (addDHCPV6Control) {
              this.addDHCPV6.setErrors({ ...this.addDHCPV6.errors, [x]: this.errors[x] })
            }
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    }

  }

  onSaveDHCPNA() {
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    this.submitted = true;
    // if (this.addDHCPNA.invalid) {
    //   return;
    // }
    let requestObj = {
      method: "POST",
      url: `bonds/${this.bondId}/dhcpv6_non_temporary_address_services/`,
      data: ''
    }
    let dnsArray: any = this.addDHCPNA.controls['dns_servers'];
    let dnsData: any[] = [];
    if (dnsArray && dnsArray.controls && dnsArray.length > 0) {
      dnsArray.controls.forEach((item: any) => {
        if (item && item.controls && item.controls.dns && item.controls.dns.value) dnsData.push(item.controls.dns.value)
      });
    }
    this.body = {
      connectedip: this.addDHCPNA.value.connectedip,
      enabled: this.addDHCPNA.value.enabled,
      pool: this.addDHCPNA.value.pool,
      dns_servers: dnsData,
      bond: `${apiURL}bonds/${this.bondId}/`
    }
    this.body.serviceType = this.type || 'DHCPv6-NA';
    this.body.type = "Services";
    this.body.bondName = localStorage.getItem('bondName');
    this.body.bondId = this.bondId;
    if (this.body?.connectedip) {
      let arr = this.connectedIPs.find((array: any) => array.url == this.body?.connectedip);
      this.body.ip = arr.ip;
    }
    requestObj.data = this.body;
    if (this.isEdit != true) {
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'DHCPv6-NA' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          this.sharedService.hideLoader();
          Object.keys(this.errors).forEach(x => {
            let addDHCPNAControl = this.addDHCPNA.get(x);
            if (addDHCPNAControl) this.addDHCPNA.setErrors({ ...this.addDHCPNA.errors, [x]: this.errors[x] });
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    } else {
      let requestObj = {
        method: "PATCH",
        url: `bonds/${this.bondId}/dhcpv6_non_temporary_address_services/${this.serviceData.id}/`,
        data: ""
      }
      requestObj.data = this.body;
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'DHCPv6-NA' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          Object.keys(this.errors).forEach(x => {
            let addDHCPNAControl = this.addDHCPNA.get(x);
            if (addDHCPNAControl) this.addDHCPNA.setErrors({ ...this.addDHCPNA.errors, [x]: this.errors[x] });
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    }
  }

  onSaveRouterAdv() {
    let apiURL = this.cookie.get('api_url');
    if (!apiURL) return;
    this.submitted = true;
    // if (this.addDHCPNA.invalid) {
    //   return;
    // }
    let requestObj = {
      method: "POST",
      url: `bonds/${this.bondId}/router_advertisement_services/`,
      data: ''
    }
    let dnsslArray: any = this.addRouterAdv.controls['dnssl_domains'];
    let dnsslData: any[] = [];
    if (dnsslArray && dnsslArray.controls && dnsslArray.length > 0) {
      dnsslArray.controls.forEach((item: any) => {
        if (item && item.controls && item.controls.dns && item.controls.dns.value) dnsslData.push(item.controls.dns.value)
      });
    }
    let rdnssArray: any = this.addRouterAdv.controls['rdnss_servers'];
    let rdnssData: any[] = [];
    if (rdnssArray && rdnssArray.controls && rdnssArray.length > 0) {
      rdnssArray.controls.forEach((item: any) => {
        if (item && item.controls && item.controls.dns && item.controls.dns.value) rdnssData.push(item.controls.dns.value)
      })
    }
    this.body = {
      connectedip: this.addRouterAdv.value.connectedip,
      enabled: this.addRouterAdv.value.enabled,
      prefix: this.addRouterAdv.value.prefix,
      min_advertise_interval: this.addRouterAdv.value.min_advertise_interval,
      max_advertise_interval: this.addRouterAdv.value.max_advertise_interval,
      set_managed_flag: this.addRouterAdv.value.set_managed_flag,
      set_other_config_flag: this.addRouterAdv.value.set_other_config_flag,
      rdnss_lifetime: this.addRouterAdv.value.rdnss_lifetime,
      rdnss_servers: rdnssData,
      dnssl_lifetime: this.addRouterAdv.value.dnssl_lifetime,
      dnssl_domains: dnsslData,
      bond: `${apiURL}bonds/${this.bondId}/`
    }
    this.body.serviceType = this.type || 'Router advertisement';
    this.body.type = "Services";
    this.body.bondName = localStorage.getItem('bondName');
    this.body.bondId = this.bondId;
    if (this.body?.connectedip) {
      let arr = this.connectedIPs.find((array: any) => array.url == this.body?.connectedip);
      this.body.ip = arr.ip;
    }
    requestObj.data = this.body;
    if (this.isEdit != true) {
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 201 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'RouterAds' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          Object.keys(this.errors).forEach(x => {
            let addRouterAdvControl = this.addRouterAdv.get(x);
            if (addRouterAdvControl) this.addRouterAdv.setErrors({ ...this.addRouterAdv.errors, [x]: this.errors[x] });
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    } else {
      let requestObj = {
        method: "PATCH",
        url: `bonds/${this.bondId}/router_advertisement_services/${this.serviceData.id}/`,
        data: ""
      }
      requestObj.data = this.body;
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'RouterAds' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          Object.keys(this.errors).forEach(x => {
            let addRouterAdvControl = this.addRouterAdv.get(x);
            if (addRouterAdvControl) this.addRouterAdv.setErrors({ ...this.addRouterAdv.errors, [x]: this.errors[x] });
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      });
    }
  }

  onSaveDnsCaching() {
    console.log("🚀 ~ onSaveDnsCaching====================")
    let requestObj = {
      method: "POST",
      url: `bonds/${this.bondId}/dns_cache_services/`,
      data: ''
    }
    let dnsArray: any = this.addDnsCaching.controls['dns_servers'];
    let dnsData: any[] = [];
    if (dnsArray && dnsArray.controls && dnsArray.length > 0) {
      dnsArray.controls.forEach((item: any) => {
        if (item && item.controls && item.controls.dns && item.controls.dns.value) dnsData.push(item.controls.dns.value)
      });
    }

    this.body = { connectedips: this.ipArray, dns_servers: dnsData }
    this.body.serviceType = this.type || 'DNS caching service';
    this.body.type = "Services";
    this.body.bondName = localStorage.getItem('bondName');
    this.body.bondId = this.bondId;
    let tempArr: any = [];
    if (this.body?.connectedips?.length > 0) {
      this.body?.connectedips.forEach((element: any) => {
        let arr = this.connectedIPs.find((array: any) => array.url == element);
        tempArr.push(arr.ip);
      });
      this.body.ip = tempArr.toString();
    }
    requestObj.data = this.body;

    if (this.isEdit != true) {
      this.sharedService.showLoader()
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 201 || addRes.code == 200) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'dns' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          Object.keys(this.errors).forEach(x => {
            let addDnsCachingControl = this.addDnsCaching.get(x);
            if (addDnsCachingControl) this.addDnsCaching.setErrors({ ...this.addDnsCaching.errors, [x]: this.errors[x] });
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    } else {
      let requestObj = {
        method: "PATCH",
        url: `bonds/${this.bondId}/dns_cache_services/${this.serviceData.id}/`,
        data: ""
      }
      requestObj.data = this.body;
      this.PartnerBondingService.addData(requestObj).subscribe((addRes) => {
        if (addRes.code == 200 || addRes.code == 201) this.sharedService.loggerSuccess(addRes.message);
        else this.sharedService.loggerError(addRes.message);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'dns' });
      }, (err) => {
        try {
          this.errors = JSON.parse(err);
          this.sharedService.hideLoader();
          if (this.errors.non_field_errors) this.sharedService.loggerError(this.errors.non_field_errors);
          else this.sharedService.loggerError('Please correct the errors.');
          Object.keys(this.errors).forEach(x => {
            let addDnsCachingControl = this.addDnsCaching.get(x);
            if (addDnsCachingControl) this.addDnsCaching.setErrors({ ...this.addDnsCaching.errors, [x]: this.errors[x] });
          })
          this.cd.detectChanges();
        } catch (e) {
          // JSON parsing failed, assume it's a plain error message
          this.sharedService.hideLoader();
          this.sharedService.loggerError(err);
          this.cd.detectChanges();
        }
      })
    }

  }

  // createDNSOptionForm(data: any = {}) {
  //   return this.fb.group({
  //     "dns_servers": this.fb.array([])
  //   })
  // }

  get dnsServers(): FormArray {
    return this.addDHCPV6.get("dns_servers") as FormArray
  }

  get naDnsServers(): FormArray {
    return this.addDHCPNA.get("dns_servers") as FormArray
  }

  get dnsCachingServers(): FormArray {
    return this.addDnsCaching.get("dns_servers") as FormArray
  }

  get rdnssServers(): FormArray {
    return this.addRouterAdv.get("rdnss_servers") as FormArray
  }

  get dnsslServers(): FormArray {
    return this.addRouterAdv.controls["dnssl_domains"] as FormArray
  }

  onAddDNSServer() {
    this.dnsServers.controls?.push(this.newDNSInfo());
  }

  onNAAddDNSServer() {
    this.naDnsServers.controls?.push(this.newDNSInfo());
  }

  onAddDnsCachingServer() {
    this.dnsCachingServers.controls?.push(this.newDNSInfo());
  }

  onAddRdnssServer() {
    this.rdnssServers.controls?.push(this.newDNSInfo());
  }

  onAddDnsslServer() {
    this.dnsslServers.controls?.push(this.newDNSInfo());
  }

  newDNSInfo(data: any = ''): FormGroup {
    return this.fb.group({ dns: [data || ''] })
  }

  onRemove(index: any) {
    this.dnsServers.removeAt(index);
  }

  onRemoveNAServer(index: any) {
    this.naDnsServers.removeAt(index);
  }

  onRemoveDnsCachingServer(index: number) {
    this.dnsCachingServers.removeAt(index);
  }

  onRemoveRdnssServer(index: number) {
    this.rdnssServers.removeAt(index);
  }

  onRemoveDnsslServer(index: number) {
    this.dnsslServers.removeAt(index);
  }

  onConnectedIPChange(event: any, data: any) {
    if (event == true) this.ipArray.push(data.url)
    else this.ipArray = this.ipArray.filter(obj => obj !== data.url);
  }
}
