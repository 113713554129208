import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerBondingApiService } from 'src/app/services/partner-bonding-api.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { SharedAlertConfigModalComponent } from '../shared-alert-config-modal/shared-alert-config-modal.component';
import { SharedBondingApiModalComponent } from '../shared-bonding-api-modal/shared-bonding-api-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { IlluminateDataModalComponent } from '../illuminate-data-modal/illuminate-data-modal.component';

@Component({
  selector: 'app-shared-module-access-list',
  templateUrl: './shared-module-access-list.component.html',
  styleUrls: ['./shared-module-access-list.component.scss']
})
export class SharedModuleAccessListComponent implements OnInit {
  authenticationService = AuthService;
  FBModule = AppConst.moduleList.FBModule;
  illuminateModule = AppConst.moduleList.illuminateModule;
  sdwanVersion: string = environment.sdwanVersion;
  apiURL: any;
  illuminateData: any;
  settingData: any;
  isPartner: boolean = false;
  permissionsObj: { view: boolean; update: boolean; delete: boolean; };

  @Input() isLoadingArrayProd: any = [];
  @Input() isLoadingArrayDev: any = [];
  @Input() listingArray: any = [];


  @Output() changeProdSwicth = new EventEmitter<any>();
  @Output() changeDevSwicth = new EventEmitter<any>();

  constructor(private cd: ChangeDetectorRef,
    private shared: SharedService, private cookie: CookieService,
    private modalService: NgbModal,
    private bondService: PartnerBondingApiService,
    private partnerBondingService: PartnerBondingService,
  ) { }

  ngOnInit(): void {
    if (Number(localStorage.getItem('role')) == AppConst.partner) this.isPartner = true;
    this.getBondingApi();
    // this.getIlluminateData();
    // this.getSettingData();
  }

  ngOnChanges() {
    this.getPermission();
    this.cd.detectChanges();
  }

  openBondingApi() {
    let modal = this.modalService.open(SharedBondingApiModalComponent, { size: 'lg' });
    if (this.apiURL) {
      modal.componentInstance.apiInfo = this.apiURL;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getBondingApi();
      this.cd.detectChanges();
    }, () => { });
  }

  openIlluminate() {
    let modal = this.modalService.open(IlluminateDataModalComponent, { size: 'md' });
    modal.componentInstance.illuminateData = this.illuminateData;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getIlluminateData();
      this.cd.detectChanges();
    }, () => { });
  }

  openAlertConfig() {
    let modal = this.modalService.open(SharedAlertConfigModalComponent, { size: 'md' });
    if (this.settingData) modal.componentInstance.settingData = this.settingData;
  }

  getBondingApi() {
    this.shared.showLoader();
    this.bondService.getBondingApi().subscribe((apiURL: any) => {
      if (apiURL && apiURL.code == 200) {
        this.apiURL = apiURL.data;
        if (apiURL?.data?.url) this.cookie.set('api_url', apiURL?.data?.url);
        else this.cookie.delete('api_url');
      }
      this.shared.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.shared.loggerError(err);
      this.shared.hideLoader();
      this.cd.detectChanges();
    });
  }

  getIlluminateData() {
    this.shared.showLoader();
    this.partnerBondingService.getIlluminateData()?.subscribe((res: any) => {
      if (res && res.data) this.illuminateData = res.data;
      this.shared.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.shared.loggerError(err);
      this.shared.hideLoader();
      this.cd.detectChanges();
    });
  }

  getSettingData() {
    this.shared.showLoader();
    this.partnerBondingService.getSettingData()?.subscribe((res: any) => {
      if (res && res.code == 200) this.settingData = res.data;
      this.shared.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.shared.loggerError(err);
      this.shared.hideLoader();
      this.cd.detectChanges();
    });
  }

  getPermission() {
    if (Number(localStorage.getItem('role')) == AppConst.admin || Number(localStorage.getItem('role')) == AppConst.partner) {
      this.permissionsObj = { view: true, update: true, delete: true }
    } else {
      this.shared.getPermission();
      if (this.shared.noPermission) this.permissionsObj = { view: true, update: true, delete: true }
      else {
        this.permissionsObj = this.shared.generalConfiguration;
        if (!this.permissionsObj) this.permissionsObj = { view: true, update: true, delete: true }
      }
    }
    this.cd.detectChanges();
  }

  onChangeProdSwitch(event: any, module: any, i: any) {
    this.changeProdSwicth.emit({ eventName: event, moduleData: module, index: i })
  }

  onChangeDevSwitch(event: any, module: any, i: any) {
    this.changeDevSwicth.emit({ eventName: event, moduleData: module, index: i })
  }
}
