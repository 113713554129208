import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/auth';
import { PartnerGroupPermissionService } from 'src/app/services/partner-group-permission.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { FormErrorService } from 'src/app/shared/services/fromError.service';

@Component({
  selector: 'app-shared-group-management',
  templateUrl: './shared-group-management.component.html',
  styleUrls: ['./shared-group-management.component.scss']
})
export class SharedGroupManagementComponent implements OnInit {
  selectedToAdd: any;
  selectedToRemove: any;
  selectedItems: any[] = [];
  groupsArray: any[] = [];
  usersArray: any[]=[];
  addGroupForm:FormGroup;
  isNew=true;
  noUsersSelected: boolean;
  selectedUsers: any[];
  userName = AppConst.userNameVal;
  authService=AuthService;
  constructor(private partnerService:PartnerGroupPermissionService,
     private cd:ChangeDetectorRef,
     private router:Router,
     private fb:FormBuilder,
    private formErrorHandler: FormErrorService,
    ) {
    
  }
  
  @Input() isView:any;
  @Input() isEdit:any;
  @Input() groupDetails:any;
  @Input() availableUsers:any;
  @Output() submit=new EventEmitter<any>();
  @Output() onNext=new EventEmitter<any>();

  
  ngOnInit(): void {
    if(this.isView || this.isEdit){
      this.addGroupForm=this.createNewForm(this.groupDetails);
      this.selectedItems=this.groupDetails.userID;
      if(this.availableUsers){
        this.groupsArray=this.availableUsers;
      }
    }
    else{
      
      this.addGroupForm=this.createNewForm();
      this.getAllAvailableUsers();
    }
  }

  createNewForm(formValues: any = {}) {
    return this.fb.group({
      name: [formValues?.name,  Validators.compose(
        [
          Validators.required,
          Validators.pattern(this.userName),
          Validators.minLength(2),
          Validators.maxLength(50)
        ])],
      userID: [formValues?.userID],
    //  availableUser:[formValues?.availableUser]
    });
  }


  onBackCalled(){
    this.router.navigate(['/partner/user-org-management/group-permission'])
  }

  getAllAvailableUsers(){
    this.partnerService.getAllUsers().subscribe(
      (res:any)=>{
        if(res){
          if(res.data){
         this.groupsArray=res.data.filter((x:any)=>x._id!=localStorage.getItem('ID'))
          }
         // this.groupsArray=res.data;      
          this.cd.detectChanges();
        }
      }
    );
  }

  assign() {
    if(this.selectedToAdd && this.selectedToAdd.length){
      this.selectedItems = this.selectedItems.concat(this.selectedToAdd);
      this.groupsArray = this.groupsArray.filter((selectedData:any) => {
      return this.selectedItems.indexOf(selectedData) < 0;
      });
      this.selectedToAdd = [];
    }
   
     }
   
     unassign() {
       if(this.selectedToRemove && this.selectedToRemove.length){
        this.groupsArray = this.groupsArray.concat(this.selectedToRemove);
        this.selectedItems = this.selectedItems.filter((selectedData:any) => {
          return this.groupsArray.indexOf(selectedData) < 0;
        });
        this.selectedToRemove = [];
       }
    
     }

     onSubmit(){
       if(!this.isView){

      if(this.addGroupForm.invalid){
        this.addGroupForm.markAllAsTouched();
        return;
      }
   //   console.log('this.selectedItems: ', this.selectedItems);
      // if(this.selectedItems.length){
      //   this.selectedUsers=this.selectedItems.map((x:any)=> x._id)
      // }
    //  console.log('selectedItems: ', this.selectedUsers);
      let body={
        name:this.addGroupForm.get('name')?.value,
        userID:this.selectedItems
      }
      localStorage.setItem('groupName',body.name);
      localStorage.setItem('selectedUsers',JSON.stringify(body.userID));
      localStorage.setItem('isNew',this.isNew+"")
      
    }
    this.onNext.emit();
    // this.router.navigate(['/partner/user-org-management/group-permission/create'])
    }


     onCancel(){
       
     }

     isControlValid(controlName: string): boolean {
      return this.formErrorHandler.isControlValid(controlName, this.addGroupForm);
    }
  
    isControlInvalid(controlName: string): boolean {
      return this.formErrorHandler.isControlInvalid(controlName, this.addGroupForm);
    }
  
    controlHasError(validation: any, controlName: string): boolean {
      return this.formErrorHandler.controlHasError(validation, controlName, this.addGroupForm);
    }
  
    isControlTouched(controlName: string): boolean {
      return this.formErrorHandler.isControlTouched(controlName, this.addGroupForm);
    }

}
